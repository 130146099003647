import { makeStyles } from "@mui/styles";

let guideBookStyles = makeStyles((theme) => {
  return {
    downloadBtn: {
      display: "flex",
      gap: 10,
      borderRadius: 5,
      width: "100%",
    },
    goBack: {
      borderRadius: 5,
    },
    bodyContent: {
      color: theme.palette.warning.darker,
    },
    headContent: {
      color: theme.palette.warning.darker,
      fontSize: 20,
    },
  };
});

export default guideBookStyles;
