import { Paper, Typography } from "@mui/material";
import React from "react";
import { FaCheckCircle, FaTimes, FaTimesCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentDetails } from "../../redux/payment/paymentSlice";
import { getALlSubPlanThunk } from "../../redux/payment/planDetailsSlice";
import CheckoutComponent from "../Checkout/Index";
import CustomButton from "../CustomButton/Index";
import CustomNotification from "../CustomNotification/Index";

export default function PricingComponent({ classes, setBoost }) {
  const [pro, setPro] = React.useState(false);
  const [premium, setPremium] = React.useState(false);
  const [isAlert, setAlert] = React.useState(false);
  const dispatch = useDispatch();
  const userPlan = useSelector((state) => state.counter);
  const subPlans = useSelector((state) => state.subscriptionPlans);
  let basicPlan = subPlans.planList.find((p) => p.plan_name === "VA Executive");
  let proPlan = subPlans.planList.find(
    (p) => p.plan_name === "VA Professional"
  );
  let premiumPlan = subPlans.planList.find(
    (p) => p.plan_name === "VA Platinum"
  );
  React.useEffect(() => {
    dispatch(getALlSubPlanThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <CustomNotification
        open={isAlert}
        variant="filled"
        severity={"warning"}
        onClose={(e) => setAlert(false)}
        alertMessage={
          "You can't downgrade your plan. For any help email us at support@vipapplicant.com"
        }
      />
      <section className={classes.section}>
        {!(pro || premium) && (
          <Paper className={classes.paper}>
            <div>
              <p className={classes.priceHead}>
                <span>{basicPlan?.plan_name}</span>
              </p>
              <Typography variant="h6" className={classes.priceTag}>
                <text className={classes.freeText}>
                  ₹{basicPlan?.plan_price}
                </text>
                <span> - Free Forever</span>
              </Typography>

              <Typography className={classes.bulletContainer}>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Unlimited Use
                  of Resume Builder
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Make One
                  Professional Resume
                </p>

                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Download or
                  Email in PDF format
                </p>

                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Download or
                  Email limited to 1 times
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> 10 Trial AI
                  Credits
                </p>
                <p>
                  <FaTimesCircle className={classes.rejectIcon} /> AI Support to
                  Write Professional Summary
                </p>
                <p>
                  <FaTimesCircle className={classes.rejectIcon} /> AI Support to
                  Write Bullet Points
                </p>
                <p>
                  <FaTimesCircle className={classes.rejectIcon} />
                  Dynamic Suggestions Support to Optimize Bullet Points
                </p>
                <p>
                  <FaTimesCircle className={classes.rejectIcon} /> Access to
                  Default Resume Templates
                </p>
                <p>
                  <FaTimesCircle className={classes.rejectIcon} />
                  Access to Resume Guide Book
                </p>
              </Typography>
            </div>
            <CustomButton
              onClick={(e) => setAlert(true)}
              disabled={userPlan.current_plan === "VA Executive" ? true : false}
              text={
                userPlan.current_plan === "VA Executive"
                  ? "Current Plan"
                  : "Downgrade to VA Executive"
              }
              variant="contained"
              fullWidth
            />
          </Paper>
        )}
        {(!(pro || premium) || pro) && (
          <Paper className={classes.paper}>
            <div>
              <p className={classes.priceHead}>{proPlan?.plan_name}</p>
              <Typography variant="h6" className={classes.priceTag}>
                <text className={classes.paidText}>₹{proPlan?.plan_price}</text>
                <span> /{proPlan?.plan_tenure} days</span>
              </Typography>
              <Typography className={classes.bulletContainer}>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Unlimited Use
                  of Resume Builder
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Make 5
                  Professional Resume
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Download or
                  Email in PDF, Doc or Text format
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> 10 Resume
                  Download
                </p>

                <p>
                  <FaCheckCircle className={classes.checkIcon} /> 50 AI Credits
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> AI Support to
                  Write Professional Summary
                </p>

                <p>
                  <FaCheckCircle className={classes.checkIcon} /> AI Support to
                  Write Bullet Points
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Dynamic
                  Suggestions Support to Optimize Bullet Points
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Access to 5
                  Resume Templates
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Access to
                  Resume Guide Book
                </p>
                <p>
                  <FaTimesCircle className={classes.rejectIcon} /> Access to
                  Expert Session
                </p>
                <p>
                  <FaTimesCircle className={classes.rejectIcon} /> Job Matching
                  Score (Upcoming Feature)
                </p>
              </Typography>
            </div>
            {!pro ? (
              <CustomButton
                variant="contained"
                fullWidth
                disabled={
                  userPlan.current_plan === "VA Professional" ? true : false
                }
                text={
                  userPlan.current_plan === "VA Professional"
                    ? "Current Plan"
                    : userPlan.current_plan === "VA Platinum"
                    ? "Downgrade to VA Professional"
                    : "Upgrade to VA Professional"
                }
                actionCallBack={() => {
                  if (userPlan.current_plan === "VA Platinum") {
                    setAlert(true);
                  } else {
                    dispatch(
                      setPaymentDetails({
                        amount: proPlan?.plan_price,
                        discount: 0,
                        productInfo: proPlan?.plan_name,
                        planDuration: `${proPlan?.plan_tenure} Days`,
                        plan_id: proPlan?.plan_id,
                      })
                    );
                    setPro(true);
                  }
                }}
              />
            ) : (
              <CustomButton
                text={"Cancel Subscription"}
                actionCallBack={() => {
                  setPremium(false);
                  setPro(false);
                }}
                fullWidth
                icon={
                  <FaTimes
                    style={{
                      marginRight: 10,
                    }}
                  />
                }
                color="error"
                variant="contained"
              />
            )}
          </Paper>
        )}
        {(!(pro || premium) || premium) && (
          <Paper className={classes.paper}>
            <div>
              <p className={classes.priceHead}>{premiumPlan?.plan_name}</p>
              <Typography variant="h6" className={classes.priceTag}>
                <text className={classes.offerText}>₹5000</text>
                <text className={classes.paidText}>
                  ₹{premiumPlan?.plan_price}
                </text>
                <span>/{premiumPlan?.plan_tenure} days</span>
              </Typography>
              <Typography className={classes.bulletContainer}>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Unlimited Use
                  of Resume Builder
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Make 15
                  Professional Resume
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Download or
                  Email in PDF, Doc or Text format
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> 15 Resume
                  Download
                </p>

                <p>
                  <FaCheckCircle className={classes.checkIcon} /> 350 AI Credits
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> AI Support to
                  Write Professional Summary
                </p>

                <p>
                  <FaCheckCircle className={classes.checkIcon} /> AI Support to
                  Write Bullet Points
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Dynamic
                  Suggestions Support to Optimize Bullet Points
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Access to 7
                  Resume Templates
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Access to
                  Resume Guide Book
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> One 30 Mins
                  Session with an Expert Coach
                </p>
                <p>
                  <FaCheckCircle className={classes.checkIcon} /> Job Matching
                  Score (Upcoming Feature)
                </p>
              </Typography>
            </div>
            {!premium ? (
              <CustomButton
                disabled={
                  userPlan.current_plan === "VA Platinum" ? true : false
                }
                text={
                  userPlan.current_plan === "VA Platinum"
                    ? "Current Plan"
                    : "Upgrade to VA Platinum"
                }
                variant="contained"
                fullWidth
                actionCallBack={() => {
                  dispatch(
                    setPaymentDetails({
                      amount: premiumPlan?.plan_price,
                      discount: 0,
                      productInfo: premiumPlan?.plan_name,
                      planDuration: `${premiumPlan?.plan_tenure} Days`,
                      plan_id: premiumPlan?.plan_id,
                    })
                  );
                  setPremium(true);
                }}
              />
            ) : (
              <CustomButton
                text={"Cancel Subscription"}
                actionCallBack={() => {
                  setPremium(false);
                  setPro(false);
                }}
                fullWidth
                icon={
                  <FaTimes
                    style={{
                      marginRight: 10,
                    }}
                  />
                }
                color="error"
                variant="contained"
              />
            )}
          </Paper>
        )}
        {(premium || pro) && <CheckoutComponent />}
      </section>
    </>
  );
}
