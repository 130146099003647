import passive from "passive-voice";

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index]);
  }
}

const personalPronoun = [
  "I",
  "We",
  "Me",
  "You",
  "They",
  "Them",
  "Us",
  "He",
  "She",
  "His",
  "Her",
  "Him",
  "It",
];
const dynamicSuggestion = (text = "", actionVerb) => {
  const rawSplitText = text?.split("•");
  const splitText = rawSplitText.filter(function (el) {
    return !!el;
  });

  // ACTION VERB
  const checkActionVerb =
    text && text.length > 0
      ? splitText
          .filter((item) => item)
          .map((item, i) => ({
            line_no: i + 1,
            first_letter: item.trim().split(" ", 2)[0]
              ? item.split(" ", 2)[0]
                ? item.split(" ", 2)[0]
                : item.split(" ", 2)[1]
              : "".replace(/(\r\n|\n|\r)/gm, ""),
          }))
          .filter((item, i) => {
            return !actionVerb.some(
              (item2) => item2.toLowerCase() === item.first_letter.toLowerCase()
            );
          })
          .map((item, i) => item.line_no)
          .join(",")
      : "";

  // 2-4 BULLET POINTS
  const bulletLength = splitText?.length;

  // IDEAL LENGTH OF BULLET POINTS
  const checkLength =
    text && text.length > 0
      ? splitText
          .filter((item) => item)
          .map((item, i) => ({
            line_no: i + 1,
            line_length: item.split(" ").length,
          }))
          .filter((item, i) => item.line_length <= 15 || item.line_length >= 30)
          .map((item, i) => item.line_no)
          .join(",")
      : "";

  // QUANTIFICATION
  const checkNumbers =
    text && text.length > 0
      ? splitText
          .filter((item) => item)
          .map((item, i) => ({
            line_no: i + 1,
            word: item.trim().replace(/(\r\n|\n|\r)/gm, ""),
          }))
          .filter((item) => !/\d/.test(item.word))
          .map((item) => item.line_no)
          .join(",")
      : "";

  // PUNCTUATED BULLET POINTS
  const checkPunctuation =
    text && text.length > 0
      ? splitText
          .filter((item) => item)
          .map((item, i) => ({
            line_no: i + 1,
            word: item.trim().replace(/(\r\n|\n|\r)/gm, ""),
          }))
          .filter(
            (item) =>
              item.word.charAt(0) !== item.word.charAt(0).toUpperCase() ||
              item.word.charAt(item.word.length - 1) !== "."
          )
          .map((item) => item.line_no)
          .join(",")
      : "";
  // ACTIVE VOICE
  const checkPassiveVoice =
    text && text.length > 0
      ? splitText
          .filter((item) => item)
          .map((item, i) => ({
            line_no: i + 1,
            word: item.trim().replace(/(\r\n|\n|\r)/gm, ""),
          }))
          .filter((item) => passive(item.word).length > 0)
          .map((item) => item.line_no)
          .join(",")
      : "";

  // PERSONAL PRONOUNS
  const checkPersonalPronoun =
    text && text.length > 0
      ? splitText
          .filter((item) => item)
          .map((item, i) => ({
            line_no: i + 1,
            word: item
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "")
              .split(" "),
          }))
          .filter((item) =>
            item.word.some((item2) =>
              personalPronoun.some(
                (item3) => item3.toLowerCase() === item2.toLowerCase()
              )
            )
          )
          .map((item) => item.line_no)
          .join(",")
      : "";

  return {
    actionVerb: {
      status:
        !checkActionVerb && !text
          ? "empty"
          : !checkActionVerb
          ? "success"
          : checkActionVerb?.split(",").length < bulletLength
          ? "warning"
          : "danger",
      checkActionVerb,
    },
    noOfBullet: {
      status:
        bulletLength >= 2 && bulletLength <= 4
          ? "success"
          : bulletLength < 2 && bulletLength > 0
          ? "danger"
          : bulletLength > 4
          ? "warning"
          : "empty",
      bulletLength,
    },
    idealLength: {
      status:
        !checkLength && !text
          ? "empty"
          : !checkLength
          ? "success"
          : checkLength?.split(",").length < bulletLength
          ? "warning"
          : "danger",
      checkLength,
    },
    quantifiers: {
      status:
        !checkNumbers && !text
          ? "empty"
          : !checkNumbers
          ? "success"
          : checkNumbers?.split(",").length < bulletLength
          ? "warning"
          : "danger",
      checkNumbers,
    },
    punctuation: {
      status:
        !checkPunctuation && !text
          ? "empty"
          : !checkPunctuation
          ? "success"
          : checkPunctuation?.split(",").length < bulletLength
          ? "warning"
          : "danger",
      checkPunctuation,
    },
    activeVoice: {
      status:
        !checkPassiveVoice && !text
          ? "empty"
          : !checkPassiveVoice
          ? "success"
          : checkPassiveVoice?.split(",").length < bulletLength
          ? "warning"
          : "danger",
      checkPassiveVoice,
    },
    personalPronouns: {
      status:
        !checkPersonalPronoun && !text
          ? "empty"
          : !checkPersonalPronoun
          ? "success"
          : checkPersonalPronoun?.split(",").length < bulletLength
          ? "warning"
          : "danger",
      checkPersonalPronoun,
    },
  };
};

export default dynamicSuggestion;
