import { makeStyles } from "@mui/styles";

const googleSocialLoginStyles = makeStyles((theme) => ({
  btnContainer: {
    width: "100%",
    display: "flex",
    marginBottom: "3vh",
  },
  loginBtn: {
    boxShadow: `${theme.shadows[9]} !important`,
    width: "75%",
    margin: "auto",
    display: "flex !important",
    justifyContent: "center",
    borderRadius: `${theme.shape.borderRadius}px !important`,
    "& div": {
      borderRadius: `${theme.shape.borderRadius}px !important`,
    },
    "& span": {
      fontWeight: "bolder !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default googleSocialLoginStyles;
