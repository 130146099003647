import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Divider } from "@mui/material";

export default function Total({ products, currencySymbol, totalPrice }) {
  return (
    <React.Fragment>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">
              {product.name?.includes("Discount") && "(-) "}
              {product.name?.includes("GST") && "(+) "}
              {currencySymbol}
              {product.price}
            </Typography>
          </ListItem>
        ))}
        <Divider />
        <ListItem
          sx={{
            py: 1,
            px: 0,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: 700 }}>Total</Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {currencySymbol}
            {totalPrice}
          </Typography>
        </ListItem>
      </List>
    </React.Fragment>
  );
}
