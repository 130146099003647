import { makeStyles } from "@mui/styles";

const customStepperStyles = makeStyles((theme) => ({
  stepperContentBox: {
    padding: 20,
    paddingTop: 80,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 70,
      padding: 5,
    },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      padding: "4px 15px",
    },
  },
  btnContainer: {
    // background: theme.palette.primary.main,
    paddingTop: 20,
    display: "flex",
    flexDirection: "row",
    pt: 2,
    justifyContent: "center",
  },
  navNext: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 10,
      right: 10,
    },
  },
  navBack: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 10,
      left: 10,
    },
  },
}));

export default customStepperStyles;
