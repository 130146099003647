import { Divider, Grid, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import BLOG3 from "../../assets/HOW-MAKING-A-RESUME-ONLINE-WITH-VIPAPPLICANT-IS-MUCH-EASIER-IN-2022.avif";
import BLOG2 from "../../assets/PERFECT-YOUR-RESUME-FORMAT-WITH-3-STEPS-TODAY.avif";
import BLOG1 from "../../assets/WRITE-AN-IMPRESSIVE-PROFESSIONAL-SUMMARY-AND-GET-YOUR-DREAM-JOB-IN-2022.avif";
import CustomCard from "../../components/CustomCard/Index";
import BottomNavigation from "../../layouts/bottomNavigation";
import DashboardSidebar from "../../layouts/index";
import useStyles from "../../styles/pages/Resources/index.style";
import shorten from "../../utils/shorten";
import GuideBook from "./components/guidebook";

export default function ResourcePage() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <DashboardSidebar />
      <div className={classes.contentDiv}>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            Guide
          </Typography>
        </div>

        <Divider />
        <Grid spacing={2} pb={4} container className={classes.containerGrid}>
          <Grid item sm={4} xs={12} className={classes.gridItem}>
            {/* <GuideButton /> */}
            <GuideBook />
          </Grid>
        </Grid>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            Blogs
          </Typography>
          <a
            text={""}
            variant="contained"
            component="a"
            href="https://vipapplicant.com/blog/"
            target={"_blank"}
            rel="noreferrer"
            className="read-blog-btn"
          >
            Read More Blogs <FaExternalLinkAlt />
          </a>
        </div>
        <Divider />
        <Grid spacing={2} container className={classes.containerGrid}>
          <Grid item sm={4} xs={12} className={classes.gridItem}>
            <a
              className={classNames(classes.aTag, "uap_resource_blog_1")}
              href="https://vipapplicant.com/blog/3-mistakes-to-avoid-for-job-resume-format-creation-in-2022/"
            >
              <CustomCard
                actionContent={
                  <img
                    height={200}
                    width={"100%"}
                    alt="not found"
                    src={BLOG1}
                  />
                }
                bodyContent={
                  <>
                    <Typography
                      variant="h5"
                      className={classes.blogHead}
                      color={"#CB7401"}
                    >
                      PERFECT YOUR RESUME FORMAT WITH 3 STEPS TODAY!
                    </Typography>
                    {shorten(
                      "You could be a perfect candidate for a job, but without a perfect resume, you may not get close to giving it your best shot. Employers may use advanced technology or new methods to recruit candidates, but the value of a good-old resume remains undeterred. While making a resume, it is not just your choice of words that matter. The way you present the information and select the criteria for job resume format also makes a difference.",
                      200
                    )}
                  </>
                }
              />
            </a>
          </Grid>
          <Grid item sm={4} xs={12} className={classes.gridItem}>
            <a
              className={classNames(classes.aTag, "uap_resource_blog_2")}
              href="https://vipapplicant.com/blog/write-an-impressive-professional-summary-and-get-your-dream-job-in-2022/"
            >
              <CustomCard
                actionContent={
                  <img
                    height={200}
                    width={"100%"}
                    alt="not found"
                    src={BLOG2}
                  />
                }
                bodyContent={
                  <>
                    <Typography
                      variant="h5"
                      className={classes.blogHead}
                      color={"#CB7401"}
                    >
                      WRITE AN IMPRESSIVE PROFESSIONAL SUMMARY AND GET YOUR
                      DREAM JOB IN 2022
                    </Typography>
                    {shorten(
                      "A resume without a professional summary is like a song without a hook. Who better than a millennial like yourself can understand this analogy? Just like a hook is the catchy part of a song and decides a song’s success, an attractive professional summary does the same thing for your resume.",
                      200
                    )}
                  </>
                }
              />
            </a>
          </Grid>
          <Grid item sm={4} xs={12} className={classes.gridItem}>
            <a
              className={classNames(classes.aTag, "uap_resource_blog_3")}
              href="https://vipapplicant.com/blog/3-mistakes-to-avoid-for-job-resume-format-creation-in-2022/"
            >
              <CustomCard
                actionContent={
                  <img
                    height={200}
                    width={"100%"}
                    alt="not found"
                    src={BLOG3}
                  />
                }
                bodyContent={
                  <>
                    <Typography
                      variant="h5"
                      className={classes.blogHead}
                      color={"#CB7401"}
                    >
                      HOW MAKING A RESUME ONLINE WITH VIPAPPLICANT IS MUCH
                      EASIER IN 2022
                    </Typography>
                    {shorten(
                      "With a new year comes the hope of new beginnings. If you wish to start your professional journey in 2022 or are looking for a job change, you may want to know that making a resume online is now just a cakewalk. Don’t believe us? Hang on and read ahead.",
                      200
                    )}
                  </>
                }
              />
            </a>
          </Grid>
        </Grid>
      </div>

      <BottomNavigation value={3} />
    </div>
  );
}
