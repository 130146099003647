const manageSpacing = (id, type) => {
  if (type === "inc") {
    document.getElementById(id).style.marginTop = !parseInt(
      document.getElementById(id).style.marginTop?.split("%")[0]
    )
      ? `1%`
      : `${
          parseInt(document.getElementById(id).style.marginTop?.split("%")[0]) +
          1
        }%`;
  } else {
    document.getElementById(id).style.marginTop = !parseInt(
      document.getElementById(id).style.marginTop?.split("%")[0]
    )
      ? `-1%`
      : `${
          parseInt(document.getElementById(id).style.marginTop?.split("%")[0]) -
          1
        }%`;
  }
};

export default manageSpacing;
