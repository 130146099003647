import { makeStyles } from "@mui/styles";

let resumeCheckStyles = makeStyles((theme) => ({
  container: {
    padding: "20px 10px",
    paddingTop: "5px",
    overflow: "auto",
    boxShadow: theme.customShadows.primary,
  },
  typography: {
    marginBottom: 20,
    textTransform: "uppercase",
    color: theme.palette.primary.main,
  },
  p: {
    display: "flex",
    gap: 15,
    padding: "2px 10px",
    borderRadius: theme.shape.borderRadiusSm,
  },
  icon: {
    margin: "auto 0px",
    color: theme.palette.success.dark,
    textShadow: theme.customShadows.success,
    fontSize: "large",
  },
  errIcon: {
    margin: "auto 0px",
    color: theme.palette.error.dark,
    textShadow: theme.customShadows.error,
    fontSize: "large",
  },
  warnIcon: {
    margin: "auto 0px",
    color: theme.palette.warning.dark,
    textShadow: theme.customShadows.warning,
    fontSize: "large",
  },
}));

export default resumeCheckStyles;
