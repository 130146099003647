
import { AppBar, IconButton, Toolbar } from "@mui/material";
// material
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { FaBars } from "react-icons/fa";
// components
import { MHidden } from "../components/@material-extend";
//

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  display: "flex",
  alignItems: "flex-start",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    display: "none",
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <RootStyle> 
      <ToolbarStyle><MHidden width="lgUp">
    <IconButton
      onClick={onOpenSidebar}
      sx={{ mr: 1, color: theme => theme.palette.warning.main }}
    >
      <FaBars />
    </IconButton>
  </MHidden></ToolbarStyle>
    </RootStyle>
  );
}
