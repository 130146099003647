import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CircularProgress, Stack, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import CustomCard from "../../../components/CustomCard/Index";
import CustomIconButton from "../../../components/CustomIcon/Index";
import CustomInputField from "../../../components/CustomInputField/Index";
import CustomModal from "../../../components/CustomModal/Index";
import CustomProgress from "../../../components/CustomProgress";
import EmptyPreview from "../../../components/EmptyPreview/Index";
import {
  deleteResumeThunk,
  fetchResumeByUserID,
  updateResumeThunk,
} from "../../../redux/Dashboard/dashboard.slice";
import useStyles from "../../../styles/pages/Dashboard/index.style";
import { formatNumber } from "../../../utils/contentFormatter";
import {
  getDateStringWithFormat,
  sortArrayWithDate,
} from "../../../utils/dateFormatter";
import shorten from "../../../utils/shorten";

export default function DashboardContent({ styles }) {
  const classes = useStyles();
  const [type, setType] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [resume_id, setResumeId] = React.useState("");
  const navigate = useNavigate();

  const dashboard = useSelector((state) => state.dashboard);
  const userPlan = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  const handleUpdateResume = (token, resume_id, resume_title) => {
    dispatch(
      updateResumeThunk({
        token,
        resume_id,
        resume_title,
        resume_updated_at: "now",
        resumeList: dashboard.resumes,
      })
    );
    setType("");
    setTitle("");
  };

  const creationLimit = userPlan.user_plan_limits.find(
    (p) => p.feature_name === "resume_creation"
  )?.usage_limit;

  const handleDeleteResume = (token, resume_id) => {
    dispatch(
      deleteResumeThunk({ token, resume_id, resumeList: dashboard.resumes })
    );
    setType("");
  };

  React.useEffect(() => {
    dispatch(fetchResumeByUserID(localStorage.getItem("token")));
  }, [dispatch]);

  if (dashboard.loading)
    return (
      <div className={classes.loader}>
        <CircularProgress disableShrink size={30} thickness={6} />
      </div>
    );

  if (dashboard.resumes.length === 0)
    return (
      <div className={classes.emptyView}>
        <EmptyPreview />
        <Typography variant="h3" color="#c6c6c6">
          Start Adding Your Resumes
        </Typography>
      </div>
    );
  return (
    <>
      <Typography
        variant="body"
        color={"primary"}
        className={classes.cardTitle}
      >
        My Resumes ({dashboard.resumes.length} / {creationLimit})
      </Typography>
      <Typography variant="caption" color="" className={classes.cardSubTitle}>
        {userPlan?.current_plan === "VA Executive"
          ? `This plan allows you to
        create ${formatNumber(creationLimit)} resume.`
          : `Congratulations! You can
        make ${formatNumber(creationLimit)} resumes with your plan.`}
      </Typography>
      <div className={classes.cardContainer}>
        <CustomModal
          isOpen={!!type}
          setOpen={setType}
          cancelBtnClass={
            type === "edit"
              ? "uap_dashboard_title_edit_cancel_btn"
              : "my_resume_delete_btn"
          }
          confirmBtnClass={
            type === "edit"
              ? "uap_dashboard_title_edit_btn"
              : "uap_dashboard_title_delete_btn"
          }
          confirmBtnColor={type === "edit" ? "primary" : "error"}
          confirmBtnText={type === "edit" ? "Update" : "Delete"}
          cancelBtnColor={type === "edit" ? "warning" : "primary"}
          cancelBtnText={"Cancel"}
          btnDisable={type === "edit" ? !title : false}
          actionCallback={
            type === "edit"
              ? (e) =>
                  handleUpdateResume(
                    localStorage.getItem("token"),
                    resume_id,
                    title
                  )
              : (e) =>
                  handleDeleteResume(localStorage.getItem("token"), resume_id)
          }
          dialogContentText={
            type === "edit"
              ? "Please provide a title for your resume"
              : "Please note the the action will delete the resume permanently."
          }
          dialogContent={
            type === "edit" ? (
              <CustomInputField
                autoFocus
                fullWidth
                label={"Title"}
                value={title}
                onChange={(e) => {
                  if (e.target.value.length <= 20) {
                    setTitle(e.target.value);
                  }
                }}
                helperText={"More than 20 character is not accepted"}
                className={classNames(
                  "uap_dashboard_resume_title_input",
                  classes.titleInput
                )}
              />
            ) : (
              ""
            )
          }
          dialogTitle={
            type === "edit"
              ? "Edit Resume Title"
              : "Are you sure you want to delete?"
          }
        />
        {sortArrayWithDate(dashboard.resumes, "resume_created_at", true).map(
          (a, idx) => (
            <Fade right key={idx}>
              <CustomCard
                key={idx}
                className={classNames(`my_resume_${idx}`)}
                variant={RouterLink}
                path={`${a.resume_id}/create`}
                actionStyle={styles.actionStyle}
                bodyStyle={styles.bodyStyle}
                style={styles.style}
                footerTitle={`Resume (${
                  [
                    a.completion_pd,
                    a.completion_ed,
                    a.completion_ex,
                    a.completion_sk,
                    a.completion_ac,
                    a.completion_xt,
                  ].filter((com) => !!com).length
                }/6)`}
                // footerSubtitle={<DashboardPopOver title={"Know more ...."} />}
                footerContent={[
                  a.completion_pd,
                  a.completion_ed,
                  a.completion_ex,
                  a.completion_sk,
                  a.completion_ac,
                  a.completion_xt,
                ]}
                actionContent={
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    width="100%"
                    className={classes.stack}
                  >
                    <CustomIconButton
                      tooltipContent="Preview resume"
                      onClick={(e) =>
                        navigate(`/dashboard/${a.resume_id}/preview`)
                      }
                      icon={<VisibilityIcon fontSize="small" />}
                      aria-label="preview"
                      className="my_resume_preview"
                    />
                    <div>
                      <Typography variant="caption">Edit</Typography>
                      <CustomIconButton
                        onClick={(e) => {
                          navigate(`/dashboard/${a.resume_id}/create`);
                        }}
                        tooltipContent="edit resume"
                        icon={<EditIcon fontSize="small" />}
                        aria-label="preview"
                        className="my_resume_main_card"
                      />
                      <CustomIconButton
                        onClick={(e) => {
                          setType("delete");
                          setResumeId(a.resume_id);
                        }}
                        tooltipContent="delete resume"
                        icon={<DeleteIcon fontSize="small" />}
                        aria-label="preview"
                        className="my_resume_delete"
                      />
                    </div>
                  </Stack>
                }
                title={
                  <>
                    {shorten(a.resume_title, 10)}{" "}
                    <CustomIconButton
                      size={"small"}
                      placement={"top"}
                      onClick={(e) => {
                        setType("edit");
                        setResumeId(a.resume_id);
                        setTitle(a.resume_title);
                      }}
                      tooltipContent="edit title"
                      icon={<FaEdit fontSize="smaller" />}
                      aria-label="preview"
                      className="my_resume_edit_title"
                    />
                  </>
                }
                subtitle={getDateStringWithFormat(
                  a.resume_updated_at,
                  "YYYY-DD-MM- MM:SS"
                )}
                contentToolTip={true}
                tooltipPlacement={"top"}
                bodyContent={
                  <Stack direction={"row"} gap={3}>
                    {/* <CustomProgress text={"Progress"} completion={a.resume_status_percent}/> */}
                    <CustomProgress
                      text={"Score"}
                      completion={a.sc_api_overall}
                    />
                  </Stack>
                }
              />
            </Fade>
          )
        )}
      </div>
    </>
  );
}
