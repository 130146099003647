import { Divider } from "@mui/material";
import React from "react";
import ScoreModalContentStyle from "../../../styles/pages/TemplatePreview/scoreModalContent.style";
import Resume from "../../Resource/data/guide.pdf";

export default function ScoreModalContent({ score, inputData }) {
  const classes = ScoreModalContentStyle();
  const showScoreFeedback = (score) => {
    if (parseInt(score) < 30) {
      return (
        <>
          <p>
            Below Average! It’s possible that your resume lacks basic
            information. Please check the following key points and work on them
            to improve your score. Do read the feedback for each section. You
            will learn additional information to improve your resume.
          </p>
          <p>
            <b>Complete all sections:</b>Complete all sections: There are 6
            sections in a good resume. An additional profile section provides
            necessary information. Please ensure that you have filled in all of
            them to the best possible extent. Download our Resume Guidebook to
            understand each of these sections better.
          </p>
          <p>
            <b>Professional Summary:</b>The first few words of your resume are
            critically important to grab the attention of the hiring manager.
            Write a rich, brief elevator pitch that says who you are, why you’re
            qualified for the job, and why you’re the right person to hire. Our
            AI generator tool enables you to write a strong summary as per your
            unique profile.
          </p>
        </>
      );
    } else if (score > 30 && score < 50) {
      return (
        <>
          <p>
            Almost there! It’s possible that your resume is not providing all
            the information. Please check the following key points and work on
            them to improve your score. Do read the feedback for each section.,
            you will learn additional information to improve your resume.
          </p>
          <p>
            <b>Complete all sections:</b>There are 6 sections in making a good
            resume and an additional profile section to provide necessary
            information. Please ensure that you have filled in all sections to
            the best possible extent.
          </p>
          <p>
            <b>Professional Summary:</b>The first few words of your resume are
            critically important because that’s how long you usually have a
            hiring manager’s attention. Write a rich, brief elevator pitch that
            says who you are, why you’re qualified for the job, and why you’re
            the right person to hire. Write a strong summary with our AI text
            generator tool and modify the output to suit your profile.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>
            Good job! Your resume covers all the necessary points that an
            employer needs to see. However, you can further polish it and make
            it much better and get to the top of the resume pile. Please check
            the following key points and work on them to improve your score. Do
            read the feedback for each section, you will learn additional
            information to improve your resume.
          </p>
          <p>
            <b>Complete all sections:</b>There are 6 sections in making a good
            resume and an additional profile section to provide necessary
            information. Please ensure that you have filled in all sections to
            the best possible extent.
          </p>
          <p>
            <b>Professional Summary:</b>The first few words of your resume are
            critically important because that’s how long you usually have a
            hiring manager’s attention. Write a rich, brief elevator pitch that
            says who you are, why you’re qualified for the job, and why you’re
            the right person to hire. Write a strong summary with our AI text
            generator tool and modify the output to suit your profile.
          </p>
        </>
      );
    }
  };
  const scorePropertyCheck = (property) => {
    switch (property) {
      case "IMPACT":
        return 30;
      case "INITIATIVE":
        return 10;
      case "SKILLS":
        return 25;
      case "ACADEMIC":
        return 20;
      case "EXPERIENCES":
        return 15;
      default:
        break;
    }
  };
  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }
  return (
    <div className={classes.container}>
      <div className={classes.section1}>
        <p className={classes.p}>How to Read Your Resume Evaluation Score?</p>
        <p
          style={{
            textAlign: "left",
          }}
        >
          We have run over fifteen essential checks on your resume and have
          grouped them into five categories: Impact, Skills, Academics,
          Experience and Accomplishments.{" "}
        </p>
        {/* Section1 --> Sub content */}
        <div className="subSection">
          {/* <p>
            Let’s understand the key aspect of each category and its relevance
            relevance to the recruiters
          </p> */}
          <span>
            <b>Impact: </b>How impressive is your resume in terms of your
            actions and accomplishments?
          </span>
          <span>
            <b>Skills: </b>How does your resume match with the skills needed for
            the job description? How have you outlined the relevant skills in
            your resumes?
          </span>
          <span>
            <b>Academic Achievements: </b>How strong is your academic foundation
            and consistency?
          </span>
          <span>
            <b>Experience: </b>How clearly outlined your work experience is?
            Does it show your competencies in a well-articulated manner?
          </span>
          <span>
            <b>Accomplishments: </b>How willing and enthusiastic are you to try
            diverse things in life?
          </span>
        </div>
      </div>
      <Divider />
      {/* Section 2  --> Feedback */}
      <div className={classes.section2}>
        <p>
          <b>Feedback: </b>If you are not happy with the report and have some
          feedback for us, please write to support@vipapplicant.com and we will
          get in touch with you to understand your views.
        </p>
        <p>
          <b>Resume Guidebook: </b>Additional Support: You can use our Resume
          Guidebook to get detailed support with examples to deepen your
          knowledge.{" "}
          <span className={classes.link}>
            <a href={Resume} download="Resume_guidebook_VIPApplicant.pdf">
              Please download it from here.
            </a>
          </span>
        </p>
      </div>
      <Divider />
      <div className={classes.section2}>
        <p className={classes.p}>
          How to improve your Resume Evaluation Score?
        </p>
        <p>
          The feedback is broken into two segments. Our suggestions help you
          make a better resume and enable the employer to quickly understand the
          highlights of your professional journey.
        </p>
        <br />
        <p>
          <b className={classes.partHead}>PART 1: Score Feedback:</b>{" "}
        </p>
        {showScoreFeedback(score.overallscore)}
        <br />
        <p>
          <b className={classes.partHead}>
            PART 2: Individual Section Feedback:
          </b>{" "}
        </p>
        {percentage(
          score?.projectinternship,
          scorePropertyCheck("EXPERIENCES")
        ) <= 50 && (
          <p>
            <b>Experience: </b>
            <p>
              Make sure you add details to the experience section. Even if you
              are a student, you can highlight your projects, internships, extra
              curriculars, etc. to demonstrate your capabilities and give an
              overview to the employers. Give details of Projects/Internships
              and Accomplishments outside the classroom, including the
              timelines. They are valuable work experiences that provide you
              with on-the-job training for your career. They also show the
              hiring managers what you are capable of. It is important to know
              how to add your internships/projects to your resume so you can
              distinguish yourself from other candidates. Also, Please mention
              the
            </p>
            links if have published any papers or hosted projects on GITHUB{" "}
            <br /> <br /> Give details of your experience in reverse
            chronological order giving details of time period.
          </p>
        )}

        {percentage(score?.academic, scorePropertyCheck("ACADEMIC")) <= 50 && (
          <p>
            <b>Academics: </b> <br />
            Please mention your academic journey in a reverse chronological
            order. For a better overview of your candidature, you need to
            provide your last two qualifications. Example: if you are an MBA
            professional, please give your MBA and Bachelor degree details. And,
            if you are a Bachelor Degree student, give details of your last
            semester and high school graduation information.
          </p>
        )}

        {percentage(score?.skills, scorePropertyCheck("SKILLS")) <= 50 && (
          <p>
            <b>Skills: </b> <br />
            As you carefully craft a resume that’s tailored to the job role,
            know that the way you report your skills for a job can determine how
            far you advance in the hiring process. If you want your resume to
            show you have what it takes to justify an interview, you need to
            show off your job skills. They can differentiate you from the
            competition to get you the position you want. <br /> <br />
            You have added a few skills in your resume. Try adding a few more
            that are relevant to the job role. Remember it is just not about the
            number of skills you add in your resume, it is also important to
            substantiate your skills with the experience of using it. You can
            try adding the skills even in the experience section while
            describing your responsibilities and achievements.
          </p>
        )}

        {percentage(score?.inititaive, scorePropertyCheck("INITIATIVE")) <=
          50 && (
          <p>
            <b>Achievement: </b> <br />
            Employers often receive dozens to hundreds of applications for a
            single open position. Having awards and accomplishments on your
            resume is one of the ways tomake your application stand out. Add the
            list of awards you have received with a brief description about why
            you have received the award. Also, add any relevant publications and
            certification you have. It shows that you have some in depth
            knowledge about a few aspects and also you have taken initiative to
            learn and grow.
          </p>
        )}
        {percentage(score?.impact, scorePropertyCheck("IMPACT")) <= 50 && (
          <p>
            <b>Impact: </b> <br />
            What does the first impression of your resume look like? Does it
            make the life of the evaluator easy or do they need to struggle to
            glean information from your resume? You need to make it easy for the
            reviewer to quickly get the desired information. Please see the
            following 5 points to improve the impact part of the resume.
            <br />
            <br />
            <ol
              style={{
                paddingLeft: "30px",
              }}
            >
              <li>Complete all six sections of the resume.</li>
              <li>
                Provide a concise well-articulated professional summary. Use our
                AI to help you craft an industry standard summary.
              </li>
              <li>Provide clearly delineated sections.</li>
              <li>
                Avoid fancy formats that are not ATS (Application Tracking
                System) friendly.
              </li>
              <li>Information needs to be in reverse chronological order.</li>
            </ol>
            <br />
            <br />
            <b>Write Strong Bullet Point:</b> It’s one of the key sections of
            the resume. Follow our formula - VPN (Verb, Project, Number). Start
            with a strong verb, give project/work details, and quantify your
            contribution.
            <br />
            <br />
            <ul
              style={{
                paddingLeft: "30px",
              }}
            >
              <li>
                Action verbs are words that express an action in a resume,
                action verbs are used to highlight your skills, experience and
                accomplishments. Using action words that are unique and powerful
                can increase your chances of capturing an employer's attention
                and moving to the next step in the hiring process.{" "}
              </li>
              <li>
                Numbers make such a huge difference in resumes . To polish your
                resume, add a few numbers to quantify your experience and
                accomplishments to showcase the impact you’re capable of making.
              </li>
            </ul>
            <br />
            <br />
            {/* To make the process easy for you, we have incorporated real-time
            feedback for each bullet point
            <br />
            <br />
            <img src={ImpactSuggestion} alt="not found" width={"80%"} />
            <br />
            <br />
            <img src={ImpactSuggestion2} alt="not found" width={"80%"} /> */}
          </p>
        )}
      </div>
    </div>
  );
}
