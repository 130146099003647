import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endPoints from "../../config/endPoint";

const initialState = {
  certifications: [],
  publications: [],
  achievements: [],
};

export const getAllDataThunk = createAsyncThunk(
  "achievement/getAllData",
  async ({ token, resumeId }, thunkAPI) => {
    let data1 = await axios.post(`${endPoints.getCertifications}`, {
      token,
      resumeId,
    });
    let data2 = await axios.post(`${endPoints.getPublications}`, {
      token,
      resumeId,
    });
    let data3 = await axios.post(`${endPoints.getAchievements}`, {
      token,
      resumeId,
    });
    return {
      certifications: data1.data.results,
      publications: data2.data.results,
      achievements: data3.data.results,
    };
  }
);

export const updateCertificationThunk = createAsyncThunk(
  "achievement/updateCertification",
  async ({ body, token }, thunkAPI) => {
    const state = thunkAPI.getState().achievement.certifications;
    let data = await axios.patch(`${endPoints.updateCertification}`, {
      ...body,
      token,
      cf_updated_at: "now",
    });
    return state.map((resume) => {
      if (resume.cf_id === body.cf_id) {
        return data.data.results[0];
      } else {
        return resume;
      }
    });
  }
);

export const createCertificationThunk = createAsyncThunk(
  "achievement/createCertification",
  async (body, thunkAPI) => {
    let data = await axios.post(`${endPoints.createCertification}`, {
      ...body,
      cf_created_at: "now",
      cf_updated_at: "now",
    });
    return data.data.results[0];
  }
);

export const deleteCertificationThunk = createAsyncThunk(
  "achievement/deleteCertification",
  async ({ token, resume_id, cf_id }, thunkAPI) => {
    const state = thunkAPI.getState().achievement;
    await axios.delete(`${endPoints.deleteCertification}`, {
      data: {
        token,
        resume_id,
        cf_id: cf_id,
        del_flag: 1,
        cf_updated_at: "now",
      },
    });
    return state.certifications.filter((resume) => resume.cf_id !== cf_id);
  }
);

export const updatePublicationThunk = createAsyncThunk(
  "achievement/updatePublication",
  async ({ body, token }, thunkAPI) => {
    const state = thunkAPI.getState().achievement.publications;
    let data = await axios.patch(`${endPoints.updatePublication}`, {
      ...body,
      token,
      pb_updated_at: "now",
    });
    return state.map((resume) => {
      if (resume.pb_id === body.pb_id) {
        return data.data.results[0];
      } else {
        return resume;
      }
    });
  }
);

export const createPublicationThunk = createAsyncThunk(
  "achievement/createPublication",
  async (body, thunkAPI) => {
    let data = await axios.post(`${endPoints.createPublication}`, {
      ...body,
      pb_updated_at: "now",
      pb_created_at: "now",
    });
    return data.data.results[0];
  }
);

export const deletePublicationThunk = createAsyncThunk(
  "achievement/deletePublication",
  async ({ token, resume_id, pb_id }, thunkAPI) => {
    const state = thunkAPI.getState().achievement;
    await axios.delete(`${endPoints.deletePublication}`, {
      data: {
        token,
        resume_id: resume_id,
        pb_id: pb_id,
        del_flag: 1,
        pb_updated_at: "now",
      },
    });
    return state.publications.filter((resume) => resume.pb_id !== pb_id);
  }
);

export const updateAchievementThunk = createAsyncThunk(
  "achievement/updateAchievement",
  async ({ body, token }, thunkAPI) => {
    const state = thunkAPI.getState().achievement.achievements;
    let data = await axios.patch(`${endPoints.updateAchievement}`, {
      ...body,
      ac_year: new Date(body.ac_year).toISOString(),
      token,
      ac_updated_at: "now",
    });
    return state.map((resume) => {
      if (resume.ac_id === body.ac_id) {
        return data.data.results[0];
      } else {
        return resume;
      }
    });
  }
);

export const createAchievementThunk = createAsyncThunk(
  "achievement/createAchievement",
  async (body, thunkAPI) => {
    let data = await axios.post(`${endPoints.createAchievement}`, {
      ...body,
      ac_year: new Date(body.ac_year).toISOString(),
      ac_updated_at: "now",
      ac_created_at: "now",
    });
    return data.data.results[0];
  }
);

export const deleteAchievementThunk = createAsyncThunk(
  "achievement/deleteAchievement",
  async ({ token, resume_id, ac_id }, thunkAPI) => {
    const state = thunkAPI.getState().achievement;
    await axios.delete(`${endPoints.deleteAchievement}`, {
      data: {
        token,
        resume_id,
        ac_id: ac_id,
        del_flag: 1,
        ac_updated_at: "now",
      },
    });
    return state.achievements.filter((resume) => resume.ac_id !== ac_id);
  }
);

export const achievementSlice = createSlice({
  name: "achievement",
  initialState,
  reducers: {
    test: (state, { payload }) => {
      state.personal.firstname = "Sidharth";
    },
  },
  extraReducers: {
    [getAllDataThunk.pending]: (state) => {
      state.loading = true;
    },
    [getAllDataThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.certifications = payload.certifications;
      state.publications = payload.publications;
      state.achievements = payload.achievements;
    },
    [getAllDataThunk.rejected]: (state) => {
      state.loading = false;
    },

    [deleteCertificationThunk.pending]: (state) => {
      state.loading = true;
    },
    [deleteCertificationThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.certifications = payload;
    },
    [deleteCertificationThunk.rejected]: (state) => {
      state.loading = false;
    },

    [updateCertificationThunk.pending]: (state) => {
      state.loading = true;
    },
    [updateCertificationThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.certifications = payload;
    },
    [updateCertificationThunk.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [createCertificationThunk.pending]: (state) => {
      state.loading = true;
    },
    [createCertificationThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.certifications = [...state.certifications, payload];
    },
    [createCertificationThunk.rejected]: (state) => {
      state.loading = false;
    },

    [deletePublicationThunk.pending]: (state) => {
      state.loading = true;
    },
    [deletePublicationThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.publications = payload;
    },
    [deletePublicationThunk.rejected]: (state) => {
      state.loading = false;
    },

    [updatePublicationThunk.pending]: (state) => {
      state.loading = true;
    },
    [updatePublicationThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.publications = payload;
    },
    [updatePublicationThunk.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [createPublicationThunk.pending]: (state) => {
      state.loading = true;
    },
    [createPublicationThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.publications = [...state.publications, payload];
    },
    [createPublicationThunk.rejected]: (state) => {
      state.loading = false;
    },
    // -----------------------------

    [deleteAchievementThunk.pending]: (state) => {
      state.loading = true;
    },
    [deleteAchievementThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.achievements = payload;
    },
    [deleteAchievementThunk.rejected]: (state) => {
      state.loading = false;
    },

    [updateAchievementThunk.pending]: (state) => {
      state.loading = true;
    },
    [updateAchievementThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.achievements = payload;
    },
    [updateAchievementThunk.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [createAchievementThunk.pending]: (state) => {
      state.loading = true;
    },
    [createAchievementThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.achievements = [...state.achievements, payload];
    },
    [createAchievementThunk.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { test } = achievementSlice.actions;

export default achievementSlice.reducer;
