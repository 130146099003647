import { makeStyles } from "@mui/styles";

const customModalStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 700,
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
    },
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.warning.main,
  },
}));

export default customModalStyles;
