import { Box, Grid, Paper } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React from 'react';
import "./components/style.css";
// import viplogo from "../images/vipLogo2.png"
import viplogo from "../../assets/vipLogo.svg";
import DTUFieldComponent from './components/main';


const useStyles = makeStyles((theme) => ({
container: { 
    flexGrow: 1, 
    padding: "50px 50px", 
    background: "#f1f1f1", 
    [theme.breakpoints.down("sm")]: {
    padding: 10
    }
}
}))

const DTUPage = ({ setIsAuth, addMobileNo, adduserId, isAuthenticated}) => {
    const classes = useStyles()
    return (
        <>
            <Grid container spacing={0} p={1} height="100vh" style={{
                // background: "url(https://images.pexels.com/photos/373883/pexels-photo-373883.jpeg)",
                backgroundSize: "cover",
                overflow: 'auto'
            }}>
                <Grid item xs={12} sm={12} md={12}>
                    <a href='https://vipapplicant.com' rel="noreferrer" target="_blank" style={{ textDecoration: "none", }}>
                        {/* <Button>VIP APPLICANT</Button> */}
                        <img src={viplogo} className="Vip-logo-2" alt="logo" style={{
                            margin: "auto",
                            marginBottom: "20px"
                        }}/>
                    </a>
                </Grid>
                <Grid item xs={12} sm={12} md={6} style={{
                    margin: "0px auto"
                }}>
                    <Box component={Paper} className={classes.container}>
                        <DTUFieldComponent  setIsAuth={setIsAuth}
                        isAuthenticated={isAuthenticated}
                        addMobileNo={addMobileNo}
                        adduserId={adduserId}/>
                    </Box>
                </Grid>
            </Grid>
            
        </>
    )
}

export default DTUPage
