import { makeStyles } from "@mui/styles";

let DashboardHeaderStyles = makeStyles((theme) => ({
  h3: {
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  modalDialogContainer: {
    display: "flex",
    justifyContent: "space-around",
    "& #section-2": {
      marginTop: 20,
    },
    "& #section-1": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .modal-image": {
      boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.37)",
      transform: "rotate(-15deg)",
      position: "absolute",
    },
  },
  btnContainer: {
    display: "flex",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 20,
    },
  },
  h4: {
    marginBottom: "30px",
    color: theme.palette.warning.dark,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  icon: {
    fontSize: "20px",
    marginBottom: 10,
  },
  faIcon: {
    color: "green",
    fontSize: 35,
  },
  btn: {
    [theme.breakpoints.down("sm")]: {},
  },
}));

export default DashboardHeaderStyles;
