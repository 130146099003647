import React from "react";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import CustomIconButton from "../../../../../components/CustomIcon/Index";
import formatDate from "../../../../../utils/dateFormatter";
import "../Template1.css";
import manageSpacing from "./spacingController";

const ExperienceTemplate = ({
  expElement,
  modify,
  margin,
  setMargin,
  id,
  multiCol,
}) => {
  console.log(id);
  const renderExperience = (work) => {
    switch (work) {
      case "Project":
        return (
          <>
            <div className="experience-item" id={id}>
              {!multiCol && (
                <div className="spacingControlItem">
                  <CustomIconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      manageSpacing(id, "dec");
                    }}
                    icon={<FaMinusCircle />}
                  />{" "}
                  <CustomIconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      manageSpacing(id, "inc");
                    }}
                    icon={<FaPlusCircle />}
                  />
                </div>
              )}
              <div className="row">
                <div className="col col70">
                  {" "}
                  <h4>
                    {expElement.typeOfWork}: {expElement.title}
                  </h4>
                </div>
                <div className="col col30 text-right">
                  <h5>
                    {formatDate(new Date(expElement.startDate))}–
                    {expElement.endDate !== "undefined" || expElement.endDate
                      ? formatDate(new Date(expElement.endDate))
                      : "Present"}
                    {}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h5>
                    {expElement.organization} - {expElement.url}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ul className="ml2">
                    {expElement.description
                      ?.replaceAll("\t", " ")
                      .split("•")
                      .map((point, index) => {
                        if (index !== 0) {
                          return <li key={index}>{point}</li>;
                        } else {
                          return null;
                        }
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="experience-item" id={id}>
              {!multiCol && (
                <div className="spacingControlItem">
                  <CustomIconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      manageSpacing(id, "dec");
                    }}
                    icon={<FaMinusCircle />}
                  />{" "}
                  <CustomIconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      manageSpacing(id, "inc");
                    }}
                    icon={<FaPlusCircle />}
                  />
                </div>
              )}
              <div className="row">
                <div className="col col70">
                  {" "}
                  <h4>
                    {expElement.role} - {expElement.typeOfWork}
                  </h4>
                </div>
                <div className="col col30 text-right">
                  <h5>
                    {formatDate(new Date(expElement.startDate))}–
                    {expElement.endDate
                      ? formatDate(new Date(expElement.endDate))
                      : "Present"}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h5>
                    {expElement.company}, {expElement.location}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ul className="ml2">
                    {expElement.description
                      ?.replaceAll("\t", " ")
                      .split("•")
                      .map((point, index) => {
                        if (index !== 0) {
                          return <li key={index}>{point}</li>;
                        } else {
                          return null;
                        }
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  const renderExperienceModify = (work) => {
    switch (work) {
      case "Project":
        return (
          <>
            <div className="experience-item">
              {!multiCol && (
                <div className="spacingControlItem">
                  <CustomIconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      manageSpacing(id, "dec");
                    }}
                    icon={<FaMinusCircle />}
                  />{" "}
                  <CustomIconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      manageSpacing(id, "inc");
                    }}
                    icon={<FaPlusCircle />}
                  />
                </div>
              )}
              <div className="row">
                <div className="col col20 align-top">
                  <h4>
                    {formatDate(new Date(expElement.startDate))}–
                    {expElement.endDate !== "undefined" || expElement.endDate
                      ? formatDate(new Date(expElement.endDate))
                      : "Present"}
                    {}
                  </h4>
                </div>
                <div className="col col80">
                  <h4>
                    {expElement.typeOfWork}: {expElement.title}
                  </h4>
                  <h5>
                    {expElement.organization} - {expElement.url}
                  </h5>
                  <div className="">
                    <ul className="ml2">
                      {expElement.description
                        ?.replaceAll("\t", " ")
                        .split("•")
                        .map((point, index) => {
                          if (index !== 0) {
                            return <li key={index}>{point}</li>;
                          } else {
                            return null;
                          }
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="experience-item">
              {!multiCol && (
                <div className="spacingControlItem">
                  <CustomIconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      manageSpacing(id, "dec");
                    }}
                    icon={<FaMinusCircle />}
                  />{" "}
                  <CustomIconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      manageSpacing(id, "inc");
                    }}
                    icon={<FaPlusCircle />}
                  />
                </div>
              )}
              <div className="row">
                <div className="col col20 align-top">
                  <h4>
                    {formatDate(new Date(expElement.startDate))}–
                    {expElement.endDate
                      ? formatDate(new Date(expElement.endDate))
                      : "Present"}
                  </h4>
                </div>
                <div className="col col80">
                  <h4>
                    {expElement.role} - {expElement.typeOfWork}
                  </h4>
                  <h5>
                    {expElement.company}, {expElement.location}
                  </h5>
                  <div className="">
                    <ul className="ml2">
                      {expElement.description
                        ?.replaceAll("\t", " ")
                        .split("•")
                        .map((point, index) => {
                          if (index !== 0) {
                            return <li key={index}>{point}</li>;
                          } else {
                            return null;
                          }
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
  };
  return modify
    ? renderExperienceModify(expElement.typeOfWork)
    : renderExperience(expElement.typeOfWork);
};

export default ExperienceTemplate;
