import { Grid } from "@mui/material";
import React from "react";
import CustomInputField from "../../../../components/CustomInputField/Index";

export default function ProjectComponent({ value = {}, handleChange }) {
  return (
    <>
      <Grid xs={12} item>
        <CustomInputField
          label={"Project Title"}
          value={value.ex_title}
          onChange={handleChange}
          name="ex_title"
          required={true}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <CustomInputField
          label={"Organization"}
          value={value.ex_org}
          onChange={handleChange}
          name="ex_org"
          fullWidth
        />
      </Grid>
      {/* <Grid xs={12} item>
        <CustomInputField
          label={"Role"}
          value={value.ex_role}
          onChange={handleChange}
          name="ex_role"
          fullWidth
        />
      </Grid> */}
      <Grid xs={12} item>
        <CustomInputField
          label={"URL"}
          required={true}
          value={value.ex_url}
          onChange={handleChange}
          name="ex_url"
          fullWidth
        />
      </Grid>
    </>
  );
}
