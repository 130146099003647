import { makeStyles } from "@mui/styles";
import { keyframes } from "@mui/system";

const shine = keyframes`
  0% {
    background-position: -100vw;    
  }
  100% {
    background-position: 100vw;   
  }
`;

const sidebarBoostStyles = makeStyles((theme) => ({
  section: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 20,
    },
  },
  highlightText: {
    fontSize: "larger",
    fontWeight: "bold",
    color: "#000",
  },
  freeText: {
    color: theme.palette.success.darker,
    fontWeight: "bolder",
  },
  paidText: {
    color: theme.palette.warning.dark,
    fontWeight: "bolder",
  },
  offerText: {
    color: theme.palette.error.dark,
    background: theme.palette.error.lighter,
    textDecoration: "line-through",
    fontSize: "0.9rem",
    padding: "3px",
    borderRadius: theme.shape.borderRadiusSm,
    marginRight: 10,
  },
  boostBtn: {
    width: "60%",
    margin: "10px auto",
    marginBottom: 40,
    fontSize: 15,
    display: "flex",
    gap: 10,

    backgroundImage: `linear-gradient(
    to right,
    transparent 0%,
    rgba(255,255,255,0.3) 50%,
    transparent 100%
  )`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `-100vw`,
    animation: `${shine} 5s infinite`,
  },
  bulletContainer: {
    fontSize: "0.85rem",
    color: theme.palette.grey[600],
    paddingLeft: 10,
    paddingBottom: 20,
    "& p": {
      padding: 4,
      margin: 0,
      display: "grid",
      gap: 5,
      alignItems: "center",
      justifyContent: "start",
      justifyItems: "center",
      gridAutoFlow: "column",
    },
  },
  checkIcon: {
    color: theme.palette.success.dark,
    textSizeAdjust: "none",
  },
  rejectIcon: {
    color: theme.palette.error.dark,
  },
  paper: {
    padding: "20px 10px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    paddingTop: "20px",
    width: "30%",
    borderTop: `5px solid ${theme.palette.warning.dark}`,
    boxShadow: theme.customShadows.primary,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "30%",
    },
  },
  priceHead: {
    fontSize: "1.2rem",
    display: "flex",
    flexDirection: "column",
    "& text": {
      fontSize: "0.8rem",
    },
  },
  priceTag: {
    fontSize: "1.3rem",
    margin: "20px 0px",
    width: "100%",
    textAlign: "center",
    fontWeight: "bolder",
    "& span": {
      fontSize: "1rem",
      color: theme.palette.grey[500],
    },
  },
  priceDesc: {
    marginBottom: 10,
  },
  priceContent: {
    color: theme.palette.grey[500],
  },
}));

export default sidebarBoostStyles;
