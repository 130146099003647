import React from "react";
import CustomBottomNavigation from "../components/CustomBottomNavigation/Index";
import { bottomNavigationConfig } from "./sidebar/sidebar.config";

export default function BottomNavigation({ value }) {
  return (
    <CustomBottomNavigation
      navigationList={bottomNavigationConfig}
      value={value}
    />
  );
}
