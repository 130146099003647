const initialState = {
    pending: false,
    userId: "",
    resumeId: "",
    title: "",
    personal: {
      firstname: "",
      lastname: "",
      email: "",
      city: "",
      linkedin: "",
      mobile: "",
      state: "",
      website: "",
      summary: "",
    },
    education: [],
    experience: [],
    gpt: [],
    skills: {
      accountingandfinance: [],
      basictechnicalskills: [],
      businessanalytics: [],
      designskills: [],
      educationskills: [],
      managementskills: [],
      marketingskills: [],
      nursingandhealthcare: [],
      programminglanguages: [],
      salesskills: [],
      softskills: [],
      webdevelopment: [],
    },
    achievements: {
      certifications: [],
      publications: [],
      awards: [],
    },
    extras: {
      hobbies: [],
      languages: [],
    },
  };


export default initialState