import { makeStyles } from "@mui/styles";
import emailBg from "../../../../../assets/emailBg.png";

const useStyles = makeStyles((theme) => ({
  emailHeader: {
    background: "#0c2c65",
    textTransform: "uppercase",
    // background: `linear-gradient(
    //     180deg,rgba(12, 14, 101, .2),rgba(12, 14, 101,.2)),url(${emailBg})`,
    backgroundImage: `url(${emailBg})`,
    backgroundSize: "cover",
    fontWeight: "bold",
    fontSize: "xx-large",
    color: "#0c2c65",
    textAlign: "center",
    padding: "40px 0px",
    "& span": {
      background: "rgba(255, 255, 255, .8)",
      padding: "0px 10px",
      borderRadius: "10px",
      letterSpacing: "2px",
    },
  },
  emailContent: {
    background: "",
    textAlign: "center",
    "& p": {
      color: "#000",
      padding: "0px 30px",
    },
    "& .head-content": {
      padding: "35px 0px 25px 0px",
    },
    "& .emailField": {
      maxWidth: "80%",
      borderWidth: "1px",
      boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
      borderColor: "white",
      "& .MuiOutlinedInput-root": {
        borderRadius: "1px",
      },
    },
    "& .otpField": {
      marginTop: "1rem",
      maxWidth: "40%",
      borderWidth: "1px",
      boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
      borderColor: "white",
      "& .MuiOutlinedInput-root": {
        borderRadius: "1px",
      },
    },
    "& .middle-content": {
      padding: "20px 0px 10px 0px",
    },
  },
  icon: {
    marginRight: "10px",
  },
  btn: {
    background: "#0c2c65",
    borderRadius: "4px",
    padding: "10px 35px",
    width: "180px",
    marginRight: "20px",
  },
  btnOutline: {
    borderColor: "#0c2c65",
    color: "#0c2c65",
    borderRadius: "4px",
    padding: "10px 35px",
    width: "110px",
  },
  emailFooter: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "2rem",
    "& .default": {
      display: "flex",
      justifyContent: "justify-around",
    },
  },
}));

export default useStyles;
