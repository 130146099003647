const createId = (num) => {
  return Number(Number(num).toString(8));
};

export const uniqueNumericId = (() => {
  const heyStack = "0123456789";
  const randomInt = () =>
    Math.floor(Math.random() * Math.floor(heyStack.length));

  return (length = 24) =>
    Array.from({ length }, () => heyStack[randomInt()]).join("");
})();

export const uniqTxnID = () => {
  const head = Date.now().toString();
  const tail = Math.random().toString().substr(2);
  return head + tail;
};

export default createId;
