import { makeStyles } from "@mui/styles";

let ResourceStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      [theme.breakpoints.up("lg")]: {
        padding: "0px 20px",
      },
    },
    containerGrid: {
      marginTop: 10,
    },
    contentHead: {
      display: "flex",
      justifyContent: "space-between",
      "& .read-blog-btn": {
        display: "flex",
        alignItems: "center",
        gap: 5,
        marginTop: 10,
        fontWeight: "bold",
      },
    },
    suggestionBtn: {},
    contentDiv: {
      padding: 40,
      textAlign: "left",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        paddingTop: 90,
        marginBottom: 70,
      },
      [theme.breakpoints.down("sm")]: {
        padding: 15,
        paddingTop: 90,
      },
    },
    subtitle: {
      margin: "20px 0px",
      textAlign: "center",
      color: theme.palette.warning.dark,
      fontSize: "17px",
      opacity: "0.5",
    },
    aTag: {
      textDecoration: "none",
    },
    blogHead: {
      marginBottom: "20px",
      fontWeight: "600",
    },
    gridItem: {
      height: "100%",
    },
  };
});

export default ResourceStyles;
