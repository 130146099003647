import { Typography } from "@mui/material";

export const renderJobHeader = (id, classes) => {
  switch (id) {
    case "3656216131":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            Trainee Software Developers
          </Typography>
          <Typography variant="p" className={classes.subHeading}>
            Webonise Pvt. Ltd.
          </Typography>
          <div className={classes.jobHeadInfo}>
            <Typography variant="p">Experience: Fresher (0-1)</Typography>
            <Typography variant="p">Location: Pune</Typography>
          </div>
        </>
      );
    case "4072791321":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            Client Success Manager
          </Typography>
          <Typography variant="p" className={classes.subHeading}>
            Webonise Pvt. Ltd.
          </Typography>
          <div className={classes.jobHeadInfo}>
            <Typography variant="p">Location: Mumbai</Typography>
          </div>
        </>
      );
    case "3656214544":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            Jr / Sr Developers, Tech Project Managers, Architects, Engineering
            Managers
          </Typography>
          <Typography variant="p" className={classes.subHeading}>
            Saama, Hashmap (NTT Data), Propelo, Next Path Way, L & T Infotech
          </Typography>
          <div className={classes.jobHeadInfo}>
            <Typography variant="p">Experience: 1+ Year</Typography>
            <Typography variant="p">Location: Remote</Typography>
          </div>
        </>
      );

    case "7523377112":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            Software Engineer
          </Typography>
          <Typography variant="p" className={classes.subHeading}>
            1DigitalStack.ai
          </Typography>
          <div className={classes.jobHeadInfo}>
            {/* <Typography variant='p'>Experience: --
                            </Typography> */}
            <Typography variant="p">Location: Delhi</Typography>
          </div>
        </>
      );
    case "5078906421":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            Fullstack Developer
          </Typography>
          {/* <Typography variant='p'
                            className={
                                classes.subHeading
                        }>
                           Function: Technology
                        </Typography> */}
          {/* <br /> */}
          <Typography variant="p" className={classes.subHeading}>
            Industry: Education & HR
          </Typography>

          <div className={classes.jobHeadInfo}>
            {/* <Typography variant='p'>Experience: --
                            </Typography> */}
            <Typography variant="p">Experience: Fresher</Typography>
            <Typography variant="p">Location: Remote</Typography>
            {/* <Typography variant='p'>Starting Date: Immediate
                            </Typography> */}
          </div>
        </>
      );

    case "4990101974":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            Business Analyst
          </Typography>
          <Typography variant="p" className={classes.subHeading}>
            1DigitalStack.ai
          </Typography>
          <div className={classes.jobHeadInfo}>
            <Typography variant="p">Experience: 0-1 year</Typography>
            <Typography variant="p">Location: Delhi</Typography>
          </div>
        </>
      );

    case "7078906426":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            Data Scientist
          </Typography>
          <Typography variant="p" className={classes.subHeading}>
            1DigitalStack.ai
          </Typography>
          <div className={classes.jobHeadInfo}>
            {/* <Typography variant='p'>Experience: --
                            </Typography> */}
            <Typography variant="p">Location: Delhi</Typography>
          </div>
        </>
      );
    case "5432135670":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            Java Developers
          </Typography>
          <Typography variant="p" className={classes.subHeading}>
            IT Services and Consulting
          </Typography>
          <div className={classes.jobHeadInfo}>
            <Typography variant="p">Employment Type: Full Time</Typography>
            <Typography variant="p">Experience: 1+ years</Typography>
          </div>
        </>
      );

    case "8527185712":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            AM/ Manager – Back End Developer
          </Typography>
          <Typography variant="p" className={classes.subHeading}>
            Edelweiss
          </Typography>
          <div className={classes.jobHeadInfo}>
            <Typography variant="p">Employment Type: Full Time</Typography>
            <Typography variant="p">Experience: 5+ years</Typography>
          </div>
        </>
      );

    case "2573128808":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            AM/ Manager – Front End Development ReactJS
          </Typography>
          <Typography variant="p" className={classes.subHeading}>
            Edelweiss
          </Typography>
          <div className={classes.jobHeadInfo}>
            <Typography variant="p">Employment Type: Full Time</Typography>
            <Typography variant="p">Experience: 5+ years</Typography>
          </div>
        </>
      );

    case "1457342611":
      return (
        <>
          <Typography variant="h5" className={classes.heading}>
            Software Testing Engineers/QA
          </Typography>
          <Typography variant="p" className={classes.subHeading}>
            Edelweiss
          </Typography>
          <div className={classes.jobHeadInfo}>
            <Typography variant="p">Employment Type: Full Time</Typography>
            <Typography variant="p">Experience: 3-5+ years</Typography>
          </div>
        </>
      );
    default:
      break;
  }
};
