import { makeStyles } from "@mui/styles";

const customCardStyles = makeStyles((theme) => ({
  divider: {
    color: theme.palette.text.disabled,
    fontSize: theme.typography.body2.fontSize,
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  subtitle: {
    fontSize: "10px",
    marginBottom: 10,
  },
  cardBodyContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardFooterContent: {
    display: "grid",
    gridAutoFlow: "column",
    gap: 3,
  },
  coloredBar: {
    border: `3px solid ${theme.palette.success.main}`,
    background: theme.palette.success.main,
    borderRadius: "50px",
  },
  disabledBar: {
    border: `3px solid ${theme.palette.text.disabled}`,
    background: theme.palette.text.disabled,
    borderRadius: "50px",
  },
}));

export default customCardStyles;
