import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endPoints from "../../config/endPoint";
import filterObj from "../../utils/appObjFilteration";
import sortArray from "../../utils/sort";

const initialState = {
  loading: false,
  experiences: [],
  actionVerbList: [],
};

// First, create the thunk
export const getExperienceThunk = createAsyncThunk(
  "experience/getExperience",
  async ({ token, resume_id }, thunkAPI) => {
    const { data } = await axios.post(`${endPoints.getExperience}`, {
      token,
      resume_id,
    });
    return sortArray(
      data.results.map((r) =>
        filterObj({
          ...r,
          ex_type: r.wx_type ? r.wx_type : "project",
        })
      ),
      "resume_created_at"
    );
  }
);

export const getActionVerbThunk = createAsyncThunk(
  "experience/getSuggestionActionVerb",
  async () => {
    let actionVerbList = await axios.get(endPoints.getActionVerb);
    return actionVerbList.data.results;
  }
);

export const createExperienceThunk = createAsyncThunk(
  "experience/createExperience",
  async (body, thunkAPI) => {
    const { data } = await axios.post(`${endPoints.createExperience}`, {
      ...filterObj(body, true),
      token: body.token,
    });
    return filterObj({
      ...data.results[0],
      ex_type: data.results[0].wx_type ? data.results[0].wx_type : "project",
    });
  }
);

export const deleteExperienceThunk = createAsyncThunk(
  "experience/deleteExperience",
  async ({ token, resume_id, wx_id, type }, thunkAPI) => {
    const state = thunkAPI.getState().experience;
    await axios.delete(`${endPoints.deleteExperience}`, {
      data:
        type.toLowerCase() === "project"
          ? { token, resume_id, pj_id: wx_id, del_flag: 1, type }
          : { token, resume_id, wx_id: wx_id, del_flag: 1, type },
    });
    return state.experiences.filter((resume) => resume.ex_id !== wx_id);
  }
);

export const updateExperienceThunk = createAsyncThunk(
  "experience/updateExperience",
  async ({ body, token }, { getState }) => {
    const state = getState().experience;
    const { data } = await axios.patch(`${endPoints.updateExperience}`, {
      ...filterObj(body, true, true),
      token,
    });
    return sortArray(
      state.experiences.map((resume) => {
        if (resume.ex_id === body.ex_id) {
          return filterObj({
            ...data.results[0],
            ex_type: data.results[0].wx_type
              ? data.results[0].wx_type
              : "project",
          });
        } else {
          return resume;
        }
      }),
      "resume_created_at"
    );
  }
);

export const experienceSlice = createSlice({
  name: "experience",
  initialState,
  reducers: {
    updateEducation: (state, { payload }) => {
      state.experiences = payload;
    },
  },
  extraReducers: {
    [getExperienceThunk.pending]: (state) => {
      state.loading = true;
    },
    [getExperienceThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.experiences = payload;
    },
    [getExperienceThunk.rejected]: (state) => {
      state.loading = false;
    },

    [deleteExperienceThunk.pending]: (state) => {
      state.loading = true;
    },
    [deleteExperienceThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.experiences = payload;
    },
    [deleteExperienceThunk.rejected]: (state) => {
      state.loading = false;
    },

    [getActionVerbThunk.pending]: (state) => {
      state.loading = true;
    },
    [getActionVerbThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.actionVerbList = payload;
    },
    [getActionVerbThunk.rejected]: (state) => {
      state.loading = false;
    },

    [updateExperienceThunk.pending]: (state) => {
      state.loading = true;
    },
    [updateExperienceThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.experiences = payload;
    },
    [updateExperienceThunk.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [createExperienceThunk.pending]: (state) => {
      state.loading = true;
    },
    [createExperienceThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.experiences = [...state.experiences, payload];
    },
    [createExperienceThunk.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateEducation } = experienceSlice.actions;

export default experienceSlice.reducer;
