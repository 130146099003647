import SpeedIcon from "@mui/icons-material/Speed";
import { Avatar, Box, Button, Paper, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import CustomProgress from "../../../../components/CustomProgress";
import { calcPercentage } from "../../helpers/percentageCalc";

const DashboardCard = ({
  inputData,
  value,
  property,
  open,
  handleOpen,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const [completion, setCompletion] = React.useState(0);
  const { resumeId } = useParams();
  // console.log(completion);
  let dashboard = useSelector((state) => state.dashboard);
  React.useEffect(() => {
    let obj = dashboard.resumes.find(
      (element) => element.resume_id === resumeId
    );
    setCompletion(obj ? calcPercentage(obj) : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Button onClick={props.isModal ? (e) => {} : handleOpen}>
      {/* <Tooltip title="Completion">
        <Paper
          elevation={8}
          sx={{
            borderRadius: "20px",
            height: 90,
            marginRight: 3,
            display: "flex",
          }}
        >
          <span
            style={{
              margin: "auto 10px",
            }}
          >
            {" "}
            <CustomProgress completion={completion} text={"Progress"} />{" "}
          </span>
        </Paper>
      </Tooltip> */}
      <Tooltip title="Overall Score">
        <Paper
          elevation={8}
          sx={{
            borderRadius: "20px",
            height: 90,
            width: { xs: 72, sm: 170, md: 200, lg: 250 },
          }}
        >
          <Box
            display="flex"
            py={1}
            px={{ xs: 1, sm: 2 }}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ height: 90 }}
          >
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Avatar
                sx={{
                  bgcolor: (theme) => theme.palette.primary.main,
                  width: 60,
                  height: 60,
                }}
              >
                <SpeedIcon size="large" />
              </Avatar>
            </Box>

            <Box
              textAlign="left"
              sx={{ pl: { xs: "0px", sm: "6px", md: "10px" } }}
            >
              <Typography
                variant="h5"
                sx={{
                  opacity: 0.9,
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" },
                }}
              >
                {value}/100
              </Typography>
              {props.isModal ? (
                ""
              ) : (
                <Typography
                  variant="subtitle2"
                  sx={{
                    opacity: 0.6,
                    fontSize: { xs: ".6rem", sm: ".8rem", md: "1rem" },
                  }}
                >
                  SEE {property} REPORT
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>
      </Tooltip>
    </Button>
  );
};

export default DashboardCard;
