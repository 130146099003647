import { Divider, Paper, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { FaArrowLeft, FaCheckCircle, FaDollarSign } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomModal from "../../components/CustomModal/Index";
import CustomPreview from "../../components/CustomPreview/Index";
import PricingComponent from "../../components/Pricing/Index";
import endPoints from "../../config/endPoint";
import BottomNavigation from "../../layouts/bottomNavigation";
import DashboardSidebar from "../../layouts/index";
import { checkUserPlanThunk } from "../../redux/counterSlice";
import sidebarBoostStyles from "../../styles/layout/sidebar.boost.style";
import PlanStyles from "../../styles/pages/payment/plan.style";
import formatDate from "../../utils/dateFormatter";
// import ParsedFileContainer from "./components/ParsedFile";

export default function DashboardPage(props) {
  const classes = PlanStyles();
  const planClasses = sidebarBoostStyles();
  const userPlan = useSelector((state) => state.counter);
  const [isMore, setMore] = React.useState(false);
  const [isVerify, setVerify] = React.useState(false);
  const token = localStorage.getItem("token");
  const [txnID, setTxnID] = React.useState("");
  const [disable, setDisable] = React.useState(false);
  const [showIllustration, setIllustration] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(
    () => {
      dispatch(checkUserPlanThunk({ token: localStorage.getItem("token") }));
      axios
        .post(endPoints.checkPendingPayments, { token })
        .then((r) => {
          setDisable(false);
        })
        .catch((e) => {
          setDisable(true);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const checkUsageLimit = (name, val, elseVal) => {
    if (name === "ai_support_ps" && val === -1) {
      return "Yes";
    } else if (name === "resume_creation" && val > 0) {
      return val;
    } else if (name === "resume_templates" && val > 0) {
      return val;
    } else if (name === "ai_support_bp" && val === -1) {
      return "Yes";
    } else if (name === "resume_guide_book" && val === -1) {
      return "Yes";
    } else if (name === "resume_download_pdf" && val === -1) {
      return "Yes";
    } else if (name === "resume_download_doc" && val === -1) {
      return "Yes";
    } else if (name === "resume_download_text" && val === -1) {
      return "Yes";
    } else {
      return elseVal;
    }
  };
  const checkUsageName = (name) => {
    switch (name) {
      case "resume_creation":
        return "Resume Creation";

      case "resume_templates":
        return "Resume Templates";

      case "ai_credits":
        return "AI Credits";

      case "ai_support_ps":
        return "AI Support (Professional Summary)";

      case "ai_support_bp":
        return "AI Support (Bullet Points)";

      case "resume_guide_book":
        return "Resume Guide Book";

      case "dynamic_suggestions":
        return "Dynamic Suggestions";

      case "resume_download":
        return "Resume Download";

      case "resume_download_pdf":
        return "Resume Download PDF";

      case "resume_download_doc":
        return "Resume Download Doc";

      case "resume_download_text":
        return "Resume Download Text";

      case "expert_sessions":
        return "Expert Sessions";

      default:
        return name.split("_").join(" ");
    }
  };

  const handlePaymentVerification = () => {
    axios
      .post(endPoints.verifyPayment, {
        token,
        txnID,
      })
      .then((r) => {
        if (r.data.results.status === "failure") {
          setIllustration(
            `We regret to inform you that your recent payment was not processed successfully. We recommend that you attempt to make the payment again in order to activate your plan. If you have already made a payment for your plan, please send a copy of the transaction receipt from Kimiyo Learnings Pvt. Ltd. to our support team at support@vipapplicant.com for further assistance.
              `
          );
          axios.post(endPoints.updateVerifiedPlan, {
            token,
            txnID,
            status: "failure",
          });
        } else {
          axios
            .post(endPoints.updateVerifiedPlan, {
              token,
              txnID,
              status: "success",
              plan_name: r.data.results.plan,
              isUpdatePlan: true,
            })
            .then((r) => {
              dispatch(
                checkUserPlanThunk({ token: localStorage.getItem("token") })
              );
              setIllustration(false);
              setTxnID("");
              setVerify(false);
              setSuccess(false);
              setDisable(true);
            });
          setSuccess(r.data.results.plan);
        }
      })
      .catch((e) => {
        setIllustration(e.response.data.error);
      });
  };
  return (
    <div className={classes.container}>
      <div>
        <DashboardSidebar />
      </div>
      <div className={classes.contentDiv}>
        <CustomModal
          cancelBtnText={"Close"}
          dialogTitle="Verify Payment"
          confirmBtnText={showIllustration || !!isSuccess ? "Finish" : "Check"}
          mbContent={4}
          dialogContent={
            showIllustration ? (
              <CustomPreview
                message={showIllustration}
                url="/static/payment.svg"
                width="40%"
                msgHead={"Transaction Not Found"}
              />
            ) : (
              <CustomInputField
                fullWidth
                label="Transaction ID"
                value={txnID}
                onChange={(e) => setTxnID(e.target.value)}
                helperText={
                  !!isSuccess ? (
                    <span className={classes.helperText}>
                      <FaCheckCircle /> Payment Verified
                    </span>
                  ) : (
                    ""
                  )
                }
              />
            )
          }
          isOpen={isVerify}
          setOpen={setVerify}
          cancelCallBack={(e) => isVerify(false)}
          actionCallback={
            showIllustration || !!isSuccess
              ? () => {
                  setIllustration(false);
                  setTxnID("");
                  setVerify(false);
                  setSuccess(false);
                }
              : handlePaymentVerification
          }
        />
        <CustomModal
          cancelBtnText={"Close"}
          dialogTitle="Usage Details"
          mbContent={1}
          dialogContent={
            <Paper className={classes.paper}>
              {userPlan.user_plan_limits.map((pl) => {
                return (
                  <p>
                    <span className="nameSpan">
                      {checkUsageName(pl.feature_name)}
                    </span>
                    <span className="divideSpan">:</span>
                    <span
                      className={`detailSpan ${
                        pl.usage_limit === pl.limit_used ? "error" : "success"
                      }`}
                    >
                      {pl.usage_limit === -1
                        ? checkUsageLimit(pl.feature_name, -1, "Unlimited")
                        : pl.usage_limit === 0
                        ? "N/A"
                        : checkUsageLimit(
                            pl.feature_name,
                            pl.usage_limit,
                            `${pl.limit_used}/${pl.usage_limit}`
                          )}
                    </span>
                  </p>
                );
              })}
            </Paper>
          }
          isOpen={isMore}
          setOpen={setMore}
          cancelCallBack={(e) => setMore(false)}
        />
        <div className={classes.contentHead}>
          <div>
            <Typography variant="h3" color={"primary"}>
              Current Plan - {userPlan.current_plan}
              <CustomButton
                className={classes.detailBtn}
                onClick={(e) => setMore(true)}
                text={"More Details"}
                variant="contained"
                color="warning"
                size="small"
              />
              <CustomButton
                className={classes.detailBtn}
                onClick={(e) => setVerify(true)}
                icon={<FaDollarSign />}
                disabled={disable}
                text={"Verify Payment"}
                variant="contained"
                color="info"
                size="small"
              />
            </Typography>
            {userPlan.current_plan !== "VA Executive" && (
              <Typography variant="body2">
                Plan Tenure:{" "}
                {formatDate(new Date(userPlan.plan_start_date), true)} -{" "}
                {formatDate(new Date(userPlan.plan_end_date), true)}
              </Typography>
            )}

            <Typography variant="h5" color={"warning"}>
              Change your plan category according to your need.
            </Typography>
          </div>
          {/* <div>
            <CustomizedSwitches />
          </div> */}
          <CustomButton
            className={classes.backBtn}
            text={"Go back"}
            onClick={(e) => {
              navigate(-1);
            }}
            icon={<FaArrowLeft />}
            variant="contained"
          />
        </div>

        <Divider className={classes.divider} />
        <div className={classes.contentSection1}></div>
        <PricingComponent classes={planClasses} />
      </div>
      <BottomNavigation value={4} />
    </div>
  );
}
