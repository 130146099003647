import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FindInPageSharpIcon from "@mui/icons-material/FindInPageSharp";
import { Button, Grid, Paper } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import JobDetailStyles from "../../../styles/pages/Job/components/jobDetails.style";
import { renderJobContent } from "./detailComps/content";
import { renderJobHeader } from "./detailComps/header";

import DashboardSidebar from "../../../layouts/index";
import JobLogin from "./detailComps/login";
import BottomNavigation from "../../../layouts/bottomNavigation";

export default function JobDetails() {
  const navigate = useNavigate();
  const classes = JobDetailStyles();
  const { jobId, jobTitle } = useParams();
  const [page, setPage] = React.useState(0);

  const handleUpload = (stage) => {
    setPage(stage + 1);
  };
  const renderContent = (stage) => {
    switch (stage) {
      case 0:
        return <JobLogin handleUpload={(e) => handleUpload(page)} />;

      case 1:
        return <JobLogin handleUpload={(e) => handleUpload(page)} />;
      case 2:
        return (
          <div className={classes.emailContent}>
            <CheckCircleOutlineIcon
              style={{
                color: "green",
                fontSize: 40,
              }}
            />
            <h3>You have successfully applied for the job.</h3>
            <p>
              You can also improve your resume on our ai powered Resume Builder.{" "}
            </p>
            <Button
              variant="contained"
              onClick={(e) => {
                navigate("/");
              }}
            >
              Visit Resume Builder
            </Button>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className={classes.container}>
        <DashboardSidebar />
        <Grid container spacing={3} className={classes.jobContainer}>
          <Grid item lg={8} spacing={2} container>
            <Grid item xs={12}>
              <Paper className={classes.jobHead}>
                {renderJobHeader(jobId, classes)}
                {/* <Divider className={
                            classes.divider
                        }/> */}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.jobDesc}>
                {renderJobContent(jobId, classes)}
                <Paper className={classes.formContainer}>
                  {renderContent(page)}
                </Paper>
                <Paper
                  className={classes.jobDesc}
                  style={{
                    padding: 20,
                    textAlign: "center",
                    marginTop: "10px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                >
                  <span>Jobs you might be interested in</span>
                  <p />
                  <Button
                    variant="contained"
                    className={`uap_${jobTitle}_see_more_jobs_btn`}
                    onClick={(e) => {
                      navigate("/jobfeed");
                    }}
                    style={{}}
                  >
                    {" "}
                    <FindInPageSharpIcon
                      style={{
                        marginRight: "10px",
                      }}
                    />{" "}
                    <span
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      See More jobs
                    </span>
                  </Button>
                </Paper>
              </Paper>
            </Grid>
          </Grid>
          <Grid item lg={4} container spacing={2} direction="row"></Grid>
        </Grid>
      </div>

      <BottomNavigation value={3} />
    </>
  );
}
