import { makeStyles } from "@mui/styles";

let CardContainerStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    justifyContent: "space-between",
    "& h5": {
      flex: 0.95,
    },
  },
  divider: {
    margin: "10px 0px",
  },
  cardContent: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
  },
  icon: {
    fontSize: "15px",
    color: theme.palette.primary.main,
  },
  typoGraphy: {
    color: theme.palette.text.disabled,
  },
}));

export default CardContainerStyles;
