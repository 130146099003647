import React from "react";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import CustomIconButton from "../../../../../components/CustomIcon/Index";
import formatDate from "../../../../../utils/dateFormatter";
import "../Template1.css";
import manageSpacing from "./spacingController";

const EducationTemplate = ({ eduElement, modify, id, multiCol }) => {
  if (modify) {
    return (
      <div className="education-item" id={id}>
        {!multiCol && (
          <div className="spacingControlItem">
            <CustomIconButton
              size="small"
              color="secondary"
              onClick={() => {
                manageSpacing(id, "dec");
              }}
              icon={<FaMinusCircle />}
            />{" "}
            <CustomIconButton
              size="small"
              color="secondary"
              onClick={() => {
                manageSpacing(id, "inc");
              }}
              icon={<FaPlusCircle />}
            />
          </div>
        )}
        <div className="row">
          <div className="col col20 align-top">
            <h4>
              <strong>{formatDate(new Date(eduElement.year))}</strong>
            </h4>
          </div>
          <div className="col col80 align-top">
            <h4 className="education-head">
              {eduElement.select?.startsWith("Class")
                ? eduElement.select
                : eduElement.degree}{" "}
              – {eduElement.major} -{" "}
              {eduElement.cgpa && (
                <>
                  {" "}
                  {eduElement.cgpa}
                  {eduElement.grade?.startsWith("CGPA") ? "/" : ""}
                  {eduElement.grade?.replace(/[^0-9%]/g, "")}{" "}
                </>
              )}
            </h4>
            <div className="">
              <p>
                {!eduElement.college
                  ? eduElement.university
                  : eduElement.college}
              </p>
            </div>
          </div>
        </div>
        <div>
          {/* <table>
            <tbody>
              <tr>
                <td className="col15 text-left" rowSpan={2} valign="top"></td>
                <td className="col70 text-left" valign="top"></td>
              </tr>
            </tbody>
          </table> */}
        </div>
      </div>
    );
  } else if (multiCol) {
    return (
      <div className="education-item" id={id}>
        <div className="row text-right">
          <h4 className="education-head">
            {eduElement.select?.startsWith("Class")
              ? eduElement.select
              : eduElement.degree}{" "}
            – {eduElement.major}
          </h4>
          <div className="">
            <p>
              {!eduElement.college ? eduElement.university : eduElement.college}
            </p>
          </div>
          <h4>
            <strong>{formatDate(new Date(eduElement.year))}</strong>
          </h4>
        </div>

        <div>
          {/* <table>
            <tbody>
              <tr>
                <td className="col15 text-left" rowSpan={2} valign="top"></td>
                <td className="col70 text-left" valign="top"></td>
              </tr>
            </tbody>
          </table> */}
        </div>
      </div>
    );
  }
  return (
    <div className="education-items" id={id}>
      <div className="spacingControlItem">
        <CustomIconButton
          size="small"
          color="secondary"
          onClick={() => {
            manageSpacing(id, "dec");
          }}
          icon={<FaMinusCircle />}
        />{" "}
        <CustomIconButton
          size="small"
          color="secondary"
          onClick={() => {
            manageSpacing(id, "inc");
          }}
          icon={<FaPlusCircle />}
        />
      </div>
      <div className="row">
        <div className="col">
          <h4>
            {eduElement.select?.startsWith("Class")
              ? eduElement.select
              : eduElement.degree}{" "}
            – {eduElement.major}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col col70">
          <p>
            {!eduElement.college ? eduElement.university : eduElement.college}
          </p>
        </div>
        <div className="col col15 text-right">
          {" "}
          {eduElement.cgpa}
          {eduElement.grade?.startsWith("CGPA") ? "/" : ""}
          {eduElement.grade?.replace(/[^0-9%]/g, "")}{" "}
        </div>
        <div className="col col15 text-right">
          {" "}
          <h5>
            <strong>{formatDate(new Date(eduElement.year))}</strong>
          </h5>
        </div>
      </div>
      <div></div>
      {/* <div>
        <table>
          <tbody>
            <tr>
              <td className="col70 text-left"></td>
              {eduElement.cgpa && <td className="col15 text-right"></td>}
              <td className="col15 text-right"></td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

export default EducationTemplate;
