import { Card, CardContent, Divider, Typography, Box } from "@mui/material";
import React from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import CustomIconButton from "../../../../components/CustomIcon/Index";
import CustomPill from "../../../../components/CustomPill/Index";
import CardContainerStyles from "../../../../styles/pages/Resume/components/CardContainer/cardContainer.styles";

export default function CardContainerComponent({
  headTitle,
  uapEditClassName,
  uapDeleteClassName,
  title,
  subtitle,
  title2,
  subtitle2,
  editCallback,
  deleteCallback,
  dividerText,
  cardClass,
}) {
  const classes = CardContainerStyles();
  return (
    <Card className={cardClass}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.box}>
          <Typography variant="h5">{headTitle}</Typography>

          <Typography variant="div">
            <CustomIconButton
              className={uapEditClassName}
              tooltipContent={"Edit"}
              placement={"top"}
              onClick={editCallback}
              icon={<FaPen className={classes.icon} />}
            />
            <CustomIconButton
              className={uapDeleteClassName}
              tooltipContent={"Delete"}
              placement={"top"}
              onClick={deleteCallback}
              icon={<FaTrash className={classes.icon} />}
            />
          </Typography>
        </Box>
        <Divider className={classes.divider}>
          {dividerText ? <CustomPill value={dividerText} /> : ""}
        </Divider>
        <Box className={classes.box}>
          <Typography variant="body2">{title}</Typography>
          <Typography variant="body2" className={classes.typoGraphy}>
            {subtitle}
          </Typography>
        </Box>
        <Box className={classes.box}>
          <Typography variant="body2" className={classes.typoGraphy}>
            {title2} {subtitle2 && "-"}
            {subtitle2}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
