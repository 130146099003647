const globalTemplateStyles = `
            *,
      *::after,
      *::before {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
      }

      body .template-innerWrapper {
        font-family: "Open Sans", sans-serif;
        line-height: normal;
        font-size: 10pt;
        color: #000;
        font-weight: normal;
        -webkit-font-smoothing: auto;
      }
      body .template-innerWrapper .full_name{
        font-size: 1.2em
      }
      body .template-innerWrapper section:first-child {
              margin-top:0;
      }
      body .template-innerWrapper section:last-child {
              margin-bottom:0;
      }
      body .template-innerWrapper section table {
font-size: 10pt;
        color: #000;
      }
      body .template-innerWrapper section {
        margin: 10px 0px;
        page-break-after:auto;
        position: relative;
      }
      body .template-innerWrapper section .education-items  {
        position: relative;
      }
      body .template-innerWrapper section .education-item  {
        position: relative;
      }
      body .template-innerWrapper section .experience-item  {
        position: relative;
      }
      
      body .template-innerWrapper section .spacingControl {
        position: absolute;
        top: 3px;
        display: flex;
        left: -115px;
      }
      body .template-innerWrapper section .spacingControlItem {
        position: absolute;
        top: 3px;
        display: flex;
        left: -115px;
      }
      body .template-innerWrapper section .section-heading {
        position: relative;
      }
      body .template-innerWrapper section .spacingControlEdu {
        position: absolute;
        top: 3px;
        display: flex;
        left: -115px;
      }
      body .template-innerWrapper section .spacingControl svg {
        cursor: pointer
      }
      .section-items .experience-item:last-child {
    margin-bottom: 0px;
}

.section-items .experience-item {
    margin-bottom: 5px;
}
 .row {
    display: block;
    width: 100%;
}
.col {
    display: inline-block;
    
}
.ex-date{
  width: 20%
}
.mw-40 {
  max-width: 40%;
}
.temp-col {
  color: #2c9498;
}
.temp-col-main {
  color: #cb7400;
  display: -webkit-box;
  display: flex;
  gap: 5px;
  align-items: center;
}
.divider {
  /* overflow: hidden; */
  color: aqua;
  height: 20px;
  width: 100%;
}
.icon-sm {
  font-size: 0.9em;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
h2,
h3,
p,
ul {
  margin-bottom: 0px;
  margin-top: 0px;
}
.flr {
  float: right;
}
table,
tr {
  width: 100%;
}
.col1 {
  width: 100%;
}
.align-top{
  vertical-align: top;
}
.col2 {
  width: 49.66%;
  display: inline-block;
}
.col3 {
  width: 33.33%;
}
.col4 {
  width: 25%;
}
.col15 {
  width: 15%;
}
.col20 {
  width: 20%;
}
.col80 {
  width: 80%;
}
.col75 {
  width: 75%;
}
.col25 {
  width: 25%;
}
.col70 {
  width: 70%;
}
.col30 {
  width: 30%;
}
.col40 {
  width: 40%;
}
.col60 {
  width: 60%;
}
.mb1 {
  margin-bottom: 5px;
}
.mb2 {
  margin-bottom: 20px;
}
.mb3 {
  margin-bottom: 30px;
}
.mt1 {
  margin-top: 10px;
}
.mt2 {
  margin-top: 20px;
}
.mt3 {
  margin-top: 30px;
}
.mb0 {
  margin-bottom: 0px;
}
.p-2{
  padding: 20px;
}
.pl-2{
  padding-left: 20px;
}
.pr-2{
  padding-right: 20px;
}
.pb-2{
  padding-bottom: 20px;
}
.border-r-2{
  border-right: 2px solid #919eabcc;
}
.border-l-2{
  border-left: 2px solid #919eabcc;
}
.border-t-2{
  border-top: 2px solid #919eabcc;
}
.border-b-2{
  border-bottom: 2px solid #919eabcc;
}
.flex {
  display: flex;
}
.ml2 {
  margin-left: 20px;
}
.pl2 {
  padding-left: 20px;
}

.table-row{
  display: table-row;
}
.table-cell{
  display: table-cell;
}

`;

export default globalTemplateStyles;
