import { InputAdornment, TextField } from "@mui/material";
import classNames from "classnames";
import React from "react";
//styles
import inputStyles from "../makestyles/InputStyles";

const useStyles = inputStyles;

const MobileNumber = ({ mobile, setMobile, OTPSent, sendOTP, className }) => {
  const classes = useStyles();

  return (
    <div>
      <TextField
        label="MOBILE NUMBER"
        placeholder="10 digit phone number"
        variant="outlined"
        className={classNames(classes.root, className)}
        fullWidth
        required={true}
        // disabled={OTPSent}
        helperText={
          OTPSent ? "OTP has been sent to this number" : "10 digit phone number"
        }
        color="primary"
        value={mobile}
        onChange={(e) => {
          if (e.target.value.match(/^\d+$/) && e.target.value.length <= 10) {
            setMobile(e.target.value);
          } else if (e.target.value.length === 0) {
            setMobile(e.target.value);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && mobile.length === 10) sendOTP();
        }}
        InputLabelProps={{
          shrink: false,
          className: classes.inputLabelNoShrink,
        }}
        InputProps={{
          // readOnly: {OTPSent},
          startAdornment: <InputAdornment position="start">+91</InputAdornment>,
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }}
      />
    </div>
  );
};

export const EmailField = ({
  mobile,
  setMobile,
  OTPSent,
  sendOTP,
  className,
}) => {
  const classes = useStyles();

  return (
    <div>
      <TextField
        label="EMAIL ID"
        type={"email"}
        placeholder="Enter a valid email ID"
        variant="outlined"
        className={classNames(classes.root, className)}
        fullWidth
        error={
          mobile &&
          !mobile.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/)
        }
        required={true}
        // disabled={OTPSent}
        helperText={
          OTPSent
            ? "OTP has been sent to this email ID"
            : "Enter a valid email ID"
        }
        color="primary"
        value={mobile}
        onChange={(e) => {
          setMobile(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") sendOTP();
        }}
        InputLabelProps={{
          shrink: false,
          className: classes.inputLabelNoShrink,
        }}
        InputProps={{
          // readOnly: {OTPSent},
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }}
      />
    </div>
  );
};

export default MobileNumber;
