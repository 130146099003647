import { makeStyles } from "@mui/styles";

let EducationStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    marginLeft: "0px",
    paddingLeft: "0px !important",
  },
  helperDiv: {
    margin: "0px auto",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    bottom: -20,
    fontSize: "small",
    fontWeight: "bold",
  },
  contentLeft: {
    paddingTop: "0px !important",
    maxHeight: 822,
    overflow: "auto",
    overflowX: "hidden",
    paddingLeft: "0px !important",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "46%",
    },
    "& #cardContainer": {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down("sm")]: {
      "& #cardContainer": {
        paddingRight: 0,
      },
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  contentRight: {
    paddingTop: "25px !important",
    marginLeft: "0px",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px !important",
      "& .MuiGrid-item": {
        paddingLeft: "0px !important",
      },
    },
  },
  typography: {
    color: theme.palette.text.disabled,
    textAlign: "center",
  },
  helperTypo: {
    textAlign: "center",
  },
  actionBtn: {
    margin: "10px auto",
    display: "flex",
    gap: 10,
    minWidth: 150,
  },
}));

export default EducationStyles;
