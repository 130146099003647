const S3 = require("aws-sdk/clients/s3");

let AWS_BUCKET_NAME = "vip-resume-upload";
let AWS_BUCKET_REGION = "ap-south-1";
let AWS_ACCESS_KEY = "AKIATTNLZDEGRVZSP4PD";
let AWS_SECRET_KEY = "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";

const bucketName = AWS_BUCKET_NAME;
const region = AWS_BUCKET_REGION;
const accessKeyId = AWS_ACCESS_KEY;
const secretAccessKey = AWS_SECRET_KEY;

const s3 = new S3({
  region,
  accessKeyId,
  secretAccessKey,
});

// uploads a file to s3
function uploadFile(file) {
  const uploadParams = {
    Bucket: bucketName,
    Body: file,
    Key: new Date().getTime() + "_" + file.name,
  };

  return s3.upload(uploadParams).promise();
}
exports.uploadFile = uploadFile;

// downloads a file from s3
function getFileStream(fileKey) {
  const downloadParams = {
    Key: fileKey,
    Bucket: bucketName,
  };

  return s3.getObject(downloadParams).createReadStream();
}
exports.getFileStream = getFileStream;
