import { CircularProgress } from "@mui/material";
import axios from "axios";
import React from "react";
import { Helmet } from "react-helmet";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import "./App.css";
import endPoints from "./config/endPoint";
import NotFound from "./pages/404/Page404";
import DashboardPage from "./pages/Dashboard/Index";
import DTUPage from "./pages/DTU/Index";
import JobFeed from "./pages/Job";
import JobDetails from "./pages/Job/components/jobDetails";
import LoginPage from "./pages/Login/Index";
import PaymentFailed from "./pages/PaymentResponse/Failure";
import PlanDetailsPage from "./pages/PaymentResponse/planDetails.page";
import PaymentSuccess from "./pages/PaymentResponse/Success";
import ProfilePage from "./pages/Profile/Index";
import ResourcePage from "./pages/Resource/Index";
import ResumePage from "./pages/Resume/Index";
import TemplatePreview from "./pages/TemplatePreview/Index";
import InternalResumePreviewMain from "./pages/TemplatePreview/internalPreview.main";
import useStyles from "./styles/main.styles";
import GlobalStyles from "./theme/globalStyles";
import ThemeConfig from "./theme/index";

const App = () => {
  const isExist = localStorage.getItem("token");
  const classes = useStyles();
  const [isAuth, setIsAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setLoading(true);
    axios
      .post(`${endPoints.isAuth}`, { token: isExist })
      .then((r) => {
        setIsAuth(true);
        setLoading(false);
      })
      .catch((e) => {
        localStorage.removeItem("token");
        setIsAuth(false);
        setLoading(false);
      });
  }, [isExist]);
  let routes = useRoutes([
    {
      path: "/",
      element: !isAuth ? (
        <>
          <LoginPage />
          <Outlet />
        </>
      ) : (
        <Navigate to={"/dashboard"} />
      ),
    },
    {
      path: "dashboard",
      element: isAuth ? <DashboardPage /> : <Navigate to={"/"} />,
    },
    {
      path: "dashboard/:resumeId/create/",
      element: isAuth ? <ResumePage /> : <Navigate to={"/"} />,
    },
    {
      path: "dashboard/:resumeId/preview/",
      element: isAuth ? <TemplatePreview /> : <Navigate to={"/"} />,
    },
    {
      path: "/resource",
      element: isAuth ? <ResourcePage /> : <Navigate to={"/"} />,
    },
    {
      path: "/profile",
      element: isAuth ? <ProfilePage /> : <Navigate to={"/"} />,
    },
    { path: "/jobfeed", element: <JobFeed /> },
    { path: "/jobfeed/:jobTitle/:jobId", element: <JobDetails /> },
    { path: "/dtu-engifest", element: <DTUPage /> },
    {
      path: "/preview/:userId/:resumeId",
      element: <InternalResumePreviewMain />,
    },
    { path: "404", element: <NotFound /> },
    { path: "success", element: <PaymentSuccess /> },
    { path: "failure", element: <PaymentFailed /> },
    {
      path: "plans",
      element: isAuth ? <PlanDetailsPage /> : <Navigate to={"/"} />,
    },
    { path: "*", element: <Navigate to={"/404"} /> },
  ]);
  if (loading)
    return (
      <div className={classes.loader}>
        <CircularProgress
          disableShrink
          size={35}
          color="primary"
          thickness={6}
        />
      </div>
    );
  return <div className={classes.headContainer}>{routes}</div>;
};

const AppWrapper = () => {
  // const [isChat, setIsChat] = React.useState(false);
  return (
    <div className="App">
      {/* <CssBaseline /> */}
      <ThemeConfig>
        <GlobalStyles />
        <Helmet>
          <meta
            property="og:image"
            itemProp="image"
            content="https://rb.vipapplicant.com/logo.svg"
          />
        </Helmet>
        <App />
        {/* <div className="chatbot-div">
          <CustomButton
            icon={
              isChat ? (
                <FaTimes />
              ) : (
                <VibrateBox>
                  <AiOutlineWechat />
                </VibrateBox>
              )
            }
            className="chatbot-icon"
            variant="contained"
            color="error"
            onClick={(e) => setIsChat(!isChat)}
          />
          <iframe
            style={{
              display: isChat ? "block" : "none",
            }}
            title="VA CHATBOT"
            allow="microphone;"
            src="https://console.dialogflow.com/api-client/demo/embedded/fa42820b-483d-42e5-8ad2-74a16107abc8"
          />
        </div> */}
      </ThemeConfig>
    </div>
  );
};

export default AppWrapper;
