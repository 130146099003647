import { makeStyles } from "@mui/styles";
import spin from "../../../../components/animate/spin";

let ExperienceStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    marginLeft: "0px",
    paddingLeft: "0px !important",
  },
  checkbox: {
    marginLeft: "0px",
  },
  dialogDiv: {
    color: theme.palette.warning.dark,
    fontWeight: "bold",
    "& ul": {
      marginLeft: 15,
      fontWeight: "normal",
      color: "#000",
    },
    "& div": {
      marginBottom: 10,
    },
  },

  helperDiv: {
    margin: "0px auto",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    bottom: -20,
    fontWeight: "bold",
    fontSize: "small",
  },
  helperTypo: {
    textAlign: "center",
  },
  popover: {
    height: "35px",
    width: "35px",
    position: "absolute",
    boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
    display: "flex",
    background: theme.palette.warning.lighter,
    color: theme.palette.warning.dark,
    right: "10px",
    fontSize: "large",
    fontFamily: "inherit",
    padding: "5px 10px",
    top: "65%",
    [theme.breakpoints.down("sm")]: {
      bottom: "60px",
      top: "inherit",
    },
    borderRadius: "50%",
  },
  contentLeft: {
    paddingTop: "0px !important",
    paddingLeft: "0px !important",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "46%",
    },
    overflow: "auto",
    overflowX: "hidden",
    "& #cardContainer": {
      display: "flex",
      maxHeight: 994,
      flexDirection: "column",
      overflowX: "hidden",
      overflow: "auto",
      gap: 20,

      padding: 20,
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  aiUsage: {
    height: "auto",
    width: "auto",
    position: "absolute",
    display: "flex",
    top: "-7%",
    color: theme.palette.warning.main,
    right: "20px",
    fontSize: "small",
    fontFamily: "inherit",
    padding: "5px 10px",
    borderRadius: "20px",
    background: theme.palette.grey[200],
    [theme.breakpoints.down("sm")]: {
      top: "-45px",
    },
  },
  contentRight: {
    paddingTop: "25px !important",
    marginLeft: "0px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px !important",
      "& .MuiGrid-item": {
        paddingLeft: "0px !important",
      },
    },
  },
  typography: {
    paddingTop: 50,
    color: theme.palette.text.disabled,
    textAlign: "center",
  },

  aiBox: {
    position: "relative",
  },
  "& .RESPO_useAI_improve_btn": {
    background: "red",
  },
  btnStyle: {
    padding: "3px 10px",
    width: "10rem",
    color: theme.palette.primary.dark,
    background: theme.palette.gradients.warning,
    position: "absolute",
    top: "77%",
    right: "calc(50% - 5rem)",
    "& span": {
      display: "flex",
      gap: 10,
      "& svg": {
        margin: "auto 0px",
        animation: `${spin} 2s infinite ease`,
      },
    },
    "&:disabled": {
      background: "#cfcfcf!important",
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down("sm")]: {
      top: "inherit",
      bottom: "30px",
      right: "calc(50% - 70px)",
      fontSize: "x-small",
    },
  },
  actionBtn: {
    margin: "10px auto",
    display: "flex",
    gap: 10,
    minWidth: 150,
  },
}));

export default ExperienceStyles;
