import { Box, Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import bgImageGreen from "../../../../assets/scoreGreen.jpg";
import bgImageRed from "../../../../assets/scoreRed.jpg";
import bgImageYellow from "../../../../assets/scoreYellow.jpg";

const DashboardScoreCard = ({
  value,
  property,
  open,
  handleOpen,
  ...props
}) => {
  const scorePropertyCheck = (property) => {
    switch (property) {
      case "IMPACT":
        return 30;
      case "INITIATIVE":
        return 10;
      case "SKILLS":
        return 25;
      case "ACADEMIC":
        return 20;
      case "EXPERIENCES":
        return 15;
      default:
        break;
    }
  };
  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }
  const titlePropertyCheck = (property) => {
    switch (property) {
      case "IMPACT":
        return "What is the impression your resume leaves on the recruiters?";
      case "INITIATIVE":
        return "How willing and enthusiastic are you to try diverse things in life?";
      case "SKILLS":
        return "Does your resume adequately represent your skills and qualifications?";
      case "ACADEMIC":
        return "How does the recruiter view your academic performance and consistency?";
      case "EXPERIENCES":
        return "What is the quantity and quality of the work you have done outside the classroom?";
      default:
        break;
    }
  };
  let avatarImg = bgImageYellow;
  if (percentage(value, scorePropertyCheck(property)) < 30)
    avatarImg = bgImageRed;
  else if (
    percentage(value, scorePropertyCheck(property)) >= 30 &&
    percentage(value, scorePropertyCheck(property)) <= 50
  )
    avatarImg = bgImageYellow;
  else avatarImg = bgImageGreen;
  return (
    <>
      <Button
        onClick={props.isModal ? (e) => {} : handleOpen}
        elevation={8}
        sx={{
          borderRadius: "50%",
          height: 90,
          width: 90,
          // bgcolor: avatarColor,
          backgroundImage: `url(${avatarImg})`,
          border: "px solid #47A879",
          backgroundPosition: "center center",
        }}
      >
        <Tooltip title={titlePropertyCheck(property)} style={{}}>
          <Box
            display="flex"
            color="grey"
            alignItems="center"
            justifyContent="center"
            sx={{ height: 90 }}
          >
            <Box
              textAlign="center"
              sx={{
                pl: { xs: "0px", sm: "6px", md: "10px" },
                backgroundColor: "transparent",
                color: "black",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  opacity: 0.9,
                  fontSize: { xs: "0.8rem", sm: "1rem", md: "1.1rem" },
                }}
              >
                {value ? value : 0}/ {scorePropertyCheck(property)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  opacity: 0.6,
                  fontSize: { xs: ".6rem", sm: ".6rem", md: "0.7rem" },
                }}
              >
                {property}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </Button>
    </>
  );
};

export default DashboardScoreCard;
