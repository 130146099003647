import InfoIcon from "@mui/icons-material/Info";
import {
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { keyframes } from "@mui/system";
import axios from "axios";
import classNames from "classnames";
import React, { useRef } from "react";
import {
  FaBolt,
  FaCheckCircle,
  FaFan,
  FaPlusCircle,
  FaRocket,
  FaTimes,
  FaTimesCircle,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/CustomButton/Index";
import CustomIconButton from "../../../components/CustomIcon/Index";
import CustomInputField from "../../../components/CustomInputField/Index";
import CustomModal from "../../../components/CustomModal/Index";
import CustomTagBasedInput from "../../../components/CustomTagBasedInput/Index";
import endPoints from "../../../config/endPoint";
import { updatePlanFeatureUsageThunk } from "../../../redux/counterSlice";
import { getExperienceThunk } from "../../../redux/Dashboard/experience.slice";
import {
  createHobbiesThunk,
  createLanguagesThunk,
  deleteExtraCurricularThunk,
  getExtracurricularThunk,
} from "../../../redux/Dashboard/extracurricular.slice";
import { getPersonalDetail } from "../../../redux/Dashboard/personalDetail.slice";
import ExtracurricularStyles from "../../../styles/pages/Resume/components/extracurricular.styles";
import { formatNumber } from "../../../utils/contentFormatter";
import { sortArrayWithDate } from "../../../utils/dateFormatter";
import createGPTHandlers from "../commonApi/gpt.handler";
import UsageContent from "./subComponents/personal/usageContent";

const spin = keyframes`
  from{
    transform: rotate(0deg);
  }
   to{
     transform: rotate(360deg);
  }
`;

const RotatedBox = styled("div")({
  display: "flex",
  alignItems: "normal",
  animation: `${spin} 2s infinite linear`,
});

export default function ExtracurricularComponent({
  value = {},
  handleChange,
  bulkUpdate,
}) {
  const classes = ExtracurricularStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const extracurricular = useSelector((state) => state.extracurricular);
  const personalDetail = useSelector((state) => state.personalDetail);
  const experience = useSelector((state) => state.experience);
  // const skills = useSelector((state) => state.skill.skills);
  const { resumeId } = useParams();

  const [suggestion, setSuggestion] = React.useState("");
  const [sample, setSample] = React.useState(false);
  const [option, setOption] = React.useState(0);
  const [data1, setData1] = React.useState("");
  const [data2, setData2] = React.useState("");
  const [data3, setData3] = React.useState("");
  const [selected, setSelected] = React.useState({
    selection: false,
    eve: null,
    start: null,
    end: null,
  });

  const token = localStorage.getItem("token");
  const [showSuggestion, setShowSuggestion] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const getLatestExperienceRole = () => {
    let arr = sortArrayWithDate(experience.experiences, "ex_end_date");
    return arr?.[0]?.ex_role?.trim();
  };

  const getSubPrompt = (data) => {
    let filteredData = data.filter((d) => d.ex_type === "Full time");
    let num = filteredData.map((d) => {
      if (!d.ex_end_date) {
        return (
          new Date().getFullYear() - new Date(d.ex_start_date).getFullYear()
        );
      }
      return (
        new Date(d.ex_end_date).getFullYear() -
        new Date(d.ex_start_date).getFullYear()
      );
    });
    let totalExp = num.reduce((a, b) => a + b, 0);
    // let role = filteredData.map((d) => d.ex_role).toString();
    // let skillsArray = Object.values(skills);
    // let skillsStr = skillsArray
    //   .map((skill) => skill.map((s) => s.label).toString())
    //   .filter((e) => e !== "")
    //   .toString();
    // return skillsArray.length > 0
    //   ? `${skillsStr}, ${totalExp} Years, ${role}`
    //   : "";
    return !!totalExp ? `\n${"Years of Experience - " + totalExp}+` : "";
  };

  const userPlanLimits = useSelector((state) => state.counter.user_plan_limits);
  const [keywords, setKeyWords] = React.useState([]);
  const [keyword, setKeyword] = React.useState("");
  const [isClicked, setClicked] = React.useState(false);
  const aiOptionRef = useRef();
  const [extra, setExtra] = React.useState({
    isHobbyExist: false,
    isLanguageExist: false,
  });

  const aiUsed = userPlanLimits.find(
    (p) => p.feature_name === "ai_credits"
  )?.limit_used;
  const aiLimit = userPlanLimits.find(
    (p) => p.feature_name === "ai_credits"
  )?.usage_limit;
  const handleClickImproveAI = async () => {
    if (process.env.NODE_ENV !== "development") {
      window.hj("event", "experience_fb");
    }
    setClicked(true);
    let d = await axios.post(`${endPoints.useAISuggestion}`, {
      token: token,
      text: `Improve the below text by starting it with an action verb:\n ${selected.eve?.target.value.substring(
        selected.start,
        selected.end
      )}`,
      data: {
        temperature: 0.7,
        max_tokens: 100,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        best_of: 1,
      },
    });
    createGPTHandlers({
      resumeId,
      callField: "summary",
      prompt: selected.eve?.target.value.substring(
        selected.start,
        selected.end
      ),
      opt1: d.data.results[0],
      opt2: null,
      opt3: null,
      selection: option,
      token,
    });
    handleChange(
      {
        name: "pd_summary",
        value:
          selected.eve?.target.value.substring(0, selected.start) +
          " " +
          d.data.results[0]?.toString().trim().replaceAll("\n", "") +
          " " +
          selected.eve?.target.value.substring(
            selected.end,
            selected.eve?.target.value.length
          ),
      },
      true
    );
    setSelected({
      selection: false,
      eve: null,
      start: null,
      end: null,
    });
    dispatch(
      updatePlanFeatureUsageThunk({ token: token, feature_name: "ai_credits" })
    );
    setShowSuggestion(true);
    setClicked(false);

    aiOptionRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleClickUseAI = async () => {
    if (process.env.NODE_ENV !== "development") {
      window.hj("event", "pers_det_button");
    }
    setClicked(true);
    let d = await axios.post(`${endPoints.useAISuggestion}`, {
      token: token,
      text: !getLatestExperienceRole()
        ? `Create a paragraph long Professional Summary without using personal pronoun for the resume on the following information.${getSubPrompt(
            experience.experiences
          )}${
            !keywords.join().trim() ? "" : `\nKeywords: ${keywords.join()}:`
          }\n${value.pd_summary}`
        : `Create a paragraph long Professional Summary without using personal pronoun for the resume on the following information.\nTargeted Role - ${getLatestExperienceRole()} ${getSubPrompt(
            experience.experiences
          )}${
            !keywords.join().trim() ? "" : `\nKeywords - ${keywords.join()}:`
          }\n${value.pd_summary}`,
      data: {
        temperature: 1,
        max_tokens: 100,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        best_of: 1,
      },
    });
    setData1(d.data.results);
    let d2 = await axios.post(`${endPoints.useAISuggestion}`, {
      token: token,
      text: !getLatestExperienceRole()
        ? `Create a paragraph long Professional Summary without using personal pronoun for the resume on the following information.${getSubPrompt(
            experience.experiences
          )}${
            !keywords.join().trim() ? "" : `\nKeywords: ${keywords.join()}:`
          }\n${value.pd_summary}`
        : `Create a paragraph long Professional Summary without using personal pronoun for the resume on the following information.\nTargeted Role - ${getLatestExperienceRole()} ${getSubPrompt(
            experience.experiences
          )}${
            !keywords.join().trim() ? "" : `\nKeywords - ${keywords.join()}:`
          }\n${value.pd_summary}`,
      data: {
        temperature: 1,
        max_tokens: 100,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        best_of: 1,
      },
    });
    setData2(d2.data.results);
    let d3 = await axios.post(`${endPoints.useAISuggestion}`, {
      token: token,
      text: !getLatestExperienceRole()
        ? `Create a paragraph long Professional Summary without using personal pronoun for the resume on the following information.${getSubPrompt(
            experience.experiences
          )}${
            !keywords.join().trim() ? "" : `\nKeywords: ${keywords.join()}:`
          }\n${value.pd_summary}`
        : `Create a paragraph long Professional Summary without using personal pronoun for the resume on the following information.\nTargeted Role - ${getLatestExperienceRole()} ${getSubPrompt(
            experience.experiences
          )}${
            !keywords.join().trim() ? "" : `\nKeywords - ${keywords.join()}:`
          }\n${value.pd_summary}`,
      data: {
        temperature: 1,
        max_tokens: 100,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        best_of: 1,
      },
    });
    setData3(d3.data.results);

    // createGPTHandlers({
    //   resumeId,
    //   callField: "summary",
    //   prompt: JSON.stringify({
    //     temperature: 1,
    //     prompt: `Create a paragraph long Professional Summary starting with an action verb for the resume on the following information: \nTargeted role : ${getLatestExperienceRole()} \n Years of Experience :7+ \n Skills : AWS, Cloud Computing , SAAS:`,
    //     max_tokens: 120,
    //     top_p: 1,
    //     frequency_penalty: 0,
    //     presence_penalty: 0,
    //     best_of: 1,
    //   }),
    //   opt1: d.data.results?.toString(),
    //   opt2: null,
    //   opt3: null,
    //   selection: option,
    //   token,
    // });
    // handleChange(
    //   {
    //     name: "pd_summary",
    //     value: value.pd_summary
    //       ? value.pd_summary + " " + d.data.results
    //       : d.data.results,
    //   },
    //   true
    // );
    dispatch(
      updatePlanFeatureUsageThunk({ token: token, featureName: "ai_credits" })
    );
    setShowSuggestion(true);
    aiOptionRef.current?.scrollIntoView({
      behavior: "smooth",
    });
    setClicked(false);
  };
  React.useEffect(() => {
    dispatch(
      getPersonalDetail({ token: token, resumeId: resumeId, cb: bulkUpdate })
    );
    dispatch(getExperienceThunk({ token: token, resume_id: resumeId }));
    // bulkUpdate(personalDetail.raw)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    handleChange(
      {
        name: "pd_summary",
        value: personalDetail.raw.pd_summary,
      },
      true
    );
    dispatch(
      getExtracurricularThunk({
        token: localStorage.getItem("token"),
        resume_id: resumeId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e, name) => {
    if (name === "hobby") {
      if (
        extracurricular.hobbies.findIndex(
          (hob) => hob.ec_item_desc === value.hobby
        ) === -1
      ) {
        dispatch(
          createHobbiesThunk({
            token: localStorage.getItem("token"),
            resume_id: resumeId,
            ec_type: "HO",
            ec_item_desc: value.hobby,
            del_flag: 0,
          })
        );
        handleChange(
          {
            name: "hobby",
            value: "",
          },
          true
        );
      } else {
        setExtra({
          ...extracurricular,
          isHobbyExist: true,
        });
      }
    } else {
      if (
        extracurricular.languages.findIndex(
          (lang) => lang.ec_item_desc === value.language
        ) === -1
      ) {
        dispatch(
          createLanguagesThunk({
            token: localStorage.getItem("token"),
            resume_id: resumeId,
            ec_type: "LG",
            ec_item_desc: value.language,
            del_flag: 0,
          })
        );

        handleChange(
          {
            name: "language",
            value: "",
          },
          true
        );
      } else {
        setExtra({
          ...extracurricular,
          isLanguageExist: true,
        });
      }
    }
  };
  if (extracurricular.loading)
    return (
      <div className={classes.loader}>
        <CircularProgress disableShrink size={30} thickness={6} />
      </div>
    );
  return (
    <form autoComplete="off">
      <Grid className={classes.container} container spacing={5} rowGap={4}>
        <Grid
          item
          xs={12}
          style={{
            marginTop: 30,
            paddingTop: 0,
            position: "relative",
          }}
        >
          <CustomTagBasedInput
            chipsArr={keywords}
            fullWidth
            label={"ADD KEYWORDS"}
            value={keyword}
            helperText={
              <span className={classes.helperText}>
                Add adjectives, achievements you want to highlight in
                professional summary.
              </span>
            }
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={(e) => {
              if (!keywords.includes(keyword) && keyword && e.key === "Enter") {
                setKeyWords([...keywords, ...keyword?.split(",")]);
                setKeyword("");
              }
            }}
            handleDeleteCallback={(idx) => {
              setKeyWords(keywords.filter((a, i) => i !== idx));
            }}
            endAdornment={
              <CustomIconButton
                style={{
                  borderRadius: "inherit",
                }}
                icon={
                  <span className={classes.iconText}>
                    Add Keyword
                    <FaPlusCircle className={classes.icon} />
                  </span>
                }
                onClick={(e) => {
                  if (!keywords.includes(keyword) && keyword) {
                    setKeyWords([...keywords, ...keyword?.split(",")]);
                    setKeyword("");
                  }
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} className={classes.aiBox}>
          <CustomModal
            mbContent={1}
            isOpen={open}
            modalWidth="md"
            setOpen={setOpen}
            dialogTitle={<b>How to Write Better Professional Summary? </b>}
            cancelBtnColor={"error"}
            cancelBtnText={
              <>
                <FaTimes
                  style={{
                    marginRight: 5,
                  }}
                />{" "}
                close
              </>
            }
            dialogContentText={
              <>
                <UsageContent classes={classes} />
                <Divider />
              </>
            }
          />
          <Tooltip
            title={`In case you need more AI Credit please upgrade your plan or reach out to our support team.(support@vipapplicant.com)`}
            placement="left"
          >
            <div className={classes.aiUsage}>
              <p
                style={{
                  margin: "auto 5px",
                }}
              >
                {" "}
                AI Usage: {aiUsed} / {aiLimit}
              </p>{" "}
              {aiUsed === aiLimit ? (
                <CustomButton
                  icon={<FaBolt />}
                  variant="contained"
                  style={{
                    margin: "auto 5px",
                    minWidth: "20px",
                    borderRadius: "rounded",
                  }}
                  size="small"
                  component={Link}
                  to={"/plans"}
                />
              ) : (
                <InfoIcon
                  style={{
                    margin: "auto 5px",
                  }}
                />
              )}
            </div>
          </Tooltip>

          <CustomModal
            cancelBtnText={"Close"}
            isOpen={sample}
            dialogTitle="Professional Summary Sample"
            setOpen={setSample}
            dialogContentText={
              <div>
                <Typography variant="h5">
                  Good Examples for Professional Summary{" "}
                </Typography>
              </div>
            }
            dialogContent={
              <div className={classes.dialogDiv}>
                <div>
                  Good Example for Sales Executive
                  <p>
                    A passionate executive with 3 years of experience as a sales
                    manager. Well-versed with modern sales tools and possess a
                    proven background in using them for sales effectiveness.
                    Raised average profit margins by 20% in the last 8 months by
                    introducing new customer loyalty programs. Grew new business
                    by 30% by implementing new customer acquisition strategies.
                  </p>
                </div>
                <div>
                  Good Example for Freshers
                  <p>
                    Hard-working entry-level programmer with proven skills in
                    Java and Python coding. Seeking to deliver programming
                    excellence at your organisation. Worked on 4 freelance
                    projects and 2 internships, including an eCommerce website.
                    Participated in Techgig’s Code Gladiators and ranked in the
                    Top 30.
                  </p>
                </div>
                <div>
                  Good Example for Project Manager
                  <p>
                    Resourceful IT Project Management Professional with 7+ years
                    of experience. Seeking to use strong strategic planning
                    skills to raise the metrics for quality, cost, and time at
                    your organisation. PMP certified and trained 20 employees in
                    lean practices at TCS and cut costs by 32%.
                  </p>
                </div>
                <div>
                  Good Example for Customer Service Executive
                  <p>
                    Results-oriented customer service executive with 2+ years of
                    experience. Looking to raise customer retention at your
                    organisation. Received 99% positive customer ratings at Tata
                    Cliq Luxury, which is 25% above the company average.
                    Received Associate of the Month twice in my two-year tenure.
                  </p>
                </div>
              </div>
            }
            mbContent={1}
          />

          <CustomButton
            text={"How to Write Better Professional Summary?"}
            className={classes.guideLineBtn}
            size="small"
            variant="contained"
            color="primary"
            onClick={(e) => setOpen(true)}
          />
          <CustomInputField
            label={"PROFESSIONAL SUMMARY"}
            fullWidth
            helperText={
              <span className={classes.helperText}>
                Important: Write one-line Professional Summary for a better AI
                result (minimum {formatNumber(8)} words).
                <CustomButton
                  size="small"
                  onClick={(e) => setSample(true)}
                  text={"Professional Summary Sample"}
                  variant="outlined"
                />
              </span>
            }
            onMouseUp={(e) => {
              var indexStart = e.target?.selectionStart;
              var indexEnd = e.target?.selectionEnd;
              setSelected({
                selection: !!e.target.value?.substring(indexStart, indexEnd),
                eve: e,
                start: indexStart,
                end: indexEnd,
              });
            }}
            value={value.pd_summary}
            onChange={handleChange}
            name={"pd_summary"}
            type="textarea"
            multiline
            rows={7}
            freeSolo={false}
          />
          {
            /* selected.selection */ false ? (
              <CustomButton
                onClick={!isClicked ? handleClickImproveAI : () => {}}
                disabled={
                  !getLatestExperienceRole() ||
                  (aiUsed < aiLimit ? false : true)
                }
                text={
                  isClicked ? (
                    <CircularProgress disableShrink size={15} thickness={6} />
                  ) : (
                    <span>
                      <FaRocket />
                      Improve with AI
                    </span>
                  )
                }
                variant="contained"
                className={classNames(
                  classes.btnStyle,
                  "RESPO_useAI_improve_btn"
                )}
                style={{
                  background: theme.palette.success.dark,
                  color: theme.palette.grey[200],
                }}
              />
            ) : (
              <CustomButton
                disabled={
                  !!getLatestExperienceRole()
                    ? !getLatestExperienceRole() || aiUsed < aiLimit
                      ? false
                      : true
                    : value.pd_summary?.replace(/\s+/g, " ").trim().split(" ")
                        .length <= 120 &&
                      value.pd_summary?.replace(/\s+/g, " ").trim().split(" ")
                        .length >= 8 &&
                      aiUsed < aiLimit
                    ? false
                    : true
                }
                onClick={!isClicked ? handleClickUseAI : () => {}}
                text={
                  isClicked ? (
                    <CircularProgress disableShrink size={15} thickness={6} />
                  ) : (
                    <span>
                      {" "}
                      {(
                        !!getLatestExperienceRole()
                          ? !!getLatestExperienceRole() && aiUsed < aiLimit
                          : value.pd_summary
                              ?.replace(/\s+/g, " ")
                              .trim()
                              .split(" ").length <= 120 &&
                            value.pd_summary
                              ?.replace(/\s+/g, " ")
                              .trim()
                              .split(" ").length >= 8 &&
                            aiUsed < aiLimit
                      ) ? (
                        <RotatedBox>
                          <FaFan />
                        </RotatedBox>
                      ) : (
                        <FaFan />
                      )}
                      Generate with AI
                    </span>
                  )
                }
                variant="contained"
                className={classNames(classes.btnStyle, "PROFE_useAI_btn")}
              />
            )
          }
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            paddingTop: 0,
          }}
        >
          {showSuggestion ? (
            <FormControl component="fieldset" ref={aiOptionRef}>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Typography variant="h1" color={"success"}>
                  <CustomIconButton
                    className="useAI_option_select"
                    icon={
                      <FaCheckCircle
                        style={{
                          color: theme.palette.success.dark,
                        }}
                      />
                    }
                    onClick={(e) => {
                      createGPTHandlers({
                        resumeId,
                        callField: "summary",
                        prompt: value.pd_summary,
                        opt1: data1[0],
                        opt2: data2[0],
                        opt3: data3[0],
                        selection: option,
                        token,
                      });
                      handleChange(
                        {
                          name: "pd_summary",
                          value: (value.pd_summary ?? "") + " " + suggestion,
                        },
                        true
                      );
                      setShowSuggestion(false);
                    }}
                  />
                </Typography>
                <Typography variant="h1" color={"error"}>
                  <CustomIconButton
                    className="useAI_option_cancel"
                    icon={
                      <FaTimesCircle
                        style={{
                          color: theme.palette.error.dark,
                        }}
                      />
                    }
                    onClick={(e) => {
                      createGPTHandlers({
                        resumeId,
                        callField: "summary",
                        prompt: `Create a paragraph long Professional Summary starting with an action verb for the resume on the following information: \n ${
                          !!getLatestExperienceRole()
                            ? "Targeted role: " + getLatestExperienceRole()
                            : ""
                        } ${getSubPrompt(experience.experiences)}`,
                        opt1: data1[0],
                        opt2: data2[0],
                        opt3: data3[0],
                        selection: option,
                        token,
                      });
                      setShowSuggestion(false);
                    }}
                  />
                </Typography>
              </div>
              <FormLabel component="legend">
                SUGGESTIONS
                <Typography
                  variant="body2"
                  color={"#c17d00"}
                  style={{
                    display: "inline",
                  }}
                >
                  ( Select one option and tick the green button to add to your
                  resume. )
                </Typography>
              </FormLabel>
              <RadioGroup
                style={{
                  display: "flex",
                  gap: 10,
                  flexDirection: "column",
                }}
                aria-label="gender"
                defaultValue="female"
                name="radio-buttons-group"
                value={suggestion}
                onChange={(e) => {
                  setSuggestion(e.target.value);
                }}
              >
                {data1[0] && (
                  <FormControlLabel
                    value={data1[0]}
                    onClick={() => setOption(1)}
                    control={<Radio />}
                    label={data1}
                  />
                )}
                {!!data2[0] && (
                  <FormControlLabel
                    value={data2[0]}
                    onClick={() => setOption(2)}
                    control={<Radio />}
                    label={data2}
                  />
                )}
                {data3[0] && (
                  <FormControlLabel
                    value={data3[0]}
                    onClick={() => setOption(3)}
                    control={<Radio />}
                    label={data3}
                  />
                )}
              </RadioGroup>
            </FormControl>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        style={{
          paddingTop: 40,
        }}
      >
        <Grid item xs={12} sm={6}>
          <CustomTagBasedInput
            uapPillClass={"extras_item_hobbies_chip"}
            uapWrapperClass={"extras_ul_hobbies_wrapper"}
            label={"INTERESTS"}
            fullWidth
            error={extra.isHobbyExist}
            chipsArr={extracurricular.hobbies.map((ex) => ex.ec_item_desc)}
            value={value.hobby}
            handleDeleteCallback={(idx) => {
              dispatch(
                deleteExtraCurricularThunk({
                  token: localStorage.getItem("token"),
                  resume_id: resumeId,
                  ec_id: extracurricular.hobbies[idx].ec_id,
                })
              );
            }}
            name="hobby"
            onKeyDown={(e) => {
              if (value.hobby) {
                e.key === "Enter" && handleClick(e, "hobby");
              }
            }}
            helperText={
              extra.isHobbyExist
                ? "Duplicate! Hobby is already added"
                : "Start adding your interests"
            }
            onChange={(e) => {
              if (
                e.target.value.length <= 40 &&
                e.target.value.match(/^[a-zA-Z\s]*$/)
              ) {
                handleChange(e);
                setExtra({
                  isHobbyExist: false,
                  isLanguageExist: false,
                });
              }
            }}
            endAdornment={
              <CustomIconButton
                style={{
                  borderRadius: "inherit",
                }}
                icon={
                  <span className={classes.iconText}>
                    Add Interest <FaPlusCircle className={classes.icon} />
                  </span>
                }
                onClick={(e) => {
                  if (value.hobby) {
                    handleClick(e, "hobby");
                  }
                }}
              />
            }
          />
          {/* <Paper
            style={{
              background: "#eee",
              marginBottom: "20px",
              marginRight: 25,
            }}
          >
            <iframe
              style={{
                width: "100%",
              }}
              height="315"
              src="https://www.youtube.com/embed/XsO-MApRcCY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Paper> */}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.languageGrid}>
          <CustomTagBasedInput
            label={"LANGUAGES"}
            fullWidth
            uapPillClass={"extras_ul_lang_item_chip"}
            uapWrapperClass={"extras_ul_lang_wrapper"}
            error={extra.isLanguageExist}
            handleDeleteCallback={(idx) => {
              dispatch(
                deleteExtraCurricularThunk({
                  token: localStorage.getItem("token"),
                  resume_id: resumeId,
                  ec_id: extracurricular.languages[idx].ec_id,
                })
              );
            }}
            chipsArr={extracurricular.languages.map((ex) => ex.ec_item_desc)}
            onKeyDown={(e) => {
              if (value.language) {
                e.key === "Enter" && handleClick(e, "language");
              }
            }}
            value={value.language}
            name="language"
            helperText={
              extra.isLanguageExist
                ? "Duplicate! Language is already added"
                : "Start adding the languages you know"
            }
            onChange={(e) => {
              if (
                e.target.value.length <= 40 &&
                e.target.value.match(/^[a-zA-Z\s]*$/)
              ) {
                handleChange(e);
                setExtra({
                  isHobbyExist: false,
                  isLanguageExist: false,
                });
              }
            }}
            endAdornment={
              <CustomIconButton
                style={{
                  borderRadius: "inherit",
                }}
                icon={
                  <span className={classes.iconText}>
                    Add Language
                    <FaPlusCircle className={classes.icon} />
                  </span>
                }
                onClick={(e) => {
                  if (value.language) {
                    handleClick(e, "language");
                  }
                }}
              />
            }
          />
        </Grid>
      </Grid>
    </form>
  );
}
