import { makeStyles } from "@mui/styles";
import spin from "../../../../components/animate/spin";

let ExtracurricularStyles = makeStyles((theme) => ({
  icon: {
    // color: theme.palette.primary.main
    fontSize: "1rem",
  },
  helperText: {
    paddingTop: 10,
    fontSize: "0.8rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogDiv: {
    color: theme.palette.warning.dark,
    fontWeight: "bold",
    "& p": {
      fontWeight: "normal",
      color: "#000",
    },
    "& div": {
      marginBottom: 10,
    },
  },
  iconText: {
    fontSize: "0.8rem",
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  container: {
    width: "100%",
    marginLeft: "0px",
    paddingLeft: "0px !important",
    "& .MuiGrid-item": {
      paddingLeft: "0px !important",
    },
  },
  languageGrid: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  typography: {
    paddingTop: 50,
    color: theme.palette.text.disabled,
    textAlign: "center",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  aiBox: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  btnStyle: {
    padding: "3px 10px",
    width: "10rem",
    color: theme.palette.primary.dark,
    background: theme.palette.gradients.warning,
    position: "absolute",
    top: "79%",
    right: "calc(50% - 5rem)",
    "& span": {
      display: "flex",
      gap: 10,
      "& svg": {
        margin: "auto 0px",
        animation: `${spin} 2s infinite ease`,
      },
    },
    "&:disabled": {
      background: "#cfcfcf!important",
      color: theme.palette.grey[100],
    },
    [theme.breakpoints.down("lg")]: {
      top: "84%",
      right: "calc(50% - 70px)",
      fontSize: "x-small",
    },
    [theme.breakpoints.down("sm")]: {
      top: "78%",
      right: "calc(50% - 70px)",
      fontSize: "x-small",
    },
  },
  guideLineBtn: {
    background: theme.palette.primary.main,
    position: "absolute",
    top: "2%",
    left: "12rem",
    "& span": {
      display: "flex",
      "& svg": {
        margin: "auto 0px",
        animation: `${spin} 2s infinite ease`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      top: "-12%",
      left: "0px",
      fontSize: "x-small",
    },
  },
  aiUsage: {
    height: "auto",
    width: "auto",
    position: "absolute",
    display: "flex",
    top: "-1%",
    color: theme.palette.warning.main,
    right: "20px",
    fontSize: "small",
    fontFamily: "inherit",
    padding: "5px 10px",
    // borderRadius: "20px",
    background: theme.palette.grey[200],
    [theme.breakpoints.down("sm")]: {
      top: "5px",
      right: 0,
      fontSize: "x-small",
      padding: "2px 7px",
    },
  },
  li: {
    marginBottom: "0.7rem",
  },
  p: {
    margin: 0,
  },
  guideLineStyle: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      left: "50px",
    },
  },
}));

export default ExtracurricularStyles;
