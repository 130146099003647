import * as React from "react";
import Alert from "@mui/material/Alert";
import customNotificationStyles from "../../styles/components/CustomNotification/index.style";
import { Snackbar } from "@mui/material";

export default function CustomNotification({
  severity,
  alertMessage,
  open,
  ...props
}) {
  const classes = customNotificationStyles();
  return (
    <Snackbar
      className={classes.slider}
      open={open}
      autoHideDuration={4500}
      {...props}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert severity={severity} {...props}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}
