import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import BottomNavigation from "../../layouts/bottomNavigation";
import DashboardSidebar from "../../layouts/index";
import jobFeedStyles from "../../styles/pages/Job/index.style";
import BasicCard from "./components/card";
import { Helmet } from "react-helmet";

const JobFeed = () => {
  const classes = jobFeedStyles();

  return (
    <>
      <Helmet>
        <meta
          name="keywords"
          content="software engineer jobs
software developer jobs
jobs for python developer
business analyst jobs
front end developer jobs
project manager jobs
operations manager jobs"
        />
        <meta
          name="title"
          content="Software engineer jobs | Developer | Java | Fullstack | Python | Frontend | Operations"
        />
        <meta
          name="description"
          content="Explore & apply for software engineer and software developer jobs. Get jobs for python developer, business analyst, front end developer, and project manager."
        />
      </Helmet>
      <div className={classes.container}>
        <DashboardSidebar />
        <div className={classes.contentDiv}>
          <Typography variant="h3" color={"primary"}>
            Job Feed
          </Typography>
          <Divider />
          <Box
            component="main"
            sx={{ flexGrow: 1, px: 3, py: { xs: 10, sm: 10, md: 10, lg: 3 } }}
          >
            {/* <Heading heading="Job Feed" /> */}
            <Grid container spacing={2}>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={"Fresher"}
                  yr={"(0-1)"}
                  location={"Pune"}
                  title={"Trainee Software Developers"}
                  subTitle={"Webonise Pvt. Ltd."}
                  link={
                    "Trainee-Software-Developers-B.Tech-Computer-Science-or-IT/3656216131"
                  }
                />
              </Grid>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={"Ex: "}
                  yr={"1+ Year"}
                  location={"Remote"}
                  title={
                    "Jr / Sr Developers, Tech Project Managers, Architects, Engineering Managers"
                  }
                  subTitle={""}
                  link={
                    "jr-sr-developers-tech-project-managers-architects-engineering-managers/3656214544"
                  }
                />
              </Grid>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={""}
                  location={"Delhi"}
                  title={"Software Engineer"}
                  subTitle={"1DigitalStack.ai"}
                  link={"1digitalstack-software-engineer/7523377112"}
                />
              </Grid>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={"Ex: "}
                  yr={"(0-1) Year"}
                  location={"Delhi"}
                  title={"Business Analyst"}
                  subTitle={"1DigitalStack.ai"}
                  link={"1digitalstack-business-analyst/4990101974"}
                />
              </Grid>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={""}
                  location={"Delhi"}
                  title={"Data Scientist"}
                  subTitle={"1DigitalStack.ai"}
                  link={"1digitalstack-data-scientist/7078906426"}
                />
              </Grid>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={"Fresher"}
                  location={"Remote"}
                  title={"Full-stack Developer"}
                  subTitle={"VIPApplicant"}
                  link={"vipapplicant-full-stack-developer/5078906421"}
                />
              </Grid>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={""}
                  location={"Mumbai"}
                  title={"Client Success Manager"}
                  subTitle={"Webonise Pvt. Ltd."}
                  link={"client-success-manager/4072791321"}
                />
              </Grid>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={"Ex: 1+ Year"}
                  location={""}
                  title={"Java Developers"}
                  subTitle={"IT Services and Consulting"}
                  link={"tech-hiring-developers-architects/5432135670"}
                />
              </Grid>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={"Ex: 5+ Years"}
                  location={""}
                  title={"AM/ Manager – Back End Developer"}
                  subTitle={"Edelweiss"}
                  link={"edelweiss-backend-developer/8527185712"}
                />
              </Grid>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={"Ex: 5+ Years"}
                  location={""}
                  title={"AM/ Manager – Front End Development ReactJS"}
                  subTitle={"Edelweiss"}
                  link={"edelweiss-frontend-developer/2573128808"}
                />
              </Grid>
              <Grid item sm={4} className={classes.gridItem}>
                <BasicCard
                  exp={"Ex: 3-5+ Years"}
                  location={""}
                  title={"Software Testing Engineers/QA"}
                  subTitle={"Edelweiss"}
                  link={"edelweiss-software-testing/1457342611"}
                />
              </Grid>
            </Grid>
          </Box>
        </div>

        <BottomNavigation value={2} />
      </div>
    </>
  );
};

export default JobFeed;
