import { toWords } from "number-to-words";
import { Country } from "country-state-city/lib/cjs";
export const formatNumber = (num = 0) => {
  if (num > 9) {
    return num;
  } else {
    return toWords(num);
  }
};

export const filterNumberByCountryCode = (number = "") => {
  const phoneCodeList = Country.getAllCountries().map((con) => {
    return con.phonecode?.split("+").length === 1
      ? "+" + con.phonecode?.split("+")[0]
      : "+" + con.phonecode?.split("+")[1];
  });
  let selectedPhnCode = phoneCodeList.filter((ph) => number.includes(ph))[0];
  return !selectedPhnCode
    ? [undefined, number]
    : [selectedPhnCode, number.split(selectedPhnCode)[1]?.trim()];
};
