// import Heading from "../components/Heading";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import classNames from "classnames";
import fileDownload from "js-file-download";
import React from "react";
import endPoints from "../../../../config/endPoint";
import RadioButtonsCustom from "../RadioComponents";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import Template5 from "./Template5";
import Template6 from "./Template6";
import Template7 from "./Template7";

const useStyles = makeStyles((theme) => ({
  resumeContainer: {
    display: "flex",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      width: "fit-content",
    },
  },
}));

const InternalResumePreview = ({
  inputData,
  TempRef,
  setTemplateData,
  url,
  setUrl,
  templateData,
}) => {
  console.log(inputData);
  const classes = useStyles();
  const [isLogo, setIsLogo] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [template, setTemplate] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  console.log(isOpen, show);
  const renderTemplate = (temp) => {
    switch (temp) {
      case 1:
        return (
          <Template1
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
            url={url}
            setUrl={setUrl}
          />
        );
      case 2:
        return (
          <Template2
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );
      case 3:
        return (
          <Template3
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );

      case 4:
        return (
          <Template4
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );

      case 5:
        return (
          <Template5
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );
      case 6:
        return (
          <Template6
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );
      case 7:
        return (
          <Template7
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );
      default:
        return (
          <Template1
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
          />
        );
    }
  };

  return (
    <div className={classes.resumeContainer}>
      <RadioButtonsCustom
        template={template}
        setTemplate={setTemplate}
        setIsOpen={setIsOpen}
        internal={true}
        style={{
          alignItems: "center",
          margin: "10px 0px 0px 20px",
          flex: 0.2,
        }}
      />
      <div
        style={{
          flex: 1,
        }}
      >
        <Box
          pt={4}
          px={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" align="left">
            MY RESUME
          </Typography>
          <div className={classes.btnContainer}>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked
                  checked={isLogo}
                  onChange={(e) => {
                    setIsLogo(e.target.checked);
                  }}
                />
              }
              label="View College Logo"
            />
          </div>
          {loading ? (
            <Button
              variant="contained"
              className={classNames(classes.btn, "resume_download_modal_btn")}
            >
              <CircularProgress
                disableShrink
                size={25}
                thickness={6}
                color="warning"
              />
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={(e) => {
                setLoading(true);
                axios
                  .post(endPoints.resumeDownload, {
                    htmlData: templateData,
                    isBorder: template === 7 ? true : false,
                  })
                  .then((r) => {
                    axios
                      .get(r.data.results, {
                        responseType: "blob",
                      })
                      .then((res) => {
                        fileDownload(
                          res.data,
                          `${inputData.personal.firstname}-VIPApplicant.pdf`
                        );

                        setLoading(false);
                      })
                      .catch((e) => setLoading(false));
                  })
                  .catch((e) => setLoading(false));
              }}
              className={classNames(classes.btn, "resume_download_modal_btn")}
            >
              {/* <SendTwoToneIcon className={classes.icon} /> */}
              Download
            </Button>
          )}
        </Box>

        <Grid m={2}>
          <Card
            sx={{
              // height: "1190px",
              // height: "550px",
              boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
              // borderRadius: "30px",
              padding: "30px 20px",
              textAlign: "left",
              backgroundColor: "lightgray",
              // overflow: "auto",
            }}
            // onWheel={zoom}
          >
            <div
              id="resume"
              style={{
                position: "relative",
                margin: "0 auto",
                width: "21cm",
                minHeight: "29.7cm",
                padding: "1.27cm",
                background: "white",
              }}
            >
              {renderTemplate(template)}
              {/* {renderPreview(template)} */}
            </div>
          </Card>
        </Grid>
        {/* <Template1 /> */}
      </div>
    </div>
  );
};

export default InternalResumePreview;
