const apiGateWayUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_URL_DEV
    : process.env.REACT_APP_BACKEND_URL;

const endPoints = {
  apiGateWay: apiGateWayUrl,
  isAuth: `${apiGateWayUrl}/isAuth`,
  checkUserPlanOrCreate: `${apiGateWayUrl}/checkUserPlanOrCreate`,
  updatePlanFeatureUsage: `${apiGateWayUrl}/updatePlanFeatureUsage`,
  getPlanDetails: `${apiGateWayUrl}/getPlanDetails`,
  freeUpgradePlan: `${apiGateWayUrl}/handleZeroPayment`,
  getActionVerb: `${apiGateWayUrl}/getActionVerb`,
  checkCoupon: `${apiGateWayUrl}/checkCoupon`,
  payUSurl: `${apiGateWayUrl}/handleSuccessfulPayment`,
  payUFurl: `${apiGateWayUrl}/handlePaymentFailure`,
  createHash: `${apiGateWayUrl}/createHash`,
  registerWithEmail: `${apiGateWayUrl}/registerWithEmail`,
  createJobApply: `${apiGateWayUrl}/jobApply/register`,
  getJobApply: `${apiGateWayUrl}/jobApply`,
  parsingUrl: `${apiGateWayUrl}/parsing`,
  parsingJobUrl: `${apiGateWayUrl}/parsingJob`,
  registerDTU: `${apiGateWayUrl}/createDTU`,
  registerUTM: `${apiGateWayUrl}/registerUTM`,
  rawParsingUrl: `${apiGateWayUrl}/rawParsing`,
  fetchResumes: `${apiGateWayUrl}/getResume`,
  getParsedResume: `${apiGateWayUrl}/getParsedResume`,
  checkCompletion: `${apiGateWayUrl}/completion`,
  getUserProfileDetail: `${apiGateWayUrl}/getUser`,
  updateUserProfileDetail: `${apiGateWayUrl}/updateUser`,
  addResume: `${apiGateWayUrl}/addResume`,
  duplicateResume: `${apiGateWayUrl}/duplicateResume`,
  updateResume: `${apiGateWayUrl}/updateResume`,
  deleteResume: `${apiGateWayUrl}/deleteResume`,
  registerUser: `${apiGateWayUrl}/registerUser`,
  getSpecialization: `${apiGateWayUrl}/getSpecialization`,
  getUniversity: `${apiGateWayUrl}/getUniversity`,
  getCollege: `${apiGateWayUrl}/getCollege`,
  getDegrees: `${apiGateWayUrl}/getDegrees`,
  useAISuggestion: `${apiGateWayUrl}/useAISuggestion`,
  getSkillType: `${apiGateWayUrl}/getSkillType`,
  getSkills: `${apiGateWayUrl}/getSkills`,
  getLibSkills: `${apiGateWayUrl}/getLibSkills`,
  getResumePreviewer: `${apiGateWayUrl}/getResumePreviewer`,
  getResumePreviewerInternal: `${apiGateWayUrl}/getResumePreviewerInternal`,
  getScores: `${apiGateWayUrl}/getScores`,
  getCertifications: `${apiGateWayUrl}/getCertifications`,
  getPublications: `${apiGateWayUrl}/getPublications`,
  getAchievements: `${apiGateWayUrl}/getAchievements`,
  updateCertification: `${apiGateWayUrl}/updateCertification`,
  createCertification: `${apiGateWayUrl}/createCertification`,
  deleteCertification: `${apiGateWayUrl}/deleteCertification`,
  updatePublication: `${apiGateWayUrl}/updatePublication`,
  createPublication: `${apiGateWayUrl}/createPublication`,
  deletePublication: `${apiGateWayUrl}/deletePublication`,
  updateAchievement: `${apiGateWayUrl}/updateAchievement`,
  createAchievement: `${apiGateWayUrl}/createAchievement`,
  deleteAchievement: `${apiGateWayUrl}/deleteAchievement`,
  getEducation: `${apiGateWayUrl}/getEducation`,
  createEducation: `${apiGateWayUrl}/createEducation`,
  deleteEducation: `${apiGateWayUrl}/deleteEducation`,
  updateEducation: `${apiGateWayUrl}/updateEducation`,
  getExperience: `${apiGateWayUrl}/getExperience`,
  createExperience: `${apiGateWayUrl}/createExperience`,
  deleteExperience: `${apiGateWayUrl}/deleteExperience`,
  updateExperience: `${apiGateWayUrl}/updateExperience`,
  getExtracurricular: `${apiGateWayUrl}/getExtracurricular`,
  createExtracurricular: `${apiGateWayUrl}/createExtracurricular`,
  deleteExtracurricular: `${apiGateWayUrl}/deleteExtracurricular`,
  getPersonalDetail: `${apiGateWayUrl}/get_personal_detail`,
  updatePersonalDetail: `${apiGateWayUrl}/updatePersonalDetail`,
  updateUserDetail: `${apiGateWayUrl}/updateUserDetail`,
  addPersonalDetail: `${apiGateWayUrl}/addPersonalDetail`,
  updateSkills: `${apiGateWayUrl}/updateSkills`,
  createSkills: `${apiGateWayUrl}/createSkills`,
  addSingleSkill: `${apiGateWayUrl}/createSkill`,
  deleteSkills: `${apiGateWayUrl}/deleteSkills`,
  emailIsVerified: `${apiGateWayUrl}/isVerified`,
  sendEmail: `${apiGateWayUrl}/send`,
  verifyMail: `${apiGateWayUrl}/verify`,
  resendMail: `${apiGateWayUrl}/resend`,
  sendResume: `${apiGateWayUrl}/send_resume`,
  checkLimit: `${apiGateWayUrl}/checkLimit`,
  createGPT: `${apiGateWayUrl}/createGPT`,
  updateLimit: `${apiGateWayUrl}/updateLimit`,
  getUserProfileData: `${apiGateWayUrl}/getProfile`,
  registerUserProfile: `${apiGateWayUrl}/registerProfile`,
  updateUserProfile: `${apiGateWayUrl}/updateProfile`,
  getLogo: `${apiGateWayUrl}/logo`,
  isSocialAuth: `${apiGateWayUrl}/isSocialAuth`,
  emailAuth: `${apiGateWayUrl}/emailAuth`,
  addUserWithSocial: `${apiGateWayUrl}/addUserWithSocial`,
  resumeDownload: `${apiGateWayUrl}/resumeDownload`,
  verifyPayment: `${apiGateWayUrl}/verifyPayment`,
  updateVerifiedPlan: `${apiGateWayUrl}/updateVerifiedPlan`,
  checkPendingPayments: `${apiGateWayUrl}/checkPendingPayments`,
};

export default endPoints;
