import { Box, Button, Grid, Link as MUILink, Stack } from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Fade from "react-reveal/Fade";
import { useLocation, useNavigate, useParams } from "react-router";
import CustomButton from "../../../../components/CustomButton/Index";
import CustomInputField from "../../../../components/CustomInputField/Index";
import endPoints from "../../../../config/endPoint";
import { setUTMValue } from "../../../../redux/utmSlice";
import { uploadFile } from "../../../../utils/s3";
import MobileNumber from "../../../Login/components/MobileNumber";
import OTPInput from "../../../Login/components/OTPInput";
import Timer from "../../../Login/components/Timer";
import gradient from "../../../Login/makestyles/gradient";

const AUTH_URL = endPoints.apiGateWay;

const createId = (num) => {
  return Number(Number(num).toString(8));
};

const JobLogin = ({ handleUpload }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [mobile, setMobile] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [OTP, setOTP] = useState("");
  const [OTPSent, setOTPSent] = useState(false);
  const [resp, setResp] = useState({});
  const [loginMessage, setLoginMessage] = useState("");
  const [isError, setisError] = useState(false);
  const { jobId, jobTitle } = useParams();

  const effectCallback = React.useCallback(() => {
    if (
      !(
        (jobId === "3656216131" &&
          jobTitle ===
            "Trainee-Software-Developers-B.Tech-Computer-Science-or-IT") ||
        (jobId === "5078906421" &&
          jobTitle === "vipapplicant-full-stack-developer") ||
        (jobTitle === "client-success-manager" && jobId === "4072791321") ||
        (jobTitle === "tech-hiring-developers-architects" &&
          jobId === "5432135670") ||
        (jobId === "3656214544" &&
          jobTitle ===
            "jr-sr-developers-tech-project-managers-architects-engineering-managers") ||
        (["4990101974", "7523377112", "7078906426"].includes(jobId) &&
          [
            "1digitalstack-business-analyst",
            "1digitalstack-software-engineer",
            "1digitalstack-data-scientist",
          ].includes(jobTitle)) ||
        (["8527185712", "2573128808", "1457342611"].includes(jobId) &&
          [
            "edelweiss-backend-developer",
            "edelweiss-frontend-developer",
            "edelweiss-software-testing",
          ].includes(jobTitle))
      )
    ) {
      navigate("/jobfeed");
    }
  }, [navigate, jobId, jobTitle]);
  useEffect(() => {
    effectCallback();
  }, [effectCallback]);

  const parseParams = (querystring) => {
    // parse query string
    const params = new URLSearchParams(querystring);

    const obj = {};

    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }

    return obj;
  };

  useEffect(() => {
    dispatch(setUTMValue(parseParams(location.search)));
  }, [location, dispatch]);

  const getOTP = async () => {
    try {
      const reqBody = { number: `+91${mobile}` };

      const response = await axios(`${AUTH_URL}/createOTP`, {
        method: "POST",
        data: reqBody,
      });
      setResp({
        ...reqBody,
        ...response.data.results,
      });

      setOTPSent(true);

      handleUpload();
    } catch (error) {}
  };

  const resendOTP = () => {
    setOTPSent(false);
    setisError(false);
    setLoginMessage("");
    setOTP("");
    getOTP();
  };

  async function fetchScores(inputData, jobDetails) {
    try {
      axios.post(`${endPoints.createJobApply}`, jobDetails);

      if (!inputData.resumeId) {
        await axios.post(
          `${endPoints.getScores}/${inputData.resumeId}`,
          inputData
        );
      } else {
        await axios.post(
          `${endPoints.getScores}/${inputData.resumeId}`,
          inputData
        );
      }
    } catch (err) {}
  }

  const verifyOTP = async () => {
    let userId = createId(resp.number.substr(3));
    try {
      const reqBody = {
        phone: `${resp.number}`,
        hash: `${resp.hash}`,
        otp: `${OTP}`,
      };

      setisError(false);

      const response = await axios(`${AUTH_URL}/verifyOTP`, {
        method: "POST",
        data: reqBody,
      });
      if (response) {
        if (!response.data.success) {
          setisError(true);
        }

        if (response.data.success) {
          setLoginMessage(response.data.results.data);
          axios.post(`${endPoints.registerUser}`, { phone: resp.number });
          localStorage.setItem("token", response.data.results.token);

          let test = await uploadFile(selectedFile);
          var config = {
            method: "post",
            url: endPoints.parsingJobUrl,
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify({
              awsPath: test.Location,
              fileName: selectedFile.name,
              userId: userId,
            }),
          };
          axios(config).then((r) => {
            axios
              .post(`${endPoints.getResumePreviewer}/${r.data.results.data}`, {
                token: localStorage.getItem("token"),
              })
              .then((respo) => {
                if (respo.data.message !== "Can't GET Resume Details!")
                  fetchScores(respo.data, {
                    uploaded_resume_json_url: r.data.results.jsonUrl,
                    uploaded_resume_url: test.Location,
                    resume_id: respo.data.resumeId,
                    user_id: userId,
                    job_id: jobId,
                    job_title: jobTitle,
                    email_id: email,
                  });
              });
          });

          handleUpload();
          //   navigate("/dashboard", {state: createId(resp.number.substr(3))});
        }
      }
    } catch (error) {}
  };

  const [email, setEmail] = useState("");

  return (
    <>
      <Fade>
        <Box mt={0} display="flex" alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={10} md={9} lg={9} alignItems="center">
            <Stack spacing={7} mt={10}>
              <MobileNumber
                mobile={mobile}
                setMobile={setMobile}
                OTPSent={OTPSent}
                sendOTP={getOTP}
              />
              <CustomInputField
                margin="dense"
                label="Email Address"
                required={true}
                helperText={"Provide a valid email address"}
                error={
                  email &&
                  !email.match(
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
                  )
                }
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // className="numberField"
                type="email"
                fullWidth
              />

              <CustomInputField
                variant="outlined"
                margin="dense"
                label="Upload your latest resume"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                className="numberField"
                type="file"
                inputProps={{
                  accept: "application/pdf",
                }}
                fullWidth
              />
              {OTPSent && (
                <OTPInput
                  OTP={OTP}
                  setOTP={setOTP}
                  isError={isError}
                  verifyOTP={verifyOTP}
                  loginMessage={loginMessage}
                />
              )}

              {OTPSent && <Timer initialMinute={2} initialSecond={0} />}

              <Stack spacing={2}>
                {!OTPSent && (
                  <CustomButton
                    variant="contained"
                    disabled={
                      !email.match(
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
                      )
                        ? true
                        : mobile.length !== 10
                    }
                    onClick={getOTP}
                    sx={gradient}
                    className={classNames(
                      `uap_${jobTitle}_apply_now_btn`,
                      "login_btn_otp"
                    )}
                    text={"Apply Now"}
                  />
                )}
                {OTPSent && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={!(OTP.length === 6)}
                      onClick={verifyOTP}
                      sx={gradient}
                      className={classNames("login_btn_otp_verify")}
                    >
                      Verify & Apply
                    </Button>
                  </div>
                )}
                {OTPSent && (
                  <MUILink
                    component="button"
                    variant="body2"
                    onClick={resendOTP}
                    className={classNames("login_btn_otp_resend")}
                  >
                    RESEND OTP
                  </MUILink>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Box>
      </Fade>
    </>
  );
};

export default JobLogin;
