import { makeStyles } from "@mui/styles";

let TemplatePreviewStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
    display: "flex",
    width: "100%",
    height: "inherit",
  },
  btnContainer: {
    marginBottom: 20,
    padding: 10,
    border: "0.5px solid lightgray",
    display: "flex",
    justifyContent: "flex-end",
  },
  templateActionContainer: {
    padding: 20,
    display: "flex",
    alignItems: "center",
    background: theme.palette.grey[200],
    position: "relative",
    zIndex: 100,
    boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
    justifyContent: "space-between",
    "& .templateName": {
      display: "flex",
      gap: 10,
      fontSize: "1.2rem",
    },
    "& .actionBtn": {
      display: "flex",
      gap: 10,
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: 65,
    },
  },
  templateScoreContainer: {
    maxWidth: "1200px",
    boxShadow: "0 0 0.125rem 0 rgba(0, 0, 0, 0.1)",
    border: "1px solid #eee",
    margin: "20px auto",
  },
  templateBodyContainer: {
    maxWidth: "1200px",
    margin: "auto",
    display: "flex",
    flexDirection: "row-reverse",
    "& .bodyCol": {},
    "& .bodyCol20": {
      width: "20%",
      background: "#eee",
      overflowY: "auto",
      height: "31cm",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        height: "fit-content !important",
      },
    },
    "& .bodyCol80": {
      width: "80%",
      padding: "30px 0",
      background: "#ccc",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
    },
  },

  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    margin: "60px auto",
  },
  planDiv: {
    "& span": {
      fontSize: "large",
    },
    "& .btnText": { display: "flex", alignItems: "center", gap: 4 },
  },
  contentDiv: {
    paddingTop: 0,
    textAlign: "left",
    width: "-webkit-fill-available",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      zoom: 0.3,
      marginTop: 150,
    },
    "& .scrollDiv": {
      overflow: "auto",
      height: "calc(100% - 90px)",
      [theme.breakpoints.down("sm")]: {
        height: "calc(100% - 350px) !important",
      },
      [theme.breakpoints.down("lg")]: {
        height: "calc(100% - 200px)",
      },
    },
  },
  body: {
    margin: "30px 0px",
    border: `1px solid ${theme.palette.divider}`,
    padding: 20,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  },
}));

export default TemplatePreviewStyles;
