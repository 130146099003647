import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endPoints from "../../config/endPoint";
import { filterNumberByCountryCode } from "../../utils/contentFormatter";

const initialState = {
  pending: false,
  personal: {},
  raw: {},
};

export const getPersonalDetail = createAsyncThunk(
  "personalDetail/getPersonalDetail",
  async ({ token, resumeId, cb }, thunkAPI) => {
    let data = await axios.post(`${endPoints.getPersonalDetail}`, {
      token,
      resumeId,
    });
    cb(
      data.data.results
        ? {
            ...data.data.results,
            pd_country_code: filterNumberByCountryCode(
              data.data.results.pd_contact_number
            )[0],
            pd_contact_number: filterNumberByCountryCode(
              data.data.results.pd_contact_number
            )[1],
          }
        : {}
    );
    return data.data.results;
  }
);

export const personalDetailSlice = createSlice({
  name: "personalDetail",
  initialState,
  reducers: {
    test: (state, { payload }) => {
      state.personal.firstname = "Sidharth";
    },
  },
  extraReducers: {
    [getPersonalDetail.pending]: (state, { payload }) => {
      state.pending = true;
    },
    [getPersonalDetail.fulfilled]: (state, { payload }) => {
      state.pending = false;
      if (payload) {
        state.raw = { ...payload };
      } else {
        state.raw = {};
      }
    },
    [getPersonalDetail.rejected]: (state, { payload }) => {
      state.pending = false;
      state.error = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { test } = personalDetailSlice.actions;

export default personalDetailSlice.reducer;
