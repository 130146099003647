import { TextField, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import customTagBasedInputStyles from "../../styles/components/CustomTagBasedInputStyle/index.styles";
import CustomPill from "../CustomPill/Index";

export default function CustomTagBasedInput({
  startAdornment,
  uapWrapperClass,
  uapPillClass,
  endAdornment,
  required,
  chipsArr,
  handleDeleteCallback,
  ...props
}) {
  const classes = customTagBasedInputStyles();
  return (
    <div>
      <TextField
        {...props}
        className={props.type === "textarea" ? "text-area" : classes.root}
        InputLabelProps={{
          shrink: false,
          className: classes.inputLabelNoShrink,
        }}
        required={required}
        InputProps={{
          // readOnly: {OTPSent},
          startAdornment: startAdornment,
          classes: {
            notchedOutline: classes.notchedOutline,
          },
          endAdornment: endAdornment,
          ...props.InputProps,
        }}
      />
      {chipsArr.toString() ? (
        <Typography
          variant="subtitle1"
          component={"div"}
          className={classNames(uapWrapperClass, classes.body)}
        >
          {chipsArr.map((chip, idx) => (
            <CustomPill
              className={`${uapPillClass}_${idx}`}
              key={idx}
              value={chip}
              handleDeleteCallback={() => handleDeleteCallback(idx)}
            />
          ))}
        </Typography>
      ) : (
        ""
      )}
    </div>
  );
}
