import ArchitectureIcon from "@mui/icons-material/Architecture";
import Check from "@mui/icons-material/Check";
import HikingIcon from "@mui/icons-material/Hiking";
import InsightsIcon from "@mui/icons-material/Insights";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import { StepButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import classNames from "classnames";
import PropTypes from "prop-types";
import * as React from "react";
import { FaBackward, FaEye, FaForward } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import customStepperStyles from "../../styles/components/CustomStepper/index.styles";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const pulse = keyframes`
 0% {
    box-shadow: 0 0 0 0 rgb(12, 44, 101, 0.7);
  }
  
  70% {
    box-shadow: 0 0 0 10px rgb(12, 44, 101, 0);
  }
  
  100% {
    box-shadow: 0 0 0 0 rgb(12, 44, 101, 0);
  }
`;

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#fff",
  }),
  "& .QontoStepIcon-completedIcon": {
    backgroundColor: theme.palette.success.dark,
    zIndex: 1,
    fontSize: 25,
    borderRadius: "50%",
    dow: `0 0 0 3px ${theme.palette.success.light}`,
  },
  "& .QontoStepIcon-circle": {
    padding: "4px",
    borderRadius: "50%",
    backgroundColor: ownerState.active
      ? theme.palette.primary.main
      : theme.palette.text.disabled,
    animation: ownerState.active && `${pulse} 2s infinite`,
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: <PersonIcon />,
    2: <SchoolIcon />,
    3: <WorkIcon />,
    4: <ArchitectureIcon />,
    5: <InsightsIcon />,
    6: <HikingIcon />,
    // 7: <PersonIcon />,
  };

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle">{icons[String(props.icon)]}</div>
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  activeStep: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

export default function CustomStepper({
  steps,
  stepIcon,
  stepperClass,
  stepDirection,
  activeStep,
  nextCallBack,
  skipAccess,
  backCallBack,
  setActiveStep,
  stepContent,
  btnType,
  handleChange,
  clearInput,
  onSpecificChange,
  value,
  ...props
}) {
  const navigate = useNavigate();
  const classes = customStepperStyles();
  const { resumeId } = useParams();

  const handleStep = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear={false}
        activeStep={activeStep}
        alternativeLabel
        className={stepperClass}
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label} className={classes.step}>
            <StepButton
              color="inherit"
              onClick={() => {
                if (skipAccess) handleStep(index);
              }}
              disableTouchRipple
              disabled={false}
            >
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <>
        <React.Fragment>
          <Box className={classes.stepperContentBox}>{stepContent}</Box>

          <Box className={classes.btnContainer}>
            <Button
              className={classNames(
                `back_btn_step_${activeStep}`,
                classes.button
              )}
              variant="contained"
              disabled={activeStep === 0}
              onClick={backCallBack}
              style={{
                background: "#ccc",
                color: "#000",
              }}
              sx={{
                mr: 1,
                display: "flex",
                padding: "5px 35px",
                gap: 1,
                "& span": { margin: "auto 0px" },
              }}
            >
              <FaBackward /> <span>Back</span>
            </Button>
            {activeStep !== 5 && (
              <Button
                className={classNames(
                  `preview_btn_step_${activeStep}`,
                  classes.button
                )}
                variant="outlined"
                color="warning"
                disabled={activeStep === 0}
                onClick={() => {
                  navigate(`/dashboard/${resumeId}/preview`);
                }}
                sx={{
                  mr: 1,
                  display: "flex",
                  padding: "5px 35px",
                  gap: 1,
                  "& span": { margin: "auto 0px" },
                }}
              >
                <FaEye /> <span>Preview</span>
              </Button>
            )}
            {activeStep === 5 ? (
              <>
                <Button
                  className={classNames(
                    `next_btn_step_${activeStep}`,
                    classes.button
                  )}
                  sx={{
                    mr: 1,
                    display: "flex",
                    padding: "5px 35px",
                    gap: 1,
                    "& span": { margin: "auto 0px" },
                  }}
                  variant="contained"
                  onClick={(e) => {
                    nextCallBack(e);
                    navigate(`/dashboard/${resumeId}/preview`);
                  }}
                  type={btnType}
                >
                  <span>Finish & Preview</span> <FaForward />
                </Button>
              </>
            ) : (
              <Button
                className={classNames(
                  `next_btn_step_${activeStep}`,
                  classes.button
                )}
                sx={{
                  mr: 1,
                  display: "flex",
                  padding: "5px 35px",
                  gap: 1,
                  "& span": { margin: "auto 0px" },
                }}
                variant="contained"
                onClick={(e) => {
                  nextCallBack(e);
                }}
                type={btnType}
              >
                <span>Next</span> <FaForward />
              </Button>
            )}
          </Box>
        </React.Fragment>
      </>
    </Box>
  );
}
