import { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@mui/icons-material/Add";
import arrowIosDownwardFill from "@mui/icons-material/Add";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import classNames from "classnames";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => {
  return (
    <ListItemButton
      disableGutters
      {...props}
      className={classNames(
        props.className,
        `sidebar_menuitem_${props.to?.split("/")[1]}`
      )}
    />
  );
})(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "uppercase",
  paddingLeft: theme.spacing(5),
  fontSize: "15px",
  paddingRight: theme.spacing(2.5),
  color: theme.palette.secondary.lighter,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.warning.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, className }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "warning.light",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.secondary.lighter,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  const planStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: "#f8991c",
    "&:before": { display: "block" },
    "&:hover": {
      background: "#f8991c",
    },
  };

  const activeSubStyle = {
    color: "text.warning",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          cClass={className}
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  cClass={className}
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "warning.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={
        title.props.children === "Plans"
          ? { ...planStyle }
          : {
              ...(isActiveRoot && activeRootStyle),
            }
      }
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding className={classNames(`sidebar_menuitems`)}>
        {navConfig.map((item, idx) => (
          <NavItem
            key={idx}
            item={item}
            active={match}
            className={classNames(`sidebar_menuitem_${item.name}`)}
          />
        ))}
      </List>
    </Box>
  );
}
