import { makeStyles } from "@mui/styles";

const loginStyles = makeStyles((theme) => ({
  stack: {
    [theme.breakpoints.down("md")]: {},
  },
  socialDivider: {
    width: "75%",
    margin: "2vh 0px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export default loginStyles;
