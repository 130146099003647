import { Divider } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import BottomNavigation from "../../layouts/bottomNavigation";
import DashboardSidebar from "../../layouts/index";
import { checkUserPlanThunk } from "../../redux/counterSlice";
import useStyles from "../../styles/pages/Dashboard/index.style";
import DashboardContent from "./components/content";
import DashboardHeader from "./components/header";
// import ParsedFileContainer from "./components/ParsedFile";

const styles = {
  actionStyle: {
    background: "#eee",
    padding: 0,
  },
  bodyStyle: {
    padding: 20,
    gap: 10,
    textAlign: "left",
  },
  style: {
    marginBottom: "30px",
    minWidth: 250,
    textDecoration: "none",
  },
  customButtonStyle: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 30px",
    justifyContent: "center",
  },
};

export default function DashboardPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(checkUserPlanThunk({ token: localStorage.getItem("token") }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.container}>
      <div>
        <DashboardSidebar />
      </div>
      <div className={classes.contentSection}>
        <DashboardHeader styles={styles} />
        <Divider className={classes.divider} />
        <DashboardContent styles={styles} />
        <Divider />
      </div>
      <BottomNavigation value={0} />
    </div>
  );
}
