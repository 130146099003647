import { makeStyles } from "@mui/styles";

let ScoreModalContentStyle = makeStyles((theme) => ({
  container: {
    overflowY: "auto",
    padding: "0px 16px",
    maxHeight: "55vh",
  },
  section1: {
    marginBottom: 10,
    "& .subSection": {
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    },
  },
  section2: {
    marginBottom: 10,
    marginTop: 25,
  },
  link: {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  },
  partHead: {
    color: theme.palette.warning.dark,
  },
  p: {
    width: "100%",
    fontSize: "large",
    fontWeight: 800,
    marginTop: 20,
  },
}));

export default ScoreModalContentStyle;
