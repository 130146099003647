import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Autocomplete,
  Button,
  Link as MUILink,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { grey } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import axios from "axios";
import classNames from "classnames";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import endPoints from "../../../config/endPoint";
import customInputStyles from "../../../styles/components/CustomInputStyle/index.styles";
import { uploadFile } from "../../../utils/s3";
import MobileNumber from "../../Login/components/MobileNumber";
import OTPInput from "../../Login/components/OTPInput";
import Timer from "../../Login/components/Timer";
import gradient from "../../Login/makestyles/gradient";
import { degreeList, specialization } from "./staticData";

const AUTH_URL = endPoints.apiGateWay;

export default function DTUFieldComponent({
  setIsAuth,
  addMobileNo,
  adduserId,
}) {
  const classes = customInputStyles();
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [degree, setDegree] = React.useState("");
  const [special, setSpecial] = React.useState("");
  const [grad, setGrad] = React.useState(null);
  const [tag, setTag] = React.useState(0);
  const [uploadedUrl, setUploadedUrl] = React.useState("");
  const location = useLocation();
  const [cgpa, setCgpa] = React.useState("");
  const [projectTitle, setProjectTitle] = React.useState("");
  const [projectDesc, setProjectDesc] = React.useState("");
  const [intComp, setIntComp] = React.useState("");
  const [intDesc, setIntDesc] = React.useState("");
  const [intRole, setIntRole] = React.useState("");
  const [skills, setSkills] = React.useState([]);

  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [OTPSent, setOTPSent] = useState(false);
  const [resp, setResp] = useState({});
  const [loginMessage, setLoginMessage] = useState("");
  const [isError, setisError] = useState(false);

  const getOTP = async () => {
    try {
      const reqBody = { number: `+91${mobile}` };

      const response = await axios(`${AUTH_URL}/createOTP`, {
        method: "POST",
        data: reqBody,
      });
      setResp({
        ...reqBody,
        ...response.data.results,
      });

      setOTPSent(true);
    } catch (error) {}
  };

  const resendOTP = () => {
    setOTPSent(false);
    setisError(false);
    setLoginMessage("");
    setOTP("");
    getOTP();
  };

  const verifyOTP = async () => {
    try {
      const reqBody = {
        phone: `${resp.number}`,
        hash: `${resp.hash}`,
        otp: `${OTP}`,
      };

      setisError(false);

      const response = await axios(`${AUTH_URL}/verifyOTP`, {
        method: "POST",
        data: reqBody,
      });
      if (response) {
        if (!response.data.success) {
          setisError(true);
        }

        if (response.data.success) {
          setLoginMessage(response.data.results.data);
          localStorage.setItem("token", response.data.results.token);

          handleSave();
          //   navigate("/dashboard", {state: createId(resp.number.substr(3))});
        }
      }
    } catch (error) {}
  };

  const handleSave = async (e) => {
    setTag(1);
    axios.post(`${endPoints.registerDTU}`, {
      mobile,
      email,
      first_name: firstName,
      last_name: lastName,
      degree,
      specialization: special,
      year_of_graduation: grad,
      percentage_cgpa: cgpa,
      project_title: projectTitle,
      project_description: projectDesc,
      internship_company: intComp,
      internship_role: intRole,
      internship_description: intDesc,
      skills: skills.toString(),
      uploaded_resume_url: uploadedUrl,
      utm_url: location.pathname,
    });
  };

  const renderContent = (tag) => {
    switch (tag) {
      case 0:
        return (
          <>
            <span style={{ textDecoration: "none" }}>
              {/* <Button>VIP APPLICANT</Button> */}
              <img
                src="https://upload.wikimedia.org/wikipedia/en/b/b5/DTU%2C_Delhi_official_logo.png"
                width={120}
                alt="logo"
                style={{
                  margin: "auto",
                }}
              />
            </span>
            <Typography
              variant="h5"
              sx={{ color: grey[800] }}
              style={{
                marginTop: 5,
              }}
            >
              Welcome to the DTU Engifest
            </Typography>
            <Typography
              variant="caption"
              style={{
                marginTop: 5,
              }}
            >
              VIPApplicant is India's First AI-Powered Resume Builder
            </Typography>

            <TextField
              variant="outlined"
              margin="dense"
              label="First Name"
              required={true}
              className={classes.root}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                // readOnly: {OTPSent},

                inputProps: {
                  maxLength: 50,
                },
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              // className="numberField"
              fullWidth
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Last Name"
              required={true}
              className={classes.root}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                // readOnly: {OTPSent},

                inputProps: {
                  maxLength: 50,
                },
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              // className="numberField"
              fullWidth
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Email Address"
              required={true}
              className={classes.root}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                // readOnly: {OTPSent},

                inputProps: {
                  maxLength: 250,
                },
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              // helperText={"Provide a valid email address"}
              error={
                email &&
                !email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/)
              }
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // className="numberField"
              type="email"
              fullWidth
            />
            <Autocomplete
              value={degree}
              onChange={(e, v) => setDegree(v)}
              options={degreeList}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Degree (Select from dropdown)"
                  required={true}
                  className={classes.root}
                  {...params}
                  InputLabelProps={{
                    shrink: false,
                    className: classes.inputLabelNoShrink,
                  }}
                  InputProps={{
                    // readOnly: {OTPSent},
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    ...params.InputProps,
                  }}
                  // className="numberField"
                  fullWidth
                />
              )}
            />
            <Autocomplete
              value={special}
              onChange={(e, v) => setSpecial(v)}
              options={specialization}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Specialization (Select from dropdown)"
                  required={true}
                  {...params}
                  className={classes.root}
                  value={special}
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                    className: classes.inputLabelNoShrink,
                  }}
                  InputProps={{
                    // readOnly: {OTPSent},
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                    ...params.InputProps,
                  }}
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Year of Graduation"
                views={["year"]}
                inputFormat="yyyy"
                minDate={new Date("jan 2015")}
                maxDate={new Date("jan 2027")}
                value={grad}
                onChange={(e) => setGrad(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: false,
                      className: classes.inputLabelNoShrink,
                    }}
                    required
                    InputProps={{
                      // readOnly: {OTPSent},
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              variant="outlined"
              margin="dense"
              label="Percentage/CGPA"
              required={true}
              className={classes.root}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                // readOnly: {OTPSent},
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={cgpa}
              onChange={(e) => {
                if (e.target.value.length <= 4 && e.target.value <= 100) {
                  setCgpa(e.target.value);
                }
              }}
              // className="numberField"
              fullWidth
              type={"number"}
            />
            <Typography
              variant="h5"
              style={{
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              Add a Project
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              label="Project Title"
              required={true}
              className={classes.root}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                // readOnly: {OTPSent},
                inputProps: {
                  maxLength: 250,
                },
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={projectTitle}
              onChange={(e) => setProjectTitle(e.target.value)}
              // className="numberField"
              fullWidth
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Project Description"
              required={true}
              multiline
              rows={6}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                inputProps: {
                  maxLength: 600,
                },
                // readOnly: {OTPSent},
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={projectDesc}
              onChange={(e) => setProjectDesc(e.target.value)}
              // className="numberField"
              fullWidth
            />
            <Typography
              variant="h5"
              style={{
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              Add an Internship
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              label="Internship Company"
              required={true}
              className={classes.root}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                // readOnly: {OTPSent},

                inputProps: {
                  maxLength: 250,
                },
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={intComp}
              onChange={(e) => setIntComp(e.target.value)}
              // className="numberField"
              fullWidth
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Internship Role"
              required={true}
              className={classes.root}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                // readOnly: {OTPSent},

                inputProps: {
                  maxLength: 250,
                },
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={intRole}
              onChange={(e) => setIntRole(e.target.value)}
              // className="numberField"
              fullWidth
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Internship Description"
              required={true}
              // className={classes.root}
              multiline
              rows={6}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                // readOnly: {OTPSent},

                inputProps: {
                  maxLength: 600,
                },
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={intDesc}
              onChange={(e) => setIntDesc(e.target.value)}
              // className="numberField"
              fullWidth
            />

            <Typography
              variant="h5"
              style={{
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              Add Skills
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              label="Skills"
              required={true}
              className={classes.root}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                // readOnly: {OTPSent},
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  if (!skills.includes(e.target.value)) {
                    setSkills([...skills, e.target.value]);
                    e.target.value = "";
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!skills.includes(e.target.value)) {
                    setSkills([...skills, e.target.value]);
                    e.target.value = "";
                  }
                }
              }}
              // className="numberField"
              fullWidth
            />
            {skills.length ? (
              <Paper
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: 10,
                  padding: 10,
                  gap: 5,
                }}
              >
                {skills.map((a) => (
                  <Chip
                    color="primary"
                    label={a}
                    onDelete={(e) => setSkills(skills.filter((b) => b !== a))}
                  />
                ))}
              </Paper>
            ) : (
              ""
            )}
            <TextField
              variant="outlined"
              margin="dense"
              label="Upload your latest resume (Optional)"
              style={{
                marginTop: 50,
                marginBottom: 10,
              }}
              InputLabelProps={{
                shrink: false,
                className: classes.inputLabelNoShrink,
              }}
              InputProps={{
                // readOnly: {OTPSent},
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={async (e) => {
                let test = await uploadFile(e.target.files[0]);
                setUploadedUrl(test.Location);
              }}
              className="numberField"
              type="file"
              inputProps={{
                accept: "application/pdf",
              }}
              fullWidth
            />
            <MobileNumber
              mobile={mobile}
              setMobile={setMobile}
              OTPSent={OTPSent}
              sendOTP={getOTP}
            />
            {OTPSent && (
              <OTPInput
                OTP={OTP}
                setOTP={setOTP}
                isError={isError}
                verifyOTP={verifyOTP}
                loginMessage={loginMessage}
              />
            )}

            {OTPSent && <Timer initialMinute={2} initialSecond={0} />}
            <Typography
              variant="caption"
              style={{
                fontSize: "14px",
                marginTop: 15,
                marginBottom: -10,
              }}
            >
              Please fill up all the mandatory details to submit.{" "}
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </Typography>
            <Stack spacing={2}>
              {!OTPSent && (
                <Button
                  variant="contained"
                  disabled={
                    !(
                      mobile.length === 10 &&
                      firstName &&
                      lastName &&
                      email &&
                      degree &&
                      special &&
                      grad &&
                      cgpa &&
                      projectDesc &&
                      projectTitle &&
                      intComp &&
                      intDesc &&
                      intRole &&
                      skills.length
                    )
                  }
                  onClick={getOTP}
                  sx={gradient}
                  className={classNames("login_btn_otp")}
                >
                  Submit
                </Button>
              )}
              {OTPSent && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={!(OTP.length === 6)}
                    onClick={verifyOTP}
                    sx={gradient}
                    className={classNames("login_btn_otp_verify")}
                  >
                    Verify & Submit
                  </Button>
                </div>
              )}
              {OTPSent && (
                <MUILink
                  component="button"
                  variant="body2"
                  onClick={resendOTP}
                  className={classNames("login_btn_otp_resend")}
                >
                  Resend OTP
                </MUILink>
              )}

              {/* <Link to="/dashboard" style={{ textDecoration: "none" }}>
                  <Button>Skip Login</Button>
                </Link> */}
            </Stack>
          </>
        );

      case 1:
        return (
          <div
            style={{
              background: "",
              textAlign: "center",
              "& p": {
                color: "#000",
                padding: "0px 30px",
              },
              "& .head-content": {
                padding: "35px 0px 25px 0px",
              },
              "& .numberField": {
                marginTop: "20px",
                maxWidth: "80%",
                borderWidth: "1px",
                boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
                borderColor: "white",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1px",
                },
              },
              "& .otpField": {
                marginTop: "1rem",
                maxWidth: "40%",
                borderWidth: "1px",
                boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
                borderColor: "white",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1px",
                },
              },
              "& .middle-content": {
                padding: "20px 0px 10px 0px",
              },
            }}
          >
            <CheckCircleOutlineIcon
              style={{
                color: "green",
                fontSize: 60,
              }}
            />
            <h3>Thank you.</h3>
            <p>
              We have successfully received your resume details. <br />
              Please visit our resume builder to create a new AI powered resume.{" "}
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                navigate("/");
              }}
            >
              Visit Resume Builder
            </Button>
          </div>
        );
      default:
        break;
    }
  };
  return <Stack spacing={5}>{renderContent(tag)}</Stack>;
}
