import EdelweissImg from "../../../../assets/edelweiss.png";

export const renderJobContent = (id, classes) => {
  switch (id) {
    case "3656216131":
      return (
        <>
          <p>
            <b>
              Company: <br /> <br />
              <img
                src="https://www.webonise.com/wp-content/themes/webo/images/logo@2x.png"
                width={150}
                alt={"not found"}
              />
            </b>
            <p>
              Webonise is a product development consultancy and engineering team
              with 200+ talented software engineers, usability experts, product
              managers and quality assurance. Webonize builds app and mobile
              experiences that people love.
            </p>
          </p>
          <>
            <p>
              <strong>Role</strong>: Trainee - Software Developer
            </p>
            <p>
              <strong>Industry</strong>: IT Services and Consulting
            </p>
            <p>
              <strong>Function</strong> : Engineering - Software
            </p>
            <p>
              <strong>Employment</strong> Type: Full Time (Internship + Job)
            </p>
            <p>
              <strong>Location</strong>: Pune
            </p>
            <p>
              <strong>Education</strong>: Bachelor&rsquo;s degree in computer
              science, software engineering, or a technology-related discipline
              is preferred.
            </p>
          </>

          <b>Responsibilities:</b>
          <ul className={classes.ul}>
            <li>
              Design, build, and maintain efficient, reusable, and reliable code
            </li>
            <li>
              Writing code — business logic, request handling, multi-platform
              presentation, API integrations — anything necessary to turn our
              product dreams into realities Help maintain code quality,
              organization and automate
            </li>
            <li>
              Integration of user-facing elements developed by front-end
              developers with server side logic
            </li>
            <li>Contribute in all phases of the development lifecycle</li>
            <li>
              Identify bottlenecks and bugs, and devise solutions to these
              problems
            </li>
            <li>
              Follow best practices (test-driven development, continuous
              integration, SCRUM, refactoring and code standards)
            </li>
          </ul>

          <b>Desired Skills & Experience:</b>

          <ul className={classes.ul}>
            <li>Good communication skills & problem/logical thinking</li>
            <li>Comfortable working on Linux/Unix</li>
            <li>
              Proficient in C & CPP and good with Data structure and algorithm
            </li>
            <li>Good understanding of OOPs & Database</li>
            <li>Basic understanding of JavaScript, Payment gateway & API</li>
            <li>Knowledge of Web Services</li>
            <li>
              Willingness to learn new technologies; work in a team environment
              and as independently
            </li>
          </ul>
          <p></p>
          <p>
            <strong>Salary Details:</strong>
          </p>
          <p>Stipend</p>
          <p>
            Interns will be entitled for an amount of INR 12,000/- (Twelve
            Thousand Only) per month as stipend during the tenure of their
            internship.
          </p>
          <p>Internship Period - 4 months&nbsp;</p>
          <p>
            On Confirmation(Performance Basis), the compensation will be INR 3.6
            LPA + 50k retention bonus (Paid after completing an year of service
            at Webonise)
          </p>

          <p>
            <strong>Who can apply?</strong>
          </p>
          <p>
            Final year students and freshers(0-1 years experience) can apply for
            the role.&nbsp;
          </p>
          <p>
            <strong>How to apply?</strong>
          </p>
          <p>
            Submit your resume giving academic details, skills, certifications
            and communication skills
          </p>
          <p>
            <strong>Process:</strong>
          </p>
          <ol className={classes.ul}>
            <li aria-level="1">Resume Filtration.</li>
            <li aria-level="1">Aptitude Test.</li>
            <li aria-level="1">Interview.</li>
          </ol>
          <div className={classes.formHeader}>
            <h4>Quick Apply : Trainee Software Developers</h4>
          </div>
        </>
      );

    case "3656214544":
      return (
        <>
          <>
            <p>
              <strong>Role</strong>: Jr / Sr Developers, Tech Project Managers,
              Architects, Engineering Managers.
            </p>
            <p>
              <strong>Industry</strong>: IT Services and Consulting
            </p>
            <p>
              <strong>Function</strong> : Engineering - Software
            </p>
            <p>
              <strong>Employment</strong> Type: Full Time
            </p>
            <p>
              <strong>Location</strong>: Remote
            </p>
            <p>
              <strong>Education</strong>: Bachelor&rsquo;s degree in computer
              science, software engineering, or a technology-related discipline
              is preferred.
            </p>
          </>

          <b>Desired Skills & Experience:</b>

          <ul className={classes.ul}>
            <li>1+ year of experience in Java, Spring Boot, Rest API etc.</li>
            <li>Microservices, Docker, Kubernetes</li>
            <li>Extensive experience on SQL / databases</li>
          </ul>
          <p></p>
          <b>Good to Have Skills: </b>
          <ul className={classes.ul}>
            <li>Good to have: AWS / Azure, Jenkins, Postges, ElasticSearch</li>
            <li>Drools BRMS</li> <li>Maven</li> <li>Apache Velocity</li>
            <li>Highly skilled in semantically compliant HTML, CSS</li>
            <li>JEE application servers (WebLogic, Glashfish4)</li>
            <li>Web servers (IIS, Apache 2.4)</li>
            <li>
              Spring Boot, Microservices, Java Structs, Eclipse IDE, J2Ee,
              Jbuilder, Core Java, Java Architecture, JSP Servlets, JDBC, JSE,
              Hibernate, Advanced Java, Spring Mvc, JSP, JEE, Spring, SOAP,
              JFace, XML, J2Se
            </li>
          </ul>
          <p />
          <div className={classes.formHeader}>
            <h4>
              Quick Apply : Jr / sr developers, Tech project managers,
              architects, Engineering Managers
            </h4>
          </div>
        </>
      );
    case "7523377112":
      return (
        <>
          <>
            <p>
              <b>
                Company: <br /> <br />
                <img
                  src="https://1digitalstack.ai/wp-content/uploads/2021/07/1digitalstack-logo.png"
                  width={150}
                  alt={"not found"}
                />
              </b>
              <p>
                1DS platform uses Machine learning-based Discoverability, Bid
                Optimization, and Data Intelligence to enable brands to grow on
                Amazon and other marketplaces. We aggregate and analyze large
                volumes of unstructured and diverse e-commerce data at scale to
                deliver actionable insights that drive brand growth. In a short
                period, we have built a BI and optimization platform used by
                many Fortune 500 Companies
              </p>
            </p>
            <b>About the Founders:</b>
            <p>
              <a
                href="https://www.linkedin.com/in/rohit-anand-7424188/"
                target={"_blank"}
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Rohit Anand
              </a>
              : Rohit Anand is a serial entrepreneur and an alumnus of INSEAD,
              France. He was behind Value Edge, a healthcare analytics startup
              that counted among its clients, big pharma companies such as Amgen
              and Roche. Value Edge was acquired by WNS Holdings, an NYSE Listed
              company. In a few years since its founding Value Edge had become
              India’s largest outsourcing provider to the life sciences industry
              with an outstanding team of 150+ with offices in Boston,
              Frankfurt, and Zurich and marquee clients in Europe and the US.
              Rohit brings hands-on experience in building teams and culture
              around fairness, transparency, and huge levels of involvement. He
              has built teams that have delighted clients across the globe and
              built repeat business based on client delight.
              <p />
              <a
                href="https://www.linkedin.com/in/tarunjyotsingh/?originalSubdomain=in"
                target={"_blank"}
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Tarun Jyot Singh{" "}
              </a>
              : A brand marketer by profession, Tarun has learnt marketing from
              Mecca of brand building – Unilever with almost 10 years of
              experience in brand building, followed by stints in private equity
              and early-stage startups. Tarun has been part of the core team
              that built a large consumer business across digital channels. He
              brings the experience of marketing strategy, deep domain
              expertise, and understanding of consumer brands and combines these
              with experience in online strategy, tactical planning, and
              execution. He is a seasoned practitioner on Amazon and an expert
              at driving growth through Marketplaces and in particular knows the
              ins and outs of running large and complex campaigns on Amazon. He
              leads operations and product at 1Digitalstack working closely with
              clients to delight them with outcomes that exceed expectations.
            </p>
            <p>
              <strong>Location</strong>: Delhi
            </p>
          </>

          <b>Roles & Responsibilities:</b>
          <ul className={classes.ul}>
            <li>Support current solution offerings</li>
            <li>Deliver new innovations</li>
            <li>
              Work as a developer for the development of software applications
              in a Node.js/Python/PHP/JavaScript development environment
            </li>
            <li>
              Perform development tasks in a self-reliant way, on-time & be
              focused on quality
            </li>
            <li>Work closely with all stakeholders</li>
            <li>Work collaboratively in a cross-located team</li>
            <li>
              Apply clean code principles such as unit testing, code reviews,
              code inspections, security checks, and automation
            </li>
          </ul>

          <b>Basic Requirement:</b>

          <ul className={classes.ul}>
            <li>0-1 yr working experience.</li>
            <li>
              Skills: Node.js/Express Framework/JavaScript/HTML/Python/PHP/.Net
              Core/Relational or NoSQL databases
            </li>
            <li>
              Exposure in API driven application development & designing RESTful
              APIs
            </li>
            <li>Ability to adapt to an ever-changing environment</li>
            <li>
              Good organisational & followup skills with strong attention to
              detail
            </li>
          </ul>
          <p></p>
          <p>
            <strong>Who can apply?</strong>
          </p>
          <p>
            Students in their final year of graduation or who have graduated in
            2021 can apply for the roles.{" "}
          </p>
          <p>
            <strong>How to apply?</strong>
          </p>
          <p>
            Paid Internship for 6 months and a full time opportunity post
            completion of the internship
          </p>
          <p>
            <strong>Process:</strong>
          </p>
          <ol className={classes.ul}>
            <li aria-level="1">Resume Filtration.</li>
            <li aria-level="1">Aptitude Test.</li>
            <li aria-level="1">Interview.</li>
          </ol>
          <div className={classes.formHeader}>
            <h4>Quick Apply : Software Engineer</h4>
          </div>
        </>
      );
    case "4990101974":
      return (
        <>
          <>
            <p>
              <b>
                Company: <br /> <br />
                <img
                  src="https://1digitalstack.ai/wp-content/uploads/2021/07/1digitalstack-logo.png"
                  width={150}
                  alt="noreferrer"
                />
              </b>
              <p>
                1DS platform uses Machine learning-based Discoverability, Bid
                Optimization, and Data Intelligence to enable brands to grow on
                Amazon and other marketplaces. We aggregate and analyze large
                volumes of unstructured and diverse e-commerce data at scale to
                deliver actionable insights that drive brand growth. In a short
                period, we have built a BI and optimization platform used by
                many Fortune 500 Companies
              </p>
            </p>
            <b>About the Founders:</b>
            <p>
              <a
                href="https://www.linkedin.com/in/rohit-anand-7424188/"
                target={"_blank"}
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Rohit Anand
              </a>
              : Rohit Anand is a serial entrepreneur and an alumnus of INSEAD,
              France. He was behind Value Edge, a healthcare analytics startup
              that counted among its clients, big pharma companies such as Amgen
              and Roche. Value Edge was acquired by WNS Holdings, an NYSE Listed
              company. In a few years since its founding Value Edge had become
              India’s largest outsourcing provider to the life sciences industry
              with an outstanding team of 150+ with offices in Boston,
              Frankfurt, and Zurich and marquee clients in Europe and the US.
              Rohit brings hands-on experience in building teams and culture
              around fairness, transparency, and huge levels of involvement. He
              has built teams that have delighted clients across the globe and
              built repeat business based on client delight.
              <p />
              <a
                href="https://www.linkedin.com/in/tarunjyotsingh/?originalSubdomain=in"
                target={"_blank"}
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Tarun Jyot Singh{" "}
              </a>
              : A brand marketer by profession, Tarun has learnt marketing from
              Mecca of brand building – Unilever with almost 10 years of
              experience in brand building, followed by stints in private equity
              and early-stage startups. Tarun has been part of the core team
              that built a large consumer business across digital channels. He
              brings the experience of marketing strategy, deep domain
              expertise, and understanding of consumer brands and combines these
              with experience in online strategy, tactical planning, and
              execution. He is a seasoned practitioner on Amazon and an expert
              at driving growth through Marketplaces and in particular knows the
              ins and outs of running large and complex campaigns on Amazon. He
              leads operations and product at 1Digitalstack working closely with
              clients to delight them with outcomes that exceed expectations.
            </p>
            <p>
              <strong>Location</strong>: Delhi
            </p>
          </>

          <b>Roles & Responsibilities:</b>
          <ul className={classes.ul}>
            <li>
              Capturing, validating and documenting business /client
              requirements
            </li>
            <li>
              Analysis of sales and market share performance to measure impact
              on sales
            </li>
            <li>
              Analysis of a Category &ndash; eg. Printers and sub category eg.
              Ink Jet Printers including Category size, growth rates, Trends,
              Pricing
            </li>
            <li>
              Experience with statistical analysis, regression modelling and
              forecasting, time series analysis, data mining, dynamic pricing,
              demand modelling, and customer/product segmentation
            </li>
            <li>
              Provide data and insights for better impact decisions and driving
              new/existing marketing initiatives
            </li>
            <li>
              Interact with Client leads over call and email for operational
              requirements
            </li>
            <li>Automate data templates and reports</li>
            <li>Create and publish the business dashboards</li>
            <li>New Product and white space analysis</li>
          </ul>

          <b>Basic Requirement:</b>

          <ul className={classes.ul}>
            <li>
              Prior experience of 0-1 year into analytics and report making/MIS
              (preferably client facing which required you to interface with
              multiple stakeholders)
            </li>
            <li>
              Hands-on experience in python, especially in data processing and
              visualization libraries like pandas, numpy, matplotlib, seaborn
            </li>
            <li>
              Experience with e-commerce, retail or marketing analytics would be
              an advantage
            </li>
            <li>
              Expertise in databases and scripting languages (Advanced SQL,
              Basic Python/R, Advanced Excel)
            </li>
            <li>
              Strong proficiency in MS Excel, MS PowerPoint, Statistical
              analysis tools and techniques
            </li>
            <li>Strong analytical and problem-solving skills</li>
            <li>
              Ability to multi-task and work on a diverse range of requirements,
              prioritize, and be proactive in a fast-paced environment
            </li>
            <li>
              Demonstrated willingness to both teach others and learn new
              techniques
            </li>
            <li>Strong logical thinking, Business problem solver</li>
            <li>
              Strong communication and organization skills, with the ability to
              synthesize a lot of information quickly, highlight the key
              takeaways, and disseminate actionable insights
            </li>
            <li>Degree of ownership in taking things to completion</li>
            <li>
              knowledge of data visualisation tools like tableau, powerbi,
              metabase, or ms data studio will be added advantage
            </li>
          </ul>
          <p></p>
          <p>
            <strong>Who can apply?</strong>
          </p>
          <p>
            Students in their final year of graduation or who have graduated in
            2021 can apply for the roles.{" "}
          </p>
          <p>
            <strong>How to apply?</strong>
          </p>
          <p>
            Paid Internship for 6 months and a full time opportunity post
            completion of the internship
          </p>
          <p>
            <strong>Process:</strong>
          </p>
          <ol className={classes.ul}>
            <li aria-level="1">Resume Filtration.</li>
            <li aria-level="1">Aptitude Test.</li>
            <li aria-level="1">Interview.</li>
          </ol>
          <div className={classes.formHeader}>
            <h4>Quick Apply : Business Analyst</h4>
          </div>
        </>
      );
    case "7078906426":
      return (
        <>
          <>
            <p>
              <b>
                Company: <br /> <br />
                <img
                  src="https://1digitalstack.ai/wp-content/uploads/2021/07/1digitalstack-logo.png"
                  width={150}
                  alt={"no"}
                />
              </b>
              <p>
                1DS platform uses Machine learning-based Discoverability, Bid
                Optimization, and Data Intelligence to enable brands to grow on
                Amazon and other marketplaces. We aggregate and analyze large
                volumes of unstructured and diverse e-commerce data at scale to
                deliver actionable insights that drive brand growth. In a short
                period, we have built a BI and optimization platform used by
                many Fortune 500 Companies
              </p>
            </p>
            <b>About the Founders:</b>
            <p>
              <a
                href="https://www.linkedin.com/in/rohit-anand-7424188/"
                target={"_blank"}
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Rohit Anand
              </a>
              : Rohit Anand is a serial entrepreneur and an alumnus of INSEAD,
              France. He was behind Value Edge, a healthcare analytics startup
              that counted among its clients, big pharma companies such as Amgen
              and Roche. Value Edge was acquired by WNS Holdings, an NYSE Listed
              company. In a few years since its founding Value Edge had become
              India’s largest outsourcing provider to the life sciences industry
              with an outstanding team of 150+ with offices in Boston,
              Frankfurt, and Zurich and marquee clients in Europe and the US.
              Rohit brings hands-on experience in building teams and culture
              around fairness, transparency, and huge levels of involvement. He
              has built teams that have delighted clients across the globe and
              built repeat business based on client delight.
              <p />
              <a
                href="https://www.linkedin.com/in/tarunjyotsingh/?originalSubdomain=in"
                target={"_blank"}
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Tarun Jyot Singh{" "}
              </a>
              : A brand marketer by profession, Tarun has learnt marketing from
              Mecca of brand building – Unilever with almost 10 years of
              experience in brand building, followed by stints in private equity
              and early-stage startups. Tarun has been part of the core team
              that built a large consumer business across digital channels. He
              brings the experience of marketing strategy, deep domain
              expertise, and understanding of consumer brands and combines these
              with experience in online strategy, tactical planning, and
              execution. He is a seasoned practitioner on Amazon and an expert
              at driving growth through Marketplaces and in particular knows the
              ins and outs of running large and complex campaigns on Amazon. He
              leads operations and product at 1Digitalstack working closely with
              clients to delight them with outcomes that exceed expectations.
            </p>
            <p>
              <strong>Location</strong>: Delhi
            </p>
          </>

          <b>Your Role:</b>
          <p>
            As at 1DigitalStack, we grow our Ecommerce marketplace advertising
            software business, we are looking for a thoughtful and proactive
            Data Scientist.
            <p />
            You effectively be a part in building the gamut of analytics
            offering including product enhancements & solution suite. This
            includes working in collaboration with other leaders and teams
            within the organization and building relationships with them to take
            the data science offering of 1DS to the next level. You ensure that
            our products & solutions are delivering actionable insights and
            provide guidance and insights with best practices to ensure
            long-term customer success.
            <p />
            You play an important role in developing data & analytics models &
            strategies and building algorithms. You collaborate closely with
            sales to support and onboard new clients. You identify expansion
            opportunities to grow the product suite. You create customized
            educational materials to support organizations learning on all the
            aspects related to product & analytics.
          </p>
          <b>Your Responsibilities:</b>
          <p>
            Data Scientist helps the department in the development of new
            insights, advanced modelling techniques, and data science
            capabilities. In leadership capacity, Data Scientist is also
            responsible for the preparation of white papers, conference
            presentations, and scientific publications. Data Scientist
            personally works on challenging fundamental data science issues
            where necessary, realizes, and develops solutions independently.
            Data Scientist helps in building and managing new data tables that
            support data collection in the department, cross-channel data
            integration, data visualization, dashboards, predictive analytics,
            and data mining. Data Scientist leverages data science tools and
            techniques in analysing large data-sets that will enable him to
            develop custom models and algorithms to uncover insights, trends,
            and patterns in the data, which will be useful in availing informed
            courses of action.
            <p />
            Data Scientist will create data science platforms to test and
            experiment with techniques inclusive of advanced analytics,
            behavioural modelling, and churn capitalizing on new data science
            approaches that can yield revenue for the business.
            <p />
            Data Scientist will also play an analytical role where s/he will be
            responsible for the evaluation of analytics and machine learning
            technologies in use in the business. Data Scientist communicates the
            findings to key stakeholders through reports and presentations with
            whom s/he makes any necessary changes. The Data Scientist performs
            similar duties as s/he deems fit for the execution of her/his duties
            and duties as delegated by the Founders, CTO, Business Heads &
            Product Heads.
            <p />
            <ul className={classes.ul}>
              <li>
                Interact with internal business teams to identify and define the
                right problems to solve
              </li>
              <li>
                Create state of the art, scalable, and self-learning solutions
                for real business problems.
              </li>
            </ul>
          </p>
          <b>This involves :</b>
          <ol className={classes.ul}>
            <li>
              Choosing the most appropriate machine learning technique based on
              business needs and available data
            </li>
            <li>
              Training and tuning a variety of machine learning models which
              include traditional as well as deep learning models
            </li>
            <li>Analysing and reporting model performance and accuracy</li>
            <li>
              Own the development and deployment of end-to-end machine
              learning/artificial intelligence &amp; other analytical solutions
            </li>
            <li>Data exploration and pre-processing</li>
            <li>Model training and optimization</li>
            <li>Model performance evaluation</li>
            <li>Solution deployment</li>
            <li>Monitoring solution outcome</li>
            <li>Rapidly prototype the solutions in an agile manner</li>
            <li>
              Experiment and develop novel machine learning algorithms in the
              area of smart document processing, image processing and speech
              analytics
            </li>
            <li>
              Deploy machine learning/ deep learning models in production; both
              on premise as well as cloud deployment
            </li>
            <li>
              Present advanced analytics solutions to internal stakeholders
            </li>
          </ol>
          <p />

          <b>Key Requirements:</b>

          <ol className={classes.ul}>
            <li>
              Background in Computer Science, Computer Applications or any
              quantitative discipline (Statistics, Mathematics, Economics,
              Operations Research etc.){" "}
            </li>
            <li>
              Working knowledge of analytical tools/languages like Python, R,
              SAS, SQL &amp; machine learning solutions (including deep
              learning)
            </li>
            <li>
              Must have working knowledge of any popular Deep learning
              frameworks like OpenCV, PyTorch, Theano, TensorFlow, Caffe
            </li>
            <li>
              Must be able to work across different domains and get a quick
              sense of the business processes &amp; flows.
            </li>
            <li>
              Ability to translate business problems into the data realm and
              identify pertinent and meaningful solutions.
            </li>
            <li>
              Exceptional communication skills, both verbal and written, with a
              strong urge to learn, iterate and innovate.
            </li>
            <li>
              Passionate about applying emerging DL frameworks to solve business
              problems and like working in an extremely dynamic, fast paced
              environment.
            </li>
            <li>
              Understanding business problems, making sense of the data
              landscape &amp; footprint, performing a combination of
              exploratory, Machine Learning &amp; Advanced Analytics.
            </li>
            <li>
              Extracting actionable insights and helping put together an
              execution roadmap.
            </li>
            <li>
              Must be able to contribute to the development of our proprietary
              analytics product, a repository of solutions and leverage
              analytics for better consumption of data.
            </li>
            <li>Organized, self-motivated, disciplined and detail oriented</li>
            <li>
              Academic/Working knowledge of deep learning frameworks like
              Tensorflow, Torch, Keras, Caffe, MXNet, Theano
            </li>
            <li>
              Knowledge of Dockers, Containers and model API development is a
              plus
            </li>
            <li>
              Experience with big data analytics technologies like Apache Spark,
              Kafka, Flume, ElasticSearch is a plus
            </li>
          </ol>
          <p></p>
          <p>
            <strong>Who can apply?</strong>
          </p>
          <p>
            Students in their final year of graduation or who have graduated in
            2021 can apply for the roles.{" "}
          </p>
          <p>
            <strong>How to apply?</strong>
          </p>
          <p>
            Paid Internship for 6 months and a full time opportunity post
            completion of the internship
          </p>
          <p>
            <strong>Process:</strong>
          </p>
          <ol className={classes.ul}>
            <li aria-level="1">Resume Filtration.</li>
            <li aria-level="1">Aptitude Test.</li>
            <li aria-level="1">Interview.</li>
          </ol>
          <div className={classes.formHeader}>
            <h4>Quick Apply : Data Scientist</h4>
          </div>
        </>
      );

    case "5078906421":
      return (
        <>
          <>
            <p>
              <b>
                Job Description: <br /> <br />
              </b>
              <p>
                VIPApplicant is a tech company focusing on resolving the current
                skill-matching gap in the market. Our AI-powered Resume product
                helps the candidates by sharing personalized feedback to get the
                job that matches their potential. We are also committed to
                helping recruiters connect with suitable candidates and remove
                inefficiencies from the hiring process.
              </p>
            </p>
          </>

          <b>Your Responsibilities:</b>
          <ol className={classes.ul}>
            <li>
              Design and build end-to-end scalable applications using MERN stack
            </li>
            <li>Develop user-facing features using React.js</li>
            <li>
              Skills required Javascript, GitHub, REST API, React JS, Redux
            </li>
            <li>Develop backend application using Node.js</li>
            <li>
              Experience in databases like SQL, SQLite, and NoSQL databases
              especially MongoDB or similar technologies
            </li>
            <li>Work on OAUTH2 based Authentication/Authorisation</li>
            <li>
              Translating designs and wireframes into code of good quality
            </li>
            <li>Troubleshoot, debug and upgrade web applications</li>
            <li>
              Test web application to ensure responsiveness and efficiency
            </li>
            <li>
              Implement effective security protocols, data protection measures,
              and storage solutions
            </li>
            <li>
              Write and maintain documentation to describe program development,
              logic, coding, testing, changes, and corrections
            </li>
          </ol>
          <p />

          <div className={classes.formHeader}>
            <h4>Quick Apply : Fullstack Developer</h4>
          </div>
        </>
      );
    case "4072791321":
      return (
        <>
          <p>
            <b>
              Company: <br /> <br />
              <img
                src="https://www.webonise.com/wp-content/themes/webo/images/logo@2x.png"
                width={150}
                alt="not found"
              />
            </b>
            <p>
              Webonise is a product development consultancy and engineering team
              with 200+ talented software engineers, usability experts, product
              managers and quality assurance. Webonize builds app and mobile
              experiences that people love.
            </p>
          </p>
          <>
            <p>
              <strong>Role</strong>: Client Success Manager
            </p>
            <p>
              <strong>Industry</strong>: IT Services and Consulting
            </p>
            <p>
              <strong>Function</strong> : Marketing
            </p>
            <p>
              <strong>Employment</strong> Type: Full Time
            </p>
            <p>
              <strong>Location</strong>: Mumbai
            </p>
          </>

          <b>Responsibilities:</b>
          <ul className={classes.ul}>
            <li>
              Work with clients to understand the overall existing workflow
            </li>
            <li>Document the existing workflow and martech capabilities</li>
            <li>Identify key questions and recommend analysis</li>
            <li>Create the revised workflow and martech recommendations</li>
            <li>Work with clients to implement the initiatives</li>
            <li>Maintain digital dashboard of several different accounts</li>
            <li>Monitor key online marketing metrics to track success</li>
            <li>Collaborate with agencies and other vendor partners</li>
          </ul>

          <b>Desired Skills & Experience:</b>

          <ul className={classes.ul}>
            <li>Excellent communication skills</li>
            <li>
              Strong analytical skills, problem solving and logical thinking
            </li>
            <li>Willingness to learn new skills</li>
            <li>Work in a team environment</li>
          </ul>
          <p></p>
          <p>
            <strong>Salary Details:</strong>
          </p>
          <p>The compensation will be INR 35000 per month.</p>

          <p>
            <strong>Who can apply?</strong>
          </p>
          <p>Final year students can apply for the role.&nbsp;</p>
          <p>
            <strong>How to apply?</strong>
          </p>
          <p>
            Submit your resume. Include the education details, skills,
            internship/projects and certifications.
          </p>
          <p>
            <strong>Process:</strong>
          </p>
          <ol className={classes.ul}>
            <li aria-level="1">Resume Filtration.</li>
            <li aria-level="1">Interview.</li>
          </ol>
          <div className={classes.formHeader}>
            <h4>Quick Apply : Trainee Software Developers</h4>
          </div>
        </>
      );
    case "8527185712":
      return (
        <>
          <p>
            <b>
              <img src={EdelweissImg} width={"100%"} alt="not found" />
            </b>
            <p />
            <b>Designation: </b>
            AM/Manager-Back End Developer
            <p />
            <b>Reporting To: </b>
            Engineering Manager
            <p />
            <b>Department: </b>
            Technology
            <p />
            <span>
              We are looking at hiring Backend Developers who will be an
              intrinsic part of Agile Development teams. We are looking at
              self-driven, self-organising innovators who will take pride in
              creating new products in the Industry by pushing boundaries
              internally and externally.
            </span>
          </p>

          <b>Job Responsibilities:</b>
          <ul className={classes.ul}>
            <li>
              Passionate about developing highly appealing and nimble UX that is
              scalable and works well even for the lowest denominator
              (browsers/devices)
            </li>
            <li>
              Strong problem-solving aptitude with the ability to understand
              business requirements and propose feasible technical designs.
            </li>
            <li>
              Excellent understanding of web application development frameworks
              and server-side considerations to deliver performance at a scale.
            </li>
            <li>
              Minimum 4+ years of experience developing secure and scalable REST
              APIs using node.js/express and related frameworks.
            </li>
            <li>
              Expert understanding of HTTP Protocol, REST APIs design, and
              related best practices.
            </li>
            <li>
              Ability to deep dive into relational and No-SQL databases
              including caching options, with the ability to troubleshoot and
              fix performance issues.
            </li>
            <li>Ability to write cron jobs and ensure reliable execution.</li>
            <li>
              Sound understanding of asynchronous operations, callbacks,
              server-to-server communication.
            </li>
            <li>
              Knowledge of handling authentication and authorization, caching,
              and distributed operations.
            </li>
          </ul>

          <b>Skills & Experience:</b>

          <ul className={classes.ul}>
            <li>
              Experience developing automated testing of all server-side
              components/APIs.
            </li>
            <li>
              Experience with using AWS services such as EC2, Lambda, API
              gateway, SQL, Cloudfront, etc.
            </li>
            <li>Must have a minimum of 5+ years of experience.</li>
          </ul>

          <div className={classes.formHeader}>
            <h4>Quick Apply : AM/Manager-Back End Developer </h4>
          </div>
        </>
      );
    case "2573128808":
      return (
        <>
          <p>
            <b>
              <img src={EdelweissImg} width={"100%"} alt="not found" />
            </b>
            <p />
            <b>Designation: </b>
            AM/ Manager – Front End Development ReactJS
            <p />
            <b>Reporting To: </b>
            Engineering Manager
            <p />
            <b>Department: </b>
            Technology
            <p />
            <span>
              We are looking at hiring Frontend Developers ReactJs who will be
              an intrinsic part of Agile Development teams. We are looking at
              self-driven, self-organising innovators who will take pride in
              creating new products in the Industry by pushing boundaries
              internally and externally.
            </span>
          </p>
          <b>Job responsibility:</b>
          <ul className={classes.ul}>
            <li>
              Passionate about developing highly appealing and nimble UX that is
              scalable and works well even for the lowest denominator
              (browsers/devices)
            </li>
            <li>
              Experience of working closely with UX designers with sound
              knowledge of HTML5 and CSS frameworks (bootstrap or others)
            </li>
            <li>
              Strong understanding of ReactJS object model with an ability to
              use object-oriented design patterns
            </li>
            <li>
              Aware of best practices in web security, asynchronous operations,
              event-handling, caching and partial page updates
            </li>
            <li>
              Basic understanding of relational and no-SQL databases, REST APIs,
              microservices, and web analytics
            </li>
            <li>
              Strong aptitude to solve problems, propose feasible technical
              designs, analyze the pros and cons to decide the most optimal
              approach.
            </li>
          </ul>

          <b>Education:</b>
          <ul className={classes.ul}>
            <li>
              You should have completed B.E./B.Tech./MCA from reputed
              College/University.
            </li>
          </ul>

          <b>Experience:</b>

          <ul className={classes.ul}>
            <li>
              Must have a minimum of 5+ years of experience in ReactJS framework
              and libraries such as React/Angular/Vue
            </li>
          </ul>
          <div className={classes.formHeader}>
            <h4>Quick Apply : AM/ Manager – Front End Development ReactJS</h4>
          </div>
        </>
      );

    case "1457342611":
      return (
        <>
          <p>
            <b>
              <img src={EdelweissImg} width={"100%"} alt="not found" />
            </b>
            <p />
            <b>Designation: </b>
            Software Testing Engineers/QA
            <p />
            <b>Reporting To: </b>
            Lead QA
            <p />
            <b>Department: </b>
            Technology
            <p />
            <span>
              We are looking at hiring Software Testing Engineers who will be an
              intrinsic part of Agile Development teams. We are looking at
              self-driven, self-organising innovators who will take pride in
              creating new products in the industry by pushing boundaries
              internally and externally.
            </span>
          </p>

          <b>Responsibilities:</b>
          <ul className={classes.ul}>
            <li>
              Responsible for performing both manual and automation testing
            </li>
            <li>
              Review product and feature designs in Sprint planning meetings to
              create test cases and test scenarios
            </li>
            <li>Create and execute test cases, test plans and gap analysis</li>
            <li>
              Report defects in the defect tracking tool and track issues
              throughout the life cycle of various Products
            </li>
            <li>
              Work with Product and Business users during UAT cycle to keep the
              UAT demos in-line with the agreed scope and schedule
            </li>
            <li>
              Work with Scrum masters and Agile Program manager and provide
              feedback on release readiness
            </li>
            <li>
              Work with development and Tech Operations team towards defect
              traceability and reproduction of defects on Integration
              environment.
            </li>
            <li>
              Responsible for developing automation test cases for Web front
              end, APIs and back end
            </li>
            <li>
              Work towards creation, enhancement and maintenance of test
              automation frameworks
            </li>
            <li>
              Participate in creation of Test strategies and project
              documentation
            </li>
            <li>
              Prioritize and manage own workload in order to deliver quality
              results and meet timelines
            </li>
            <li>
              Support a positive work environment that promotes responsibility,
              accountability, quality, innovation and teamwork while ensuring
              timely communication of issues/ points of interest
            </li>
            <li>
              Identify and recommend ideas and opportunities to enhance
              productivity, effectiveness and operational efficiency of the team
            </li>
          </ul>

          <b>Skills:</b>

          <ul className={classes.ul}>
            <li>Good hands on knowledge of software development life cycle</li>
            <li>
              Experience using test automation tools such as Selenium, Web
              driver, Rest Assured
            </li>
            <li>Good at database testing and in writing SQL queries.</li>
            <li>Experience in Whitebox testing</li>
            <li>
              Sound knowledge and experience in Web Testing and API Testing
            </li>
            <li>
              Previous experience of using source control tools such as GIT,
              SVN, Bit Bucket
            </li>
            <li>
              Previous experience of using bug tracking tools such as Jira or
              Bugzilla
            </li>
            <li>
              Sound knowledge of testing and software development practices of
              internet scale applications and methodologies and defect
              management practices
            </li>
            <li>
              Good understanding of diverse technologies and distributed systems
              with the ability to understand and acquire new technical skills.
            </li>
            <li>
              Experience working on Agile Projects and exposure to collaboration
              tools such as JIRA
            </li>
            <li>Excellent written and verbal communication skills</li>
            <li>
              Be able to thrive in a fast-paced, dynamic environment with
              minimal support
            </li>
          </ul>

          <b>Educational qualification:</b>

          <ul className={classes.ul}>
            <li>
              Bachelor’s degree in Engineering or Computer Applications or an
              equivalent in working experience
            </li>
          </ul>
          <b>Experience:</b>

          <ul className={classes.ul}>
            <li>
              3-5 years + years of experience as a Quality or Software testing
              engineer preferably from a Fintech/B2C firm
            </li>
          </ul>

          <div className={classes.formHeader}>
            <h4>Quick Apply : Software Testing Engineers/QA</h4>
          </div>
        </>
      );

    case "5432135670":
      return (
        <>
          <h2>Apply for following jobs </h2>
          <ol
            className="olDesc"
            style={{
              paddingTop: 0,
            }}
          >
            <li>Java Tech Architects</li>
            <li>Engineering Manager</li>
            <li>Lead Architect</li>
            <li>Software Development Engineer – III (Backend)</li>
            <li>Java Developers/Sr. Developers</li>
            <li>Digital Marketing Expert</li>
          </ol>
          <ol className="olDesc">
            <li>
              <>
                <p
                  className={classes.role}
                  style={{
                    marginTop: 0,
                  }}
                >
                  <strong className={classes.strongRole}>
                    Role: Java Tech Architects
                  </strong>
                </p>
                <p>
                  <strong>Industry</strong>: IT Services and Consulting
                </p>
                <p>
                  <strong>Function</strong> : Engineering - Software
                </p>
                <p>
                  <strong>Employment</strong> Type: Full Time, Permanent
                </p>
                <p>
                  <strong>Experience</strong>: MumShould have overall 10-12
                  years of development experience on Java, Microservices and
                  Cloud Educationbai
                </p>
                <p>
                  <strong>UG</strong>: B.Tech/B.E. in Any Specialisation
                </p>
                <p>
                  <strong>PG</strong>: M.Tech in Any Specialization, MCA in Any
                  Specialisation
                </p>
              </>

              <b>Desired Skills:</b>
              <ul className={classes.ul}>
                <li>
                  Full Stack developer, Java, Spring boot, Microservices,
                  Technical Architecture, Azure
                </li>
                <li>
                  Java/J2EE, including Web Sockets, Spring MVC, Spring Security,
                  ORM, Hibernate, JPA Frameworks, Struts etc., DTO, MQ based
                  messaging and either of database technologies: Oracle, MySQL,
                  SQL Server
                </li>
                <li>
                  Should have experience building modern solutions on Azure or
                  AWS or GCP Cloud and willingness to learn &amp; acquire new
                  technologies.
                </li>
                <li>
                  Strong Experience with Front end Frameworks /Technologies such
                  as Angular, React.js, Vue.js
                </li>
                <li>
                  Experience on Applications Servers: Apache, Tomcat, JBoss,
                  WebLogic
                </li>
                <li>
                  Experience with API gateways and containerized applications
                  using Docker, Kubernetes or other similar tools
                </li>
                <li>
                  Experience on Agile Projects. Very good knowledge about Agile
                  Scrum
                </li>
                <li>
                  Ability to work well with clients both individually as well as
                  in a highly collaborative team
                </li>
                <li>Excellent communication and interpersonal skills</li>
              </ul>
            </li>
            <li>
              <>
                <p className={classes.role}>
                  <strong className={classes.strongRole}>
                    Role: Engineering Manager
                  </strong>
                </p>
                <p>
                  <strong>Industry</strong>: Software Product
                </p>
                <p>
                  <strong>Function</strong> : Engineering - Software
                </p>
                <p>
                  <strong>Employment</strong> Type: Full Time, Permanent
                </p>
                <p>
                  <strong>Experience</strong>:{" "}
                  <ul
                    style={{
                      paddingLeft: 50,
                    }}
                  >
                    <li>At least 8+ years professional experience</li>
                    <li>
                      At least 2 years of experience in managing software
                      development teams
                    </li>
                  </ul>
                </p>
                <p>
                  <strong>Education</strong>: Bachelor&#39;s/Master’s in
                  computer science
                </p>
              </>

              <b>Desired Skills:</b>
              <ul className={classes.ul}>
                <li>Deep understanding of transactional and NoSQL DBs</li>
                <li>Deep understanding of Messaging systems – Kafka</li>
                <li>
                  Good experience on cloud infrastructure - AWS preferably
                </li>
                <li>Good to have: Data pipelines, ES</li>
                <li>
                  Exceptional team managing skills; experience in building large
                  scale distributed Systems
                </li>
                <li>Experience in Scalable Systems</li>
                <li>Expertise in Java/Python and multithreading</li>
              </ul>
            </li>
            <li>
              <>
                <p className={classes.role}>
                  <strong className={classes.strongRole}>
                    Role: Lead Architect
                  </strong>
                </p>
                <p>
                  <strong>Industry</strong>: Software Product
                </p>
                <p>
                  <strong>Function</strong> : Engineering – Software, Marketing
                  Technology
                </p>
                <p>
                  <strong>Employment</strong> Type: Full Time, Permanent
                </p>
                <p>
                  <strong>Experience</strong>: 4-6 years
                </p>
              </>

              <b>Skills Required:</b>
              <ul className={classes.ul}>
                <li>Full Stack Modular Development</li>
                <li>Agile software development methodology</li>
                <li>
                  JavaScript, ReactJS, Redux, React-Native, AngularJS,
                  NodeJS-Express, HTML5, CSS3, Web services, Deployment.
                </li>
              </ul>
            </li>
            <li>
              <>
                <p className={classes.role}>
                  <strong className={classes.strongRole}>
                    Role: Software Development Engineer – III (Backend)
                  </strong>
                </p>
                <p>
                  <strong>Industry</strong>: Software Product
                </p>
                <p>
                  <strong>Function</strong> : Engineering – Software, Marketing
                  Technology
                </p>
                <p>
                  <strong>Employment</strong> Type: Full Time, Permanent
                </p>
                <p>
                  <strong>Experience</strong>: 3-6 years of relevant work
                  experience
                </p>
                <p>
                  <strong>Education</strong>: BTech degree in computer science
                  or related field
                </p>
              </>

              <b>Desired Skills:</b>
              <ul className={classes.ul}>
                <li>
                  Strong knowledge of any of the databases like MySQL, NoSQL,
                  SQL Server, Oracle, PostgreSQL
                </li>
                <li>Experience in Java and web technologies</li>
                <li>
                  Experience in scripting languages like Python, PHP, etc.
                </li>
                <li>
                  Hands-on experience with systems that are asynchronous,
                  RESTful and demand concurrency
                </li>
                <li>
                  Knowledge of best software engineering practices for all
                  stages of software development life cycle
                </li>
                <li>
                  Experience with API gateways and containerized applications
                  using Docker, Kubernetes or other similar tools
                </li>
                <li>
                  Experience on Agile Projects. Very good knowledge about Agile
                  Scrum
                </li>
                <li>
                  Ability to work well with clients both individually as well as
                  in a highly collaborative team
                </li>
                <li>Excellent communication and interpersonal skills</li>
              </ul>
            </li>
            <li>
              <>
                <p className={classes.role}>
                  <strong className={classes.strongRole}>
                    Role: Java Developers/Sr. Developers
                  </strong>
                </p>
                <p>
                  <strong>Industry</strong>: Software Product
                </p>
                <p>
                  <strong>Function</strong> : Engineering - Software
                </p>
                <p>
                  <strong>Employment</strong> Type: Full Time, Permanent
                </p>
                <p>
                  <strong>Experience</strong>: 2+ years of experience
                </p>
                <p>
                  <strong>Education</strong>: Bachelor’s degree in computer
                  science, software engineering, or a technology-related
                  discipline is preferred.
                </p>
              </>

              {/* <b>Desired Skills:</b>
              <ul className={classes.ul}></ul> */}
            </li>
            <li>
              <>
                <p className={classes.role}>
                  <strong className={classes.strongRole}>
                    Role: Digital Marketing Expert
                  </strong>
                </p>
                <p>
                  <strong>Industry</strong>: Software Product
                </p>
                <p>
                  <strong>Function</strong> : Marketing
                </p>
                <p>
                  <strong>Employment</strong> Type: Full Time, Permanent
                </p>
                <p>
                  <strong>Experience</strong>: 2-4 years
                </p>
                <p>
                  <strong>Education</strong>: Bachelor’s degree
                </p>
              </>

              <b>Desired Skills:</b>
              <ul className={classes.ul}>
                <li>
                  Experience in managing and analysing Google, Facebook and
                  Youtube Ads campaigns
                </li>
                <li>
                  Measure and report performance of all digital marketing
                  campaigns, and assess against goals (ROI and KPIs) and Report
                  to CSM
                </li>
                <li>Ability to multi-tasking</li>
                <li>
                  Strong Experience with Front end Frameworks /Technologies such
                  as Angular, React.js, Vue.js
                </li>
                <li>Strong communication skills</li>
              </ul>
            </li>
          </ol>

          <div className={classes.formHeader}>
            <h4>Quick Apply : Java Developers</h4>
          </div>
        </>
      );
    default:
      break;
  }
};
