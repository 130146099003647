import { CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { MotionContainer } from "../../../components/animate";
import CustomInputAndSelectField from "../../../components/CustomInputAndSelectField/Index";
import CustomModal from "../../../components/CustomModal/Index";
import achievementCategory from "../../../constants/achievement.data";
import {
  deleteAchievementThunk,
  deleteCertificationThunk,
  deletePublicationThunk,
  getAllDataThunk,
} from "../../../redux/Dashboard/achievement.slice";
import AchievementStyles from "../../../styles/pages/Resume/components/achievement.styles";
import formatDate from "../../../utils/dateFormatter";
import CardContainerComponent from "./CardContainer/Index";
import Awards from "./subComponents/achievement/awards";
import Certification from "./subComponents/achievement/certification";
import Publication from "./subComponents/achievement/publication";
import _ from "lodash";

export default function AchievementComponent({
  onSpecificChange,
  value = {},
  handleChange,
  bulkUpdate,
  clearInput,
  setIsEditing,
  isEditing,
}) {
  const classes = AchievementStyles();
  const achievement = useSelector((state) => state.achievement);
  const dispatch = useDispatch();
  const [type, setType] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [cat, setCat] = React.useState("");
  const [isUpdate, setUpdate] = React.useState("");
  const { resumeId } = useParams();

  React.useEffect(() => {
    if (!_.isEmpty(value)) {
      setIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange]);

  React.useEffect(() => {
    dispatch(
      getAllDataThunk({
        token: localStorage.getItem("token"),
        resumeId: resumeId,
      })
    );
  }, [dispatch, resumeId]);

  const handleDeletePublication = (token, resume_id) => {
    dispatch(deletePublicationThunk({ token, resume_id, pb_id: type }));
    setType("");
  };

  const handleDeleteCertification = (token, resume_id) => {
    dispatch(deleteCertificationThunk({ token, resume_id, cf_id: type }));
    setType("");
  };

  const handleDeleteAchievement = (token, resume_id) => {
    dispatch(deleteAchievementThunk({ token, resume_id, ac_id: type }));
    setType("");
  };

  const renderAchievement = (achievement) => {
    switch (achievement) {
      case "Certification":
        return (
          <Certification
            classes={classes}
            setType={setUpdate}
            setCategory={setCat}
            onSpecificChange={onSpecificChange}
            actionBtn={classes.actionBtn}
            value={value}
            updateValue={isUpdate}
            handleChange={handleChange}
            clearInput={clearInput}
            bulkUpdate={bulkUpdate}
            setIsEditing={setIsEditing}
          />
        );
      case "Publication":
        return (
          <Publication
            classes={classes}
            setType={setUpdate}
            setCategory={setCat}
            actionBtn={classes.actionBtn}
            value={value}
            updateValue={isUpdate}
            handleChange={handleChange}
            clearInput={clearInput}
            bulkUpdate={bulkUpdate}
            setIsEditing={setIsEditing}
          />
        );
      case "Awards/Achievements":
        return (
          <Awards
            classes={classes}
            setType={setUpdate}
            setCategory={setCat}
            onSpecificChange={onSpecificChange}
            actionBtn={classes.actionBtn}
            setIsEditing={setIsEditing}
            value={value}
            updateValue={isUpdate}
            handleChange={handleChange}
            clearInput={clearInput}
            bulkUpdate={bulkUpdate}
          />
        );
      default:
        return "";
    }
  };
  if (achievement.loading)
    return (
      <div className={classes.loader}>
        <CircularProgress disableShrink size={30} thickness={6} />
      </div>
    );
  return (
    <>
      <CustomModal
        isOpen={!!type}
        setOpen={setType}
        confirmBtnColor={"error"}
        confirmBtnText={"Delete"}
        mbContent={2}
        dialogTitle={`Deleting ${category}`}
        cancelBtnColor={"primary"}
        cancelBtnText={"Cancel"}
        actionCallback={(e) => {
          if (category === "achievement") {
            handleDeleteAchievement(localStorage.getItem("token"), resumeId);
          }
          if (category === "publication") {
            handleDeletePublication(localStorage.getItem("token"), resumeId);
          }
          if (category === "certification") {
            handleDeleteCertification(localStorage.getItem("token"), resumeId);
          }
        }}
        dialogContentText={
          "Please note the the action will delete the resume permanently."
        }
        dialogContent={"Are you sure you want to delete?"}
      />
      <Grid className={classes.container} container spacing={5} rowGap={4}>
        <Grid item sm={6} xs={12} className={classes.contentLeft}>
          <Box id="cardContainer">
            {/* <Paper
              style={{
                background: "#eee",
                marginBottom: "20px",
              }}
            >
              <iframe
                style={{
                  width: "100%",
                }}
                height="315"
                src="https://www.youtube.com/embed/2vz0eemqOlE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>{" "}
            </Paper> */}

            {false ? (
              <Typography className={classes.typography} variant="h5">
                Choose a category and start adding your Courses / Trainings
              </Typography>
            ) : (
              <>
                {achievement.publications
                  .filter((ach) => {
                    return parseInt(ach.pb_id) !== parseInt(isUpdate);
                  })
                  .map((a) => (
                    <MotionContainer
                      open={true}
                      children={
                        <CardContainerComponent
                          uapEditClassName={"ach_pub_edit_btn"}
                          uapDeleteClassName="ach_pub_delete_btn"
                          dividerText={"Publication"}
                          headTitle={a.pb_title}
                          editCallback={(e) => {
                            setCat("Publication");
                            setUpdate(a.pb_id);
                            bulkUpdate(a);
                          }}
                          deleteCallback={(e) => {
                            setCategory("publication");
                            setType(a.pb_id);
                          }}
                          title={a.pb_where_org}
                          title2={a.pb_link}
                        />
                      }
                    />
                  ))}
                {achievement.certifications
                  .filter((ach) => {
                    return parseInt(ach.cf_id) !== parseInt(isUpdate);
                  })
                  .sort((a, b) => {
                    return (
                      new Date(b.cf_completion_date) -
                      new Date(a.cf_completion_date)
                    );
                  })
                  .map((a) => (
                    <MotionContainer
                      open={true}
                      children={
                        <CardContainerComponent
                          uapEditClassName={"ach_cef_edit_btn"}
                          uapDeleteClassName="ach_cef_delete_btn"
                          dividerText={"Certification"}
                          headTitle={a.cf_name}
                          editCallback={(e) => {
                            setCat("Certification");
                            setUpdate(a.cf_id);
                            bulkUpdate(a);
                          }}
                          deleteCallback={(e) => {
                            setCategory("certification");
                            setType(a.cf_id);
                          }}
                          title={a.cf_where_inst}
                          subtitle={formatDate(new Date(a.cf_completion_date))}
                        />
                      }
                    />
                  ))}
                {achievement.achievements
                  .filter((ach) => {
                    return parseInt(ach.ac_id) !== parseInt(isUpdate);
                  })
                  .sort((a, b) => {
                    return new Date(b.ac_year) - new Date(a.ac_year);
                  })
                  .map((a) => (
                    <MotionContainer
                      open={true}
                      children={
                        <CardContainerComponent
                          uapEditClassName={"ach_award_edit_btn"}
                          uapDeleteClassName="ach_award_delete_btn"
                          dividerText={"Achievement"}
                          headTitle={a.ac_name}
                          editCallback={(e) => {
                            setCat("Awards/Achievements");
                            handleChange(
                              {
                                name: "achievement",
                                value: "Awards/Achievements",
                              },
                              true
                            );
                            setUpdate(a.ac_id);
                            bulkUpdate(a);
                          }}
                          deleteCallback={(e) => {
                            setCategory("achievement");
                            setType(a.ac_id);
                          }}
                          title={formatDate(new Date(a.ac_year))}
                        />
                      }
                    />
                  ))}
              </>
            )}
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          container
          spacing={3}
          className={classes.contentRight}
          rowGap={5}
        >
          <Grid sm={12} item xs={12} className={classes.gridItem}>
            <CustomInputAndSelectField
              value={cat}
              changeCallBack={(e, v) => setCat(v)}
              name={"achievement"}
              label={"Select a Category"}
              className={"uap_ach_dropdown"}
              fullWidth
              options={achievementCategory.map((achievement) => achievement)}
              freeSolo={false}
            />
          </Grid>
          {renderAchievement(cat)}
        </Grid>
      </Grid>
    </>
  );
}
