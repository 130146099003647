import axios from "axios";
import endPoints from "../../../../../config/endPoint";
const sendMail = async (to, userId) => {
  return axios.post(`${endPoints.sendEmail}`, {
    email: to,
    user_id: userId,
  });
};

const verifyMail = async (otp, token, hash, userId) => {
  return axios.post(`${endPoints.verifyMail}`, {
    otp: `${otp}`,
    token: token,
    hash: hash,
    user_id: userId,
  });
};

const resendMail = async (to, subject = "VIP APPLICANT", content = " ") => {
  return axios.post(`${endPoints.resendMail}`, {
    to: to,
    subject: "Test VIP applicant",
    content:
      "Hii BRo I am not going to send your resume" /* doc.output("datauristring") */,
  });
};

function blobToDataURL(blob) {
  return new Promise((fulfill, reject) => {
    let reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (e) => fulfill(reader.result);
    reader.readAsDataURL(blob);
  });
}

const sendResume = async (to, subject = "VIP APPLICANT", content = " ") => {
  let pdfUrl = await blobToDataURL(content);
  return axios.post(`${endPoints.sendResume}`, {
    to: to,
    subject: subject,
    content: pdfUrl,
  });
};

export { sendMail, verifyMail, sendResume, resendMail };
