import { makeStyles } from "@mui/styles";

let ResumeStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "inherit",
  },
  contentDiv: {
    padding: 60,
    textAlign: "left",
    width: "100%",
    overflow: "auto",
    [theme.breakpoints.down("lg")]: {
      marginTop: 70,
      padding: 10,
    },
  },
  body: {
    margin: "30px 0px",
    border: `1px solid ${theme.palette.divider}`,
    padding: 20,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  stepperBar: {
    "& span": {
      fontSize: "10px",
      whiteSpace: "nowrap",
      // background: "red",
    },
    [theme.breakpoints.down("sm")]: {
      zoom: "0.7",
    },
  },
}));

export default ResumeStyles;
