import { CircularProgress, Grid, InputAdornment } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slide } from "react-reveal";
import { useParams } from "react-router";
import CustomInputAndSelectField from "../../../components/CustomInputAndSelectField/Index";
import CustomInputField from "../../../components/CustomInputField/Index";
import { getPersonalDetail } from "../../../redux/Dashboard/personalDetail.slice";
import PersonalDetailStyle from "../../../styles/pages/Resume/components/personalDetails.styles";

import { City, Country, State } from "country-state-city";

export default function PersonalDetailComponent({
  value = {},
  onSpecificChange,
  handleChange,
  bulkUpdate,
  formError,
  clearInput,
}) {
  const classes = PersonalDetailStyle();
  const personalDetail = useSelector((state) => state.personalDetail);
  const token = localStorage.getItem("token");
  const { resumeId } = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      getPersonalDetail({ token: token, resumeId: resumeId, cb: bulkUpdate })
    );
    // bulkUpdate(personalDetail.raw)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (personalDetail.pending)
    return (
      <div className={classes.loader}>
        <CircularProgress disableShrink size={30} thickness={6} />
      </div>
    );
  return (
    <Slide bottom>
      <Grid className={classes.container} container spacing={5} rowGap={4}>
        <Grid sm={6} item xs={12}>
          <CustomInputField
            label={"First Name"}
            name="pd_first_name"
            error={formError.pd_first_name}
            helperText={formError.pd_first_name}
            value={value.pd_first_name}
            onChange={(e) => {
              if (
                e.target.value.length <= 40 &&
                e.target.value.match(/^[a-zA-Z\s]*$/)
              ) {
                handleChange(e);
              }
            }}
            required={true}
            fullWidth
          />
        </Grid>
        <Grid sm={6} item xs={12}>
          <CustomInputField
            label={"Last Name"}
            name="pd_last_name"
            value={value.pd_last_name}
            onChange={(e) => {
              if (
                e.target.value.length <= 40 &&
                e.target.value.match(/^[a-zA-Z\s]*$/)
              ) {
                handleChange(e);
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid sm={6} item xs={12}>
          <CustomInputField
            label={"Email"}
            name={"pd_email_id"}
            helperText={
              formError.pd_email_id
                ? formError.pd_email_id
                : "Use a formal email address"
            }
            required={true}
            error={
              value.pd_email_id ||
              value.pd_email_id === "" ||
              formError.pd_email_id
                ? !value.pd_email_id?.match(
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
                  )
                : false
            }
            value={value.pd_email_id}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid sm={6} item xs={12}>
          <div className={classes.multiGridContainer}>
            <CustomInputField
              label={"Contact Number"}
              error={formError.pd_contact_number}
              required={true}
              value={value.pd_contact_number}
              type="number"
              onChange={(e) => {
                if (
                  // e.target.value.match(/^\d+$/) &&
                  e.target.value.length < 11
                ) {
                  handleChange(e);
                } else if (e.target.value.length === 0) {
                  handleChange(e);
                }
              }}
              startAdornment={
                <InputAdornment position="start" style={{}}>
                  <select
                    value={value.pd_country_code}
                    onChange={(e) => {
                      handleChange(
                        { name: "pd_country_code", value: e.target.value },
                        true
                      );
                    }}
                    defaultValue={91}
                    style={{
                      maxWidth: 90,
                      fontSize: "medium",
                      border: "none",
                    }}
                  >
                    {Country.getAllCountries()
                      .sort((a, b) => {
                        return (
                          ["US", "IN"].indexOf(b.isoCode) -
                          ["US", "IN"].indexOf(a.isoCode)
                        );
                      })
                      .map((con, idx) => {
                        return (
                          <option
                            key={idx}
                            value={
                              con.phonecode?.split("+").length === 1
                                ? "+" + con.phonecode?.split("+")[0]
                                : "+" + con.phonecode?.split("+")[1]
                            }
                          >
                            {con.flag} +
                            {con.phonecode?.split("+").length === 1
                              ? con.phonecode?.split("+")[0]
                              : con.phonecode?.split("+")[1]}{" "}
                          </option>
                        );
                      })}
                  </select>
                  {/* {value.pd_country_code?.phonecode
                    ? "+" + value.pd_country_code?.phonecode
                    : ""} */}
                </InputAdornment>
              }
              name={"pd_contact_number"}
              fullWidth
              helperText="Enter a valid mobile number without country code"
            />
          </div>
        </Grid>
        <Grid sm={6} item xs={12}>
          <CustomInputField
            label={"LinkedIn ADDRESS"}
            name="pd_linkedin_address"
            value={value.pd_linkedin_address}
            onChange={(e) => {
              if (e.target.value.length <= 200) {
                handleChange(e);
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid sm={6} item xs={12}>
          <CustomInputField
            label={"Github / Website url"}
            value={value.pd_any_other_website}
            onChange={(e) => {
              if (e.target.value.length <= 100) {
                handleChange(e);
              }
            }}
            name="pd_any_other_website"
            fullWidth
          />
        </Grid>
        <Grid sm={6} item xs={12}>
          <CustomInputAndSelectField
            freeSolo={false}
            label="Select Country"
            value={value.pd_country}
            getOptionLabel={(option) => option.name ?? option}
            name="pd_country"
            changeCallBack={(e, v) =>
              handleChange(
                {
                  value: v,
                  name: "pd_country",
                },
                true
              )
            }
            options={Country.getAllCountries()}
          />
        </Grid>
        <Grid sm={3} item xs={12}>
          <CustomInputAndSelectField
            label={"State"}
            value={value.pd_state}
            name="pd_state"
            fullWidth
            getOptionLabel={(stateObj) => stateObj.name ?? stateObj}
            changeCallBack={(e, v) =>
              handleChange(
                {
                  name: "pd_state",
                  value: v,
                },
                true
              )
            }
            options={State.getStatesOfCountry(value.pd_country?.isoCode)}
            freeSolo={false}
          />
        </Grid>
        <Grid sm={3} item xs={12}>
          <CustomInputAndSelectField
            label={"City"}
            value={value.pd_city}
            name="pd_city"
            fullWidth
            changeCallBack={(e, v) => onSpecificChange("pd_city", v)}
            options={City.getCitiesOfState(
              value.pd_country?.isoCode,
              value.pd_state?.isoCode
            ).map((st) => st.name)}
            freeSolo={false}
          />
          {/* <CustomInputField
            label={"City"}
            value={value.pd_city}
            onChange={(e) => {
              if (e.target.value.match(/^[a-zA-Z\s]*$/)) {
                handleChange(
                  {
                    name: e.target.name,
                    value: capitalizeFirstLetter(e.target.value),
                  },
                  true
                );
              }
            }}
            name="pd_city"
            fullWidth
          /> */}
        </Grid>
      </Grid>
    </Slide>
  );
}
