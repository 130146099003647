import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

const vibrate = keyframes`
0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
`;

const VibrateBox = styled("div")({
  animation: `${vibrate} 2s infinite`,
  lineHeight: 1,
});

export default VibrateBox;
