import axios from "axios";
import endPoints from "../../../../../config/endPoint";

const checkIsVerified = async (userId) => {
  let data = await axios.post(`${endPoints.emailIsVerified}`, {
      user_id: userId,
    });
  return data
};

export default checkIsVerified;
