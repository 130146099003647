import React from "react";

import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaInfoCircle,
  FaLock,
  FaTimesCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dynamicSuggestion from "../../../../../utils/checkMissing";

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  info: {
    color: "#0052cc",
  },
  popOverContainer: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  popover: {
    background: "aliceblue",
    "& p": {
      fontSize: "0.8rem",
    },
  },
  bulletContainer: { textAlign: "left", marginLeft: 20, marginTop: 10 },
  inActiveIcon: {
    color: theme.palette.grey[500],
  },
  danger: {
    color: "#ff0000", //theme.palette.error.main,
  },
  warning: {
    color: "#ffa50f", //theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  listItem: {
    borderBottom: "1px solid #e6e6e6",
    "& p": {
      color: theme.palette.grey[500],
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
}));

const ActiveVoiceInfo = ({ classes, handleClick }) => {
  return (
    <IconButton
      size="small"
      variant="contained"
      onClick={(e) => {
        handleClick(
          e,
          <Typography className={classes.typography} sx={{ p: 2 }}>
            <p>
              <b>Active Voice</b>
            </p>
            <p>
              You are the star and the subject of your resume. When you are
              applying for a job, first you have got to impress the recruitment
              managers with the words on the resume. So, choose your words
              wisely and get the tone and language of the resume right. Give
              yourself enough credit for your actions and achievements. Try to
              use an active voice while writing the bullet points in a resume.
              <p />
              Example:
              <p />
              She & Tech club at NIT, a community of 100 women coders was built
              and led by me.
              <p />
              Built and led a community of 100 women coders of the She & Tech
              club at NIT and conducted project and coding challenges.
              <p />
              The statement using active voice highlights the contribution and
              achievements and is easy to understand.
            </p>
          </Typography>
        );
      }}
    >
      <FaInfoCircle
        className={classes.info}
        style={{
          fontSize: 16,
        }}
      />
    </IconButton>
  );
};

const PersonalPronounInfo = ({ classes, handleClick }) => {
  return (
    <IconButton
      size="small"
      variant="contained"
      onClick={(e) => {
        handleClick(
          e,
          <Typography className={classes.typography} sx={{ p: 2 }}>
            <p>
              <b>Personal Pronouns</b>
            </p>
            <p>
              You have put your name on top of the resume. It means that the
              actions, experience and other details are describing YOUR
              capabilities. Using personal pronouns would take up space and
              create redundancy. List of personal pronouns.( I, We, Me, You,
              They, Them, Us, He, She, His, Her, Him, It,)
            </p>
          </Typography>
        );
      }}
    >
      <FaInfoCircle
        className={classes.info}
        style={{
          fontSize: 16,
          marginLeft: 0,
        }}
      />
    </IconButton>
  );
};

const ActiveVerbInfo = ({ classes, handleClick }) => {
  return (
    <IconButton
      size="small"
      variant="contained"
      onClick={(e) => {
        handleClick(
          e,
          <Typography className={classes.typography} sx={{ p: 2 }}>
            <p>
              <b>Action words/verbs</b>
            </p>
            <p>
              Action Words/Verbs power up your resume. An action verb used at
              the beginning of the bullet points emphasizes YOUR competencies
              and abilities to get a task done. Recruiters also feel that they
              make the sentences in the resume, impactful and easier to
              comprehend.
              <p />
              Example: Worked on implementing the price prediction module.
              <p />
              OR
              <p />
              Implemented the price prediction module. Both these sentences mean
              the same, but the 2nd sentence describes the action of the person
              clearly. Weak, vague, or overused verbs may diminish the value of
              the excellent work you did in a particular role.
            </p>
          </Typography>
        );
      }}
    >
      <FaInfoCircle
        className={classes.info}
        style={{
          fontSize: 16,
          marginLeft: 0,
        }}
      />
    </IconButton>
  );
};

const QuantificationInfo = ({ classes, handleClick }) => {
  return (
    <IconButton
      size="small"
      variant="contained"
      onClick={(e) => {
        handleClick(
          e,
          <Typography className={classes.typography} sx={{ p: 2 }}>
            <p>
              <b>Quantification</b>
            </p>
            <p>
              Numbers make a tremendous difference in resumes. To polish your
              resume, add a few numbers to quantify your experience and
              accomplishments to showcase the impact you’re capable of making.
              <p />
              Example: Using PTC Creo, we have designed a bottle that can
              withstand high stress.
              <p />
              (or)
              <p />
              Designed a bottle using PTC Creo by optimizing 12 properties of
              the plastic material to withstand high stress of 12.8psi Try
              adding numbers while defining your responsibilities and
              accomplishments to add a punch to your candidature. Of course, not
              all your career highlights may be quantifiable, but many of them
              will be. Figure out which numbers are the most crucial to your
              success and the job you are applying for.
            </p>
          </Typography>
        );
      }}
    >
      <FaInfoCircle
        className={classes.info}
        style={{
          fontSize: 16,
          marginLeft: 0,
        }}
      />
    </IconButton>
  );
};

const BasicBulletPointContainer = ({ classes, text, experience }) => {
  let { personalPronouns, activeVoice, punctuation } = dynamicSuggestion(
    text,
    experience.actionVerbList
  );
  return (
    <div className={classes.bulletContainer}>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          <FaLock />
        </ListItemIcon>
        <ListItemText
          primary="Action Verb"
          secondary={
            <>
              Upgrade plan to unlock this feature.{" "}
              <Typography variant="body2" component={Link} to={`/plans`}>
                Upgrade Now
              </Typography>
            </>
          }
        />
      </ListItem>

      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          <FaLock />
        </ListItemIcon>
        <ListItemText
          primary="2-4 Bullet Points"
          secondary={
            <>
              Upgrade plan to unlock this feature.{" "}
              <Typography variant="body2" component={Link} to={`/plans`}>
                Upgrade Now
              </Typography>
            </>
          }
        />
      </ListItem>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          <FaLock />
        </ListItemIcon>
        <ListItemText
          primary="Ideal Length"
          secondary={
            <>
              Upgrade plan to unlock this feature.{" "}
              <Typography variant="body2" component={Link} to={`/plans`}>
                Upgrade Now
              </Typography>
            </>
          }
        />
      </ListItem>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          <FaLock />
        </ListItemIcon>
        <ListItemText
          primary="Quantification"
          secondary={
            <>
              Upgrade plan to unlock this feature.{" "}
              <Typography variant="body2" component={Link} to={`/plans`}>
                Upgrade Now
              </Typography>
            </>
          }
        />
      </ListItem>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          {activeVoice.status === "empty" ? (
            <FaCheckCircle className={classes.inActiveIcon} />
          ) : activeVoice.status === "danger" ? (
            <FaTimesCircle className={classes.danger} />
          ) : activeVoice.status === "warning" ? (
            <FaExclamationCircle className={classes.warning} />
          ) : (
            <FaCheckCircle className={classes.success} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Active Voice"
          secondary={
            activeVoice.status === "empty" ? (
              <>Consider writing all the bullet points in active voice.</>
            ) : activeVoice.status === "danger" ? (
              <>
                Consider writing all the bullet points in active voice.
                <br />
                <span className={classes[activeVoice.status]}>
                  Review bullet point : {activeVoice.checkPassiveVoice}
                </span>
              </>
            ) : activeVoice.status === "warning" ? (
              <>
                Consider writing all the bullet points in active voice.
                <br />
                <span className={classes[activeVoice.status]}>
                  Review bullet point : {activeVoice.checkPassiveVoice}
                </span>
              </>
            ) : (
              <>Consider writing all the bullet points in active voice.</>
            )
          }
        />
      </ListItem>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          {personalPronouns.status === "empty" ? (
            <FaCheckCircle className={classes.inActiveIcon} />
          ) : personalPronouns.status === "danger" ? (
            <FaTimesCircle className={classes.danger} />
          ) : personalPronouns.status === "warning" ? (
            <FaExclamationCircle className={classes.warning} />
          ) : (
            <FaCheckCircle className={classes.success} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Personal Pronouns"
          secondary={
            personalPronouns.status === "empty" ? (
              <>Bullet points should not contain any personal pronouns.</>
            ) : personalPronouns.status === "danger" ? (
              <>
                Bullet points should not contain any personal pronouns.
                <br />
                <span className={classes[personalPronouns.status]}>
                  Review bullet point : {personalPronouns.checkPersonalPronoun}
                </span>
              </>
            ) : personalPronouns.status === "warning" ? (
              <>
                Bullet points should not contain any personal pronouns.
                <br />
                <span className={classes[personalPronouns.status]}>
                  Review bullet point : {personalPronouns.checkPersonalPronoun}
                </span>
              </>
            ) : (
              <>Bullet points should not contain any personal pronouns.</>
            )
          }
        />
      </ListItem>

      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          {punctuation.status === "empty" ? (
            <FaCheckCircle className={classes.inActiveIcon} />
          ) : punctuation.status === "danger" ? (
            <FaTimesCircle className={classes.danger} />
          ) : punctuation.status === "warning" ? (
            <FaExclamationCircle className={classes.warning} />
          ) : (
            <FaCheckCircle className={classes.success} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Punctuated Bullet Points"
          secondary={
            punctuation.status === "empty" ? (
              <>
                Capitalize the first letter of the bullet point and end with a
                full stop or a period.
              </>
            ) : punctuation.status === "danger" ? (
              <>
                Capitalize the first letter of the bullet point and end with a
                full stop or a period.
                <br />
                <span className={classes[punctuation.status]}>
                  {" "}
                  Review bullet point : {punctuation.checkPunctuation}
                </span>
              </>
            ) : punctuation.status === "warning" ? (
              <>
                Capitalize the first letter of the bullet point and end with a
                full stop or a period.
                <br />
                <span className={classes[punctuation.status]}>
                  {" "}
                  Review bullet point : {punctuation.checkPunctuation}
                </span>
              </>
            ) : (
              <>
                Capitalize the first letter of the bullet point and end with a
                full stop or a period.
              </>
            )
          }
        />
      </ListItem>
    </div>
  );
};

export default function BulletSuggestion({ text }) {
  const classes = useStyles();
  const experience = useSelector((state) => state.experience);
  const userPlan = useSelector((state) => state.counter);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [content, setContent] = React.useState("");

  const handleClick = (event, cont) => {
    setAnchorEl(event.currentTarget);
    setContent(cont);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let {
    actionVerb,
    noOfBullet,
    idealLength,
    personalPronouns,
    activeVoice,
    punctuation,
    quantifiers,
  } = dynamicSuggestion(text, experience.actionVerbList);

  return userPlan.current_plan === "VA Executive" ? (
    <BasicBulletPointContainer
      classes={classes}
      experience={experience}
      text={text}
    />
  ) : (
    <div className={classes.bulletContainer}>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          {actionVerb.status === "empty" ? (
            <FaCheckCircle className={classes.inActiveIcon} />
          ) : actionVerb.status === "danger" ? (
            <FaTimesCircle className={classes.danger} />
          ) : actionVerb.status === "warning" ? (
            <FaExclamationCircle className={classes.warning} />
          ) : (
            <FaCheckCircle className={classes.success} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <span className={classes.alignCenter}>
              Action Verb{" "}
              <ActiveVerbInfo classes={classes} handleClick={handleClick} />
            </span>
          }
          secondary={
            actionVerb.status === "empty" ? (
              <>Use action verbs at the start of each bullet point.</>
            ) : actionVerb.status === "danger" ? (
              <>
                {" "}
                Use action verbs at the start of each bullet point. <br />
                <span className={classes[actionVerb.status]}>
                  Review bullet point: {actionVerb.checkActionVerb}
                </span>
              </>
            ) : actionVerb.status === "warning" ? (
              <>
                {" "}
                Use action verbs at the start of each bullet point.
                <br />
                <span className={classes[actionVerb.status]}>
                  Review bullet point: {actionVerb.checkActionVerb}
                </span>
              </>
            ) : (
              <> Use action verbs at the start of each bullet point.</>
            )
          }
        />
      </ListItem>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          {noOfBullet.status === "empty" ? (
            <FaCheckCircle className={classes.inActiveIcon} />
          ) : noOfBullet.status === "danger" ? (
            <FaTimesCircle className={classes.danger} />
          ) : noOfBullet.status === "warning" ? (
            <FaExclamationCircle className={classes.warning} />
          ) : (
            <FaCheckCircle className={classes.success} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="2-4 Bullet Points"
          secondary={
            noOfBullet.status === "empty" ? (
              <>Use 2-4 bullet points to describe each job role.</>
            ) : noOfBullet.status === "danger" ? (
              <>
                Use 2-4 bullet points to describe each job role.
                <br />
                <span className={classes[noOfBullet.status]}>
                  Add atleast 2 bullet points for better resume.
                </span>
              </>
            ) : noOfBullet.status === "warning" ? (
              <>
                Use 2-4 bullet points to describe each job role.
                <br />
                <span className={classes[noOfBullet.status]}>
                  More than 4 bullet points is not recommended for better
                  resume.
                </span>
              </>
            ) : (
              <>Use 2-4 bullet points to describe each job role.</>
            )
          }
        />
      </ListItem>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          {idealLength.status === "empty" ? (
            <FaCheckCircle className={classes.inActiveIcon} />
          ) : idealLength.status === "danger" ? (
            <FaTimesCircle className={classes.danger} />
          ) : idealLength.status === "warning" ? (
            <FaExclamationCircle className={classes.warning} />
          ) : (
            <FaCheckCircle className={classes.success} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Ideal Length of a Bullet Point"
          secondary={
            idealLength.status === "empty" ? (
              <>Each bullet point should be of 15-30 words.</>
            ) : idealLength.status === "danger" ? (
              <>
                <>Each bullet point should be of 15-30 words.</>
                <br />
                <span className={classes[idealLength.status]}>
                  Review bullet point: {idealLength.checkLength}
                </span>
              </>
            ) : idealLength.status === "warning" ? (
              <>
                <>Each bullet point should be of 15-30 words.</>
                <br />
                <span className={classes[idealLength.status]}>
                  Review bullet point : {idealLength.checkLength}
                </span>
              </>
            ) : (
              <>
                <>Each bullet point should be of 15-30 words.</>
              </>
            )
          }
        />
      </ListItem>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          {quantifiers.status === "empty" ? (
            <FaCheckCircle className={classes.inActiveIcon} />
          ) : quantifiers.status === "danger" ? (
            <FaTimesCircle className={classes.danger} />
          ) : quantifiers.status === "warning" ? (
            <FaExclamationCircle className={classes.warning} />
          ) : (
            <FaCheckCircle className={classes.success} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <span className={classes.alignCenter}>
              Quantification{" "}
              <QuantificationInfo classes={classes} handleClick={handleClick} />
            </span>
          }
          secondary={
            quantifiers.status === "empty" ? (
              <>Add one quantification in the bullet point wherever possible.</>
            ) : quantifiers.status === "danger" ? (
              <>
                Add one quantification in the bullet point wherever possible.
                <br />
                <span className={classes[quantifiers.status]}>
                  Review bullet point : {quantifiers.checkNumbers}
                </span>
              </>
            ) : quantifiers.status === "warning" ? (
              <>
                Add one quantification in the bullet point wherever possible.
                <br />
                <span className={classes[quantifiers.status]}>
                  {" "}
                  Review bullet point : {quantifiers.checkNumbers}
                </span>
              </>
            ) : (
              <>Add one quantification in the bullet point wherever possible.</>
            )
          }
        />
      </ListItem>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          {activeVoice.status === "empty" ? (
            <FaCheckCircle className={classes.inActiveIcon} />
          ) : activeVoice.status === "danger" ? (
            <FaTimesCircle className={classes.danger} />
          ) : activeVoice.status === "warning" ? (
            <FaExclamationCircle className={classes.warning} />
          ) : (
            <FaCheckCircle className={classes.success} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <span className={classes.alignCenter}>
              Active Voice{" "}
              <ActiveVoiceInfo handleClick={handleClick} classes={classes} />
            </span>
          }
          secondary={
            activeVoice.status === "empty" ? (
              <>Consider writing all the bullet points in active voice.</>
            ) : activeVoice.status === "danger" ? (
              <>
                Consider writing all the bullet points in active voice.
                <br />
                <span className={classes[activeVoice.status]}>
                  Review bullet point : {activeVoice.checkPassiveVoice}
                </span>
              </>
            ) : activeVoice.status === "warning" ? (
              <>
                Consider writing all the bullet points in active voice.
                <br />
                <span className={classes[activeVoice.status]}>
                  Review bullet point : {activeVoice.checkPassiveVoice}
                </span>
              </>
            ) : (
              <>Consider writing all the bullet points in active voice.</>
            )
          }
        />
      </ListItem>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          {personalPronouns.status === "empty" ? (
            <FaCheckCircle className={classes.inActiveIcon} />
          ) : personalPronouns.status === "danger" ? (
            <FaTimesCircle className={classes.danger} />
          ) : personalPronouns.status === "warning" ? (
            <FaExclamationCircle className={classes.warning} />
          ) : (
            <FaCheckCircle className={classes.success} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <span className={classes.alignCenter}>
              Personal Pronouns{" "}
              <PersonalPronounInfo
                classes={classes}
                handleClick={handleClick}
              />
            </span>
          }
          secondary={
            personalPronouns.status === "empty" ? (
              <>Bullet points should not contain any personal pronouns.</>
            ) : personalPronouns.status === "danger" ? (
              <>
                Bullet points should not contain any personal pronouns.
                <br />
                <span className={classes[personalPronouns.status]}>
                  Review bullet point : {personalPronouns.checkPersonalPronoun}
                </span>
              </>
            ) : personalPronouns.status === "warning" ? (
              <>
                Bullet points should not contain any personal pronouns.
                <br />
                <span className={classes[personalPronouns.status]}>
                  Review bullet point : {personalPronouns.checkPersonalPronoun}
                </span>
              </>
            ) : (
              <>Bullet points should not contain any personal pronouns.</>
            )
          }
        />
      </ListItem>
      <ListItem className={classes.listItem} alignItems="flex-start">
        <ListItemIcon>
          {punctuation.status === "empty" ? (
            <FaCheckCircle className={classes.inActiveIcon} />
          ) : punctuation.status === "danger" ? (
            <FaTimesCircle className={classes.danger} />
          ) : punctuation.status === "warning" ? (
            <FaExclamationCircle className={classes.warning} />
          ) : (
            <FaCheckCircle className={classes.success} />
          )}
        </ListItemIcon>
        <ListItemText
          primary="Punctuated Bullet Points"
          secondary={
            punctuation.status === "empty" ? (
              <>
                Capitalize the first letter of the bullet point and end with a
                full stop or a period.
              </>
            ) : punctuation.status === "danger" ? (
              <>
                Capitalize the first letter of the bullet point and end with a
                full stop or a period.
                <br />
                <span className={classes[punctuation.status]}>
                  {" "}
                  Review bullet point : {punctuation.checkPunctuation}
                </span>
              </>
            ) : punctuation.status === "warning" ? (
              <>
                Capitalize the first letter of the bullet point and end with a
                full stop or a period.
                <br />
                <span className={classes[punctuation.status]}>
                  {" "}
                  Review bullet point : {punctuation.checkPunctuation}
                </span>
              </>
            ) : (
              <>
                Capitalize the first letter of the bullet point and end with a
                full stop or a period.
              </>
            )
          }
        />
      </ListItem>

      {/* POPOVER OF EXTRA INFORMATION */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          className: classes.popover,
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className={classes.popOverContainer}
      >
        {content}
      </Popover>
    </div>
  );
}
