import { useTheme } from "@emotion/react";
import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box, keyframes } from "@mui/system";
import axios from "axios";
import classNames from "classnames";
import { State } from "country-state-city/lib/cjs";
import React, { useRef } from "react";
import {
  FaBolt,
  FaCheck,
  FaFan,
  FaLightbulb,
  FaPlusCircle,
  FaRocket,
  FaTimes,
  FaTimesCircle,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Flip from "react-reveal/Flip";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import VibrateBox from "../../../components/animate/vibrate";
import CustomButton from "../../../components/CustomButton/Index";
import CustomDatePicker from "../../../components/CustomDateField/Index";
import CustomIconButton from "../../../components/CustomIcon/Index";
import CustomInputAndSelectField from "../../../components/CustomInputAndSelectField/Index";
import CustomInputField from "../../../components/CustomInputField/Index";
import CustomModal from "../../../components/CustomModal/Index";
import CustomNotification from "../../../components/CustomNotification/Index";
import endPoints from "../../../config/endPoint";
import expCategoryList from "../../../constants/experience.data";
import { updatePlanFeatureUsageThunk } from "../../../redux/counterSlice";
import {
  createExperienceThunk,
  deleteExperienceThunk,
  getActionVerbThunk,
  getExperienceThunk,
  updateExperienceThunk,
} from "../../../redux/Dashboard/experience.slice";
import ExperienceStyles from "../../../styles/pages/Resume/components/experience.styles";
import capitalizeFirstLetter from "../../../utils/capitalize";
import formatDate, { sortArrayWithDate } from "../../../utils/dateFormatter";
import createGPTHandlers from "../commonApi/gpt.handler";
import CardContainerComponent from "./CardContainer/Index";
import ProjectComponent from "./subComponents/experience.subcomponent";
import SuggestionCard from "./subComponents/experience/SuggestionCard";
import _ from "lodash";

const spin = keyframes`
  from{
    transform: rotate(0deg);
  }
   to{
     transform: rotate(360deg);
  }
`;

const RotatedBox = styled("div")({
  display: "flex",
  alignItems: "normal",
  animation: `${spin} 2s infinite linear`,
});

export default function ExperienceComponent({
  onSpecificChange,
  value = {},
  handleChange,
  clearInput,
  bulkUpdate,
  formError,
  setFormError,
  setIsEditing,
  isEditing,
}) {
  const classes = ExperienceStyles();
  const bullet = "\u2022";
  // const bulletWithSpace = `${bullet} `;
  // const enter = 13;
  const theme = useTheme();
  const { resumeId } = useParams();
  const [type, setType] = React.useState("");
  const [isUpdate, setUpdate] = React.useState("");
  const [option, setOption] = React.useState(0);
  const [suggestion, setSuggestion] = React.useState("");
  const [sample, setSample] = React.useState(false);
  const [showSuggestion, setShowSuggestion] = React.useState(false);
  const [data1, setData1] = React.useState("");
  const [data2, setData2] = React.useState("");
  const [data3, setData3] = React.useState("");
  const [delType, setDelType] = React.useState("");
  const token = localStorage.getItem("token");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checkDate, setCheckDate] = React.useState(false);

  const handleClick = (event) => {
    document.getElementsByName("ex_desc")[0].focus();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setSelected({
    //   selection: false,
    //   eve: null,
    //   start: null,
    //   end: null,
    // });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [isClicked, setClicked] = React.useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dispatch = useDispatch();
  const experience = useSelector((state) => state.experience);
  const userPlanLimits = useSelector((state) => state.counter.user_plan_limits);
  const [isWorking, setWorking] = React.useState(false);
  const aiOptionRef = useRef();
  const [selected, setSelected] = React.useState({
    selection: false,
    eve: null,
    start: null,
    end: null,
  });
  let previousLength = 0;

  const aiUsed = userPlanLimits.find(
    (p) => p.feature_name === "ai_credits"
  )?.limit_used;
  const aiLimit = userPlanLimits.find(
    (p) => p.feature_name === "ai_credits"
  )?.usage_limit;

  const handleInputChange = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);
    if (newLength > previousLength) {
      if (event.nativeEvent.inputType !== "deleteContentBackward") {
        if (characterCode === 10) {
          event.target.value = `${event.target.value}${bullet} `;
        } else if (newLength === 1) {
          event.target.value = `${bullet} ${event.target.value}`;
        }
      }
    }
    handleChange(
      {
        name: "ex_desc",
        value: event.target.value,
      },
      true
    );

    previousLength = newLength;
  };

  // const handleInput = (event) => {
  //   const { keyCode, target } = event;
  //   const { selectionStart, value } = target;

  //   if (keyCode === enter) {
  //     target.value = [...value]
  //       .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
  //       .join("");

  //     target.selectionStart = selectionStart + bulletWithSpace.length;
  //     target.selectionEnd = selectionStart + bulletWithSpace.length;
  //   }

  //   if (value[0] !== bullet) {
  //     target.value = `${bulletWithSpace}${value}`;
  //   }
  // };
  React.useEffect(() => {
    if (!_.isEmpty(value)) {
      setIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange]);

  React.useEffect(() => {
    dispatch(getExperienceThunk({ token: token, resume_id: resumeId }));

    if (experience.actionVerbList?.length <= 0) {
      dispatch(getActionVerbThunk());
    }
    // handleChange(
    //   {
    //     name: "ex_start_date",
    //     value: `${month[new Date().getMonth()]} ${new Date().getFullYear()}`,
    //   },
    //   true
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteResume = (token, resume_id) => {
    dispatch(
      deleteExperienceThunk({ token, resume_id, wx_id: type, type: delType })
    );
    setType("");
  };

  const handleUpdate = () => {
    setIsEditing(false);
    dispatch(updateExperienceThunk({ body: value, token }));
    setUpdate("");
    clearInput();
    setWorking(false);
    handleClose();
  };

  const handleClickImproveAI = async () => {
    if (process.env.NODE_ENV !== "development") {
      window.hj("event", "experience_fb");
    }
    setClicked(true);
    let d = await axios.post(`${endPoints.useAISuggestion}`, {
      token: token,
      text: `Improve the below text by starting it with an action verb:\n ${selected.eve?.target.value.substring(
        selected.start,
        selected.end
      )}`,
      data: {
        temperature: 0.7,
        max_tokens: 100,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        best_of: 1,
      },
    });
    createGPTHandlers({
      resumeId,
      callField: "responsibilities",
      prompt: value.ex_desc,
      opt1: d.data.results[0],
      opt2: null,
      opt3: null,
      selection: option,
      token,
    });
    handleChange(
      {
        name: "ex_desc",
        value:
          selected.eve?.target.value.substring(0, selected.start) +
          " " +
          d.data.results[0]?.toString().trim().replaceAll("\n", "") +
          " " +
          selected.eve?.target.value.substring(
            selected.end,
            selected.eve?.target.value.length
          ),
      },
      true
    );
    setSelected({
      selection: false,
      eve: null,
      start: null,
      end: null,
    });
    dispatch(
      updatePlanFeatureUsageThunk({ token: token, featureName: "ai_credits" })
    );
    setShowSuggestion(true);
    setClicked(false);
  };

  const handleClickUseAI = async () => {
    if (process.env.NODE_ENV !== "development") {
      window.hj("event", "experience_fb");
    }
    setClicked(true);
    handleClose();
    if (!value.ex_desc?.toString()?.trim()) {
      let d = await axios.post(`${endPoints.useAISuggestion}`, {
        token: token,
        text: `Generate one long resume bullet point for the experience section in 20 to 30 words for the ${value.ex_role} job role starting with an action verb and a quantifier:`,
        data: {
          temperature: 1,
          max_tokens: 60,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          best_of: 1,
        },
      });
      setData1(d.data.results);
      let d2 = await axios.post(`${endPoints.useAISuggestion}`, {
        token: token,
        text: `Generate one long resume bullet point for the experience section in 20 to 30 words for the ${value.ex_role} job role starting with an action verb and a quantifier:`,
        data: {
          temperature: 1,
          max_tokens: 60,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          best_of: 1,
        },
      });
      setData2(d2.data.results);
      let d3 = await axios.post(`${endPoints.useAISuggestion}`, {
        token: token,
        text: `Generate one long resume bullet point for the experience section in 20 to 30 words for the ${value.ex_role} job role starting with an action verb and a quantifier:`,
        data: {
          temperature: 1,
          max_tokens: 60,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          best_of: 1,
        },
      });
      setData3(d3.data.results);
      aiOptionRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      let d = await axios.post(`${endPoints.useAISuggestion}`, {
        token: token,
        text: `Generate one long bullet points relevant to the below text on the job duties of a ${value.ex_role} : \n
${value.ex_desc}`,
        data: {
          temperature: 1,
          max_tokens: 60,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          best_of: 1,
        },
      });
      setData1(d.data.results);
      let d2 = await axios.post(`${endPoints.useAISuggestion}`, {
        token: token,
        text: `Generate one long bullet points relevant to the below text on the job duties of a ${value.ex_role} : \n
${value.ex_desc}`,
        data: {
          temperature: 1,
          max_tokens: 60,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          best_of: 1,
        },
      });
      setData2(d2.data.results);
      let d3 = await axios.post(`${endPoints.useAISuggestion}`, {
        token: token,
        text: `Generate one long resume bullet point for the experience section in 20 to 30 words for the ${value.ex_role} job role starting with an action verb and a quantifier:`,
        data: {
          temperature: 1,
          max_tokens: 60,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          best_of: 1,
        },
      });
      setData3(d3.data.results);
    }

    // createGPTHandlers({
    //   resumeId,
    //   callField: "responsibilities",
    //   prompt: value.ex_desc,
    //   opt1: d.data.results[0],
    //   opt2: null,
    //   opt3: null,
    //   selection: option,
    //   token,
    // });
    // handleChange(
    //   {
    //     name: "ex_desc",
    //     value: value.ex_desc
    //       ? value.ex_desc +
    //         "\n" +
    //         bullet +
    //         d.data.results[0]?.toString().trim().replaceAll("\n", "")
    //       : bullet + d.data.results[0]?.toString().trim(),
    //   },
    //   true
    // );
    dispatch(
      updatePlanFeatureUsageThunk({ token: token, featureName: "ai_credits" })
    );
    setShowSuggestion(true);

    aiOptionRef.current?.scrollIntoView({
      behavior: "smooth",
    });
    setClicked(false);
  };

  const handleSave = (e) => {
    if (Object.keys(value).length !== 0) {
      if (!!value.ex_start_date) {
        handleChange(
          {
            name: "ex_start_date",
            value: `${
              month[new Date().getMonth()]
            } ${new Date().getFullYear()}`,
          },
          true
        );
      }
      if (!!value.ex_end_date) {
        handleChange(
          {
            name: "ex_end_date",
            value: `${
              month[new Date().getMonth()]
            } ${new Date().getFullYear()}`,
          },
          true
        );
      }
      dispatch(
        createExperienceThunk({
          token: token,
          resume_id: resumeId,
          ...value,
        })
      );
      clearInput();

      setWorking(false);
      handleClose();
    } else {
      alert("Please add some Data");
    }
  };
  if (experience.loading)
    return (
      <div className={classes.loader}>
        <CircularProgress disableShrink size={30} thickness={6} />
      </div>
    );
  return (
    <>
      {/* <CustomNotification
        severity={"error"}
        variant="filled"
        alertMessage={"Fill all mandatory fields"}
        open={Object.keys(formError).length ? true : false}
        onClose={setFormError}
      /> */}
      <CustomNotification
        severity={"warning"}
        variant="filled"
        alertMessage={"Select start date first"}
        open={checkDate}
        onClose={setCheckDate}
      />
      {/* <CustomModal
        isOpen={true}
        dialogContent={
          <SuggestionCard text={value.ex_desc} typeOfWork={value.ex_type} />
        }
      /> */}

      <CustomModal
        isOpen={!!type}
        setOpen={setType}
        confirmBtnColor={"error"}
        confirmBtnText={"Delete"}
        cancelBtnColor={"primary"}
        cancelBtnText={"Cancel"}
        dialogTitle={"Deleting Experience"}
        mbContent={2}
        actionCallback={(e) => handleDeleteResume(token, resumeId, delType)}
        dialogContentText={
          "Please note the the action will delete the resume permanently."
        }
        dialogContent={"Are you sure you want to delete?"}
      />
      <Grid className={classes.container} container spacing={3} rowGap={4}>
        <Grid item sm={6} className={classes.contentLeft} xs={12}>
          <Box id="cardContainer" className={"exp_card_wrapper"}>
            <Typography className={classes.helperTypo} variant="h5">
              ALL EXPERIENCES
              <Typography className={classes.helperTypo} variant="body2">
                Add your experience details - one at a time.
              </Typography>
            </Typography>
            {/* <Paper
              style={{
                background: "#eee",
                marginBottom: "20px",
              }}
            >
              <iframe
                style={{
                  width: "100%",
                }}
                height="315"
                src="https://www.youtube.com/embed/eIOupQXYHC8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>{" "}
            </Paper> */}

            {!experience.experiences.length
              ? ""
              : // <Typography className={classes.typography} variant="h5">
                //   Start adding your Work Experience
                // </Typography>
                sortArrayWithDate(
                  experience.experiences.filter((exp) => {
                    return parseInt(exp.ex_id) !== parseInt(isUpdate);
                  }),
                  "ex_end_date"
                ).map((a, idx) => (
                  <Flip bottom key={idx}>
                    <CardContainerComponent
                      cardClass={classNames(
                        `exp_card_item_${idx}`,
                        "exp_card_item"
                      )}
                      uapDeleteClassName="exp_card_delete_btn"
                      uapEditClassName={"exp_card_edit_btn"}
                      headTitle={a.ex_org}
                      title={a.ex_role ? a.ex_role : a.ex_title}
                      editCallback={(e) => {
                        setUpdate(a.ex_id);
                        bulkUpdate(a);
                        if (!a.ex_end_date) {
                          setWorking(true);
                        } else {
                          setWorking(false);
                        }
                      }}
                      deleteCallback={(e) => {
                        setDelType(a.ex_type);
                        setType(a.ex_id);
                      }}
                      subtitle={`${formatDate(new Date(a.ex_start_date))} - ${
                        a.ex_end_date
                          ? formatDate(new Date(a.ex_end_date))
                          : "Present"
                      }`}
                      title2={a.ex_type}
                      subtitle2={a.ex_city}
                    />
                  </Flip>
                ))}
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          container
          className={classes.contentRight}
          spacing={3}
          rowGap={5}
        >
          <Grid sm={12} item xs={12}>
            <CustomInputAndSelectField
              label={"SELECT TYPE OF WORK"}
              changeCallBack={(e, v) => onSpecificChange("ex_type", v)}
              name={"ex_type"}
              value={value.ex_type}
              required={true}
              fullWidth
              options={expCategoryList.map((exp) => exp).sort()}
              freeSolo={false}
            />
          </Grid>
          {value.ex_type !== "Project" ? (
            <>
              <Grid sm={12} item xs={12}>
                <CustomInputField
                  label={"Role"}
                  required={true}
                  name="ex_role"
                  value={value.ex_role}
                  onChange={(e) => {
                    if (e.target.value.match(/^[a-zA-Z\s]*$/)) {
                      handleChange(
                        {
                          name: e.target.name,
                          value: capitalizeFirstLetter(e.target.value),
                        },
                        true
                      );
                    }
                  }}
                  fullWidth
                  freeSolo={false}
                />
              </Grid>
              <Grid sm={12} item xs={12}>
                <CustomInputField
                  label={"Organisation"}
                  name="ex_org"
                  value={value.ex_org}
                  onChange={(e) => {
                    if (e.target.value.match(/^[0-9a-zA-Z\s]*$/)) {
                      handleChange(
                        {
                          name: e.target.name,
                          value: capitalizeFirstLetter(e.target.value),
                        },
                        true
                      );
                    }
                  }}
                  required={true}
                  fullWidth
                  freeSolo={false}
                />
              </Grid>

              <Grid sm={6} item xs={12}>
                <CustomInputAndSelectField
                  label={"State"}
                  name={"ex_state"}
                  value={value.ex_state}
                  changeCallBack={(e, v) =>
                    handleChange(
                      {
                        name: "ex_state",
                        value: v,
                      },
                      true
                    )
                  }
                  required={true}
                  fullWidth
                  options={[
                    ...State.getStatesOfCountry("IN")
                      .map((state) => state.name)
                      .sort(),
                    ...State.getStatesOfCountry("US")
                      .map((state) => state.name)
                      .sort(),
                  ].sort()}
                  freeSolo={false}
                />
              </Grid>
              <Grid sm={6} item xs={12}>
                <CustomInputField
                  name="ex_city"
                  label={"City"}
                  value={value.ex_city}
                  onChange={(e) => {
                    if (e.target.value.match(/^[a-zA-Z\s]*$/)) {
                      handleChange(
                        {
                          name: e.target.name,
                          value: capitalizeFirstLetter(e.target.value),
                        },
                        true
                      );
                    }
                  }}
                  required={true}
                  fullWidth
                  freeSolo={false}
                />
              </Grid>
            </>
          ) : (
            <ProjectComponent value={value} handleChange={handleChange} />
          )}
          <Grid sm={6} item xs={12}>
            {/* <MaterialUIPickers /> */}
            <CustomDatePicker
              label={"Start Date"}
              maxDate={new Date()}
              minDate={"1 Jan 1970"}
              name={"ex_start_date"}
              value={!!value.ex_start_date ? value.ex_start_date : null}
              changeCallBack={(e, v) => {
                handleChange(
                  {
                    name: "ex_start_date",
                    value: v,
                  },
                  true
                );
              }}
              required={true}
              fullWidth
            />
          </Grid>
          <Grid sm={6} item xs={12}>
            <CustomDatePicker
              label={"End Date"}
              required={true}
              minDate={new Date(`${value.ex_start_date}`)}
              maxDate={new Date("1 Dec 2050")}
              disabled={value.ex_end_date ? !value.ex_end_date : isWorking}
              name={"ex_end_date"}
              value={!!value.ex_end_date ? value.ex_end_date : null}
              onClick={(e) => {
                if (!value.ex_start_date) {
                  setCheckDate(true);
                }
              }}
              changeCallBack={(e, v) => {
                onSpecificChange("ex_end_date", v);
              }}
              fullWidth
            />
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={isWorking}
                  onChange={(e) => {
                    setWorking(e.target.checked);
                    handleChange(
                      {
                        name: "ex_end_date",
                        value: null,
                      },
                      true
                    );
                  }}
                />
              }
              label="Working Here"
            />
          </Grid>
          <Grid sm={12} item xs={12} className={classes.aiBox}>
            <CustomModal
              cancelBtnText={"Close"}
              isOpen={sample}
              dialogTitle="Bullet Points Sample"
              setOpen={setSample}
              dialogContentText={
                <div>
                  <Typography variant="h5">
                    Good Examples for Bullet points{" "}
                  </Typography>
                </div>
              }
              dialogContent={
                <div className={classes.dialogDiv}>
                  <div>
                    Experienced - HR Generalist Resume Bullet Points Example
                    <ul>
                      <li>
                        Collaborated with 9 hiring managers across 4 different
                        teams to aid in hiring and recruiting efforts including
                        writing and managing online job postings, Screening
                        candidate resumes, and conducting first-round
                        interviews.
                      </li>
                    </ul>
                  </div>
                  <div>
                    Experienced - Senior FullStack developer resume bullet
                    points example.
                    <ul>
                      <li>Mentor and Scrum master of a 20-person team</li>
                      <li>
                        Developed a PHP script to restrict hosts from uploading
                        low-quality files or unsupported formats,saving over 20
                        TB of storage and 400h of labor/yr.
                      </li>
                    </ul>
                  </div>
                  <div>
                    Experienced - HR Generalist Resume Bullet Points Example
                    <ul>
                      <li>
                        Collaborated with 9 hiring managers across 4 different
                        teams to aid in hiring and recruiting efforts including
                        writing and managing online job postings, Screening
                        candidate resumes, and conducting first-round
                        interviews.
                      </li>
                    </ul>
                  </div>
                  <div>
                    IT - Android Developer Resume Bullet Points Example
                    <ul>
                      <li>
                        Collaborated with quality engineers, user research,
                        product management, design, and support teams to ensure
                        quality in all phases of app development.
                      </li>
                    </ul>
                  </div>
                  <div>
                    Sales - Sales Manager Resume Bullet Points Example
                    <ul>
                      <li>
                        Sales manager for 5 marketing campaigns worth $1M to
                        $3M; implementing strategic marketing and sales goals
                        and leading a team of 20 creative and technical sales
                        staff
                      </li>
                    </ul>
                  </div>
                  <div>
                    Undergraduate Student Resume with internship experience
                    Bullet Points Example
                    <ul>
                      <li>
                        Assisted in building a beautiful user interface for
                        clients without compromising functionality for
                        aesthetics.
                      </li>
                      <li>
                        Wrote unit tests in jest to ensure code was tested and
                        100% bug free.
                      </li>
                    </ul>
                  </div>
                </div>
              }
              mbContent={1}
            />
            <CustomInputField
              label={"RESPONSIBILITIES/ACHIEVEMENTS"}
              multiline
              rows={7}
              name="ex_desc"
              onMouseUp={(e) => {
                var indexStart = e.target?.selectionStart;
                var indexEnd = e.target?.selectionEnd;
                setSelected({
                  selection: !!e.target.value?.substring(indexStart, indexEnd),
                  eve: e,
                  start: indexStart,
                  end: indexEnd,
                });
              }}
              onFocus={handleClick}
              value={value.ex_desc}
              onPaste={(e) => {
                if (value.ex_desc) {
                  handleChange(
                    {
                      name: "ex_desc",
                      value: e.target.value,
                    },
                    true
                  );
                } else {
                  handleChange(
                    {
                      name: "ex_desc",
                      value: bullet + " " + e.target.value,
                    },
                    true
                  );
                }
              }}
              onChange={handleInputChange}
              type="textarea"
              required={true}
              fullWidth
              freeSolo={false}
              helperText={
                <p
                  style={{
                    paddingTop: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Write one bullet-point for a better AI output.{" "}
                  <CustomButton
                    size="small"
                    onClick={(e) => setSample(true)}
                    text={"Bullet Point Sample"}
                    variant="outlined"
                  />
                </p>
              }
            />
            <Tooltip
              title={`In case you need more AI Credit please upgrade your plan or reach out to our support team.(support@vipapplicant.com)`}
              placement="left"
            >
              <div className={classes.aiUsage}>
                <p
                  style={{
                    margin: "auto 5px",
                  }}
                >
                  {" "}
                  AI Usage: {aiUsed} / {aiLimit}
                </p>{" "}
                {aiUsed === aiLimit ? (
                  <CustomButton
                    icon={<FaBolt />}
                    variant="contained"
                    style={{
                      margin: "auto 5px",
                      minWidth: "20px",
                      borderRadius: "rounded",
                    }}
                    size="small"
                    component={Link}
                    to={"/plans"}
                  />
                ) : (
                  <InfoIcon
                    style={{
                      margin: "auto 5px",
                    }}
                  />
                )}
              </div>
            </Tooltip>

            {
              /*selected.selection*/ false ? (
                <CustomButton
                  onClick={!isClicked ? handleClickImproveAI : () => {}}
                  disabled={!value.ex_role || (aiUsed < aiLimit ? false : true)}
                  text={
                    isClicked ? (
                      <CircularProgress disableShrink size={15} thickness={6} />
                    ) : (
                      <span>
                        <FaRocket />
                        Improve with AI
                      </span>
                    )
                  }
                  variant="contained"
                  className={classNames(
                    classes.btnStyle,
                    "RESPO_useAI_improve_btn"
                  )}
                  style={{
                    background: theme.palette.success.dark,
                    color: theme.palette.grey[200],
                  }}
                />
              ) : (
                <CustomButton
                  onClick={!isClicked ? handleClickUseAI : () => {}}
                  disabled={!value.ex_role || (aiUsed < aiLimit ? false : true)}
                  text={
                    isClicked ? (
                      <CircularProgress disableShrink size={15} thickness={6} />
                    ) : (
                      <span>
                        {!!value.ex_role &&
                        (aiUsed < aiLimit ? true : false) ? (
                          <RotatedBox>
                            <FaFan />
                          </RotatedBox>
                        ) : (
                          <FaFan />
                        )}
                        Generate with AI
                      </span>
                    )
                  }
                  variant="contained"
                  className={classNames(classes.btnStyle, "RESPO_useAI_btn")}
                />
              )
            }
            {anchorEl && (
              <SuggestionCard
                text={value.ex_desc}
                typeOfWork={value.ex_type}
                outSideClick={handleClose}
              />
            )}
            {anchorEl ? (
              <IconButton
                className={classes.popover}
                aria-describedby={id}
                variant="contained"
                onClick={handleClose}
                color="success"
                size="small"
              >
                <FaTimes />
              </IconButton>
            ) : (
              <IconButton
                className={classes.popover}
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                color="success"
                size="small"
              >
                <VibrateBox>
                  <FaLightbulb />
                </VibrateBox>
              </IconButton>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              position: "relative",
            }}
          >
            {showSuggestion ? (
              <FormControl
                component="fieldset"
                id="ai-option"
                ref={aiOptionRef}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography variant="h1" color={"success"}>
                    <CustomIconButton
                      className="useAI_option_select"
                      icon={
                        <FaCheck
                          style={{
                            color: theme.palette.success.dark,
                          }}
                        />
                      }
                      onClick={(e) => {
                        createGPTHandlers({
                          resumeId,
                          callField: "responsibilities",
                          prompt: value.ex_desc,
                          opt1: data1[0],
                          opt2: data2[0],
                          opt3: data3[0],
                          selection: option,
                          token,
                        });
                        handleChange(
                          {
                            name: "ex_desc",
                            value: value.ex_desc
                              ? value.ex_desc +
                                "\n" +
                                (suggestion?.toString().trim().includes(bullet)
                                  ? ""
                                  : bullet) +
                                suggestion
                                  ?.toString()
                                  .trim()
                                  .replaceAll("\n", "")
                                  .replaceAll(bullet, `\n${bullet}`)
                              : (suggestion?.toString().trim().includes(bullet)
                                  ? ""
                                  : bullet) +
                                suggestion
                                  ?.toString()
                                  .trim()
                                  .replaceAll(bullet, `\n${bullet}`),
                          },
                          true
                        );
                        setShowSuggestion(false);
                      }}
                    />
                  </Typography>
                  <Typography variant="h1" color={"error"}>
                    <CustomIconButton
                      className="useAI_option_cancel"
                      icon={
                        <FaTimesCircle
                          style={{
                            color: theme.palette.error.dark,
                          }}
                        />
                      }
                      onClick={(e) => {
                        createGPTHandlers({
                          resumeId,
                          callField: "responsibilities",
                          prompt: value.ex_desc,
                          opt1: data1,
                          opt2: data2,
                          opt3: data3,
                          selection: 0,
                          token,
                        });
                        setShowSuggestion(false);
                      }}
                    />
                  </Typography>
                </div>
                <FormLabel component="legend">
                  SUGGESTIONS{" "}
                  <Typography
                    variant="body2"
                    color={"#c17d00"}
                    style={{
                      display: "inline",
                    }}
                  >
                    ( Select one option and tick the green button to add to your
                    resume. )
                  </Typography>
                </FormLabel>
                <RadioGroup
                  className="useAI_option_wrapper"
                  style={{
                    display: "flex",
                    gap: 10,
                    flexDirection: "column",
                  }}
                  aria-label="gender"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={suggestion}
                  onChange={(e) => setSuggestion(e.target.value)}
                >
                  {data1[0] && (
                    <FormControlLabel
                      className="useAI_option_1"
                      value={data1[0]}
                      onClick={(e) => setOption(1)}
                      control={<Radio />}
                      label={data1}
                    />
                  )}

                  {data2[0] && (
                    <FormControlLabel
                      className="useAI_option_2"
                      onClick={(e) => setOption(2)}
                      value={data2[0]}
                      control={<Radio />}
                      label={data2}
                    />
                  )}
                  {data3[0] && (
                    <FormControlLabel
                      className="useAI_option_3"
                      onClick={(e) => setOption(3)}
                      value={data3[0]}
                      control={<Radio />}
                      label={data3}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            ) : (
              ""
            )}

            {/* <SuggestionCard text={value.ex_desc} typeOfWork={value.ex_type} /> */}
          </Grid>
          <CustomButton
            disabled={
              value.ex_type === "Project"
                ? !(
                    value.ex_title &&
                    value.ex_org &&
                    value.ex_url &&
                    value.ex_start_date &&
                    value.ex_desc
                  )
                : !(
                    value.ex_type &&
                    value.ex_org &&
                    value.ex_role &&
                    value.ex_start_date &&
                    value.ex_desc &&
                    value.ex_city &&
                    value.ex_state
                  )
            }
            text={isUpdate ? "Update" : "Save"}
            onClick={isUpdate ? handleUpdate : handleSave}
            className={
              isUpdate
                ? classNames(classes.actionBtn, "uap_exp_update_btn")
                : classNames(classes.actionBtn, "uap_exp_save_btn")
            }
            variant={isUpdate ? "outlined" : "contained"}
            color={isUpdate ? "warning" : "primary"}
            icon={<FaPlusCircle />}
          />
          <div variant="body2" className={classes.helperDiv}>
            {isUpdate ? "Update" : "Save"} your details before moving on
          </div>
        </Grid>
      </Grid>
    </>
  );
}
