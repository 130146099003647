import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  utm_source: "",
  utm_medium: "",
  utm_campaign: "",
  utm_id: "",
  utm_term: "",
  utm_content: "",
};

export const utmSlice = createSlice({
  name: "UTM",
  initialState,
  reducers: {
    setUTMValue: (state, action) => {
      state.utm_source = action.payload.utm_source;
      state.utm_medium = action.payload.utm_medium;
      state.utm_campaign = action.payload.utm_campaign;
      state.utm_id = action.payload.utm_id;
      state.utm_term = action.payload.utm_term;
      state.utm_content = action.payload.utm_content;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUTMValue } = utmSlice.actions;

export default utmSlice.reducer;
