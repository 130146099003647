import axios from "axios";
import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router";
import endPoints from "../../config/endPoint";
import initialState from "../../redux/Dashboard/initialState";
import TemplatePreviewStyles from "../../styles/pages/TemplatePreview/index.style";
import InternalResumePreview from "./components/templates/internalPreview";

export default function InternalResumePreviewMain() {
  const classes = TemplatePreviewStyles();
  const [inputData, setInputData] = React.useState(initialState);
  const { resumeId, userId } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [templateData, setTemplateData] = React.useState("");
  const TempRef = useRef();
  const [isLogo, setIsLogo] = React.useState(true);
  const [show, setShow] = React.useState(false);
  const [url, setUrl] = React.useState("");
  React.useEffect(() => {
    if (
      !(
        userId === "102741274355" ||
        userId === "3211436304" ||
        userId === "70542211262" ||
        userId === "76102275076"
      )
    ) {
      navigate("/");
    } else {
      axios
        .post(`${endPoints.getResumePreviewerInternal}/${resumeId}`, {
          token,
        })
        .then((r) => {
          if (r.data.personal.firstname) setInputData(r.data);
          else alert("At least fill your personal details.");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      {/* <DashboardSidebar /> */}
      {/* <EmailModal
        content={TempRef.current}
        userId={inputData.userId}
        htmlData={templateData}
        inputData={inputData}
        isLogo={isLogo}
        setIsLogo={setIsLogo}
        setShow={setShow}
        isBorder={template === 7 ? true : false}
      /> */}
      <div className={classes.contentDiv}>
        <InternalResumePreview
          inputData={inputData}
          TempRef={TempRef}
          isLogo={isLogo}
          setIsLogo={setIsLogo}
          setShow={setShow}
          show={show}
          setTemplateData={setTemplateData}
          url={url}
          setUrl={setUrl}
          templateData={templateData}
        />
      </div>
    </div>
  );
}
