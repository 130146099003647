const checkError = async (state, name, errObj, errorCb, nexCb) => {
    if(name === "personal"){
        if(!state.pd_first_name)  errorCb("pd_first_name", "Please enter your firstname")
        if(!state.pd_email_id || !state.pd_email_id.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/))  errorCb("pd_email_id", "Please enter your valid email")
        if(!state.pd_contact_number)  errorCb("pd_contact_number", "Please enter your 10 digit contact number")

        if((!state.pd_first_name) || 
            (!state.pd_email_id || !state.pd_email_id.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/)) ||
            (!state.pd_contact_number)){
            return
        }
        else {
            nexCb()
        }
    }
    if(name === "education"){
        if(!state.length) {
            
            if(!state.ed_degree_qual) errorCb("ed_degree_qual", "Fill out this Field")
            if(state.ed_degree_qual === "Class 10" || state.ed_degree_qual === "Class 12") {
                if(!state.ed_school_name) errorCb("ed_school_name", "Fill out this Field")
                if(!state.subject) errorCb("subject", "Fill out this Field")
            } else  {
                if(!state.ed_college_name) errorCb("ed_college_name", "Fill out this Field")
                if(!state.ed_major_special) errorCb("ed_major_special", "Fill out this Field")
            }
            if(!state.ed_type) errorCb("ed_type", "Fill out this Field")
            if(!state.ed_graduate_year) errorCb("ed_graduate_year", "Fill out this Field")
            if(!state.ed_grade_marks) errorCb("ed_grade_marks", "Fill out this Field")
            if(!state.ed_grading_sys) errorCb("ed_grading_sys", "Fill out this Field")
        }
        else {
            nexCb()
        }
        
    }
    if(name ==="experience"){
        if(!state.length) {
             errorCb("errorExperience", "Please add atleast one experience.")
        }else {
            nexCb()
        }
    }
    if(name === "skills"){
        if(!state.pd_first_name) errorCb("pd_first_name", "Fill out this Field")
        if(!state.pd_email_id) errorCb("pd_email_id", "Fill out this Field")
        if(!state.pd_contact_number) errorCb("pd_contact_number", "Fill out this Field")
    }
    if(name === "achievement"){
        if(!state.pd_first_name) errorCb("pd_first_name", "Fill out this Field")
        if(!state.pd_email_id) errorCb("pd_email_id", "Fill out this Field")
        if(!state.pd_contact_number) errorCb("pd_contact_number", "Fill out this Field")
    }
    if(name === "extracurricular"){
        if(!state.pd_first_name) errorCb("pd_first_name", "Fill out this Field")
        if(!state.pd_email_id) errorCb("pd_email_id", "Fill out this Field")
        if(!state.pd_contact_number) errorCb("pd_contact_number", "Fill out this Field")
    }
}

export default checkError