import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import * as React from "react";
import { useNavigate } from "react-router";
import customBottomNavigationStyles from "../../styles/components/CustomBottomNavigationStyle/index.styles";

export default function CustomBottomNavigation({
  navigationList,
  value,
  ...props
}) {
  const classes = customBottomNavigationStyles();
  const navigate = useNavigate();

  return (
    <Box className={classes.container}>
      <BottomNavigation
        className={classes.navigation}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          if (navigationList[newValue].name !== "home") {
            navigate(navigationList[newValue].path);
          }
        }}
      >
        {navigationList.map((nav) =>
          nav.name === "home" ? (
            <BottomNavigationAction
              href="https://vipapplicant.com/"
              className={classes.navigationButton}
              label={nav.title}
              icon={nav.icon}
            />
          ) : (
            <BottomNavigationAction
              className={classes.navigationButton}
              label={nav.title}
              icon={nav.icon}
            />
          )
        )}
      </BottomNavigation>
    </Box>
  );
}
