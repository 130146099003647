import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import * as React from "react";
import customModalStyles from "../../styles/components/CustomModal/index.style";
import CustomButton from "../CustomButton/Index";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomModal({
  fullWidth,
  dialogTitle,
  dialogContent,
  cancelCallBack,
  actionCallback,
  dialogContentText,
  cancelBtnText,
  confirmBtnText,
  cancelBtnColor,
  confirmBtnColor,
  btnDisable,
  mbContent,
  isOpen,
  setOpen,
  confirmBtnClass,
  cancelBtnClass,
  closeCallback,
  minContentWidth,
  modalWidth = "lg",
}) {
  const classes = customModalStyles();

  const handleClose = () => {
    setOpen(false);
    if (closeCallback) closeCallback();
    // cancelCallBack()
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={fullWidth}
        maxWidth={modalWidth}
        onClose={handleClose}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography variant="h4">{dialogTitle}</Typography>
        </DialogTitle>
        <DialogContent
          className={classes.dialogContent}
          style={{
            minWidth: minContentWidth,
          }}
        >
          <DialogContentText mb={mbContent ? mbContent : 8}>
            {dialogContentText}
          </DialogContentText>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          {cancelBtnText && (
            <CustomButton
              className={cancelBtnClass}
              text={cancelBtnText}
              variant="outlined"
              color={cancelBtnColor}
              onClick={handleClose}
            />
          )}
          {confirmBtnText && (
            <CustomButton
              className={confirmBtnClass}
              disabled={btnDisable}
              text={confirmBtnText}
              variant="contained"
              color={confirmBtnColor}
              onClick={actionCallback}
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
