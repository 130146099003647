import { makeStyles } from "@mui/styles";

const customBottomNavigationStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    position: "fixed",
    zIndex: 99,
    bottom: 0,
  },
  navigation: {
    display: "none",
    background: theme.palette.primary.main,
    color: theme.palette.secondary.lighter,
    "& .Mui-selected": {
      color: theme.palette.warning.light,
      borderTop: `3px solid ${theme.palette.warning.main}`,
      "& .alert-helper": {
        display: "none",
      },
      "& span": {
        border: "none",
      },
      "& .alert-helper-ylw": {
        display: "none",
      },
    },
    [theme.breakpoints.down("lg")]: {
      display: "flex",
    },
  },
  navigationButton: {
    color: theme.palette.secondary.lighter,
    "& .sidebar-icon": {
      fontSize: 20,
    },
  },
}));

export default customBottomNavigationStyles;
