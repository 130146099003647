import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endPoints from "../../config/endPoint";
import sortArray from "../../utils/sort";

const initialState = {
  loading: false,
  resumes: [],
};

// First, create the thunk
export const fetchResumeByUserID = createAsyncThunk(
  "resumes/fetchByIdStatus",
  async (token, thunkAPI) => {
    const { data } = await axios.post(endPoints.fetchResumes, {
      token,
      rs_type: "UP",
    });
    return sortArray(data.results, "resume_created_at");
  }
);

export const createResumeThunk = createAsyncThunk(
  "dashboard/createResume",
  async (body, thunkAPI) => {
    const { data } = await axios.post(`${endPoints.addResume}`, body);
    return data.results[0];
  }
);

export const deleteResumeThunk = createAsyncThunk(
  "dashboard/deleteResume",
  async ({ token, resume_id, resumeList }, thunkAPI) => {
    await axios.delete(`${endPoints.deleteResume}`, {
      data: { token, resume_id },
    });
    return resumeList.filter((resume) => resume.resume_id !== resume_id);
  }
);

export const updateResumeThunk = createAsyncThunk(
  "dashboard/updateResume",
  async (
    { token, resume_id, resume_title, resume_updated_at, resumeList },
    thunkAPI
  ) => {
    const { data } = await axios.patch(`${endPoints.updateResume}`, {
      token,
      resume_id,
      resume_title,
      resume_updated_at,
    });
    return sortArray(
      resumeList.map((resume) => {
        if (resume.resume_id === resume_id) {
          return data.results[0];
        } else {
          return resume;
        }
      }),
      "resume_created_at"
    );
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateResumes: (state, { payload }) => {
      state.resumes = payload;
    },
  },
  extraReducers: {
    [fetchResumeByUserID.pending]: (state) => {
      state.loading = true;
    },
    [fetchResumeByUserID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.resumes = payload;
    },
    [fetchResumeByUserID.rejected]: (state) => {
      state.loading = false;
    },

    [deleteResumeThunk.pending]: (state) => {
      state.loading = true;
    },
    [deleteResumeThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.resumes = payload;
    },
    [deleteResumeThunk.rejected]: (state) => {
      state.loading = false;
    },

    [updateResumeThunk.pending]: (state) => {
      state.loading = true;
    },
    [updateResumeThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.resumes = payload;
    },
    [updateResumeThunk.rejected]: (state) => {
      state.loading = false;
    },

    [createResumeThunk.pending]: (state) => {
      state.loading = true;
    },
    [createResumeThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.resumes = [...state.resumes, payload];
    },
    [createResumeThunk.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateResumes } = dashboardSlice.actions;

export default dashboardSlice.reducer;
