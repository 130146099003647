import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FaBackward, FaDownload } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/CustomButton/Index";
import CustomModal from "../../../components/CustomModal/Index";
import guideBookStyles from "../../../styles/pages/Resources/component/guidebook.styles";
import Resume from "../data/guide.pdf";
import guideBookImg from "../data/guideBook.png";

export default function GuideBook() {
  const classes = guideBookStyles();
  const dashboard = useSelector((state) => state.dashboard);
  const userPlan = useSelector((state) => state.counter);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <CustomModal
        isOpen={open}
        cancelBtnText="Close"
        dialogTitle={"Ready to download"}
        minContentWidth={50}
        mbContent={4}
        dialogContentText={
          userPlan.current_plan === "VA Executive"
            ? "Please Upgrade your plan to access this guidebook."
            : "Please create your resume and fill up your personal details."
        }
        dialogContent={
          <CustomButton
            component={Link}
            variant="contained"
            className={classes.goBack}
            to={
              userPlan.current_plan === "VA Executive" ? "/plans" : "/dashboard"
            }
            text={`Go to ${
              userPlan.current_plan === "VA Executive" ? "Plans" : "Dashboard"
            }`}
            actionCallBack={(e) => setOpen(false)}
            icon={
              <FaBackward
                style={{
                  marginRight: 10,
                }}
              />
            }
          />
        }
        setOpen={setOpen}
      />
      {/* <CustomNotification
        alertMessage={
          "Please create your resume and fill up your personal details."
        }
        severity="warning"
        open={open}
        variant="filled"
        onClose={setOpen}
      /> */}
      <Card
        sx={{
          display: "flex",
          background: (theme) => theme.palette.warning.lighter,
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: 120, padding: 2 }}
          image={guideBookImg}
          alt="Not found"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              component="div"
              variant="h5"
              className={classes.headContent}
            >
              Resume Guidebook
            </Typography>
            <Typography
              variant="body2"
              component="div"
              className={classes.bodyContent}
            >
              Optimize your resume using our guidebook.
            </Typography>
          </CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 3,
              pb: 2,
              pr: 3,
            }}
          >
            {dashboard.resumes.find((dash) => dash.completion_pd === 1) &&
            dashboard.resumes.length !== 0 &&
            userPlan.current_plan !== "VA Executive" ? (
              <CustomButton
                className={classes.downloadBtn}
                href={Resume}
                download="Resume_guidebook_VIPApplicant.pdf"
                component="a"
                text={"Download"}
                icon={<FaDownload />}
                variant="contained"
              />
            ) : (
              <CustomButton
                className={classes.downloadBtn}
                text={"Download"}
                onClick={(e) => setOpen(true)}
                icon={<FaDownload />}
                variant="contained"
              />
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
}
