import { Paper } from "@mui/material";
import * as React from "react";
import checkOutStyles from "../../styles/components/checkout/checkout.style";
import Billing from "./section/Billing";

export default function CheckoutComponent() {
  const classes = checkOutStyles();
  return (
    <Paper className={classes.paper}>
      <Billing />
    </Paper>
  );
}
