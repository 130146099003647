import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

let ProfileStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      padding: "0px 20px",
    },
  },
  expSection: {
    width: "100%",
    display: "flex",
    gap: 10,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  headSec: {
    fontSize: 14,
    display: "flex",
    gap: 2,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  contentDiv: {
    padding: "40px 10px",
    textAlign: "left",
    maxWidth: "80%",
    [theme.breakpoints.down("lg")]: {
      paddingTop: 90,
      maxWidth: "100%",
      marginBottom: 70,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 15,
      paddingTop: 90,
    },
  },
  body: {
    margin: "30px 0px",
    border: `1px solid ${theme.palette.divider}`,
    padding: 20,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  inputLabelNoShrink: {
    transform: "translate(2px, -30px) scale(1)",
    color: grey[800],
  },
  root: {
    "& .MuiInputBase-root": {
      color: grey[800],
      height: 50,
    },
    "& .MuiFormLabel-root": {
      color: grey[800],
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
    borderColor: "white",
  },
  profileContainer: {
    flexGrow: 1,
    px: 2,
    display: "flex",
    gap: "2px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "auto",
    },
  },
}));

export default ProfileStyles;
