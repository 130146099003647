import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";

function Progress({ text, ...props }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          position: "absolute",
          color: grey[300],
          left: 0,
        }}
        size={60}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        size={60}
        color={"warning"}
        {...props}
      />

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            style={{
              textAlign: "center",
              fontSize: 15,
            }}
          >
            {text === "Score"
              ? `${Math.round(props.value)}`
              : `${Math.round(props.value)}%`}
          </Typography>
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{ fontSize: 9, textAlign: "center" }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

Progress.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CustomProgress({ completion, text, ...props }) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (completion) setProgress(completion);
  }, [completion]);

  return <Progress value={progress} text={text} {...props} />;
}
