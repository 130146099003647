import { Grid } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import CustomButton from "../../../../../components/CustomButton/Index";
import CustomDatePicker from "../../../../../components/CustomDateField/Index";
import CustomInputField from "../../../../../components/CustomInputField/Index";
import {
  createCertificationThunk,
  updateCertificationThunk,
} from "../../../../../redux/Dashboard/achievement.slice";

export default function Certification({
  onSpecificChange,
  classes,
  actionBtn,
  updateValue,
  value,
  clearInput,
  handleChange,
  setType,
  setCategory,
  setIsEditing,
}) {
  const dispatch = useDispatch();
  const { resumeId } = useParams();
  const token = localStorage.getItem("token");
  const handleSave = () => {
    dispatch(
      createCertificationThunk({
        token: token,
        resume_id: resumeId,
        ...value,
      })
    );
    clearInput();
    setType("");
    setCategory("");
  };

  const handleUpdate = () => {
    setIsEditing(false);
    dispatch(
      updateCertificationThunk({
        body: {
          ...value,
        },
        token,
      })
    );
    clearInput();
    setType("");
    setCategory("");
  };
  return (
    <>
      <Grid item sm={12} xs={12} className={classes.gridItem}>
        <CustomInputField
          value={value.cf_name}
          onChange={(e) => {
            if (e.target.value.length <= 100) {
              handleChange(e);
            }
          }}
          label={"Certification Name"}
          name={"cf_name"}
          required={true}
          fullWidth
        />
      </Grid>
      <Grid item sm={12} xs={12} className={classes.gridItem}>
        <CustomInputField
          value={value.cf_where_inst}
          onChange={(e) => {
            if (e.target.value.match(/^[a-zA-Z0-9\s]*$/)) {
              handleChange(e);
            }
          }}
          label={"Certified By"}
          name={"cf_where_inst"}
          required={true}
          fullWidth
        />
      </Grid>
      <Grid item sm={12} xs={12} className={classes.gridItem}>
        <CustomDatePicker
          name={"date"}
          maxDate={new Date()}
          minDate={"1 Jan 1970"}
          value={value.cf_completion_date ? value.cf_completion_date : null}
          changeCallBack={(e, v) => {
            onSpecificChange("cf_completion_date", v);
          }}
          label={"Date"}
          required={true}
          fullWidth
        />
      </Grid>
      <CustomButton
        disabled={
          !(value.cf_name && value.cf_where_inst && value.cf_completion_date)
        }
        text={updateValue ? "Update" : "Save"}
        onClick={updateValue ? handleUpdate : handleSave}
        className={
          updateValue
            ? classNames(actionBtn, "uap_cerf_update_btn")
            : classNames(actionBtn, "uap_cerf_save_btn")
        }
        variant={updateValue ? "outlined" : "contained"}
        color={updateValue ? "warning" : "primary"}
        icon={<FaPlusCircle />}
      />
      <div variant="body2" className={classes.helperDiv}>
        {updateValue ? "Update" : "Save"} your details before moving on
      </div>
    </>
  );
}
