import { configureStore } from "@reduxjs/toolkit";
import counterSlice from "./redux/counterSlice";
import achievementSlice from "./redux/Dashboard/achievement.slice";
import dashboardSlice from "./redux/Dashboard/dashboard.slice";
import educationSlice from "./redux/Dashboard/education.slice";
import experienceSlice from "./redux/Dashboard/experience.slice";
import extracurricularSlice from "./redux/Dashboard/extracurricular.slice";
import personalDetailSlice from "./redux/Dashboard/personalDetail.slice";
import skillSlice from "./redux/Dashboard/skill.slice";
import paymentSlice from "./redux/payment/paymentSlice";
import planDetailsSlice from "./redux/payment/planDetailsSlice";
import utmSlice from "./redux/utmSlice";

export const store = configureStore({
  reducer: {
    counter: counterSlice,
    UTM: utmSlice,
    personalDetail: personalDetailSlice,
    dashboard: dashboardSlice,
    education: educationSlice,
    experience: experienceSlice,
    achievement: achievementSlice,
    extracurricular: extracurricularSlice,
    skill: skillSlice,
    payment: paymentSlice,
    subscriptionPlans: planDetailsSlice,
  },
});
