import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import fileDownload from "js-file-download";
import endPoints from "../../../../../config/endPoint";
import { updatePlanFeatureUsageThunk } from "../../../../../redux/counterSlice";
import { sendMail, sendResume, verifyMail } from "../api/mail";

const renderEmailFooter = (
  userId,
  classes,
  key,
  content,
  dispatch,
  { email, setEmail },
  { page, setPage },
  { otp, token, hash, setHash, setToken, setOtp },
  inputData,
  isLogo,
  setIsLogo,
  setShow,
  emailData,
  setEmailData,
  template,
  waiting,
  setWaiting,
  handleClose,
  htmlData,
  isBorder,
  loading,
  setLoading,
  isEmail,
  setEmailLoading
) => {
  switch (key) {
    case "1st":
      return (
        <Button
          onClick={(e) => {
            if (!waiting) {
              setWaiting(true);
              sendMail(email, userId)
                .then(({ data }) => {
                  setHash(data.hash);
                  setToken(data.token);
                  setPage("2nd");
                  setWaiting(false);
                })
                .catch((e) => setWaiting(false));
            }
          }}
          variant="contained"
          className={classNames(classes.btn, "resume_email_otp_send_btn")}
        >
          {!waiting ? (
            <>
              <SendTwoToneIcon className={classes.icon} />
              Send Code
            </>
          ) : (
            <CircularProgress
              disableShrink
              color="warning"
              size={25}
              thickness={6}
            />
          )}
        </Button>
      );

    case "2nd":
      return (
        <Button
          onClick={(e) => {
            if (!waiting) {
              verifyMail(otp, token, hash, userId)
                .then(({ data }) => {
                  // sendResume(email, "Resume - VIPApplicant", emailData);
                  setPage("3rd");
                  setWaiting(false);
                })
                .catch((e) => setWaiting(false));
            }
          }}
          variant="contained"
          className={classNames(classes.btn, "resume_verify_btn")}
        >
          {!waiting ? (
            <>
              <SendTwoToneIcon className={classes.icon} />
              Verify
            </>
          ) : (
            <CircularProgress
              disableShrink
              color="warning"
              size={25}
              thickness={6}
            />
          )}
        </Button>
      );
    case "3rd":
      return loading ? (
        <Button
          variant="contained"
          className={classNames(classes.btn, "resume_download_modal_btn")}
        >
          <CircularProgress
            disableShrink
            size={25}
            thickness={6}
            color="warning"
          />
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={(e) => {
            setLoading(true);
            axios
              .post(endPoints.resumeDownload, { token, htmlData, isBorder })
              .then((r) => {
                axios
                  .get(r.data.results, {
                    responseType: "blob",
                  })
                  .then((res) => {
                    fileDownload(
                      res.data,
                      `${inputData.personal.firstname}-VIPApplicant.pdf`
                    );

                    dispatch(
                      updatePlanFeatureUsageThunk({
                        token: localStorage.getItem("token"),
                        featureName: "resume_download",
                      })
                    );
                    setLoading(false);
                    handleClose();
                  })
                  .catch((e) => setLoading(false));
              })
              .catch((e) => setLoading(false));
          }}
          className={classNames(classes.btn, "resume_download_modal_btn")}
        >
          {/* <SendTwoToneIcon className={classes.icon} /> */}
          Download
        </Button>
      );

    default:
      return (
        <div className="default">
          {loading ? (
            <Button
              variant="contained"
              className={classNames(classes.btn, "resume_download_modal_btn")}
            >
              <CircularProgress
                disableShrink
                size={25}
                thickness={6}
                color="warning"
              />
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={(e) => {
                setLoading(true);
                axios
                  .post(endPoints.resumeDownload, { token, htmlData, isBorder })
                  .then((r) => {
                    axios
                      .get(r.data.results, {
                        responseType: "blob",
                      })
                      .then((res) => {
                        fileDownload(
                          res.data,
                          `${inputData.personal.firstname}-VIPApplicant.pdf`
                        );

                        dispatch(
                          updatePlanFeatureUsageThunk({
                            token: localStorage.getItem("token"),
                            featureName: "resume_download",
                          })
                        );
                        setLoading(false);
                        handleClose();
                      })
                      .catch((e) => setLoading(false));
                  })
                  .catch((e) => setLoading(false));
              }}
              className={classNames(classes.btn, "resume_download_modal_btn")}
            >
              {/* <SendTwoToneIcon className={classes.icon} /> */}
              Download
            </Button>
          )}
          {isEmail ? (
            <Button
              variant="contained"
              className={classNames(classes.btn, "resume_email_modal_btn")}
            >
              <CircularProgress
                disableShrink
                size={25}
                thickness={6}
                color="warning"
              />
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                setEmailLoading(true);
                axios
                  .post(endPoints.resumeDownload, {
                    token,
                    htmlData,
                    isBorder,
                  })
                  .then((r) => {
                    axios
                      .get(r.data.results, {
                        responseType: "blob",
                      })
                      .then((d) => {
                        sendResume(email, "VIPApplicant - Your Resume", d.data);
                        setEmailLoading(false);
                      })
                      .catch((e) => setEmailLoading(false));
                  })
                  .catch((e) => setEmailLoading(false));
                dispatch(
                  updatePlanFeatureUsageThunk({
                    token: localStorage.getItem("token"),
                    featureName: "resume_download",
                  })
                );
              }}
              variant="contained"
              className={classNames(classes.btn, "resume_email_modal_btn")}
            >
              {/* <SendTwoToneIcon className={classes.icon} /> */}
              Email
            </Button>
          )}
        </div>
      );
  }
};

export default renderEmailFooter;
