const formatDate = (dat, showDate = false) => {
  if (!dat) return null;
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (showDate && dat)
    return `${dat.getDate()} ${
      monthNames[dat.getMonth()]
    } ${dat.getFullYear()}`;

  if (dat) return `${monthNames[dat.getMonth()]} ${dat.getFullYear()}`;
};

export const getDateStringWithFormat = (date, format) => {
  switch (format) {
    case "YYYY-DD-MM- MM:SS":
      return (
        new Date(date).getFullYear() +
        "-" +
        (new Date(date).getMonth() + 1) +
        "-" +
        new Date(date).getDate() +
        " " +
        new Date(date).getHours() +
        ":" +
        new Date(date).getMinutes()
      );

    default:
      return date;
  }
};

export const sortArrayWithDate = (arr, name, flip = false) => {
  if (flip) {
    return [...arr].sort(function (a, b) {
      if (!a[name]) {
        return 1;
      }
      if (!b[name]) {
        return -1;
      }
      return new Date(a[name]) - new Date(b[name]);
    });
  } else {
    return [...arr].sort(function (a, b) {
      if (!a[name]) {
        return -1;
      }
      if (!b[name]) {
        return 1;
      }
      return new Date(b[name]) - new Date(a[name]);
    });
  }
};

export default formatDate;
