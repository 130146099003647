import { useTheme } from "@emotion/react";
import axios from "axios";
import React from "react";
import {
  FaAddressCard,
  FaBookOpen,
  FaRunning,
  FaTrophy,
  FaUserCircle,
  FaUserCog,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import CustomModal from "../../components/CustomModal/Index";
import CustomStepper from "../../components/custom_stepper/Index";
import endPoints from "../../config/endPoint";
import useForm from "../../hooks/useForm.hook";
import DashboardSidebar from "../../layouts/index";
import { checkUserPlanThunk, getLimitThunk } from "../../redux/counterSlice";
import { fetchResumeByUserID } from "../../redux/Dashboard/dashboard.slice";
import ResumeStyles from "../../styles/pages/Resume/index.style";
import { filterNumberByCountryCode } from "../../utils/contentFormatter";
import AchievementComponent from "./components/achievements.component";
import EducationComponent from "./components/education.component";
import ExperienceComponent from "./components/experience.component";
import ExtracurricularComponent from "./components/extracurricular.component";
import PersonalDetailComponent from "./components/personalDetails.component";
import SkillsComponent from "./components/skills.component";
import checkError from "./handlers/error";

export default function ResumePage() {
  const classes = ResumeStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useDispatch();
  const {
    value,
    handleChange,
    bulkUpdate,
    onSpecificChange,
    clearInput,
    handleError,
    clearError,
    formError,
  } = useForm({});

  const personalDetail = useSelector((state) => state.personalDetail);
  const education = useSelector((state) => state.education);
  const experience = useSelector((state) => state.experience);
  const skill = useSelector((state) => state.skill);
  // const extracurricular = useSelector((state) => state.extracurricular);
  const achievement = useSelector((state) => state.achievement);
  const dashboard = useSelector((state) => state.dashboard);
  let token = localStorage.getItem("token");
  const [name, setName] = React.useState("");
  const [isAlert, setAlert] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [btnIdf, setBtnIdf] = React.useState("");
  const { resumeId } = useParams();

  // "It looks like you have been editing something. " +
  //   "If you leave before saving, your changes will be lost.";

  const getResume = (resumeId) => {
    return dashboard.resumes?.find((resume) => resume.resume_id === resumeId);
  };
  const handleNext = async (val) => {
    const newActiveStep = activeStep + 1;

    if (activeStep === 0) {
      await checkError(value, "personal", formError, handleError, () => {
        if (
          personalDetail.raw &&
          Object.keys(personalDetail.raw).length === 0 &&
          Object.getPrototypeOf(personalDetail.raw) === Object.prototype
        ) {
          if (!name?.trim()) {
            axios.patch(`${endPoints.updateUserProfileDetail}`, {
              ...value,
              token: token,
              resume_id: resumeId,
            });
          }

          axios
            .post(`${endPoints.addPersonalDetail}`, {
              ...value,
              token: token,

              resume_id: resumeId,
            })
            .then((r) => {
              axios.post(`${endPoints.checkCompletion}`, {
                token,
                resume_id: resumeId,
                completion_pd: 1,
              });
            });
        } else {
          if (!name?.trim()) {
            axios.patch(`${endPoints.updateUserProfileDetail}`, {
              ...value,
              token: token,
              resume_id: resumeId,
            });
          }
          value["pd_country"] =
            typeof value.pd_country === "string"
              ? value.pd_country
              : value.pd_country?.name;
          value["pd_state"] =
            typeof value.pd_state === "string"
              ? value.pd_state
              : value.pd_state?.name;
          value["pd_contact_number"] =
            (`${
              !value.pd_country_code
                ? "+91"
                : value.pd_country_code?.split("+").length === 1
                ? "+" + value.pd_country_code?.split("+")[0]
                : "+" + value.pd_country_code?.split("+")[1]
            }` ?? "") +
            `${filterNumberByCountryCode(value.pd_contact_number)[1]}`;
          axios
            .patch(`${endPoints.updatePersonalDetail}`, {
              ...personalDetail.raw,
              token: token,
              pd_updated_at: "now",
              ...value,
            })
            .then((r) => {
              axios.post(`${endPoints.checkCompletion}`, {
                token,
                resume_id: resumeId,
                completion_pd: 1,
              });
            });
        }
        setActiveStep(newActiveStep);
        clearInput();
        setIsEditing(false);
        clearError();
      });
    }
    if (activeStep === 1) {
      checkError(
        education.educations.length > 0 ? education.educations : [],
        "education",
        formError,
        handleError,
        () => {
          if (isEditing) {
            setAlert(true);
            setBtnIdf("next");
          } else {
            if (education.educations.length > 0) {
              axios.post(`${endPoints.checkCompletion}`, {
                token,
                resume_id: resumeId,
                completion_ed: 1,
              });
            }
            setActiveStep(newActiveStep);
            clearInput();
            setIsEditing(false);
          }
        }
      );
    }

    if (activeStep === 2) {
      // checkError(experience.experiences.length ? experience.experiences :[], "experience",formError, handleError, () => {

      // })
      if (isEditing) {
        setAlert(true);

        setBtnIdf("next");
      } else {
        if (experience.experiences.length > 0) {
          axios.post(`${endPoints.checkCompletion}`, {
            token,
            resume_id: resumeId,
            completion_ex: 1,
          });
        }
        setActiveStep(newActiveStep);
        clearInput();
        setIsEditing(false);
      }
    }

    if (activeStep === 3) {
      if (Object.values(skill.skills).filter((s) => s.length > 0).length > 0) {
        axios.post(`${endPoints.checkCompletion}`, {
          token,
          resume_id: resumeId,
          completion_sk: 1,
        });
      }
      setActiveStep(newActiveStep);
      clearInput();
      setIsEditing(false);
    }

    if (activeStep === 4) {
      if (isEditing) {
        setAlert(true);
        setBtnIdf("next");
      } else {
        if (
          achievement.publications.length > 0 ||
          achievement.achievements.length ||
          achievement.certifications.length
        ) {
          axios.post(`${endPoints.checkCompletion}`, {
            token,
            resume_id: resumeId,
            completion_ac: 1,
          });
        }
        setActiveStep(newActiveStep);
        clearInput();
        setIsEditing(false);
      }
    }
    if (activeStep === 5) {
      axios
        .patch(`${endPoints.updatePersonalDetail}`, {
          token: token,
          pd_updated_at: "now",
          pd_summary: value.pd_summary,
          resume_id: resumeId,
          pd_id: personalDetail.raw.pd_id,
        })
        .catch((e) => e);
      if (value.pd_summary) {
        axios.post(`${endPoints.checkCompletion}`, {
          token,
          resume_id: resumeId,
          completion_xt: 1,
        });
      }
      clearInput();
      setIsEditing(false);
    }
  };

  const handleBack = () => {
    if (isEditing) {
      setAlert(true);
      setBtnIdf("back");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      clearInput();
    }
  };
  const renderResumeComponent = (step) => {
    switch (step) {
      case 0:
        return (
          <PersonalDetailComponent
            formError={formError}
            value={value}
            handleChange={handleChange}
            onSpecificChange={onSpecificChange}
            bulkUpdate={bulkUpdate}
            clearInput={clearInput}
          />
        );
      case 1:
        return (
          <EducationComponent
            value={value}
            formError={formError}
            setFormError={clearError}
            handleChange={handleChange}
            clearInput={clearInput}
            bulkUpdate={bulkUpdate}
            onSpecificChange={onSpecificChange}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        );
      case 2:
        return (
          <ExperienceComponent
            value={value}
            formError={formError}
            setFormError={clearError}
            clearInput={clearInput}
            handleChange={handleChange}
            bulkUpdate={bulkUpdate}
            onSpecificChange={onSpecificChange}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        );
      case 3:
        return (
          <SkillsComponent
            value={value}
            clearInput={clearInput}
            handleChange={handleChange}
            bulkUpdate={bulkUpdate}
            onSpecificChange={onSpecificChange}
          />
        );
      case 4:
        return (
          <AchievementComponent
            value={value}
            clearInput={clearInput}
            handleChange={handleChange}
            bulkUpdate={bulkUpdate}
            onSpecificChange={onSpecificChange}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        );
      case 5:
        return (
          <ExtracurricularComponent
            value={value}
            handleChange={handleChange}
            onSpecificChange={onSpecificChange}
            bulkUpdate={bulkUpdate}
          />
        );
      default:
        return (
          <PersonalDetailComponent
            value={value}
            handleChange={handleChange}
            bulkUpdate={bulkUpdate}
            onSpecificChange={onSpecificChange}
          />
        );
    }
  };

  React.useEffect(() => {
    dispatch(getLimitThunk({ token: token }));
    dispatch(checkUserPlanThunk({ token: token }));
    dispatch(fetchResumeByUserID(token));
    axios
      .post(`${endPoints.getUserProfileDetail}`, { token: token })
      .then((r) => {
        setName(r.data.results[0]?.first_name);
      });
  }, [dispatch, token]);
  return (
    <div className={classes.container}>
      <DashboardSidebar />
      <div className={classes.contentDiv}>
        <CustomModal
          isOpen={isAlert}
          setOpen={setAlert}
          dialogTitle="Unsaved Changes"
          dialogContentText={
            "It looks like you have been editing something. \n" +
            "If you leave before saving, your changes will be lost."
          }
          mbContent={1}
          cancelBtnText="Cancel"
          confirmBtnText={"Okay"}
          actionCallback={(e) => {
            if (btnIdf === "next") {
              setActiveStep(activeStep + 1);
            } else {
              setActiveStep(activeStep - 1);
            }
            clearInput();
            setAlert(false);
            setIsEditing(false);
          }}
        />
        <CustomStepper
          stepperClass={classes.stepperBar}
          skipAccess={
            getResume(resumeId)?.completion_pd === 1 &&
            getResume(resumeId)?.completion_ed === 1
          }
          stepContent={renderResumeComponent(activeStep)}
          stepIcon={[
            <FaUserCircle
              style={{
                fontSize: 25,
                color: theme.palette.info.dark,
              }}
            />,
            <FaBookOpen
              style={{
                fontSize: 25,
                color: theme.palette.info.dark,
              }}
            />,
            <FaAddressCard
              style={{
                fontSize: 25,
                color: theme.palette.info.dark,
              }}
            />,
            <FaUserCog
              style={{
                fontSize: 25,
                color: theme.palette.info.dark,
              }}
            />,
            <FaTrophy
              style={{
                fontSize: 25,
                color: theme.palette.info.dark,
              }}
            />,
            <FaRunning
              style={{
                fontSize: 25,
                color: theme.palette.info.dark,
              }}
            />,
          ]}
          steps={[
            "Personal Details",
            "Education",
            "Experience",
            "Skills",
            "Courses / Trainings",
            "Professional Summary",
          ]}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          nextCallBack={() => handleNext(value)}
          backCallBack={handleBack}
          value={value}
          handleChange={handleChange}
          formError={formError}
          handleError={handleError}
          clearError={clearError}
          onSpecificChange={onSpecificChange}
          clearInput={clearInput}
          btnType={"submit"}
        />
      </div>

      {/* <BottomNavigation value={0} /> */}
    </div>
  );
}
