import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useRef } from "react";
import { FaArrowCircleLeft, FaBolt, FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import VibrateBox from "../../components/animate/vibrate";
import CustomModal from "../../components/CustomModal/Index";
import endPoints from "../../config/endPoint";
import BottomNavigation from "../../layouts/bottomNavigation";
import DashboardSidebar from "../../layouts/index";
import { checkUserPlanThunk } from "../../redux/counterSlice";
import initialState from "../../redux/Dashboard/initialState";
import TemplatePreviewStyles from "../../styles/pages/TemplatePreview/index.style";
import EmailModal from "./components/EmailModal";
import RadioButtonsCustom from "./components/RadioComponents";
import DashboardHeaderFinish from "./components/Score/Index";
import ScoreModalContent from "./components/ScoreModalContent";
import ResumePreviewer from "./components/templates/ResumePreviewer";

export default function TemplatePreview() {
  const classes = TemplatePreviewStyles();
  const [height, setHeight] = React.useState(1525);
  // const planClasses = sidebarBoostStyles();
  const navigate = useNavigate();
  const [inputData, setInputData] = React.useState(initialState);
  const [scoreModal, setScoreModal] = React.useState({});
  const { resumeId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const token = localStorage.getItem("token");
  const dashboard = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [template, setTemplate] = React.useState(1);
  const [templateData, setTemplateData] = React.useState("");
  const TempRef = useRef();
  const divRef = useRef();
  const [isLogo, setIsLogo] = React.useState(true);
  const [show, setShow] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const userPlan = useSelector((state) => state.counter);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    setHeight(
      divRef.current?.clientHeight ? divRef.current?.clientHeight : 1525
    );
    if (!divRef.current?.clientHeight && count < 30) {
      setTimeout(() => {
        setCount(count + 1);
      }, 1000);
    }
  }, [count, template]);

  React.useEffect(() => {
    setLoading(true);
    if (userPlan.current_plan === "VA Executive")
      setTimeout(() => setIsOpen(true), 4000);
    dispatch(checkUserPlanThunk({ token }));
    axios
      .post(`${endPoints.getResumePreviewer}/${resumeId}`, { token })
      .then((r) => {
        // console.log("si");
        if (r.data.personal.firstname) setInputData(r.data);
        else alert("At least fill your personal details.");

        setLoading(false);
      })
      .catch((e) => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.container} id="rte">
      <div>
        <DashboardSidebar />
      </div>
      <CustomModal
        dialogTitle={"Upgrade Your Plan"}
        cancelBtnText={"I don't want to upgrade"}
        isOpen={isOpen}
        setOpen={setIsOpen}
        mbContent={1}
        actionCallback={() => {
          navigate("/plans");
        }}
        confirmBtnText={
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
          >
            <VibrateBox>
              <FaBolt />
            </VibrateBox>{" "}
            Upgrade Now
          </Typography>
        }
        dialogContent={
          <p
            style={{
              textAlign: "center",
            }}
          >
            <Box
              component="img"
              src="/static/upgrade.jpeg"
              sx={{ height: 150, mx: "auto", my: { xs: 1, sm: 2 } }}
            />
            <span
              style={{
                fontSize: "large",
              }}
            >
              Upgrade your plan to access all features of VA Resume Builder
            </span>
          </p>
        }
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: "80%",
            overflow: "auto",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "none",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            padding={"0px 16px"}
          >
            Resume Suggestions
          </Typography>
          <Typography
            padding={"0px 16px"}
            style={{
              color: "#a9a9a9",
              fontWeight: 100,
            }}
          >
            How to Read Your Resume Evaluation Score?
          </Typography>
          <div className={classes.div}>
            <DashboardHeaderFinish
              inputData={inputData}
              type="none"
              setScoreModal={setScoreModal}
              isModal={true}
            />
          </div>

          <Divider sx={{ mt: 1 }} />
          <ScoreModalContent score={scoreModal} inputData={inputData} />
        </Box>
      </Modal>
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress disableShrink size={30} thickness={6} />
        </div>
      ) : (
        <div className={classes.contentDiv}>
          <div className={classes.templateActionContainer}>
            <div className="templateName">
              {!dashboard.resumes?.find((res) => res.resume_id === resumeId)
                ?.resume_title
                ? "MY RESUME"
                : `MY RESUME (${
                    dashboard.resumes?.find((res) => res.resume_id === resumeId)
                      ?.resume_title
                  })`}
              <Button
                component={Link}
                to={`/plans`}
                disabled={userPlan.current_plan === "VA Platinum"}
                variant="contained"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                {userPlan.current_plan === "VA Platinum" ? (
                  <FaBolt />
                ) : (
                  <VibrateBox>
                    <FaBolt />
                  </VibrateBox>
                )}{" "}
                Upgrade
              </Button>
            </div>
            <div className="actionBtn">
              <Button
                variant="contained"
                color="info"
                className="uap_pdf_preview_profile"
                component={Link}
                to={`/profile`}
              >
                <FaArrowCircleLeft
                  style={{
                    marginRight: "5px",
                    fontSize: "18px",
                  }}
                />
                Go to profile
              </Button>
              <Button
                variant="contained"
                color="warning"
                className="pdf_preview_edit"
                component={Link}
                to={`/dashboard/${resumeId}/create`}
                sx={{ borderRadius: 100, width: 105, color: "#000" }}
              >
                <FaPen
                  style={{
                    marginRight: "5px",
                    fontSize: "18px",
                  }}
                />
                Edit
              </Button>
              <EmailModal
                content={TempRef.current}
                userId={inputData.userId}
                htmlData={templateData}
                inputData={inputData}
                isLogo={isLogo}
                setIsLogo={setIsLogo}
                setShow={setShow}
                isBorder={template === 7 ? true : false}
              />
            </div>
          </div>
          <div className="scrollDiv">
            <div className={classes.templateScoreContainer}>
              <DashboardHeaderFinish
                inputData={inputData}
                type="none"
                handleOpen={handleOpen}
              />
            </div>
            {(template === 1 || template === 9) && url && (
              <div className={classes.btnContainer}>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      checked={isLogo}
                      onChange={(e) => {
                        setIsLogo(e.target.checked);
                      }}
                    />
                  }
                  label="View College Logo"
                />
              </div>
            )}
            <div className={classes.templateBodyContainer}>
              <div className="bodyCol bodyCol80">
                <div id="resumeContainer" ref={divRef}>
                  <ResumePreviewer
                    inputData={inputData}
                    TempRef={TempRef}
                    isLogo={isLogo}
                    setIsLogo={setIsLogo}
                    setShow={setShow}
                    show={show}
                    setTemplateData={setTemplateData}
                    template={template}
                    url={url}
                    setUrl={setUrl}
                    setHeight={setHeight}
                  />
                </div>
              </div>
              <div
                className="bodyCol bodyCol20"
                style={{
                  height: height + 60,
                }}
              >
                <RadioButtonsCustom
                  template={template}
                  setTemplate={setTemplate}
                  setIsOpen={setIsOpen}
                  style={{
                    alignItems: "center",
                    width: "100%",
                    padding: 10,
                    margin: "10px 0px",
                    flex: 0.2,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <BottomNavigation value={1} />
    </div>
  );
}
