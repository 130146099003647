import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import endPoints from "../../config/endPoint";

const initialState = {
  planList: [],
};

export const getALlSubPlanThunk = createAsyncThunk(
  "SUBSCRIPTION_PLAN/getAllSubPlanThunk",
  async () => {
    let plans = await axios.get(endPoints.getPlanDetails);
    return plans.data.results;
  }
);

export const subscriptionPlanSlice = createSlice({
  name: "SUBSCRIPTION_PLAN",
  initialState,
  reducers: {},
  extraReducers: {
    [getALlSubPlanThunk.pending]: (state, { payload }) => {
      state.pending = true;
    },
    [getALlSubPlanThunk.fulfilled]: (state, { payload }) => {
      state.pending = false;
      state.planList = payload;
    },
    [getALlSubPlanThunk.rejected]: (state, { payload }) => {
      state.pending = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { validatePayment } = subscriptionPlanSlice.actions;

export default subscriptionPlanSlice.reducer;
