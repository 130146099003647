import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomNotification from "../../../../components/CustomNotification/Index";
import checkIsVerified from "./api/user";
import useStyles from "./styles/email.style";
import renderEmailContent from "./utils/emailContentRenderFunc";
import renderEmailFooter from "./utils/emailFooterRendererFunc";

export default function EmailModal({
  content,
  userId,
  inputData,
  isLogo,
  setIsLogo,
  setShow,
  template,
  htmlData,
  isBorder,
}) {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [page, setPage] = React.useState("1st");
  const [otp, setOtp] = React.useState("1st");
  const [hash, setHash] = React.useState("1st");
  const [token, setToken] = React.useState("1st");
  const [emailData, setEmailData] = React.useState("");
  const [waiting, setWaiting] = React.useState(false);
  const [warn, setWarn] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isEmail, setEmailLoading] = React.useState(false);
  const classes = useStyles();

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    checkIsVerified(userId).then((r) => {
      let { data } = r;
      setEmail(data.email);
      if (data.verified) {
        setPage("4th");
      } else {
        setPage("1st");
      }
    });
  }, [open, userId]);

  const userPlanLimit = useSelector((state) => state.counter.user_plan_limits);

  const downloadUsageLimit = userPlanLimit.find(
    (plan) => plan.feature_name === "resume_download"
  )?.usage_limit;
  const downloadedCount = userPlanLimit.find(
    (plan) => plan.feature_name === "resume_download"
  )?.limit_used;
  return (
    <div>
      <CustomNotification
        alertMessage={
          "You have reached your download limit. Please upgrade your plan."
        }
        open={warn}
        onClose={() => setWarn(false)}
        variant="filled"
        severity={"warning"}
      />
      <Button
        onClick={
          downloadedCount < downloadUsageLimit ||
          parseInt(downloadUsageLimit) === -1
            ? handleClickOpen
            : () => setWarn(true)
        }
        className="email_download_btn"
        variant="contained"
        color="primary"
        sx={{ borderRadius: 100, width: 205 }}
      >
        <MoveToInboxIcon
          style={{
            marginRight: "5px",
          }}
        />
        Email & Download
      </Button>
      <Dialog open={open} onClose={handleClose} className="">
        <DialogTitle className={classes.emailHeader}>
          <span>Congratulations</span>
        </DialogTitle>
        <DialogContent className={classes.emailContent}>
          {renderEmailContent(
            page,
            { email, setEmail },
            { otp, setOtp },
            downloadUsageLimit === -1
              ? "Unlimited"
              : downloadUsageLimit - downloadedCount
          )}
        </DialogContent>
        <DialogActions className={classes.emailFooter}>
          {renderEmailFooter(
            userId,
            classes,
            page,
            content,
            dispatch,
            { email, setEmail },
            { page, setPage },
            { otp, setOtp, hash, setHash, token, setToken },
            inputData,
            isLogo,
            setIsLogo,
            setShow,
            emailData,
            setEmailData,
            template,
            waiting,
            setWaiting,
            handleClose,
            htmlData,
            isBorder,
            loading,
            setLoading,
            isEmail,
            setEmailLoading
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
