import { makeStyles } from "@mui/styles";

let SkillsStyles = makeStyles((theme) => ({
  container: {},
  contentLeft: {
    paddingTop: "0px !important",
    maxHeight: 822,
    overflow: "auto",
    "& #cardContainer": {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      paddingRight: 20,
    },
  },
  iconText: {
    fontSize: "0.8rem",
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  icon: {
    fontSize: "1rem",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  cardList: {
    marginTop: "1.2rem",
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "0px 15px",
  },
  contentRight: {
    paddingTop: "25px !important",
    display: "block",
  },
  typography: {
    paddingTop: 50,
    color: theme.palette.text.disabled,
    textAlign: "center",
  },

  skillField: {
    marginTop: 55,
  },
  skillContainer: {
    border: `1px solid ${theme.palette.divider}`,
    padding: 8,
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
  },
  actionBtn: {
    margin: "10px auto",
    display: "flex",
    gap: 10,
    minWidth: 150,
  },
}));

export default SkillsStyles;
