const filterObj = (obj, reverse = false, update = false) => {
  if (!reverse) {
    if (obj.ex_type.toLowerCase() !== "project") {
      return {
        user_id: obj.user_id,
        resume_id: obj.resume_id,
        ex_id: obj.wx_id,
        ex_title: "",
        ex_url: "",
        ex_type: obj.wx_type,
        ex_role: obj.wx_role,
        ex_org: obj.wx_org,
        ex_industry: obj.wx_industry,
        ex_start_date: obj.wx_start_date,
        ex_end_date: obj.wx_end_date,
        ex_country: obj.wx_location_country,
        ex_state: obj.wx_location_state,
        ex_city: obj.wx_location_city,
        ex_desc: obj.wx_role_description,
        ex_current: obj.wx_iscurrentemployer,
        ex_salary: obj.wx_curr_salary,
        ex_created_at: obj.wx_created_at,
        ex_updated_at: obj.wx_updated_at,
        del_flag: obj.del_flag,
      };
    } else {
      return {
        user_id: obj.user_id,
        resume_id: obj.resume_id,
        ex_id: obj.pj_id,
        ex_type: "Project",
        ex_title: obj.pj_title,
        ex_url: obj.pj_url,
        ex_role: "",
        ex_org: obj.pj_org,
        ex_industry: "",
        ex_start_date: obj.pj_start_date,
        ex_end_date: obj.pj_end_date,
        ex_country: "",
        ex_state: "",
        ex_city: "",
        ex_desc: obj.pj_description,
        ex_current: "",
        ex_salary: "",
        ex_created_at: obj.pj_created_at,
        ex_updated_at: obj.pj_updated_at,
        del_flag: obj.del_flag,
      };
    }
  } else {
    if (obj.ex_type.toLowerCase() !== "project") {
      return {
        user_id: obj.user_id,
        resume_id: obj.resume_id,
        wx_id: obj.ex_id,
        wx_type: obj.ex_type,
        wx_role: obj.ex_role,
        wx_org: obj.ex_org,
        wx_industry: obj.ex_industry,
        wx_start_date: obj.ex_start_date,
        wx_end_date: obj.ex_end_date,
        wx_location_country: obj.ex_country,
        wx_location_state: obj.ex_state,
        wx_location_city: obj.ex_city,
        wx_role_description: obj.ex_desc,
        wx_iscurrentemployer: obj.ex_current,
        wx_curr_salary: obj.ex_salary,
        wx_created_at: obj.ex_created_at,
        wx_updated_at: obj.ex_updated_at,
        del_flag: obj.del_flag,
      };
    } else {
      return {
        user_id: obj.user_id,
        resume_id: obj.resume_id,
        type: "project",
        pj_id: obj.ex_id,
        pj_title: obj.ex_title,
        pj_org: obj.ex_org,
        pj_url: obj.ex_url,
        pj_start_date: obj.ex_start_date,
        pj_end_date: obj.ex_end_date,
        pj_description: obj.ex_desc,
        pj_created_at: obj.ex_created_at,
        pj_updated_at: obj.ex_updated_at,
        del_flag: obj.del_flag,
      };
    }
  }
};

export default filterObj;
