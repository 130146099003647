import { makeStyles } from "@mui/styles";
import spin from "../../../../components/animate/spin";

let PersonalDetailStyle = makeStyles((theme) => ({
  container: {},
  multiGridContainer: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  contentDiv: {
    padding: 60,
    textAlign: "left",
    width: "100%",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  body: {
    margin: "30px 0px",
    border: `1px solid ${theme.palette.divider}`,
    padding: 20,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  aiBox: {
    position: "relative",
  },
  btnStyle: {
    color: theme.palette.primary.dark,
    background: theme.palette.gradients.warning,
    position: "absolute",
    top: "85%",
    right: "45%",
    "& span": {
      display: "flex",
      gap: 10,
      "& svg": {
        margin: "auto 0px",
        animation: `${spin} 2s infinite ease`,
      },
    },
    "&:disabled": {
      background: "#cfcfcf!important",
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down("sm")]: {
      right: "30%",
      fontSize: "x-small",
    },
  },
  guideLineBtn: {
    background: theme.palette.primary.main,
    position: "absolute",
    top: "2%",
    left: "15rem",
    "& span": {
      display: "flex",
      "& svg": {
        margin: "auto 0px",
        animation: `${spin} 2s infinite ease`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      top: "-10%",
      left: "40px",
      fontSize: "x-small",
    },
  },
  aiUsage: {
    height: "auto",
    width: "auto",
    position: "absolute",
    display: "flex",
    top: "2%",
    color: theme.palette.warning.main,
    right: "20px",
    fontSize: "small",
    fontFamily: "inherit",
    padding: "5px 10px",
    borderRadius: "20px",
    background: theme.palette.grey[200],
    [theme.breakpoints.down("sm")]: {
      top: "-24px",
      fontSize: "x-small",
      padding: "2px 7px",
    },
  },
  li: {
    marginBottom: "0.7rem",
  },
  p: {
    margin: 0,
  },
  guideLineStyle: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      left: "50px",
    },
  },
}));

export default PersonalDetailStyle;
