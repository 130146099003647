import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { FaCheck, FaCreditCard, FaTimes } from "react-icons/fa";
import CustomButton from "../../CustomButton/Index";
import endPoints from "../../../config/endPoint";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentDetails } from "../../../redux/payment/paymentSlice";
import checkOutStyles from "../../../styles/components/checkout/checkout.style";
import Total from "./Review";
import { useNavigate } from "react-router";

export default function Billing() {
  const paymentData = useSelector((state) => state.payment);
  const classes = checkOutStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [applied, setApplied] = React.useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  React.useEffect(() => {
    axios
      .post(`${endPoints.getUserProfileDetail}`, { token: token })
      .then((r) => {
        let user = r.data.results[0];
        dispatch(
          setPaymentDetails({
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email_id,
            phone: user.contact_number,
          })
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePayment = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      parseFloat(
        parseFloat(paymentData.amount) -
          parseFloat(paymentData.discount) +
          parseFloat((paymentData.amount - paymentData.discount) * 0.18)
      ) > 0
    ) {
      if (
        paymentData.amount &&
        paymentData.email &&
        paymentData.firstName &&
        paymentData.productInfo
      ) {
        await axios
          .post(endPoints.createHash, {
            token: localStorage.getItem("token"),
            ...paymentData,
            udf1: !!paymentData.coupon ? paymentData.coupon : "",
            udf2: !!parseFloat(paymentData.discount)
              ? paymentData.discount
              : "",
            total: parseFloat(
              parseFloat(paymentData.amount) -
                parseFloat(paymentData.discount) +
                parseFloat((paymentData.amount - paymentData.discount) * 0.18)
            ).toFixed(2),
          })
          .then((r) => {
            e.target.elements["txnid"].value = r.data.results.txnId;
            e.target.elements["hash"].value = r.data.results.hash;
            e.target.elements["key"].value = r.data.results.key;
            e.target.elements["discount"].value = paymentData.discount;
            e.target.elements["udf2"].value = !!parseFloat(paymentData.discount)
              ? paymentData.discount
              : "";
            e.target.elements["udf1"].value = !!paymentData.coupon
              ? paymentData.coupon
              : "";

            e.target.submit();
          })
          .catch((e) => setLoading(false));
      } else {
        alert("Provide Mandatory fields.");
        setLoading(false);
      }
    } else {
      axios
        .post(endPoints.freeUpgradePlan, {
          ...paymentData,
          plan_tenure: paymentData.planDuration?.split(" ")[0],
          token,
        })
        .then((r) => {
          navigate("/success");
        });
    }
  };
  return (
    <form
      onSubmit={handlePayment}
      method="post"
      action={process.env.REACT_APP_PAY_U_MONEY_URL}
      autoComplete="off"
    >
      <input type="hidden" name="key" />
      <input
        type="hidden"
        name="amount"
        value={parseFloat(
          parseFloat(paymentData.amount) -
            parseFloat(paymentData.discount) +
            parseFloat((paymentData.amount - paymentData.discount) * 0.18)
        ).toFixed(2)}
      />
      <input type="hidden" name="txnid" />
      <input type="hidden" name="productinfo" value={paymentData.productInfo} />
      <input type="hidden" name="surl" value={endPoints.payUSurl} />
      <input type="hidden" name="furl" value={endPoints.payUFurl} />
      <input type="hidden" name="hash" />
      <input type="hidden" name="discount" />
      <input type="hidden" name="udf1" />
      <input type="hidden" name="udf2" />
      <Typography variant="h6" gutterBottom>
        Billing Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstname"
            name="firstname"
            label="First name"
            InputLabelProps={{ shrink: paymentData.firstName ? true : false }}
            className={classes.checkoutInput}
            value={paymentData.firstName}
            onChange={(e) =>
              dispatch(
                setPaymentDetails({
                  firstName: e.target.value,
                })
              )
            }
            fullWidth
            autoComplete="given-name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastname"
            name="lastname"
            label="Last name"
            InputLabelProps={{ shrink: paymentData.lastName ? true : false }}
            value={paymentData.lastName}
            onChange={(e) =>
              dispatch(
                setPaymentDetails({
                  lastName: e.target.value,
                })
              )
            }
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="email"
            name="email"
            InputLabelProps={{ shrink: paymentData.email ? true : false }}
            value={paymentData.email}
            onChange={(e) =>
              dispatch(
                setPaymentDetails({
                  email: e.target.value,
                })
              )
            }
            label="Email Address"
            fullWidth
            required
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Contact Number"
            InputLabelProps={{ shrink: paymentData.phone ? true : false }}
            value={paymentData.phone}
            onChange={(e) => {
              if (e.target.value.match(/^[+1-9-\d]*$/)) {
                dispatch(
                  setPaymentDetails({
                    phone: e.target.value,
                  })
                );
              }
            }}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="country"
            name="country"
            label="Country"
            value={paymentData.country}
            onChange={(e) =>
              dispatch(
                setPaymentDetails({
                  country: e.target.value,
                })
              )
            }
            fullWidth
            autoComplete="shipping country"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="state"
            name="state"
            value={paymentData.state}
            onChange={(e) =>
              dispatch(
                setPaymentDetails({
                  state: e.target.value,
                })
              )
            }
            label="State/Province/Region"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="city"
            name="city"
            label="City"
            value={paymentData.city}
            onChange={(e) =>
              dispatch(
                setPaymentDetails({
                  city: e.target.value,
                })
              )
            }
            fullWidth
            autoComplete="shipping address-level2"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            id="address1"
            name="address1"
            label="Address line 1"
            value={paymentData.address}
            onChange={(e) =>
              dispatch(
                setPaymentDetails({
                  address: e.target.value,
                })
              )
            }
            fullWidth
            autoComplete="shipping address-line1"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            id="zipcode"
            name="zipcode"
            value={paymentData.zip}
            onChange={(e) =>
              dispatch(
                setPaymentDetails({
                  zipcode: e.target.value,
                })
              )
            }
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="coupon"
            name="coupon"
            label="Enter Coupon Code"
            autoComplete="off"
            error={applied?.includes("Invalid") ? true : false}
            value={paymentData.coupon}
            helperText={
              applied ? (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: applied?.includes("Invalid") ? "red" : "green",
                    gap: 5,
                  }}
                >
                  {applied?.includes("Invalid") ? <FaTimes /> : <FaCheck />}
                  {applied}
                </span>
              ) : (
                ""
              )
            }
            onChange={(e) => {
              dispatch(
                setPaymentDetails({
                  coupon: e.target.value,
                })
              );
              setApplied("");
            }}
            InputProps={{
              endAdornment: !applied ? (
                <Button
                  disabled={!paymentData.coupon}
                  onClick={(e) => {
                    axios
                      .post(endPoints.checkCoupon, {
                        coupon: paymentData.coupon,
                        token: token,
                        today: new Date(),
                        plan_id: paymentData.plan_id,
                      })
                      .then((r) => {
                        if (r.data.results.status === "active") {
                          if (r.data.results.discountType === "amount") {
                            dispatch(
                              setPaymentDetails({
                                discount: r.data.results.discount,
                              })
                            );
                          } else {
                            dispatch(
                              setPaymentDetails({
                                discount: parseFloat(
                                  (paymentData.amount *
                                    parseFloat(r.data.results.discount)) /
                                    100
                                ).toFixed(2),
                              })
                            );
                          }

                          setApplied(
                            `Coupon Applied Successfully. ${
                              r.data.results.discountType === "amount"
                                ? "₹" + r.data.results.discount
                                : r.data.results.discount + "%"
                            } Discount Added.`
                          );
                        }
                      })
                      .catch((e) => {
                        setApplied("Invalid Coupon!");
                      });
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    margin: 4,
                  }}
                  variant="contained"
                  color="warning"
                >
                  Apply
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  onClick={(e) => {
                    dispatch(
                      setPaymentDetails({
                        discount: 0.0,
                        coupon: "",
                      })
                    );
                    setApplied("");
                  }}
                >
                  <FaTimes />
                </Button>
              ),
            }}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Total
            currencySymbol={"₹"}
            totalPrice={parseFloat(
              parseFloat(paymentData.amount) -
                parseFloat(paymentData.discount) +
                parseFloat((paymentData.amount - paymentData.discount) * 0.18)
            ).toFixed(2)}
            products={[
              {
                name: `Plan - ${paymentData.productInfo} (${paymentData.planDuration})`,
                price: `${parseFloat(paymentData.amount).toFixed(2)}`,
              },
              {
                name: `Discount`,
                price: `${parseFloat(paymentData.discount).toFixed(2)}`,
              },
              {
                name: "GST (@ 18%)",
                price: `${parseFloat(
                  (paymentData.amount - paymentData.discount) * 0.18
                ).toFixed(2)}`,
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <CustomButton
            type="submit"
            text={"Proceed to pay"}
            variant="contained"
            style={{
              marginTop: 15,
            }}
            fullWidth
            icon={
              loading ? (
                <CircularProgress
                  disableShrink
                  size={20}
                  color="warning"
                  thickness={8}
                  style={{
                    marginRight: 10,
                  }}
                />
              ) : (
                <FaCreditCard
                  style={{
                    marginRight: 10,
                  }}
                />
              )
            }
          />
        </Grid>
      </Grid>
    </form>
  );
}
