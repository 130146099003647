import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";

const Timer = ({ initialMinute = 0, initialSeconds = 0 }) => {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) setSeconds(seconds - 1);

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      {minutes === 0 && seconds === 0 ? (
        <Typography
          sx={{ opacity: 0.7, fontSize: 16, marginTop: "20px" }}
          variant="caption"
        >
          Times up!
        </Typography>
      ) : (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          style={{
            marginTop: "20px",
          }}
        >
          <Stack direction="column" spacing={2}>
            <Avatar
              sx={{ bgcolor: "#0A285C", opacity: "0.8" }}
              variant="square"
            >
              {/* <Typography sx={{ opacity: 0.7, fontSize: 20 }} variant="caption" > {minutes} : {seconds < 10 ?  `0${seconds}` : seconds} </Typography>  */}

              <Typography sx={{ opacity: 0.7, fontSize: 20 }} variant="caption">
                {" "}
                {minutes}{" "}
              </Typography>
            </Avatar>
            <Typography sx={{ opacity: 0.7, fontSize: 14 }} variant="caption">
              {" "}
              MM{" "}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Avatar
              sx={{ bgcolor: "#071E46", opacity: "0.95" }}
              variant="square"
            >
              <Typography sx={{ opacity: 0.7, fontSize: 20 }} variant="caption">
                {" "}
                {seconds < 10 ? `0${seconds}` : seconds}{" "}
              </Typography>
            </Avatar>
            <Typography sx={{ opacity: 0.7, fontSize: 14 }} variant="caption">
              {" "}
              SS{" "}
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Timer;
