import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endPoints from "../../config/endPoint";
import sortArray from "../../utils/sort";

const initialState = {
  loading: false,
  educations: [],
};

// First, create the thunk
export const getEducationThunk = createAsyncThunk(
  "education/getEducation",
  async ({ token, resume_id }, thunkAPI) => {
    const { data } = await axios.post(`${endPoints.getEducation}`, {
      token,
      resume_id,
    });
    return sortArray(data.results, "resume_created_at");
  }
);

export const createEducationThunk = createAsyncThunk(
  "education/createEducation",
  async (body, thunkAPI) => {
    const { data } = await axios.post(`${endPoints.createEducation}`, {
      ...body,
      ed_created_at: "now",
      ed_updated_at: "now",
    });
    return data.results[0];
  }
);

export const deleteEducationThunk = createAsyncThunk(
  "education/deleteEducation",
  async ({ token, resume_id, ed_id }, thunkAPI) => {
    console.log(ed_id);
    const state = thunkAPI.getState().education;
    await axios.delete(`${endPoints.deleteEducation}`, {
      data: { token, resume_id, ed_id: ed_id, del_flag: 1 },
    });
    return state.educations.filter((resume) => resume.ed_id !== ed_id);
  }
);

export const updateEducationThunk = createAsyncThunk(
  "education/updateEducation",
  async ({ body, token }, { getState }) => {
    const state = getState().education;
    const { data } = await axios.patch(`${endPoints.updateEducation}`, {
      token: token,
      ...body,
      ed_updated_at: "now",
    });
    return sortArray(
      state.educations.map((resume) => {
        if (resume.ed_id === body.ed_id) {
          return data.results[0];
        } else {
          return resume;
        }
      }),
      "resume_created_at"
    );
  }
);

export const educationSlice = createSlice({
  name: "education",
  initialState,
  reducers: {
    updateEducation: (state, { payload }) => {
      state.educations = payload;
    },
  },
  extraReducers: {
    [getEducationThunk.pending]: (state) => {
      state.loading = true;
    },
    [getEducationThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.educations = payload;
    },
    [getEducationThunk.rejected]: (state) => {
      state.loading = false;
    },

    [deleteEducationThunk.pending]: (state) => {
      state.loading = true;
    },
    [deleteEducationThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.educations = payload;
    },
    [deleteEducationThunk.rejected]: (state) => {
      state.loading = false;
    },

    [updateEducationThunk.pending]: (state) => {
      state.loading = true;
    },
    [updateEducationThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.educations = payload;
    },
    [updateEducationThunk.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [createEducationThunk.pending]: (state) => {
      state.loading = true;
    },
    [createEducationThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.educations = [...state.educations, payload];
    },
    [createEducationThunk.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateEducation } = educationSlice.actions;

export default educationSlice.reducer;
