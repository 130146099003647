import { DialogContentText, TextField, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const renderEmailContent = (
  key,
  { email, setEmail },
  { otp, setOtp },
  limit
) => {
  switch (key) {
    case "1st":
      return (
        <DialogContentText>
          <Typography variant="h4" className="head-content">
            Your Resume is ready to Download
          </Typography>
          <p>Please verify your profile email address to start download.</p>
          <TextField
            autoFocus
            className="emailField"
            variant="outlined"
            margin="dense"
            id="name"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContentText>
      );

    case "2nd":
      return (
        <DialogContentText>
          <Typography variant="h4" className="head-content">
            Your Resume is ready to Download
          </Typography>
          <p>Please verify your profile email address to start download.</p>
          <TextField
            autoFocus
            className="emailField"
            variant="outlined"
            margin="dense"
            id="name"
            value={email}
            InputProps={{
              readOnly: true,
            }}
            label="Email Address"
            type="email"
            fullWidth
          />
          <TextField
            autoFocus
            className="otpField"
            variant="outlined"
            margin="dense"
            id="name"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            label="Verification Code"
            type="number"
            fullWidth
          />
        </DialogContentText>
      );

    case "3rd":
      return (
        <DialogContentText>
          <Typography variant="h4" className="head-content">
            Your Resume is ready to Download
          </Typography>
          <p>Thank you for verifying for email ID.</p>
          <TextField
            autoFocus
            className="emailField"
            variant="outlined"
            margin="dense"
            id="name"
            color="success"
            focused
            value={email}
            InputProps={{
              endAdornment: (
                <CheckCircleRoundedIcon
                  style={{
                    color: "green",
                  }}
                />
              ),
            }}
            label="Email Address"
            type="email"
            fullWidth
          />
          <p className="middle-content">
            We have sent your resume and a free resume building guide on your
            email.
          </p>
        </DialogContentText>
      );

    default:
      return (
        <DialogContentText>
          <Typography variant="h4" className="head-content">
            Your Resume is ready to Download
          </Typography>
          <p>
            We will send you the resume on your verified email ID{" "}
            <span
              style={{
                color: "green",
              }}
            >
              ({email})
            </span>
          </p>
          <Typography>Downloads Left: {limit}</Typography>
        </DialogContentText>
      );
  }
};

export default renderEmailContent;
