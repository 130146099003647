import { Paper, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import endPoints from "../../../config/endPoint";
import resumeCheckStyles from "../../../styles/pages/Dashboard/components/resumeCheck.style";

export default function ResumeCheckComponent() {
  const classes = resumeCheckStyles();
  const token = localStorage.getItem("token");
  const [profile, setProfile] = React.useState(false);
  const [jobApply, setJobApply] = React.useState(false);

  const dashboard = useSelector((state) => state.dashboard);
  React.useEffect(() => {
    axios.post(`${endPoints.getUserProfileData}`, { token }).then((r) => {
      if (r.data.results.length !== 0) {
        setProfile(true);
      }
    });
    axios.post(`${endPoints.getJobApply}`, { token }).then((r) => {
      if (r.data.results.length) {
        setJobApply(true);
      }
    });
  }, [token]);
  return (
    <>
      <Paper className={classes.container} variant="outlined">
        <Typography variant="h4" className={classes.typography}>
          Steps to follow
        </Typography>
        <p className={classes.p}>
          {dashboard.resumes.filter(
            (re) =>
              re.completion_pd === 1 &&
              re.completion_ed === 1 &&
              re.completion_ex === 1 &&
              re.completion_sk === 1 &&
              re.completion_ac === 1 &&
              re.completion_xt === 1
          ).length > 0 ? (
            <FaCheckCircle className={classes.icon} />
          ) : (
            <FaExclamationTriangle className={classes.warnIcon} />
          )}{" "}
          1. Complete your resume
        </p>
        <p className={classes.p}>
          {profile ? (
            <FaCheckCircle className={classes.icon} />
          ) : (
            <FaExclamationTriangle className={classes.warnIcon} />
          )}{" "}
          2. Complete your profile
          <Link to={"/profile"}>Profile</Link>{" "}
        </p>

        <p className={classes.p}>
          {" "}
          {jobApply ? (
            <FaCheckCircle className={classes.icon} />
          ) : (
            <FaExclamationTriangle className={classes.warnIcon} />
          )}
          3. Apply for job <Link to={"/jobfeed"}>Job Feed</Link>{" "}
        </p>
      </Paper>
    </>
  );
}
