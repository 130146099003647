import axios from "axios";
import React from "react";
import {
  FaBrain,
  FaBriefcase,
  FaCertificate,
  FaGraduationCap,
  FaMinusCircle,
  FaParagraph,
  FaPlusCircle,
  FaStar,
  FaTrophy,
} from "react-icons/fa";
import endPoints from "../../../../config/endPoint";
import find_duplicate_in_array from "../../../../utils/checkDuplicate";
import { sortArrayWithDate } from "../../../../utils/dateFormatter";
import globalTemplateStyles from "./globalStyle";

import filterURL from "../../../../utils/urlCleaning";
import EducationTemplate from "./template2Elements/EducationTemplate";
import ExperienceTemplate from "./template2Elements/ExperienceTemplate";
import PublicationTemplate from "./template2Elements/PublicationTemplate";
import CustomIconButton from "../../../../components/CustomIcon/Index";
import { uniqueNumericId } from "../../../../utils/createId";
const Template7 = (
  { inputData, isLogo, setIsLogo, setShow, onClick, setTemplateData },
  ref
) => {
  const [url, setUrl] = React.useState("");
  const [margin, setMargin] = React.useState(10);
  const [margin1, setMargin1] = React.useState(10);
  const [margin2, setMargin2] = React.useState(null);
  const [margin3, setMargin3] = React.useState(10);
  const [margin4, setMargin4] = React.useState(10);
  const [margin5, setMargin5] = React.useState(10);
  const [margin6, setMargin6] = React.useState(10);
  const [margin7, setMargin7] = React.useState(10);
  React.useEffect(() => {
    inputData.htmlData = ref.current.innerHTML;
    setTemplateData(ref.current.innerHTML);
    axios
      .post(`${endPoints.getLogo}`, {
        token: localStorage.getItem("token"),
        university:
          inputData.education.length !== 0 &&
          inputData.education.reduce((a, b) => {
            return new Date(a.year) > new Date(b.year) ? a : b;
          }).university,
        institute:
          inputData.education.length !== 0 &&
          inputData.education.reduce((a, b) => {
            return new Date(a.year) > new Date(b.year) ? a : b;
          }).college,
      })
      .then((res) => {
        setUrl(res.logoUrl + url);
        !res.logoUrl ? setShow(false) : setShow(true);
        !res.logoUrl ? setIsLogo(false) : setIsLogo(true);
      });
    // fetch(`${endPoints.logo}`, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },

    //   body: JSON.stringify({
    //     university:
    //       inputData.education.length !== 0 &&
    //       inputData.education.reduce((a, b) => {
    //         return new Date(a.year) > new Date(b.year) ? a : b;
    //       }).university,
    //     institute:
    //       inputData.education.length !== 0 &&
    //       inputData.education.reduce((a, b) => {
    //         return new Date(a.year) > new Date(b.year) ? a : b;
    //       }).college,
    //   }),
    // }).then((r) => {
    //   r.json().then((res) => {
    //     setUrl(res.logoUrl);
    //     !res.logoUrl ? setShow(false) : setShow(true);
    //     !res.logoUrl ? setIsLogo(false) : setIsLogo(true);
    //   });
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData, setIsLogo, setShow]);

  React.useEffect(() => {
    setTemplateData(ref.current.innerHTML);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogo, margin, margin2, margin3, margin4, margin5, margin6, margin7]);
  // const zoom = (e) => {
  //   console.log("zooming")
  //   e.preventDefault();

  //   let scale = 1
  //   scale += e.deltaY * -0.01;

  //   // Restrict scale
  //   scale = Math.min(Math.max(.125, scale), 4);

  //   // Apply scale transform
  //   // e.style.transform = `scale(${scale})`;
  //   console.log(e,scale )
  // }

  return (
    <div ref={ref} id="template-7" className="pdf-wrapper">
      <html>
        <head>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400&display=swap"
            rel="stylesheet"
          ></link>
          <style>
            {globalTemplateStyles}{" "}
            {`
            body .template-innerWrapper .full_name h2{
  color: #0d2c64;
            }
           
            body .template-innerWrapper section .section-heading {
              font-size: 1.1em;
  color: #0d2c64;
      }
      body .template-innerWrapper section .section-heading .head-icon {
              margin-right: 3px;
              vertical-align: middle;
      }
      body .template-innerWrapper section .section-heading .head-text {
              vertical-align: middle;
      }
      .section-items .education-item:last-child {
    margin-bottom: 0px;
}

.section-items .education-item {
    margin-bottom: 5px;
}
.section-items .education-item h4 {
    color: #cb7400
}
.section-items .experience-item:last-child {
    margin-bottom: 0px;
}
.section-items .experience-item {
    margin-bottom: 5px;
}
.section-items .experience-item h4 {
    color: #cb7400
}
.skills-section .section-items .ind-item {
    margin-bottom: 5px;
}
.skills-section .section-items strong {
    color: #cb7400;
}
.publication-section .section-items li h5 {
    color: #cb7400;
}
.extracurricular-section .section-items strong {
    color: #cb7400;
}
.certification-section .section-items .cf-name {
    color: #cb7400;
}
          `}
          </style>
        </head>
        <body>
          <div className="template-innerWrapper">
            <section>
              <table>
                <tr>
                  <td valign="top" align="left">
                    <div className="full_name">
                      <h2
                        style={{
                          margin: "0px",
                        }}
                      >
                        {" "}
                        {inputData.personal.firstname}{" "}
                        {inputData.personal.lastname}
                      </h2>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td valign="bottom" align="left">
                    <p
                    // style={{
                    //   display: "flex",
                    //   textAlign: "right",
                    //   flexWrap: "wrap",
                    //   justifyContent: "center",
                    //   gap: 10,
                    // }}
                    >
                      <a href={`mailto:${inputData.personal.email}`}>
                        {inputData.personal.email}
                      </a>
                      {inputData.personal.mobile && " | "}
                      <a href={`tel:${inputData.personal.mobile}`}>
                        {inputData.personal.mobile}
                      </a>
                      {inputData.personal.linkedin && " | "}
                      <a
                        rel="noreferrer"
                        href={
                          inputData.personal.linkedin
                            ? inputData.personal.linkedin.startsWith("http")
                              ? inputData.personal.linkedin
                              : `https://www.linkedin.com/in/${inputData.personal.linkedin}`
                            : ""
                        }
                        target="_blank"
                      >
                        {filterURL(inputData.personal.linkedin)}
                      </a>
                      {inputData.personal.city && " | "}
                      <span
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                        }}
                      >
                        {inputData.personal.city}, {inputData.personal.state}
                      </span>
                    </p>
                  </td>
                </tr>
              </table>
            </section>
            {inputData.personal.summary?.length !== 0 &&
              !!inputData.personal.summary && (
                <section
                  style={
                    margin
                      ? {
                          marginTop: margin,
                        }
                      : {}
                  }
                >
                  <div className="spacingControl">
                    <CustomIconButton
                      size="small"
                      tooltipContent={"Decrease Spacing"}
                      color="primary"
                      onClick={() => {
                        setMargin(margin - 10);
                      }}
                      icon={<FaMinusCircle />}
                    />{" "}
                    <CustomIconButton
                      size="small"
                      tooltipContent={"Increase Spacing"}
                      color="primary"
                      onClick={() => {
                        setMargin(margin + 10);
                      }}
                      icon={<FaPlusCircle />}
                    />
                  </div>
                  <div className="section-heading">
                    <h3 className="mb0 mt1">
                      <span className="head-icon">
                        <FaParagraph />{" "}
                      </span>
                      <span className="head-text">
                        {inputData.personal.summary?.length !== 0 &&
                          !!inputData.personal.summary &&
                          "SUMMARY"}
                      </span>

                      <hr className="mb1" />
                    </h3>
                  </div>
                  <p className="section-items">
                    {inputData.personal.summary?.replace(/•/g, "\n")}
                  </p>
                </section>
              )}
            {inputData.education.length !== 0 && (
              <section
                style={
                  margin1
                    ? {
                        marginTop: margin1,
                      }
                    : {}
                }
              >
                <div className="spacingControl">
                  <CustomIconButton
                    size="small"
                    tooltipContent={"Decrease Spacing"}
                    color="primary"
                    onClick={() => {
                      setMargin1(margin1 - 10);
                    }}
                    icon={<FaMinusCircle />}
                  />{" "}
                  <CustomIconButton
                    size="small"
                    tooltipContent={"Increase Spacing"}
                    color="primary"
                    onClick={() => {
                      setMargin1(margin1 + 10);
                    }}
                    icon={<FaPlusCircle />}
                  />
                </div>
                <div className="section-heading">
                  <h3 className="mb0">
                    {" "}
                    <span className="head-icon">
                      <FaGraduationCap />{" "}
                    </span>
                    <span className="head-text">EDUCATION</span>
                    <hr className="mb1" />
                  </h3>
                </div>
                <div className="section-items">
                  {sortArrayWithDate(inputData.education, "year").map(
                    (el, index) => {
                      return (
                        <EducationTemplate
                          key={index}
                          eduElement={el}
                          modify={true}
                          id={`ed_sec_${index}${uniqueNumericId(10)}`}
                        />
                      );
                    }
                  )}
                </div>
              </section>
            )}
            <section>
              {find_duplicate_in_array(inputData.experience).map(
                (el, index) => {
                  return (
                    <>
                      <div
                        className="section-heading"
                        style={
                          typeof margin2 === "object" && margin2 !== null
                            ? {
                                marginTop: margin2[index],
                              }
                            : {}
                        }
                      >
                        <div className="spacingControlEdu">
                          <CustomIconButton
                            size="small"
                            tooltipContent={"Decrease Spacing"}
                            color="primary"
                            onClick={() => {
                              console.log(margin2);
                              if (!margin2) {
                                setMargin2({
                                  ...margin2,
                                  [index]: -10,
                                });
                              } else {
                                setMargin2({
                                  ...margin2,
                                  [index]: !margin2[index]
                                    ? -10
                                    : margin2[index] - 10,
                                });
                              }
                            }}
                            icon={<FaMinusCircle />}
                          />{" "}
                          <CustomIconButton
                            size="small"
                            tooltipContent={"Increase Spacing"}
                            color="primary"
                            onClick={() => {
                              if (!margin2) {
                                setMargin2({
                                  ...margin2,
                                  [index]: 10,
                                });
                              } else {
                                setMargin2({
                                  ...margin2,
                                  [index]: !margin2[index]
                                    ? 10
                                    : margin2[index] + 10,
                                });
                              }
                            }}
                            icon={<FaPlusCircle />}
                          />
                        </div>
                        <h3
                          className="mb0"
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          <span className="head-icon">
                            <FaBriefcase />
                          </span>
                          <span className="head-text">
                            {el[0].typeOfWork === "Full time"
                              ? "Work Experience"
                              : el[0].typeOfWork}
                          </span>
                          <hr className="mb1" />
                        </h3>
                      </div>
                      <div className="section-items">
                        {sortArrayWithDate(el, "endDate").map((e, i) => (
                          <ExperienceTemplate
                            key={i}
                            id={`ex_sec_${i}${uniqueNumericId(10)}`}
                            expElement={e}
                            modify={true}
                          />
                        ))}
                      </div>
                    </>
                  );
                }
              )}
            </section>
            {(inputData.skills.accountingandfinance.length !== 0 ||
              inputData.skills.basictechnicalskills.length !== 0 ||
              inputData.skills.businessanalytics.length !== 0 ||
              inputData.skills.designskills.length !== 0 ||
              inputData.skills.educationskills.length !== 0 ||
              inputData.skills.managementskills.length !== 0 ||
              inputData.skills.marketingskills.length !== 0 ||
              inputData.skills.nursingandhealthcare.length !== 0 ||
              inputData.skills.programminglanguages.length !== 0 ||
              inputData.skills.salesskills.length !== 0 ||
              inputData.skills.softskills.length !== 0 ||
              inputData.skills.webdevelopment.length !== 0) && (
              <section
                className="skills-section"
                style={
                  margin6
                    ? {
                        marginTop: margin6,
                      }
                    : {}
                }
              >
                <div className="spacingControl">
                  <CustomIconButton
                    size="small"
                    tooltipContent={"Decrease Spacing"}
                    color="primary"
                    onClick={() => {
                      setMargin6(margin6 - 10);
                    }}
                    icon={<FaMinusCircle />}
                  />{" "}
                  <CustomIconButton
                    size="small"
                    tooltipContent={"Increase Spacing"}
                    color="primary"
                    onClick={() => {
                      setMargin6(margin6 + 10);
                    }}
                    icon={<FaPlusCircle />}
                  />
                </div>
                <div className="section-heading">
                  <h3 className="mb0">
                    <span className="head-icon">
                      <FaBrain />
                    </span>
                    <span className="head-text">SKILLS</span>
                    <hr className="mb1" />
                  </h3>
                </div>
                <div className="section-items">
                  {inputData.skills.accountingandfinance.length !== 0 && (
                    <div className="ind-item">
                      <strong>Accounting and Finance</strong>:{" "}
                      {inputData.skills.accountingandfinance.map((el, i) => {
                        return (
                          el.label +
                          (i ===
                          inputData.skills.accountingandfinance.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}

                  {inputData.skills.basictechnicalskills.length !== 0 && (
                    <div className="ind-item">
                      <strong>Basic Technical Skills</strong>:{" "}
                      {inputData.skills.basictechnicalskills.map((el, i) => {
                        return (
                          el.label +
                          (i ===
                          inputData.skills.basictechnicalskills.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}

                  {inputData.skills.businessanalytics.length !== 0 && (
                    <div className="ind-item">
                      <strong>Business Analytics</strong>:{" "}
                      {inputData.skills.businessanalytics.map((el, i) => {
                        return (
                          el.label +
                          (i === inputData.skills.businessanalytics.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}

                  {inputData.skills.designskills.length !== 0 && (
                    <div className="ind-item">
                      <strong>Design Skills</strong>:{" "}
                      {inputData.skills.designskills.map((el, i) => {
                        return (
                          el.label +
                          (i === inputData.skills.designskills.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}

                  {inputData.skills.educationskills.length !== 0 && (
                    <div className="ind-item">
                      <strong>Education Skills</strong>:{" "}
                      {inputData.skills.educationskills.map((el, i) => {
                        return (
                          el.label +
                          (i === inputData.skills.educationskills.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}

                  {inputData.skills.managementskills.length !== 0 && (
                    <div className="ind-item">
                      <strong>Management Skills</strong>:{" "}
                      {inputData.skills.managementskills.map((el, i) => {
                        return (
                          el.label +
                          (i === inputData.skills.managementskills.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}
                  {inputData.skills.marketingskills.length !== 0 && (
                    <div className="ind-item">
                      <strong>Marketing Skills</strong>:{" "}
                      {inputData.skills.marketingskills.map((el, i) => {
                        return (
                          el.label +
                          (i === inputData.skills.marketingskills.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}
                  {inputData.skills.nursingandhealthcare.length !== 0 && (
                    <div className="ind-item">
                      <strong>Nursing and HealthCare</strong>:{" "}
                      {inputData.skills.nursingandhealthcare.map((el, i) => {
                        return (
                          el.label +
                          (i ===
                          inputData.skills.nursingandhealthcare.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}
                  {inputData.skills.programminglanguages.length !== 0 && (
                    <div>
                      <strong>Programming Languages</strong>:{" "}
                      {inputData.skills.programminglanguages.map((el, i) => {
                        return (
                          el.label +
                          (i ===
                          inputData.skills.programminglanguages.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}
                  {inputData.skills.salesskills.length !== 0 && (
                    <div className="ind-item">
                      <strong>Sales Skills</strong>:{" "}
                      {inputData.skills.salesskills.map((el, i) => {
                        return (
                          el.label +
                          (i === inputData.skills.salesskills.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}
                  {inputData.skills.softskills.length !== 0 && (
                    <div className="ind-item">
                      <strong>Soft Skills</strong>:{" "}
                      {inputData.skills.softskills.map((el, i) => {
                        return (
                          el.label +
                          (i === inputData.skills.softskills.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}
                  {inputData.skills.webdevelopment.length !== 0 && (
                    <div className="ind-item">
                      <strong>Web Development</strong>:{" "}
                      {inputData.skills.webdevelopment.map((el, i) => {
                        return (
                          el.label +
                          (i === inputData.skills.webdevelopment.length - 1
                            ? ""
                            : ", ")
                        );
                      })}
                    </div>
                  )}
                </div>
              </section>
            )}
            {inputData.achievements.publications.length !== 0 && (
              <section
                className="publication-section"
                style={
                  margin5
                    ? {
                        marginTop: margin5,
                      }
                    : {}
                }
              >
                <div className="spacingControl">
                  <CustomIconButton
                    size="small"
                    tooltipContent={"Decrease Spacing"}
                    color="primary"
                    onClick={() => {
                      setMargin5(margin5 - 10);
                    }}
                    icon={<FaMinusCircle />}
                  />{" "}
                  <CustomIconButton
                    size="small"
                    tooltipContent={"Increase Spacing"}
                    color="primary"
                    onClick={() => {
                      setMargin5(margin5 + 10);
                    }}
                    icon={<FaPlusCircle />}
                  />
                </div>
                <div className="section-heading">
                  <h3 className="mb0">
                    <span className="head-icon">
                      <FaTrophy />
                    </span>
                    <span className="head-text">PUBLICATIONS</span>
                    <hr className="mb1" />
                  </h3>
                </div>
                <div className="section-items">
                  <ul className="ml2">
                    {inputData.achievements.publications.map((el, index) => {
                      return (
                        <PublicationTemplate key={index} pubElement={el} />
                      );
                    })}
                  </ul>
                </div>
              </section>
            )}

            {inputData.achievements.certifications.length !== 0 && (
              <section
                className="certification-section"
                style={
                  margin4
                    ? {
                        marginTop: margin4,
                      }
                    : {}
                }
              >
                <div className="spacingControl">
                  <CustomIconButton
                    size="small"
                    tooltipContent={"Decrease Spacing"}
                    color="primary"
                    onClick={() => {
                      setMargin4(margin4 - 10);
                    }}
                    icon={<FaMinusCircle />}
                  />{" "}
                  <CustomIconButton
                    size="small"
                    tooltipContent={"Increase Spacing"}
                    color="primary"
                    onClick={() => {
                      setMargin4(margin4 + 10);
                    }}
                    icon={<FaPlusCircle />}
                  />
                </div>
                <div>
                  <div className="section-heading">
                    <h3 className="mb0">
                      <span className="head-icon">
                        <FaCertificate />
                      </span>
                      <span className="head-text">CERTIFICATIONS</span>
                      <hr className="mb1" />
                    </h3>
                  </div>
                  <div className="section-items">
                    <ul className="ml2">
                      {sortArrayWithDate(
                        inputData.achievements.certifications,
                        "certDate"
                      ).map((el) => {
                        return (
                          <li className="mb1">
                            <span className="cf-name">
                              {el.certificationName}
                            </span>{" "}
                            - {el.certifiedBy} (
                            {new Date(el.certDate).toDateString().split(" ")[1]}{" "}
                            -{" "}
                            {new Date(el.certDate).toDateString().split(" ")[3]}
                            )
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </section>
            )}

            {inputData.achievements.awards.length !== 0 && (
              <section
                style={
                  margin3
                    ? {
                        marginTop: margin3,
                      }
                    : {}
                }
              >
                <div className="spacingControl">
                  <CustomIconButton
                    size="small"
                    tooltipContent={"Decrease Spacing"}
                    color="primary"
                    onClick={() => {
                      setMargin3(margin3 - 10);
                    }}
                    icon={<FaMinusCircle />}
                  />{" "}
                  <CustomIconButton
                    size="small"
                    tooltipContent={"Increase Spacing"}
                    color="primary"
                    onClick={() => {
                      setMargin3(margin3 + 10);
                    }}
                    icon={<FaPlusCircle />}
                  />
                </div>
                <div className="section-heading">
                  <h3 className="mb0">
                    <span className="head-icon">
                      <FaTrophy />
                    </span>
                    <span className="head-text">AWARDS</span>
                    <hr className="mb1" />
                  </h3>
                </div>
                <div className="section-items">
                  <ul className="ml2">
                    {inputData.achievements.awards.map((el) => {
                      return (
                        <li className="mb1">
                          {el.desc} ({el.year})
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
            )}

            {inputData.extras.hobbies.length !== 0 &&
              inputData.extras.languages.length !== 0 && (
                <section
                  className="extracurricular-section"
                  style={
                    margin7
                      ? {
                          marginTop: margin7,
                        }
                      : {}
                  }
                >
                  <div className="spacingControl">
                    <CustomIconButton
                      size="small"
                      tooltipContent={"Decrease Spacing"}
                      color="primary"
                      onClick={() => {
                        setMargin7(margin7 - 10);
                      }}
                      icon={<FaMinusCircle />}
                    />{" "}
                    <CustomIconButton
                      size="small"
                      tooltipContent={"Increase Spacing"}
                      color="primary"
                      onClick={() => {
                        setMargin7(margin7 + 10);
                      }}
                      icon={<FaPlusCircle />}
                    />
                  </div>
                  <div className="section-heading">
                    <h3 className="mb0">
                      <span className="head-icon">
                        <FaStar />
                      </span>
                      <span className="head-text">EXTRACURRICULAR</span>
                      <hr className="mb1" />
                    </h3>
                  </div>
                  {inputData.extras.hobbies.length !== 0 && (
                    <div className="section-items mb1">
                      <strong>Interests</strong>:{" "}
                      {inputData.extras.hobbies.map((el, i) => {
                        if (el.label) {
                          return (
                            el.label +
                            (i === inputData.extras.hobbies.length - 1
                              ? ""
                              : ", ")
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  )}
                  {inputData.extras.languages.length !== 0 && (
                    <div className="section-items">
                      <strong>Languages</strong>:{" "}
                      {inputData.extras.languages.map((el, i) => {
                        if (el.label) {
                          return (
                            el.label +
                            (i === inputData.extras.languages.length - 1
                              ? ""
                              : ", ")
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  )}
                </section>
              )}
          </div>

          {/* --------- */}
        </body>
      </html>
    </div>
  );
};

export default React.forwardRef(Template7);
