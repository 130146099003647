const gradingSystem = [
  {
    value: "4",
    label: "CGPA out of 4",
  },
  {
    value: "10",
    label: "CGPA out of 10",
  },
  {
    value: "100",
    label: "Percentage(%)",
  },
];

export const eductionType = [
  "Doctorate/PhD",
  "Post-Graduation/Masters",
  "Bachelors",
  "Diploma",
  "Class 12",
  "Class 10",
  // "Freshman",
  // "Sophomore",
  // "Junior",
  // "Senior",
];

export const boardsList = [
  { label: "CISCE(ICSE/ISC)" },
  { label: "CBSE" },
  { label: "National Open School" },
  { label: "IB(International Baccalaureate)" },
  { label: "State Board" },
];

export const majorsList = [
  { lib_degspez_degree: "Science(Medical)" },
  { lib_degspez_degree: "Science(Non-Medical)" },
  { lib_degspez_degree: "Commerce" },
  { lib_degspez_degree: "Humanities" },
  { lib_degspez_degree: "Others" },
];

export default gradingSystem;
