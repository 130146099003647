import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import * as React from "react";

export default function DatePickerProfile({ date, handleChange, ...props }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        minDate={new Date("Jan 1960")}
        maxDate={new Date()}
        InputLabelProps={{
          shrink: false,
          // className: props.classes.inputLabelNoShrink,
        }}
        InputProps={{
          classes: {
            notchedOutline: props.classes.notchedOutline,
          },
        }}
        className={classNames(props.classes.root, props.className)}
        inputFormat="dd MMM yyyy"
        value={date}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </LocalizationProvider>
  );
}
