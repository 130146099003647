import { TextField } from "@mui/material";
import classNames from "classnames";
import React from "react";
//styles
import inputStyles from "../makestyles/InputStyles";

const useStyles = inputStyles;

const OTPInput = ({
  OTP,
  setOTP,
  isError,
  loginMessage,
  verifyOTP,
  className,
}) => {
  const classes = useStyles();

  return (
    <div>
      <TextField
        autoFocus
        label="OTP"
        placeholder="6 digit OTP"
        maxLength={6}
        variant="outlined"
        className={classNames(classes.root, className)}
        fullWidth
        required={true}
        helperText={loginMessage === "" ? "6 digit OTP" : loginMessage}
        error={isError}
        color="secondary"
        value={OTP}
        onChange={(e) => {
          if (e.target.value.match(/^\d+$/) && e.target.value.length <= 6) {
            setOTP(e.target.value);
          } else if (e.target.value.length === 0) {
            setOTP(e.target.value);
          }
        }}
        // onKeyDown={(e) => {
        //     if (e.key === "Enter" && OTP.length === 6) verifyOTP();
        //   }}
        inputProps={{ maxLength: 6 }}
        InputLabelProps={{
          shrink: false,
          className: classes.inputLabelNoShrink,
        }}
        InputProps={{
          classes: {
            notchedOutline: classes.notchedOutline,
          },
        }}
      />
    </div>
  );
};

export default OTPInput;
