import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomNotification from "../../components/CustomNotification/Index";
import endPoints from "../../config/endPoint";
import BottomNavigation from "../../layouts/bottomNavigation";
import DashboardSidebar from "../../layouts/index";
import ProfileStyles from "../../styles/pages/Profile/index.style";
import DatePickerProfile from "./components/DateComponent";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// import Fade from 'react-reveal/Fade'

function ProfileInput({
  freeSolo,
  options,
  label,
  required,
  type,
  textProps,
  helperText,
  name,
  ...props
}) {
  return (
    <Autocomplete
      {...props}
      freeSolo={freeSolo}
      options={options}
      fullWidth
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          type={type}
          required={required}
          error={!!helperText}
          label={label}
          helperText={helperText}
          InputLabelProps={{
            shrink: false,
            // className: props.classes.inputLabelNoShrink,
          }}
          InputProps={{
            classes: {
              notchedOutline: props.classes.notchedOutline,
            },
            ...params.InputProps,
          }}
          className={props.classes.root}
        />
      )}
    />
  );
}

const MultiSelect = ({
  freeSolo,
  options,
  label,
  required,
  type,
  textProps,
  helperText,
  name,
  ...props
}) => (
  <FormControl sx={{ width: "100%" }} className={props.className}>
    <Select
      labelId="demo-multiple-checkbox-label"
      id="demo-multiple-checkbox"
      fullWidth
      multiple
      {...props}
      input={<OutlinedInput label={label} />}
      renderValue={(selected) => selected.join(", ")}
    >
      {options.map((name) => (
        <MenuItem key={name} value={name}>
          <Checkbox checked={props.value.indexOf(name) > -1} />
          <ListItemText primary={name} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const InputText = (props) => (
  <TextField
    fullWidth
    {...props}
    InputLabelProps={{
      shrink: false,
      // className: props.classes.inputLabelNoShrink,
    }}
    InputProps={{
      classes: {
        notchedOutline: props.classes.notchedOutline,
      },
    }}
    className={classNames(props.classes.root, props.className)}
  />
);

const ProfilePage = () => {
  const classes = ProfileStyles();
  const [userInfo, setuserInfo] = useState({});
  const dashboard = useSelector((state) => state.dashboard);
  const [isModify, setModify] = useState(false);
  const userId = localStorage.getItem("token");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = useState({
    gender: "",
    resume: "",
    industry: "",
    dob: "",
    currentLoc: "",
    preferredLoc: "",
    exServ: false,
    diffAbled: false,
    workOption: "",
    functionalArea: "",
    noticePeriod: "",
    experienceMonth: null,
    experienceYear: "",
    anualSalary: "",
    expectedSalary: "",
  });

  const [resumes, setResumeList] = React.useState([]);
  const token = localStorage.getItem("token");
  const [warn, setWarn] = React.useState(false);

  const [profile, setProfile] = useState({
    gender: "",
    dob: null,
    resume: "",
    industry: "",
    currentLoc: "",
    preferredLoc: [],
    exServ: false,
    diffAbled: false,
    workOption: "",
    functionalArea: "",
    noticePeriod: "",
    experienceMonth: null,
    experienceYear: "",
    anualSalary: "",
    expectedSalary: "",
  });

  const checkErrorAndFilter = (data) => {
    if (!data.gender)
      return setError({
        gender: "Fill this out",
      });
    if (!data.dob)
      return setError({
        dob: "Fill this out",
      });
    if (!data.resume)
      return setError({
        resume: "Fill this out",
      });
    if (!data.industry)
      return setError({
        industry: "Fill this out",
      });
    if (!data.currentLoc)
      return setError({
        currentLoc: "Fill this out",
      });
    if (0 === data.preferredLoc.length || data.preferredLoc.length > 5)
      return setError({
        preferredLoc: "Can not be more than five or empty",
      });
    if (!data.workOption)
      return setError({
        workOption: "Fill this out",
      });
    if (!data.noticePeriod)
      return setError({
        noticePeriod: "Fill this out",
      });
    if (data.experienceMonth === null)
      return setError({
        experienceMonth: "Fill this out",
      });
    if (!data.experienceYear)
      return setError({
        experienceYear: "Fill this out",
      });
    if (!data.anualSalary)
      return setError({
        anualSalary: "Fill this out",
      });
    if (!data.expectedSalary)
      return setError({
        expectedSalary: "Fill this out",
      });
    return {
      resumeId: resumes.find((res) => res?.resume_title === data?.resume)
        .resume_id,
      resume_id: resumes.find((res) => res?.resume_title === data?.resume)
        .resume_id,
      rsempprof_dob: `${new Date(data?.dob).getDate()}-${
        new Date(data?.dob).getMonth() + 1
      }-${new Date(data?.dob).getFullYear()}`,
      rsempprof_gender:
        data?.gender.toLowerCase() === "male"
          ? "M"
          : data?.gender.toLowerCase() === "female"
          ? "F"
          : "O",
      rsempprof_diff_abled: data?.diffAbled ? "Y" : "N",
      rsempprof_ex_serv_man: data?.exServ ? "Y" : "N",
      rsempprof_exp_years: data?.experienceYear,
      rsempprof_exp_months: data?.experienceMonth,
      rsempprof_industry: data?.industry,
      rsempprof_curr_loc_city: data?.currentLoc,
      rsempprof_pref_loc_city: data?.preferredLoc.toString(),
      rsempprof_pref_loc_pincode: null,
      rsempprof_annual_salary: data?.anualSalary,
      rsempprof_expected_salary: data?.expectedSalary,
      rsempprof_notice_period: [
        {
          key: 0,
          label: "Immediately Available",
        },
        {
          key: 1,
          label: "1 Month",
        },
        {
          key: 2,
          label: "2 Months",
        },
        {
          key: 3,
          label: "3 Months",
        },
        {
          key: 4,
          label: "4 Months",
        },
        {
          key: 5,
          label: "5 Months",
        },
        {
          key: 6,
          label: "6 Months",
        },
      ].find((d) => d.label === data.noticePeriod).key,
      rsempprof_work_options: [
        {
          key: "FT",
          label: "Full Time",
        },
        {
          key: "PT",
          label: "Part Time",
        },
        {
          key: "FR",
          label: "Freelance",
        },
        {
          key: "CN",
          label: "Consulting",
        },
        {
          key: "RM",
          label: "Remote",
        },
      ].find((d) => d.label === data.workOption).key,
      rsempprof_work_permit: "Y",
    };
  };

  const onProfileChange = (name, value) => {
    setError({
      gender: "",
      resume: "",
      industry: "",
      currentLoc: "",
      preferredLoc: "",
      exServ: false,
      diffAbled: false,
      workOption: "",
      functionalArea: "",
      noticePeriod: "",
      experienceMonth: null,
      experienceYear: "",
      anualSalary: "",
      expectedSalary: "",
    });
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const onSave = async (prof) => {
    setLoading(true);
    let data = checkErrorAndFilter(prof);
    if (data) {
      await axios.patch(endPoints.updateUserProfileDetail, {
        token,
        updateAll: true,
        first_name: userInfo.name?.split(" ")[0],
        last_name: userInfo.name?.split(" ")[1],
        email_id: userInfo.email_id,
        contact_number: userInfo.contact_number,
      });
      axios
        .post(`${endPoints.registerUserProfile}`, { ...data, token })
        .then((r) => {
          setWarn(true);
          setLoading(false);
          setModify(true);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const onModify = async (prof) => {
    let data = checkErrorAndFilter(prof);
    if (data) {
      setLoading(true);
      await axios
        .patch(endPoints.updateUserProfileDetail, {
          token,
          updateAll: true,
          first_name: userInfo.name?.split(" ")[0],
          last_name: userInfo.name?.split(" ")[1],
          email_id: userInfo.email_id,
          contact_number: userInfo.contact_number,
        })
        .catch((e) => setLoading(false));
      axios
        .patch(`${endPoints.updateUserProfile}`, { ...data, token })
        .then((r) => {
          setWarn(true);
          setLoading(false);
        })
        .catch((e) => setLoading(false));
    }
  };

  useEffect(() => {
    async function fetchUserInfo() {
      try {
        axios
          .post(`${endPoints.getUserProfileDetail}`, { token: token })
          .then((r) => {
            setuserInfo({
              ...r.data.results[0],
              name: r.data.results[0].first_name
                ? `${r.data.results[0].first_name} ${
                    r.data.results[0].last_name
                      ? r.data.results[0].last_name
                      : ""
                  }`
                : "",
            });
          });
        const { data } = await axios.post(endPoints.fetchResumes, { token });
        setResumeList(data.results);

        axios.post(`${endPoints.getUserProfileData}`, { token }).then((r) => {
          if (r.data.results.length > 0) {
            setProfile({
              dob: new Date(r.data.results[0].rsempprof_dob),
              resume: dashboard.resumes.find((res) => {
                return res.resume_id === r.data.results[0].resume_id;
              })?.resume_title,
              anualSalary: Math.round(
                r.data.results[0].rsempprof_annual_salary
              ),
              currentLoc: r.data.results[0].rsempprof_curr_loc_city,
              diffAbled:
                r.data.results[0].rsempprof_diff_abled === "Y" ? true : false,
              exServ:
                r.data.results[0].rsempprof_ex_serv_man === "Y" ? true : false,
              experienceMonth: r.data.results[0].rsempprof_exp_months,
              expectedSalary: Math.round(
                r.data.results[0].rsempprof_expected_salary
              ),
              experienceYear: r.data.results[0].rsempprof_exp_years,
              gender:
                r.data.results[0].rsempprof_gender === "F"
                  ? "Female"
                  : r.data.results[0].rsempprof_gender === "M"
                  ? "Male"
                  : "Other",
              industry: r.data.results[0].rsempprof_industry,
              noticePeriod: [
                {
                  key: 0,
                  label: "Immediately Available",
                },
                {
                  key: 1,
                  label: "1 Month",
                },
                {
                  key: 2,
                  label: "2 Months",
                },
                {
                  key: 3,
                  label: "3 Months",
                },
                {
                  key: 4,
                  label: "4 Months",
                },
                {
                  key: 5,
                  label: "5 Months",
                },
                {
                  key: 6,
                  label: "6 Months",
                },
              ].find((d) => d.key === r.data.results[0].rsempprof_notice_period)
                .label,
              preferredLoc:
                r.data.results[0].rsempprof_pref_loc_city.split(","),
              workOption: [
                {
                  key: "FT",
                  label: "Full Time",
                },
                {
                  key: "PT",
                  label: "Part Time",
                },
                {
                  key: "FR",
                  label: "Freelance",
                },
                {
                  key: "CN",
                  label: "Consulting",
                },
                {
                  key: "RM",
                  label: "Remote",
                },
              ].find((d) => d.key === r.data.results[0].rsempprof_work_options)
                .label,
            });

            setModify(true);
          }
        });
      } catch (err) {}
    }
    if (userId) fetchUserInfo();
  }, [userId, dashboard.resumes, token]);

  return (
    <div className={classes.container}>
      <DashboardSidebar />
      <CustomNotification
        variant="filled"
        severity={"success"}
        alertMessage="Successful"
        open={warn}
        onClose={() => setWarn(false)}
      />
      <div className={classes.contentDiv}>
        <Typography variant="h3" color={"primary"}>
          Profile
        </Typography>
        <Divider />

        <Box
          component="main"
          textAlign="left"
          sx={{ flexGrow: 1, paddingTop: 5 }}
        >
          <Box className={classes.profileContainer}>
            {/* <Paper color="info" variant="outlined" sx={{ px: 3, py: 4, display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "auto",
          minWidth: "20%"
        }}>
            <Typography variant="disabled" sx={{ color: grey[400],}}>
              Some Other information's
            </Typography>
            <div style={{
              background: "#1976d221",
              padding: "20px 0px",
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              gap: 10,
              alignItems: "center",
            
            }}>
            <Avatar
        src="https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg"
        sx={{ width: 86, height: 86 }}
      />
      <Button variant="outlined">Capture</Button>
            </div>
          </Paper> */}
            <Paper
              color="info"
              variant="outlined"
              sx={{
                px: 3,
                py: 4,
                display: "flex",
                flexDirection: "column",
                gap: 3,
                minWidth: "80%",
              }}
            >
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12} md={12} lg={6}>
                  {
                    <Typography
                      variant="h6"
                      color="grey"
                      className={classes.headSec}
                    >
                      {" "}
                      <span
                        style={{
                          margin: "auto 0px",
                          minWidth: "fit-content",
                        }}
                      >
                        Name :
                      </span>
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: false,
                          className: classes.inputLabelNoShrink,
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        value={userInfo.name}
                        onChange={(e) => {
                          setuserInfo({
                            ...userInfo,
                            name: e.target.value,
                          });
                        }}
                        className={classNames(classes.root, "profile_name")}
                      />{" "}
                    </Typography>
                  }
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={6}>
                  {
                    <Typography
                      variant="h6"
                      color="grey"
                      className={classes.headSec}
                    >
                      <span
                        style={{
                          margin: "auto 0px",
                          minWidth: "fit-content",
                        }}
                      >
                        Mobile :
                      </span>
                      <TextField
                        fullWidth
                        type="number"
                        InputLabelProps={{
                          shrink: false,
                          className: classes.inputLabelNoShrink,
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        value={
                          userInfo.contact_number
                            ? `${userInfo.contact_number}`
                            : ""
                        }
                        onChange={(e) => {
                          if (e.target.value.length <= 15) {
                            setuserInfo({
                              ...userInfo,
                              contact_number: e.target.value,
                            });
                          }
                        }}
                        className={classNames(
                          classes.root,
                          "profile_mobile_num"
                        )}
                      />{" "}
                    </Typography>
                  }
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6}>
                  {
                    <Typography
                      variant="h6"
                      color="grey"
                      className={classes.headSec}
                    >
                      {" "}
                      <span
                        style={{
                          margin: "auto 0px",
                          minWidth: "fit-content",
                        }}
                      >
                        Email :
                      </span>
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: false,
                          className: classes.inputLabelNoShrink,
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        value={userInfo.email_id ? `${userInfo.email_id}` : ""}
                        onChange={(e) => {
                          setuserInfo({
                            ...userInfo,
                            email_id: e.target.value,
                          });
                        }}
                        className={classNames(classes.root, "profile_email")}
                      />{" "}
                    </Typography>
                  }
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      Gender :
                    </span>{" "}
                    <ProfileInput
                      className={"profile_gender"}
                      classes={classes}
                      helperText={error.gender}
                      onChange={(e, v) => onProfileChange("gender", v)}
                      value={profile.gender}
                      options={["Female", "Male", "Other"]}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      Choose Resume :
                    </span>{" "}
                    <ProfileInput
                      className={"profile_resume"}
                      classes={classes}
                      helperText={error.resume}
                      onChange={(e, v) => onProfileChange("resume", v)}
                      value={profile.resume}
                      options={resumes.map((res) => res.resume_title)}
                    />
                  </Typography>
                </Grid>
                <Grid item lg={6}>
                  <FormControlLabel
                    className={"profile_diffAbled"}
                    control={
                      <Checkbox
                        checked={profile.diffAbled}
                        onChange={(e) =>
                          onProfileChange("diffAbled", e.target.checked)
                        }
                      />
                    }
                    label="Differently Abled"
                  />
                </Grid>
                <Grid item lg={6}>
                  <FormControlLabel
                    className={"profile_exServ"}
                    control={
                      <Checkbox
                        checked={profile.exServ}
                        onChange={(e) =>
                          onProfileChange("exServ", e.target.checked)
                        }
                      />
                    }
                    label="Ex-Serviceman"
                  />
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      DOB :
                    </span>{" "}
                    <div
                      className={"profile_dob"}
                      style={{
                        width: "100%",
                        display: "flex",
                        gap: 10,
                      }}
                    >
                      <DatePickerProfile
                        className={"profile_dob"}
                        classes={classes}
                        date={profile.dob}
                        handleChange={(value) => {
                          onProfileChange("dob", value);
                        }}
                      />
                      {/* <ProfileInput classes = {classes} helperText={error.dob} value={profile.dobDate}  onChange={(e, v) => onProfileChange("dobDate", v)} options={[1, 2, 3, 4, 5, 6, 7,8, 9,10, 11, 12, 13, 14, 15,16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]}/>
            <ProfileInput classes = {classes} helperText={error.dob} value={profile.dobMonth} onChange={(e, v) => onProfileChange("dobMonth", v)}  options={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}/>
            <ProfileInput classes = {classes} helperText={error.dob} value={profile.dobYear} onChange={(e, v) => onProfileChange("dobYear", v)}  options={[1970,1971,1972,1973,1974,1975,1976,1977,1978,1979,1980,1981,1982,1983,1984,1985,1986,1987,1988,1989,1990,1991,1992,1993,1994,1995,1996,1997,1998,1999,2000,2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015]}/> */}
                    </div>
                  </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={6}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      Current Location :
                    </span>{" "}
                    <ProfileInput
                      className={"profile_current_loc"}
                      classes={classes}
                      helperText={error.currentLoc}
                      onChange={(e, v) => onProfileChange("currentLoc", v)}
                      value={profile.currentLoc}
                      options={[
                        "Ahmedabad",
                        "Bangalore",
                        "Chandigarh",
                        "Chennai",
                        "Delhi",
                        "Gurgaon",
                        "Hyderabad",
                        "Kolkata",
                        "Mumbai",
                        "Noida",
                        "Pune",
                        "Guntur",
                        "Kakinada",
                        "Kurnool",
                        "Nellore",
                        "Nizamabad",
                        "Rajamundry",
                        "Tirupati",
                        "Vijayawada",
                        "Visakhapatnam",
                        "Warangal",
                        "Anantapur",
                        "Guntakal",
                        "Hyderabad",
                        "Rajahmundry",
                        "Secunderabad",
                        "Andhra Pradesh - Other",
                        "Itanagar",
                        "Arunachal Pradesh - Other",
                        "Dispur",
                        "Guwahati",
                        "Silchar",
                        "Assam - Other",
                        "Bhilaigarh",
                        "Patna",
                        "Bihar - Other",
                        "Bhagalpur",
                        "Bilaspur",
                        "Raipur",
                        "Bhillai",
                        "Chhattisgarh - Other",
                        "Goa",
                        "Panaji",
                        "Panjim",
                        "Vasco Da Gama",
                        "Goa - Other",
                        "Anand",
                        "Ankleshwar",
                        "Baroda",
                        "Bharuch",
                        "Bhavnagar",
                        "Bhuj",
                        "Gandhinagar",
                        "Jamnagar",
                        "Kandla",
                        "Porbandar",
                        "Rajkot",
                        "Surat",
                        "Vadodara",
                        "Valsad",
                        "Vapi",
                        "Ahmedabad",
                        "Gir",
                        "Gujarat - Other",
                        "Ambala",
                        "Faridabad",
                        "Hisar",
                        "Karnal",
                        "Kurukshetra",
                        "Panipat",
                        "Rohtak",
                        "Sonipat / Sonepat",
                        "Yamunanagar",
                        "Chandigarh",
                        "Gurgaon",
                        "Haryana - Other",
                        "Baddi",
                        "Dalhousie",
                        "Dharamsala",
                        "Kulu/Manali",
                        "Shimla",
                        "Himachal Pradesh - Other",
                        "Jammu",
                        "Srinagar",
                        "Jammu & Kashmir - Other",
                        "Bokaro",
                        "Dhanbad",
                        "Jamshedpur",
                        "Ranchi",
                        "Jharkhand - Other",
                        "Bengaluru / Bangalore",
                        "Belgaum",
                        "Bellary",
                        "Bidar",
                        "Dharwad",
                        "Gulbarga",
                        "Hubli",
                        "Kolar",
                        "Mangalore",
                        "Mysore",
                        "Shimoga",
                        "Karnataka - Other",
                        "Alappuzha / Alleppey",
                        "Cochin / Kochi / Ernakulam",
                        "Idukki",
                        "Kannur",
                        "Kasargod",
                        "Kollam",
                        "Kottayam",
                        "Kozhikode / Calicut",
                        "Malappuram",
                        "Pallakad",
                        "Pathanamthitta",
                        "Thrissur / Trissur",
                        "Thiruvananthapuram / Trivandrum",
                        "Wayanad",
                        "Palakkad",
                        "Palghat",
                        "Kerala - Other",
                        "Bhopal",
                        "Gwalior",
                        "Indore",
                        "Jabalpur",
                        "Ujjain",
                        "Madhya Pradesh - Other",
                        "Ahmednagar",
                        "Aurangabad",
                        "Jalgaon",
                        "Kolhapur",
                        "Nagpur",
                        "Nasik",
                        "Sholapur",
                        "Mumbai",
                        "Mumbai Suburbs",
                        "Navi Mumbai",
                        "Pune",
                        "Solapur",
                        "Maharashtra - Other",
                        "Imphal",
                        "Manipur - Other",
                        "Shillong",
                        "Meghalaya - Other",
                        "Aizawal",
                        "Mizoram - Other",
                        "Kohima",
                        "Dimapur",
                        "Nagaland - Other",
                        "Bhubaneshwar",
                        "Cuttack",
                        "Paradeep",
                        "Puri",
                        "Rourkela",
                        "Odisha - Other",
                        "Amritsar",
                        "Bhatinda",
                        "Jalandhar",
                        "Ludhiana",
                        "Mohali",
                        "Pathankot",
                        "Patiala",
                        "Bathinda",
                        "Chandigarh",
                        "Punjab - Other",
                        "Ajmer",
                        "Jaipur",
                        "Jaisalmer",
                        "Jodhpur",
                        "Kota",
                        "Udaipur",
                        "Rajasthan - Other",
                        "Gangtok",
                        "Sikkim - Other",
                        "Coimbatore",
                        "Erode",
                        "Hosur",
                        "Madurai",
                        "Nagercoil",
                        "Ooty",
                        "Salem",
                        "Thanjavur",
                        "Tirunalveli",
                        "Trichy",
                        "Tuticorin",
                        "Vellore",
                        "Chennai",
                        "Cuddalore",
                        "Tamil Nadu - Other",
                        "Agartala",
                        "Tripura - Other",
                        "Daman",
                        "Kavaratti",
                        "Pondicherry",
                        "Port Blair",
                        "Silvassa",
                        "Chandigarh",
                        "Dadra & Nagar Haveli",
                        "Delhi",
                        "Agra",
                        "Aligarh",
                        "Allahabad",
                        "Bareilly",
                        "Faizabad",
                        "Ghaziabad",
                        "Gorakhpur",
                        "Kanpur",
                        "Lucknow",
                        "Mathura",
                        "Meerut",
                        "Moradabad",
                        "Varanasi",
                        "Noida",
                        "Uttar Pradesh - Other",
                        "Dehradun",
                        "Roorkee",
                        "Uttaranchal - Other",
                        "Asansol",
                        "Durgapur",
                        "Haldia",
                        "Kharagpur",
                        "Siliguri",
                        "Kolkata",
                        "West Bengal - Other",
                        "Other India",
                        "Quilon",
                        "Australia",
                        "Bahrain",
                        "Bangladesh",
                        "Belgium",
                        "Canada",
                        "China",
                        "Doha",
                        "Dubai",
                        "Egypt",
                        "France",
                        "Germany",
                        "Gulf",
                        "Hongkong",
                        "Indonesia",
                        "Ireland",
                        "Italy",
                        "Japan",
                        "Jordan",
                        "Kenya",
                        "Kuwait",
                        "Lebanon",
                        "Libya",
                        "Macau",
                        "Malaysia",
                        "Maldives",
                        "Mauritius",
                        "Mexico",
                        "Nepal",
                        "Netherlands",
                        "New Zealand",
                        "Norway",
                        "Oman",
                        "Pakistan",
                        "Philippines",
                        "Qatar",
                        "Russia",
                        "Saudi Arabia",
                        "Singapore",
                        "South Africa",
                        "South Korea",
                        "Spain",
                        "SriLanka",
                        "Sweden",
                        "Switzerland",
                        "Taiwan",
                        "Thailand",
                        "United Kingdom",
                        "United Arab Emirates",
                        "United States",
                        "Vietnam",
                        "Yemen",
                        "Zimbabwe",
                        "Luxembourg",
                        "Other International",
                      ]}
                    />
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      Preferred Location :
                    </span>{" "}
                    <MultiSelect
                      className={"profile_prefered_loc"}
                      multiple
                      helperText={error.preferredLoc}
                      value={profile.preferredLoc}
                      classes={classes}
                      onChange={(e) =>
                        onProfileChange("preferredLoc", e.target.value)
                      }
                      options={[
                        "Ahmedabad",
                        "Bangalore",
                        "Chandigarh",
                        "Chennai",
                        "Delhi",
                        "Gurgaon",
                        "Hyderabad",
                        "Kolkata",
                        "Mumbai",
                        "Noida",
                        "Pune",
                        "Guntur",
                        "Kakinada",
                        "Kurnool",
                        "Nellore",
                        "Nizamabad",
                        "Rajamundry",
                        "Tirupati",
                        "Vijayawada",
                        "Visakhapatnam",
                        "Warangal",
                        "Anantapur",
                        "Guntakal",
                        "Hyderabad",
                        "Rajahmundry",
                        "Secunderabad",
                        "Andhra Pradesh - Other",
                        "Itanagar",
                        "Arunachal Pradesh - Other",
                        "Dispur",
                        "Guwahati",
                        "Silchar",
                        "Assam - Other",
                        "Bhilaigarh",
                        "Patna",
                        "Bihar - Other",
                        "Bhagalpur",
                        "Bilaspur",
                        "Raipur",
                        "Bhillai",
                        "Chhattisgarh - Other",
                        "Goa",
                        "Panaji",
                        "Panjim",
                        "Vasco Da Gama",
                        "Goa - Other",
                        "Anand",
                        "Ankleshwar",
                        "Baroda",
                        "Bharuch",
                        "Bhavnagar",
                        "Bhuj",
                        "Gandhinagar",
                        "Jamnagar",
                        "Kandla",
                        "Porbandar",
                        "Rajkot",
                        "Surat",
                        "Vadodara",
                        "Valsad",
                        "Vapi",
                        "Ahmedabad",
                        "Gir",
                        "Gujarat - Other",
                        "Ambala",
                        "Faridabad",
                        "Hisar",
                        "Karnal",
                        "Kurukshetra",
                        "Panipat",
                        "Rohtak",
                        "Sonipat / Sonepat",
                        "Yamunanagar",
                        "Chandigarh",
                        "Gurgaon",
                        "Haryana - Other",
                        "Baddi",
                        "Dalhousie",
                        "Dharamsala",
                        "Kulu/Manali",
                        "Shimla",
                        "Himachal Pradesh - Other",
                        "Jammu",
                        "Srinagar",
                        "Jammu & Kashmir - Other",
                        "Bokaro",
                        "Dhanbad",
                        "Jamshedpur",
                        "Ranchi",
                        "Jharkhand - Other",
                        "Bengaluru / Bangalore",
                        "Belgaum",
                        "Bellary",
                        "Bidar",
                        "Dharwad",
                        "Gulbarga",
                        "Hubli",
                        "Kolar",
                        "Mangalore",
                        "Mysore",
                        "Shimoga",
                        "Karnataka - Other",
                        "Alappuzha / Alleppey",
                        "Cochin / Kochi / Ernakulam",
                        "Idukki",
                        "Kannur",
                        "Kasargod",
                        "Kollam",
                        "Kottayam",
                        "Kozhikode / Calicut",
                        "Malappuram",
                        "Pallakad",
                        "Pathanamthitta",
                        "Thrissur / Trissur",
                        "Thiruvananthapuram / Trivandrum",
                        "Wayanad",
                        "Palakkad",
                        "Palghat",
                        "Kerala - Other",
                        "Bhopal",
                        "Gwalior",
                        "Indore",
                        "Jabalpur",
                        "Ujjain",
                        "Madhya Pradesh - Other",
                        "Ahmednagar",
                        "Aurangabad",
                        "Jalgaon",
                        "Kolhapur",
                        "Nagpur",
                        "Nasik",
                        "Sholapur",
                        "Mumbai",
                        "Mumbai Suburbs",
                        "Navi Mumbai",
                        "Pune",
                        "Solapur",
                        "Maharashtra - Other",
                        "Imphal",
                        "Manipur - Other",
                        "Shillong",
                        "Meghalaya - Other",
                        "Aizawal",
                        "Mizoram - Other",
                        "Kohima",
                        "Dimapur",
                        "Nagaland - Other",
                        "Bhubaneshwar",
                        "Cuttack",
                        "Paradeep",
                        "Puri",
                        "Rourkela",
                        "Odisha - Other",
                        "Amritsar",
                        "Bhatinda",
                        "Jalandhar",
                        "Ludhiana",
                        "Mohali",
                        "Pathankot",
                        "Patiala",
                        "Bathinda",
                        "Chandigarh",
                        "Punjab - Other",
                        "Ajmer",
                        "Jaipur",
                        "Jaisalmer",
                        "Jodhpur",
                        "Kota",
                        "Udaipur",
                        "Rajasthan - Other",
                        "Gangtok",
                        "Sikkim - Other",
                        "Coimbatore",
                        "Erode",
                        "Hosur",
                        "Madurai",
                        "Nagercoil",
                        "Ooty",
                        "Salem",
                        "Thanjavur",
                        "Tirunalveli",
                        "Trichy",
                        "Tuticorin",
                        "Vellore",
                        "Chennai",
                        "Cuddalore",
                        "Tamil Nadu - Other",
                        "Agartala",
                        "Tripura - Other",
                        "Daman",
                        "Kavaratti",
                        "Pondicherry",
                        "Port Blair",
                        "Silvassa",
                        "Chandigarh",
                        "Dadra & Nagar Haveli",
                        "Delhi",
                        "Agra",
                        "Aligarh",
                        "Allahabad",
                        "Bareilly",
                        "Faizabad",
                        "Ghaziabad",
                        "Gorakhpur",
                        "Kanpur",
                        "Lucknow",
                        "Mathura",
                        "Meerut",
                        "Moradabad",
                        "Varanasi",
                        "Noida",
                        "Uttar Pradesh - Other",
                        "Dehradun",
                        "Roorkee",
                        "Uttaranchal - Other",
                        "Asansol",
                        "Durgapur",
                        "Haldia",
                        "Kharagpur",
                        "Siliguri",
                        "Kolkata",
                        "West Bengal - Other",
                        "Other India",
                        "Quilon",
                        "Australia",
                        "Bahrain",
                        "Bangladesh",
                        "Belgium",
                        "Canada",
                        "China",
                        "Doha",
                        "Dubai",
                        "Egypt",
                        "France",
                        "Germany",
                        "Gulf",
                        "Hongkong",
                        "Indonesia",
                        "Ireland",
                        "Italy",
                        "Japan",
                        "Jordan",
                        "Kenya",
                        "Kuwait",
                        "Lebanon",
                        "Libya",
                        "Macau",
                        "Malaysia",
                        "Maldives",
                        "Mauritius",
                        "Mexico",
                        "Nepal",
                        "Netherlands",
                        "New Zealand",
                        "Norway",
                        "Oman",
                        "Pakistan",
                        "Philippines",
                        "Qatar",
                        "Russia",
                        "Saudi Arabia",
                        "Singapore",
                        "South Africa",
                        "South Korea",
                        "Spain",
                        "SriLanka",
                        "Sweden",
                        "Switzerland",
                        "Taiwan",
                        "Thailand",
                        "United Kingdom",
                        "United Arab Emirates",
                        "United States",
                        "Vietnam",
                        "Yemen",
                        "Zimbabwe",
                        "Luxembourg",
                        "Other International",
                      ]}
                      disableCloseOnSelect
                      getOptionLabel={(option) => (option ? option : "")}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            className={`profile_diff_loc_check`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                    />
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      Industry :
                    </span>{" "}
                    <ProfileInput
                      className={"profile_industry"}
                      classes={classes}
                      helperText={error.industry}
                      onChange={(e, v) => onProfileChange("industry", v)}
                      value={profile.industry}
                      options={[
                        "Accounting / Auditing",
                        "Advertising & Marketing",
                        "Agriculture / Forestry / Fishing",
                        "Analytics / KPO / Research",
                        "Animation & VFX",
                        "Architecture / Interior Design",
                        "Auto Components",
                        "Automobile",
                        "Aviation",
                        "Banking",
                        "Beauty & Personal Care",
                        "Beverage",
                        "BFSI",
                        "Biotechnology",
                        "BPM",
                        "BPO / Call Centre",
                        "Building Material",
                        "Chemicals",
                        "Clinical Research / Contract Research",
                        "Consumer Electronics & Appliances",
                        "Consumer, Retail & Hospitality",
                        "Content Development / Language",
                        "Courier / Logistics",
                        "Defence & Aerospace",
                        "Design",
                        "E-Learning / EdTech",
                        "Education",
                        "Education / Training",
                        "Electrical Equipment",
                        "Electronic Components / Semiconductors",
                        "Electronics Manufacturing",
                        "Emerging Technologies",
                        "Engineering & Construction",
                        "Events / Live Entertainment",
                        "Facility Management Services",
                        "Fertilizers / Pesticides / Agro chemicals",
                        "Film / Music / Entertainment",
                        "Financial Services",
                        "FinTech / Payments",
                        "Fitness & Wellness",
                        "FMCG",
                        "Food Processing",
                        "Furniture & Furnishing",
                        "Gaming",
                        "Gems & Jewellery",
                        "Government / Public Administration",
                        "Hardware & Networking",
                        "Healthcare & Life Sciences",
                        "Hotels & Restaurants",
                        "Import & Export",
                        "Industrial Automation",
                        "Industrial Equipment / Machinery",
                        "Infrastructure, Transport & Real Estate",
                        "Insurance",
                        "Internet",
                        "Investment Banking / Venture Capital / Private Equity",
                        "Iron & Steel",
                        "IT Services",
                        "IT Services & Consulting",
                        "Law Enforcement / Security Services",
                        "Leather",
                        "Legal",
                        "Management Consulting",
                        "Manufacturing & Production",
                        "Media, Entertainment & Telecom",
                        "Medical Devices & Equipment",
                        "Medical Services / Hospital",
                        "Metals & Mining",
                        "Miscellaneous",
                        "NBFC",
                        "NGO / Social Services / Industry Association",
                        "Oil & Gas",
                        "Packaging & Containers",
                        "Petrochemical / Plastics / Rubber",
                        "Pharmaceutical & Life Sciences",
                        "Ports & Shipping",
                        "Power",
                        "Printing & Publishing",
                        "Professional Services",
                        "Pulp & Paper",
                        "Railways",
                        "Real Estate",
                        "Recruitment / Staffing",
                        "Retail",
                        "Software Product",
                        "Sports / Leisure & Recreation",
                        "Technology",
                        "Telecom / ISP",
                        "Textile & Apparel",
                        "Travel & Tourism",
                        "TV / Radio",
                        "Urban Transport",
                        "Water Treatment / Waste Management",
                      ]}
                    />
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      Work options :
                    </span>{" "}
                    <ProfileInput
                      className={"profile_work_option"}
                      classes={classes}
                      helperText={error.workOption}
                      onChange={(e, v) => onProfileChange("workOption", v)}
                      value={profile.workOption}
                      options={[
                        "Full Time",
                        "Part Time",
                        "Freelance",
                        "Consulting",
                        "Remote",
                      ]}
                    />
                  </Typography>
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={6}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      Notice Period :
                    </span>{" "}
                    <ProfileInput
                      className={"profile_notice_period"}
                      classes={classes}
                      helperText={error.noticePeriod}
                      onChange={(e, v) => onProfileChange("noticePeriod", v)}
                      value={profile.noticePeriod}
                      options={[
                        "Immediately Available",
                        "1 Month",
                        "2 Months",
                        "3 Months",
                        "4 Months",
                        "5 Months",
                        "6 Months",
                      ]}
                    />
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      Experience :
                    </span>{" "}
                    <div className={classes.expSection}>
                      <div
                        style={{
                          display: "flex",
                          gap: 10,
                          width: "inherit",
                        }}
                      >
                        <ProfileInput
                          className={"profile_experience_year"}
                          classes={classes}
                          helperText={error.experienceYear}
                          onChange={(e, v) =>
                            onProfileChange("experienceYear", v)
                          }
                          value={profile.experienceYear}
                          options={[
                            1,
                            2,
                            3,
                            4,
                            5,
                            6,
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15,
                            16,
                            17,
                            18,
                            19,
                            20,
                            21,
                            22,
                            23,
                            24,
                            25,
                            26,
                            27,
                            28,
                            29,
                            30,
                            "30+",
                          ]}
                        />
                        <span
                          style={{
                            margin: "auto 0px",
                          }}
                        >
                          years
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: 10,
                          width: "inherit",
                        }}
                      >
                        <ProfileInput
                          classes={classes}
                          className={"profile_experience_month"}
                          helperText={error.experienceMonth}
                          onChange={(e, v) =>
                            onProfileChange("experienceMonth", v)
                          }
                          value={profile.experienceMonth}
                          options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                        />{" "}
                        <span
                          style={{
                            margin: "auto 0px",
                          }}
                        >
                          months
                        </span>
                      </div>
                    </div>
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      Annual Salary (INR) :
                    </span>{" "}
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        width: "100%",
                      }}
                    >
                      <InputText
                        className={"profile_anual_salary"}
                        classes={classes}
                        helperText={error.anualSalary}
                        freeSolo={true}
                        onChange={(e) => {
                          if (
                            (e.target.value.match(/^[1-9]+[0-9]*$/) ||
                              e.target.value.length === 0) &&
                            e.target.value.length <= 2
                          ) {
                            onProfileChange("anualSalary", e.target.value);
                          }
                        }}
                        value={profile.anualSalary}
                        options={[]}
                      />
                      <span
                        style={{
                          margin: "auto 0px",
                        }}
                      >
                        (lakhs/year)
                      </span>
                    </div>
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={6}>
                  <Typography
                    variant="h6"
                    color="grey"
                    className={classes.headSec}
                  >
                    <span
                      style={{
                        margin: "auto 0px",
                        minWidth: "fit-content",
                      }}
                    >
                      Expected Salary (INR) :
                    </span>
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        width: "100%",
                      }}
                    >
                      <InputText
                        className={"profile_expected_salary"}
                        classes={classes}
                        helperText={error.expectedSalary}
                        onChange={(e) => {
                          if (
                            (e.target.value.match(/^[1-9]+[0-9]*$/) ||
                              e.target.value.length === 0) &&
                            e.target.value.length <= 2
                          ) {
                            onProfileChange("expectedSalary", e.target.value);
                          }
                        }}
                        value={profile.expectedSalary}
                        options={[]}
                        freeSolo={true}
                      />
                      <span
                        style={{
                          margin: "auto 0px",
                        }}
                      >
                        (lakhs/year)
                      </span>
                    </div>
                  </Typography>
                </Grid>
              </Grid>
              {/* { userInfo.first_name && <Avatar sx={{ bgcolor: "#071E46" }}>{userInfo.first_name.charAt(0)}</Avatar> } */}
              {loading ? (
                <Button variant="contained">
                  <CircularProgress
                    disableShrink
                    size={20}
                    color="warning"
                    thickness={6}
                  />
                </Button>
              ) : !isModify ? (
                <Button
                  className={"profile_save_btn"}
                  variant="contained"
                  onClick={(e) => {
                    onSave(profile);
                  }}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  className={"profile_modify_btn"}
                  variant="contained"
                  onClick={(e) => {
                    onModify(profile);
                  }}
                >
                  Modify Changes
                </Button>
              )}
            </Paper>

            {/* { !authStat && <Typography variant="h6" color="grey" mb={2} sx={{fontSize: 14, display: " flexDirection: "column",flex", gap: 2}} >Please login to view your profile</Typography> } */}
          </Box>
        </Box>
      </div>

      <BottomNavigation value={1} />
    </div>
  );
};

export default ProfilePage;

// export default function ProfilePage() {
//     const classes = useStyles()
//     return (
//         <div className={classes.container}>
//             <DashboardSidebar />
//             <div className={classes.contentDiv}>
//                 <Typography variant='h3' color={"primary"}>
//                     Profile
//                 </Typography>
//                 <Divider/>
//                 <Typography variant='subtitle1' component={"div"} className={classes.body}>
//                     <Typography component={'p'}>
//                     Name : Sidharth Pradhan
//                     </Typography><Typography component={'p'}>
//                     Mobile : +918338897470
//                     </Typography><Typography component={'p'}>
//                     Email : sidharthpradhan063@gmail.com
//                     </Typography>
//                 </Typography>
//             </div>
//         </div>
//     )
// }
