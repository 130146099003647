import React from "react";
import "../Template1.css";

const PublicationTemplate = ({ pubElement }) => {
  return (
    <li className="mb1">
      <div>
        <div className="col1">
          <h4>{pubElement.title}</h4>
        </div>
      </div>
      <div>
        <div className="col2">
          <h5>
            {pubElement.organisation} -{" "}
            <a href={pubElement.website}>{pubElement.website}</a>
          </h5>{" "}
        </div>
      </div>
      <div></div>
    </li>
  );
};

export default PublicationTemplate;
