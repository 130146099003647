import { CardContent, Divider, Tooltip, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import * as React from "react";
import customCardStyles from "../../styles/components/CustomCard/index.style";

export default function CustomCard({
  title,
  subtitle,
  bodyContent,
  actionContent,
  contentToolTip,
  actionStyle,
  variant,
  path,
  bodyStyle,
  tooltipPlacement,
  dividerText,
  footerTitle,
  footerSubtitle,
  footerContent,
  ...props
}) {
  const classes = customCardStyles();
  return (
    <Card {...props}>
      <CardActions style={actionStyle}> {actionContent} </CardActions>
      <Divider className={classes.divider}>{dividerText}</Divider>{" "}
      {!!contentToolTip ? (
        <CardContent
          style={bodyStyle}
          // component={variant}
          // to={path}
        >
          <section className={classes.cardBodyContent}>
            <div className={classes.headerContainer}>
              <Typography variant="subtitle1" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="body2" className={classes.subtitle}>
                {subtitle}
              </Typography>
            </div>
            {bodyContent}
          </section>
          <div>
            <h4
              style={{
                marginBottom: 10,
              }}
            >
              {footerTitle}
            </h4>
            {footerSubtitle}
            <footer className={classes.cardFooterContent}>
              {footerContent.map((a, idx) => (
                <Tooltip
                  title={
                    [
                      "Personal Details",
                      "Education",
                      "Experience",
                      "Skills",
                      "Courses/Training",
                      "Professional summary",
                    ][idx]
                  }
                >
                  <div
                    className={a ? classes.coloredBar : classes.disabledBar}
                  ></div>
                </Tooltip>
              ))}
            </footer>
          </div>
        </CardContent>
      ) : (
        <CardContent style={bodyStyle} component={variant} to={path}>
          {title && <Typography>{title}</Typography>}
          {subtitle && <Typography>{subtitle}</Typography>}
          {bodyContent}{" "}
        </CardContent>
      )}{" "}
    </Card>
  );
}
