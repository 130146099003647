import { makeStyles } from "@mui/styles";

let PlanStyles = makeStyles((theme) => {
  return {
    helperText: {
      color: "green",
      display: "flex",
      alignItems: "center",
      gap: 4,
      fontWeight: "bold",
    },
    container: {
      display: "flex",
      [theme.breakpoints.up("lg")]: {
        padding: "0px 20px",
      },
    },
    paper: {
      display: "grid",
      "& p": {
        margin: 1,
        display: "flex",
        alignItems: "center",
        padding: 10,
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.grey[400]}`,
        "& .nameSpan": {
          flex: 0.5,
          textTransform: "capitalize",
          [theme.breakpoints.down("sm")]: {
            flex: 0.6,
          },
        },
        "& .divideSpan": {
          textAlign: "right",
        },
        "& .detailSpan": {
          marginLeft: 30,
          flex: 0.6,
          [theme.breakpoints.down("sm")]: {
            flex: 0.3,
          },
        },
        "& .error": {
          color: theme.palette.error.main,
          fontWeight: "bold",
        },
        "& .success": {
          color: theme.palette.success.dark,
          fontWeight: "bold",
        },
      },
    },
    detailBtn: {
      marginLeft: "10px",
      marginBottom: "5px",
    },
    backBtn: {
      position: "absolute",
      display: "flex",
      gap: 5,
      right: 10,
      top: "30%",
      // fontWeight: "bold",
      fontSize: "large",
      [theme.breakpoints.down("sm")]: {
        fontSize: "small",
        top: "-10px",
        right: 0,
      },
    },
    divider: {
      marginBottom: 20,
    },
    containerGrid: {
      marginTop: 10,
    },
    contentHead: {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      "& h3": {},
      "& h5": {
        color: theme.palette.warning.dark,
        marginBottom: 20,
      },
    },
    contentDiv: {
      padding: "40px 10px",
      textAlign: "left",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        paddingTop: 90,
        marginBottom: 70,
      },
      [theme.breakpoints.down("sm")]: {
        padding: 15,
        paddingTop: 90,
      },
    },
    contentSection1: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 20,
      "& span": {
        color: theme.palette.success.dark,
        fontSize: 21,
      },
    },
  };
});

export default PlanStyles;
