import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const cardStyles = makeStyles((theme) => ({
  cardContainer: {
    boxShadow: "2px 5px 15px rgb(0 0 0 / 17%)",
    borderRadius: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
}));

function BasicCard({ title, subTitle, link, exp, yr, location }) {
  const navigate = useNavigate();
  const classes = cardStyles();
  return (
    <Card className={classNames(classes.cardContainer, "uap_jobfeed_card")}>
      <CardContent
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 20,
          gap: "10%",
        }}
      >
        <div
          style={{
            textAlign: "left",
          }}
        >
          <Typography>
            <b>{title}</b>
          </Typography>
          <Typography>{subTitle}</Typography>
        </div>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {exp}
            <span>{yr}</span>
          </Typography>
          <Typography>{location}</Typography>
        </div>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <Button
          size="small"
          className={`uap_${link?.split("/")[0]}_details_btn`}
          variant="contained"
          onClick={(e) => {
            navigate(`/jobfeed/${link}`);
          }}
        >
          Details
        </Button>
      </CardActions>
    </Card>
  );
}

export default BasicCard;
