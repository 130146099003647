import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

const spin = keyframes`
  from{
    transform: rotateX(0deg);
  }
   to{
     transform: rotateX(360deg);
  }
`;

const Spin = styled("div")({
  animation: `${spin} 2s ease`,
});

export default Spin;
