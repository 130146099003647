import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  amount: null,
  productInfo: null,
  firstName: "",
  email: "",
  phone: "",
};

export const paymentSlice = createSlice({
  name: "PAYMENT",
  initialState,
  reducers: {
    setPaymentDetails: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPaymentDetails } = paymentSlice.actions;

export default paymentSlice.reducer;
