import { Box, Typography } from "@mui/material";
import React from "react";
import { formatNumber } from "../../../../../utils/contentFormatter";

export default function UsageContent({ classes }) {
  return (
    <Box
      p={0.2}
      px={0.2}
      style={{
        padding: "0.8rem 1.5rem",
      }}
    >
      <ol>
        <li className={classes.li}>
          Choose one adjective that describes you the best. Make sure that it
          adds value to your candidature. (passionate, responsible, diligent).
        </li>
        <li className={classes.li}>
          Mention one key certification or qualification that sets you aside
          from the rest (six sigma, data science, etc.).
        </li>
        <li className={classes.li}>
          Include your job title in your current organization (data scientist,
          developer).
        </li>
        <li className={classes.li}>
          Mention one or two skills. It can get tricky as you will have more
          skills to talk. The easiest way is to identify the most important
          skills required for the job you are applying for, from the JD
          (collaboration, teamwork, critical thinking).
        </li>
      </ol>

      <Typography
        variant="h4"
        style={{
          marginTop: "2rem",
          marginBottom: "1rem",
          marginLeft: "-1rem",
          fontWeight: "bold",
          color: "#F8991D",
        }}
      >
        AI Usage Guidelines
      </Typography>

      <ol>
        <li className={classes.li}>
          The AI output is COMPLETELY dependant on your input.
          <br /> Good quality input = Relevant AI output.
        </li>
        <li className={classes.li}>
          Write atleast one full sentence using keywords relevant to your
          experience.
        </li>
        <li className={classes.li}>
          Select {formatNumber(1)} of the {formatNumber(3)} AI suggested outputs
          using the Green Tick Button.
        </li>
        <li className={classes.li}>
          In case AI-output is repeating or irrelevant, you can also reject all
          the options by using the RED Cross Button.
        </li>
        <li className={classes.li}>
          After selecting {formatNumber(1)} of the AI-suggested sentence, you
          can still edit it and make it more relevant for yourself.
        </li>
      </ol>
    </Box>
  );
}
