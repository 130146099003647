import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endPoints from "../../config/endPoint";

const initialState = {
  skills: {},
};

export const getSkillsThunk = createAsyncThunk(
  "skill/getSkills",
  async ({ token, resumeId }, thunkAPI) => {
    let data = await axios.post(`${endPoints.getSkills}`, {
      token,
      resumeId,
    });
    return data.data.results.data;
  }
);

export const updateSkillsThunk = createAsyncThunk(
  "skill/updateSkills",
  async ({ token, resumeId }, thunkAPI) => {
    let data = await axios.post(`${endPoints.updateSkills}`, {
      token,
      resumeId,
      sk_updated_at: "now",
    });
    return data.data.results.data;
  }
);

export const createSkillsThunk = createAsyncThunk(
  "skill/createSkills",
  async ({ token, resumeId }, thunkAPI) => {
    let data = await axios.post(`${endPoints.createSkills}`, {
      token,
      resumeId,
      sk_created_at: "now",
      sk_updated_at: "now",
    });
    return data.data.results.data;
  }
);

export const skillSlice = createSlice({
  name: "skill",
  initialState,
  reducers: {
    updateSkills: (state, { payload }) => {
      state.skills = payload;
    },
  },
  extraReducers: {
    [getSkillsThunk.pending]: (state, { payload }) => {
      state.pending = true;
    },
    [getSkillsThunk.fulfilled]: (state, { payload }) => {
      state.pending = false;
      if (payload) {
        state.personal.firstname = payload.pd_first_name;
        state.personal.lastname = payload.pd_last_name;
        state.personal.email = payload.pd_email_id;
        state.personal.city = payload.pd_city;
        state.personal.mobile = payload.pd_contact_number;
        state.personal.linkedin = payload.pd_linkedin_address;
        state.personal.state = payload.pd_state;
        state.personal.website = payload.pd_any_other_website;
        state.personal.summary = payload.pd_summary;
        state.personal.country = payload.pd_country;
      }
    },
    [getSkillsThunk.rejected]: (state, { payload }) => {
      state.pending = false;
      state.error = payload;
    },

    [updateSkillsThunk.pending]: (state, { payload }) => {
      state.pending = false;
      state.error = payload;
    },
    [updateSkillsThunk.fulfilled]: (state, { payload }) => {
      state.pending = false;
      state.error = payload;
    },
    [updateSkillsThunk.rejected]: (state, { payload }) => {
      state.pending = false;
      state.error = payload;
    },

    [createSkillsThunk.pending]: (state, { payload }) => {
      state.pending = false;
      state.error = payload;
    },
    [createSkillsThunk.fulfilled]: (state, { payload }) => {
      state.pending = false;
      state.error = payload;
    },
    [createSkillsThunk.rejected]: (state, { payload }) => {
      state.pending = false;
      state.error = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateSkills } = skillSlice.actions;

export default skillSlice.reducer;
