import axios from "axios";
import { GoogleLogin } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import endPoints from "../../../../config/endPoint";
import { checkUserPlanThunk } from "../../../../redux/counterSlice";
import googleSocialLoginStyles from "../../../../styles/pages/Login/components/google.styles";

function GoogleSocialLogin() {
  const classes = googleSocialLoginStyles();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const utm = useSelector((state) => state.UTM);

  const onSuccess = (res) => {
    // console.log("Login Success: currentUser:", res.profileObj);
    axios
      .post(`${endPoints.addUserWithSocial}`, {
        userId: res.googleId,
        first_name: res.profileObj.givenName,
        last_name: res.profileObj.familyName,
        email: res.profileObj.email,
      })
      .then((r) => {
        axios
          .post(endPoints.isSocialAuth, {
            userId: r.data.userId,
          })
          .then((response) => {
            localStorage.setItem("token", response.data.results.token);
            dispatch(
              checkUserPlanThunk({
                token: response.data.results.token,
                email: res.profileObj.email,
              })
            );
            if (Object.values(utm).find((v) => v !== "") && r.data.isNewUTM) {
              axios.post(`${endPoints.registerUTM}`, {
                ...utm,
                token: response.data.results.token,
              });
            }

            navigate("/dashboard");
          });
      });
  };

  const onFailure = (res) => {
    // console.log("Login failed: res:", res);
  };

  return (
    <div className={classes.btnContainer}>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Log In with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        className={classes.loginBtn}
      />
    </div>
  );
}

export default GoogleSocialLogin;
