import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import endPoints from "../config/endPoint";

const initialState = {
  user_limit: 0,
  limit_used: 0,
  completion: 0,
  user_plan_limits: [],
};

export const getLimitThunk = createAsyncThunk(
  "counter/getLimit",
  async ({ token }) => {
    let { data } = await axios.post(`${endPoints.checkLimit}`, { token });
    return data.results[0];
  }
);

export const checkUserPlanThunk = createAsyncThunk(
  "counter/checkUserThunk",
  async ({ token, email, phone }) => {
    let userPlanData = await axios.post(endPoints.checkUserPlanOrCreate, {
      token: token,
      email,
      phone,
    });

    return userPlanData.data.results;
  }
);

export const updatePlanFeatureUsageThunk = createAsyncThunk(
  "counter/updatePlanFeatureUsageThunk",
  async ({ token, featureName }, thunkAPI) => {
    let state = thunkAPI.getState().counter;
    let dataToUpdate = state.user_plan_limits.find(
      (pl) => pl.feature_name === featureName
    );
    let { data } = await axios.patch(endPoints.updatePlanFeatureUsage, {
      token,
      ...dataToUpdate,
      feature_usage: dataToUpdate.limit_used + 1,
    });

    return state.user_plan_limits.map((lm) => {
      if (lm.pf_id !== data.results.pf_id) {
        return lm;
      } else {
        return {
          ...state.user_plan_limits.find(
            (pl) => pl.feature_name === featureName
          ),
          limit_used: data.results.feature_usage,
        };
      }
    });
  }
);
export const updateLimitThunk = createAsyncThunk(
  "counter/getLimit",
  async ({ token }, thunkAPI) => {
    let state = thunkAPI.getState().counter;
    let { data } = await axios.post(`${endPoints.updateLimit}`, {
      token,
      gpt_api_call_mcnt: state.limit_used + 1,
    });
    return data.results[0];
  }
);

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state, action) => {
      state.limit_used += 1;
    },
    setLimit: (state, action) => {
      state.user_limit = action.payload.user_limit;
      state.limit_used = action.payload.limit_used;
    },
    setCompletion: (state, action) => {
      state.completion += action.payload;
    },
  },
  extraReducers: {
    [getLimitThunk.pending]: (state) => {
      state.loading = true;
    },
    [getLimitThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user_limit = payload.gpt_api_call_limit;
      state.limit_used = payload.gpt_api_call_mcnt;
    },
    [getLimitThunk.rejected]: (state) => {
      state.loading = false;
      state.error = "Can't Fetch Limit";
    },
    [checkUserPlanThunk.pending]: (state) => {
      state.loading = true;
    },
    [checkUserPlanThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.current_plan = payload[0].current_plan;
      state.plan_start_date = payload[0].plan_start_date;
      state.plan_end_date = payload[0].plan_end_date;
      state.instUserId = payload[0].instUserId;
      state.user_plan_limits = payload;
    },
    [checkUserPlanThunk.rejected]: (state) => {
      state.loading = false;
    },
    [updatePlanFeatureUsageThunk.pending]: (state) => {
      state.loading = true;
    },
    [updatePlanFeatureUsageThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user_plan_limits = payload;
    },
    [updatePlanFeatureUsageThunk.rejected]: (state) => {
      state.loading = false;
    },
    [updateLimitThunk.pending]: (state) => {
      state.loading = true;
    },
    [updateLimitThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user_limit = payload.gpt_api_call_limit;
      state.limit_used = payload.gpt_api_call_mcnt;
    },
    [updateLimitThunk.rejected]: (state) => {
      state.loading = false;
      state.error = "Can't Fetch Limit";
    },
  },
});

// Action creators are generated for each case reducer function
export const { increment, setLimit, setCompletion } = counterSlice.actions;

export default counterSlice.reducer;
