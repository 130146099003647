import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import endPoints from "../../../../config/endPoint";
import DashboardCard from "./DashboardCard";
import DashboardScoreCard from "./DashboardScoreCard";

const DashboardHeaderFinish = ({ inputData, setScoreModal, ...props }) => {
  const [scores, setscores] = useState({
    academic: "-",
    impact: "-",
    inititaive: "-",
    overallscore: "-",
    overused_wd_count: "-",
    overused_wd_list: {},
    projectinternship: "-",
    skills: "-",
  });

  const { resumeId } = useParams();
  const userPlan = useSelector((state) => state.counter);

  useEffect(() => {
    async function fetchScores() {
      try {
        if (!inputData.resumeId) {
          axios
            .get(`${endPoints.getScores}/${resumeId}/${userPlan.instUserId}`)
            .then((r) =>
              setscores({
                academic: r.data.sc_api_academic,
                impact: r.data.sc_api_impact,
                inititaive: r.data.sc_api_inititaive,
                overallscore: r.data.sc_api_overall,
                overused_wd_count: "-",
                overused_wd_list: {},
                projectinternship: r.sc_api_experience,
                skills: r.sc_api_skills,
              })
            );
        } else {
          let response = await axios.post(
            `${endPoints.getScores}/${inputData.resumeId}/${userPlan.instUserId}`,
            inputData
          );
          setscores(response.data.data);
          setScoreModal(response.data.data);
        }
      } catch (err) {}
    }
    fetchScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);

  return (
    <Box
      display="flex"
      gap={4}
      width="fit-content"
      alignItems="center"
      sx={{
        borderRadius: "15px",
        margin: "10px auto",
      }}
    >
      <DashboardCard value={scores?.overallscore} property="SCORE" {...props} />
      <DashboardScoreCard value={scores?.impact} property="IMPACT" {...props} />
      <DashboardScoreCard
        value={scores?.inititaive}
        property="INITIATIVE"
        {...props}
      />
      <DashboardScoreCard value={scores?.skills} property="SKILLS" {...props} />
      <DashboardScoreCard
        value={scores?.academic}
        property="ACADEMIC"
        {...props}
      />
      <DashboardScoreCard
        value={scores?.experience}
        property="EXPERIENCES"
        {...props}
      />
    </Box>
  );
};

export default DashboardHeaderFinish;
