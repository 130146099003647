import { makeStyles } from "@mui/styles";

const checkOutStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px 10px",
    paddingTop: "20px",
    width: "66%",
    borderTop: `5px solid ${theme.palette.warning.dark}`,
    boxShadow: theme.customShadows.primary,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "30%",
    },
  },
}));

export default checkOutStyles;
