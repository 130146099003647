import { Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import * as React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "6rem",
    height: "8rem",
    boxShadow: " 2px 5px 15px rgb(0 0 0 / 17%)",
    [theme.breakpoints.down("sm")]: {
      width: "4rem",
      height: "5rem",
    },
  },
  lockIcon: {
    margin: "0px 10px",
  },
  radioGrp: {
    display: "flex",
    overflow: "auto",
    gap: 40,
    paddingLeft: 20,
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "row",
      flexWrap: "inherit",
    },
  },
  img: {
    objectFit: "cover",
  },
  paper: {
    width: "100%",
    borderRadius: "15px",
    minWidth: "140px",
    cursor: "pointer",
    margin: "10px 0",
    padding: 20,
    "& .description": {
      borderBottom: "1px solid lightgrey",
    },
    "&:hover": {
      boxShadow: " 2px 5px 15px rgb(0 0 0 / 17%)",
    },
    "& .image": {
      width: "100%",
    },
  },

  selected: {
    border: `2px solid ${theme.palette.primary.dark}`,
    background: "#0c2c651a",
    "& .image": {
      width: "100%",
      position: "relative",
      "&:before": {
        content: '""',
        background: "#0c2c651a",
        width: "100%",
        height: "100%",
        position: "absolute",
      },
    },

    "& .icon": {
      color: theme.palette.primary.main,
      position: "absolute",
      fontSize: "36px",
      top: "calc(50% - 18px)",
      left: "calc(50% - 18px)",
    },
  },
  locked: {
    color: theme.palette.grey[500],
    border: `2px solid ${theme.palette.grey[400]}`,
    background: theme.palette.grey[200],
    "& .image": {
      width: "100%",
      position: "relative",
      "&:before": {
        content: '""',
        background: "#dfe3e833",
        width: "100%",
        height: "100%",
        position: "absolute",
      },
    },

    "& .lockIcon": {
      color: theme.palette.error.darker,
      position: "absolute",
      fontSize: "36px",
      top: "calc(50% - 18px)",
      left: "calc(50% - 18px)",
    },
  },
  radioContainer: {
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      gap: 20,
    },
  },

  span2: {
    background: "#ddd",
  },
  templateHead: {
    paddingTop: 10,
    fontSize: "14px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  p: {
    maxWidth: 200,
    fontSize: "11px",
  },
}));

export default function RadioButtonsCustom({
  template,
  setTemplate,
  style,
  setHTMLTemplate,
  setIsOpen,
  internal,
}) {
  const classes = useStyles();

  const userPlan = useSelector((state) => state.counter);
  return (
    <div className={classes.radioContainer} style={style}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((radio, idx) => (
        <Paper
          key={idx}
          className={classNames(
            classes.paper,
            template === radio ? classes.selected : "",
            userPlan.current_plan === "VA Executive" && radio > 3
              ? classes.locked
              : userPlan.current_plan === "VA Professional" && radio > 5
              ? classes.locked
              : ""
          )}
          onClick={(e) => {
            if (internal) {
              setTemplate(radio);
            } else {
              if (userPlan.current_plan === "VA Executive" && radio <= 3) {
                setTemplate(radio);
              } else if (
                userPlan.current_plan === "VA Professional" &&
                radio <= 5
              ) {
                setTemplate(radio);
              } else if (userPlan.current_plan === "VA Platinum") {
                setTemplate(radio);
              } else {
                setIsOpen(true);
              }
            }
          }}
        >
          <Typography variant="h5" className="name">
            Template {radio}
          </Typography>
          <Typography variant="body2" className="description">
            {[1, 2, 3].includes(radio)
              ? "Executive"
              : [4, 5].includes(radio)
              ? "Professional / Platinum"
              : "Platinum"}
          </Typography>
          <div className="image">
            {" "}
            <img
              src={`/static/t${radio}-thumbnail.jpeg`}
              width="100%"
              alt="not-found"
            />
            {userPlan.current_plan === "VA Executive" && radio > 3 ? (
              <span className="lockIcon">
                <FaLock />
              </span>
            ) : userPlan.current_plan === "VA Professional" && radio > 5 ? (
              <span className="lockIcon">
                <FaLock />
              </span>
            ) : (
              ""
            )}
            {template === radio ? (
              <span className="icon">
                <BsPatchCheckFill />
              </span>
            ) : (
              ""
            )}
          </div>
        </Paper>
      ))}
    </div>
  );
}
