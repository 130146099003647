import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import endPoints from "../../config/endPoint";

const initialState = {
  loading: false,
  hobbies: [],
  languages: [],
};

// First, create the thunk
export const getExtracurricularThunk = createAsyncThunk(
  "extracurricular/getExtracurricular",
  async ({ token, resume_id }, thunkAPI) => {
    const { data } = await axios.post(`${endPoints.getExtracurricular}`, {
      token,
      resume_id,
    });
    return data.results;
  }
);

export const createHobbiesThunk = createAsyncThunk(
  "extracurricular/createHobby",
  async (body, thunkAPI) => {
    const { data } = await axios.post(`${endPoints.createExtracurricular}`, {
      ...body,
      ec_created_at: "now",
      ec_updated_at: "now",
    });
    return data.results[0];
  }
);

export const createLanguagesThunk = createAsyncThunk(
  "extracurricular/createLanguage",
  async (body, thunkAPI) => {
    const { data } = await axios.post(`${endPoints.createExtracurricular}`, {
      ...body,
      ec_created_at: "now",
      ec_updated_at: "now",
    });
    return data.results[0];
  }
);

export const deleteExtraCurricularThunk = createAsyncThunk(
  "extracurricular/deleteExtracurricular",
  async ({ token, resume_id, ec_id }, thunkAPI) => {
    const state = thunkAPI.getState().extracurricular;
    const { data } = await axios.delete(`${endPoints.deleteExtracurricular}`, {
      data: {
        token,
        resume_id,
        ec_id: ec_id,
        del_flag: 1,
        ec_updated_at: "now",
      },
    });
    return {
      hobbies: state.hobbies.filter(
        (hob) => hob.ec_id !== data.results[0].ec_id
      ),
      languages: state.languages.filter(
        (hob) => hob.ec_id !== data.results[0].ec_id
      ),
    };
  }
);

export const dashboardSlice = createSlice({
  name: "extracurricular",
  initialState,
  reducers: {
    updateResumes: (state, { payload }) => {
      state.resumes = payload;
    },
  },
  extraReducers: {
    [getExtracurricularThunk.pending]: (state) => {
      state.loading = true;
    },
    [getExtracurricularThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hobbies = payload.filter((dat) => dat.ec_type === "HO");
      state.languages = payload.filter((dat) => dat.ec_type === "LG");
    },
    [getExtracurricularThunk.rejected]: (state) => {
      state.loading = false;
    },

    [deleteExtraCurricularThunk.pending]: (state) => {
      state.loading = false;
    },
    [deleteExtraCurricularThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hobbies = payload.hobbies;
      state.languages = payload.languages;
    },
    [deleteExtraCurricularThunk.rejected]: (state) => {
      state.loading = false;
    },

    [createHobbiesThunk.pending]: (state) => {
      state.loading = false;
    },
    [createHobbiesThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hobbies = [...state.hobbies, payload];
    },
    [createHobbiesThunk.rejected]: (state) => {
      state.loading = false;
    },
    [createLanguagesThunk.pending]: (state) => {
      state.loading = false;
    },
    [createLanguagesThunk.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.languages = [...state.languages, payload];
    },
    [createLanguagesThunk.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateResumes } = dashboardSlice.actions;

export default dashboardSlice.reducer;
