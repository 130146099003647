import { Grid } from "@mui/material";
import React from "react";
import CustomInputAndSelectField from "../../../../../components/CustomInputAndSelectField/Index";
import CustomInputField from "../../../../../components/CustomInputField/Index";
import {
  boardsList,
  majorsList,
} from "../../../../../constants/education.data";

export default function ClassSubComponent({
  value,
  onSpecificChange,
  handleChange,
}) {
  return (
    <>
      <Grid xs={12} item>
        <CustomInputAndSelectField
          value={value.ed_major_special}
          changeCallBack={(e, v) => {
            onSpecificChange("ed_major_special", v);
            handleChange(
              {
                name: "ed_board_name",
                value: "",
              },
              true
            );
          }}
          label={"Subject"}
          options={majorsList.map((maj) => maj.lib_degspez_degree).sort()}
          name="ed_major_special"
          required={true}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <CustomInputAndSelectField
          value={value.ed_board_name}
          changeCallBack={(e, v) => onSpecificChange("ed_board_name", v)}
          label={"Board"}
          options={boardsList.map((b) => b.label).sort()}
          name="ed_board_name"
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <CustomInputField
          label={"School Name"}
          value={value.ed_school_name}
          onChange={(e) => onSpecificChange("ed_school_name", e.target.value)}
          required={true}
          name="ed_school_name"
          fullWidth
        />
      </Grid>
    </>
  );
}
