import { CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import classNames from "classnames";
import React from "react";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import CustomCard from "../../../components/CustomCard/Index";
import CustomIconButton from "../../../components/CustomIcon/Index";
import CustomInputAndSelectField from "../../../components/CustomInputAndSelectField/Index";
import CustomInputField from "../../../components/CustomInputField/Index";
import CustomNotification from "../../../components/CustomNotification/Index";
import CustomPill from "../../../components/CustomPill/Index";
import endPoints from "../../../config/endPoint";
import { updateSkills } from "../../../redux/Dashboard/skill.slice";
import SkillsStyles from "../../../styles/pages/Resume/components/skills.styles";
import shorten from "../../../utils/shorten";

export default function SkillsComponent({
  onSpecificChange,
  handleChange,
  bulkUpdate,
  value = {},
}) {
  const classes = SkillsStyles();
  const [data, setData] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [skillsType, setSkillsType] = React.useState([]);
  const [type, setType] = React.useState("");
  const [extraSkill, setExtraSkill] = React.useState("");
  const token = localStorage.getItem("token");
  const [isLoading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const allSkills = useSelector((state) => state.skill.skills);
  const [notification, setNotification] = React.useState({
    severity: "",
    open: false,
    alertMessage: "",
  });
  const [error, setError] = React.useState("");
  const { resumeId } = useParams();

  React.useEffect(() => {
    async function fetchSkillType() {
      let skillsData = await axios.post(`${endPoints.getSkillType}`, { token });
      if (!skillsData) setLoading(false);
      setSkillsType(skillsData.data.results);
      let fetchedSkills = await axios.post(
        `${endPoints.getSkills}/${resumeId}`,
        { token }
      );
      dispatch(updateSkills(fetchedSkills.data.results));
      bulkUpdate(fetchedSkills.data.results);
      setLoading(false);
    }
    fetchSkillType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading)
    return (
      <div className={classes.loader}>
        <CircularProgress disableShrink size={30} thickness={6} />
      </div>
    );
  return (
    <>
      <CustomNotification
        severity={notification.severity}
        variant="filled"
        alertMessage={notification.alertMessage}
        open={notification.open}
        onClose={(e) =>
          setNotification({
            severity: "",
            open: false,
            alertMessage: "",
          })
        }
      />
      <Grid className={classes.container} container spacing={5} rowGap={4}>
        <Grid item sm={6} xs={12} className={classes.contentLeft}>
          <Box id="cardContainer">
            {/* <Paper
              style={{
                background: "#eee",
                marginBottom: "20px",
              }}
            >
              <iframe
                style={{
                  width: "100%",
                }}
                height="315"
                src="https://www.youtube.com/embed/LPFZyCun8iI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Paper> */}
            {!type ? (
              <Typography className={classes.typography} variant="h5">
                Choose a category and start adding your Skills
              </Typography>
            ) : (
              <>
                <CustomCard
                  className={classes.cardList}
                  dividerText={`${skills.length} skills`}
                  actionContent={
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        padding: "10px 0px",
                      }}
                    >
                      Suggested Skills
                    </p>
                  }
                  bodyStyle={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 10,
                    padding: 0,
                    paddingBottom: "24px",
                  }}
                  bodyContent={skills
                    .map((a) => (
                      <CustomPill
                        value={shorten(a.lib_sk_name, 50)}
                        clickable={true}
                        onClick={(e) => {
                          if (
                            value[type].findIndex(
                              (el) => el.label === a.lib_sk_name
                            ) === -1
                          ) {
                            axios.post(
                              `${endPoints.addSingleSkill}/${resumeId}`,
                              {
                                label: a.lib_sk_name,
                                token,
                                category: {
                                  label: type,
                                },
                              }
                            );
                            setData([
                              ...data,
                              {
                                label: a.lib_sk_name,
                                category: {
                                  label: a.lib_sk_type,
                                },
                              },
                            ]);
                            onSpecificChange(type, [
                              ...value[type],
                              {
                                label: a.lib_sk_name,
                                category: {
                                  label: a.lib_sk_type,
                                },
                              },
                            ]);
                            dispatch(
                              updateSkills({
                                ...allSkills,
                                [a.lib_sk_type]: [
                                  ...value[type],
                                  {
                                    label: a.lib_sk_name,
                                    category: {
                                      label: a.lib_sk_type,
                                    },
                                  },
                                ],
                              })
                            );
                          } else {
                          }
                        }}
                      />
                    ))
                    .sort((a, b) => {
                      let fa = a?.props?.value?.toLowerCase(),
                        fb = b?.props?.value?.toLowerCase();

                      if (fa < fb) {
                        return -1;
                      }
                      if (fa > fb) {
                        return 1;
                      }
                      return 0;
                    })}
                />
                {/* <p /> */}
                {/* <CustomCard
                  className={classes.cardList}
                  dividerText={`${skills.length} skills`}
                  actionContent={
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        padding: "10px 0px",
                      }}
                    >
                      Recommended Skills
                    </p>
                  }
                  bodyStyle={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 10,
                  }}
                  bodyContent={skills.map((a) => (
                    <CustomPill
                      value={shorten(a.lib_sk_name, 15)}
                      clickable={true}
                      onClick={(e) => {
                        if (
                          value[type].findIndex(
                            (el) => el.label === a.lib_sk_name
                          ) === -1
                        ) {
                          axios.post(
                            `${endPoints.addSingleSkill}/${resumeId}`,
                            {
                              label: a.lib_sk_name,
                              token,
                              category: {
                                label: type,
                              },
                            }
                          );
                          setData([
                            ...data,
                            {
                              label: a.lib_sk_name,
                              category: {
                                label: a.lib_sk_type,
                              },
                            },
                          ]);
                          onSpecificChange(type, [
                            ...value[type],
                            {
                              label: a.lib_sk_name,
                              category: {
                                label: a.lib_sk_type,
                              },
                            },
                          ]);
                        } else {
                        }
                      }}
                    />
                  ))}
                /> */}
              </>
            )}
          </Box>
          {/* <>
                    <CircularProgress disableShrink size={30} thickness={6}
                />
                Loading...
                </> */}
        </Grid>
        <Grid item sm={6} xs={12} container className={classes.contentRight}>
          <Grid sm={12} xs={12} item>
            <CustomInputAndSelectField
              value={type}
              className={"uap_skills_cat_dropdown"}
              changeCallBack={(e, v) => {
                setType(v);
                axios
                  .post(`${endPoints.getLibSkills}`, {
                    token,
                    skillType: v,
                    approveStatus: "0",
                  })
                  .then((r) => setSkills(r.data.results));
              }}
              label={"Select a Category"}
              fullWidth
              helperText="Select an appropriate skill category"
              options={skillsType.map((skill) => skill.label).sort()}
              freeSolo={false}
            />
          </Grid>
          <Grid sm={12} xs={12} item className={classes.skillField}>
            <CustomInputField
              label={"Skills"}
              fullWidth
              value={extraSkill}
              error={error}
              onChange={(e) => {
                if (
                  e.target.value.match(/^[a-zA-Z0-9.&\s]*$/) &&
                  e.target.value.length <= 40
                ) {
                  setError("");
                  setExtraSkill(e.target.value);
                }
              }}
              helperText={
                error ? error : "Add your skills based on the category above"
              }
              freeSolo={false}
              onKeyDown={(e) => {
                if (extraSkill) {
                  if (type) {
                    if (e.key === "Enter") {
                      if (
                        value[type].findIndex(
                          (el) => el.label === extraSkill
                        ) === -1
                      ) {
                        axios.post(`${endPoints.addSingleSkill}/${resumeId}`, {
                          label: extraSkill,
                          token: token,
                          category: {
                            label: type,
                          },
                        });
                        onSpecificChange(type, [
                          ...value[type],
                          {
                            label: extraSkill,
                            category: {
                              label: type,
                            },
                          },
                        ]);
                        setExtraSkill("");
                        dispatch(
                          updateSkills({
                            ...allSkills,
                            [type]: [
                              ...value[type],
                              {
                                label: extraSkill,
                                category: {
                                  label: type,
                                },
                              },
                            ],
                          })
                        );
                      } else {
                        setError("Already added to the list");
                      }
                    }
                  } else {
                    setNotification({
                      severity: "error",
                      alertMessage: "Please select some skill category",
                      open: true,
                    });
                  }
                }
              }}
              endAdornment={
                <CustomIconButton
                  style={{
                    borderRadius: "inherit",
                  }}
                  icon={
                    <span className={classes.iconText}>
                      Add Skill <FaPlusCircle className={classes.icon} />
                    </span>
                  }
                  onClick={() => {
                    if (extraSkill) {
                      if (type) {
                        if (
                          value[type].findIndex(
                            (el) => el.label === extraSkill
                          ) === -1
                        ) {
                          axios.post(
                            `${endPoints.addSingleSkill}/${resumeId}`,
                            {
                              label: extraSkill,
                              token: token,
                              category: {
                                label: type,
                              },
                            }
                          );
                          onSpecificChange(type, [
                            ...value[type],
                            {
                              label: extraSkill,
                              category: {
                                label: type,
                              },
                            },
                          ]);
                          dispatch(
                            updateSkills({
                              ...allSkills,
                              [type]: [
                                ...value[type],
                                {
                                  label: extraSkill,
                                  category: {
                                    label: type,
                                  },
                                },
                              ],
                            })
                          );
                          setExtraSkill("");
                        } else {
                          setError("Already added to the list");
                        }
                      } else {
                        setNotification({
                          severity: "error",
                          alertMessage: "Please select some skill category",
                          open: true,
                        });
                      }
                    }
                  }}
                />
              }
            />
          </Grid>
          <Grid item sm={12}>
            {Object.keys(value).length > 0
              ? Object.keys(value).map((a) => (
                  <Grid
                    className={classNames(
                      "skills_cat",
                      `skills_cat_${a?.slice(0, 3)}`
                    )}
                    sm={12}
                    xs={12}
                    item
                    style={{
                      marginTop: 35,
                      marginBottom: 10,
                    }}
                  >
                    {typeof value[a] === "object" && value[a]?.length > 0 ? (
                      <>
                        <Typography>{a}</Typography>
                        <Typography
                          variant="div"
                          className={classNames(
                            classes.skillContainer,
                            "skills_cat_span"
                          )}
                        >
                          {value[a].map((val, idx) => (
                            <CustomPill
                              variant="contained"
                              color="primary"
                              className={`skills_chip_${idx}`}
                              value={val.label}
                              onDelete={(e) => {
                                axios.delete(
                                  `${endPoints.deleteSkills}/${resumeId}`,
                                  {
                                    data: {
                                      skills: value[a]
                                        .filter((sk) => sk.label === val.label)
                                        .map((sk) => ({
                                          resume_id: resumeId,
                                          sk_id: sk.key,
                                          sk_updated_at: "now",
                                          del_flag: 1,
                                        })),
                                      token: token,
                                    },
                                  }
                                );
                                onSpecificChange(
                                  a,
                                  value[a].filter(
                                    (sk) => sk.label !== val.label
                                  )
                                );
                                dispatch(
                                  updateSkills({
                                    ...allSkills,
                                    [a]: value[a].filter(
                                      (sk) => sk.label !== val.label
                                    ),
                                  })
                                );
                              }}
                            />
                          ))}
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                ))
              : ""}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
