import { makeStyles } from "@mui/styles";

const JobDetailStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    padding: 10,
    fontSize: 17,
  },
  heading: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  subHeading: {
    fontSize: "16px",
  },
  strongRole: {
    fontSize: "20px",
    marginTop: 30,
  },
  role: {
    marginTop: 60,
  },
  jobContainer: {
    padding: 30,
    background: "#f7f7f7",
    height: "fit-content",
    [theme.breakpoints.down("lg")]: {
      marginTop: 50,
      padding: 0,
      marginBottom: 70,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 50,
      padding: 0,
    },
  },
  jobHead: {
    textAlign: "left",
    padding: 25,
    boxShadow:
      "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)",
  },
  jobDesc: {
    textAlign: "left",
    padding: 25,
    boxShadow:
      "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)",
    "& .olDesc": {
      padding: 20,
      //   "& li": {
      //     marginBottom: 20,
      //   },
    },
  },
  jobInterest: {
    height: "200px",
    padding: 15,
    boxShadow:
      "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  jobMore: {
    height: "200px",
    boxShadow:
      "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)",
  },
  divider: {
    marginTop: "10px",
  },
  jobHeadInfo: {
    display: "flex",
    flexDirection: "column",
    "& span": {
      fontSize: "14px",
      color: "#666",
    },
  },
  ul: {
    marginLeft: 40,
    "& li": {
      margin: "5px 0px",
    },
  },
  formHeader: {
    padding: 20,
    margin: "50px auto",
    marginBottom: 0,
    textAlign: "center",
    // background: "#FF9900",
    textTransform: "uppercase",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  emailContent: {
    background: "",
    textAlign: "center",
    "& p": {
      color: "#000",
      padding: "0px 30px",
    },
    "& .head-content": {
      padding: "35px 0px 25px 0px",
    },
    "& .numberField": {
      marginTop: "20px",
      maxWidth: "80%",
      borderWidth: "1px",
      boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
      borderColor: "white",
      "& .MuiOutlinedInput-root": {
        borderRadius: "1px",
      },
    },
    "& .otpField": {
      marginTop: "1rem",
      maxWidth: "40%",
      borderWidth: "1px",
      boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
      borderColor: "white",
      "& .MuiOutlinedInput-root": {
        borderRadius: "1px",
      },
    },
    "& .middle-content": {
      padding: "20px 0px 10px 0px",
    },
  },
  emailFooter: {
    width: "100%",
    textAlign: "center",
  },
  formContainer: {
    padding: 20,
    margin: "50px auto",
    marginTop: 0,
    background: "#f7f7f7",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default JobDetailStyles;
