// import Heading from "../components/Heading";
import { Box, FormControlLabel, Grid, Stack } from "@mui/material";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import React from "react";
// import RadioButtonsCustom from "./Radio";
import Template1 from "./Template1";
// import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import Template5 from "./Template5";
import Template6 from "./Template6";
import Template7 from "./Template7";
import Template8 from "./Template8";
import Template9 from "./Template9";
// import { PDFViewer } from "@react-pdf/renderer";

const useStyles = makeStyles((theme) => ({
  resumeContainer: {
    display: "flex",
    margin: "0 auto",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      width: "fit-content",
    },
  },
}));

const ResumePreviewer = ({
  inputData,
  isLogo,
  setIsLogo,
  TempRef,
  setShow,
  setTemplateData,
  show,
  template,
  setHeight,
  url,
  setUrl,
}) => {
  const classes = useStyles();

  // const renderTemplate = (temp) => {
  //   switch (temp) {
  //     case 1:
  //       return (
  //         <Resume1
  //           inputData={inputData}
  //           isLogo={isLogo}
  //           setIsLogo={setIsLogo}
  //           setShow={setShow}
  //           htmlData={inputData.htmlData}
  //         />
  //       );
  //     case 2:
  //       return (
  //         <Resume2
  //           inputData={inputData}
  //           isLogo={isLogo}
  //           setIsLogo={setIsLogo}
  //           setShow={setShow}
  //         />
  //       );
  //     case 3:
  //       return (
  //         <Resume3
  //           inputData={inputData}
  //           isLogo={isLogo}
  //           setIsLogo={setIsLogo}
  //           setShow={setShow}
  //         />
  //       );
  //     case 4:
  //       return (
  //         <Resume4
  //           inputData={inputData}
  //           isLogo={isLogo}
  //           setIsLogo={setIsLogo}
  //           setShow={setShow}
  //         />
  //       );
  //     case 5:
  //       return (
  //         <Resume5
  //           inputData={inputData}
  //           isLogo={isLogo}
  //           setIsLogo={setIsLogo}
  //           setShow={setShow}
  //         />
  //       );
  //     case 6:
  //       return (
  //         <Resume6
  //           inputData={inputData}
  //           isLogo={isLogo}
  //           setIsLogo={setIsLogo}
  //           setShow={setShow}
  //         />
  //       );

  //     default:
  //       return (
  //         <Resume1
  //           inputData={inputData}
  //           isLogo={isLogo}
  //           setIsLogo={setIsLogo}
  //           setShow={setShow}
  //         />
  //       );
  //   }
  // };

  const renderPreview = (temp) => {
    switch (temp) {
      case 1:
        return (
          <Template1
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
            url={url}
            setUrl={setUrl}
          />
        );
      case 2:
        return (
          <Template2
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );
      case 3:
        return (
          <Template3
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );

      case 4:
        return (
          <Template4
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );

      case 5:
        return (
          <Template5
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );
      case 6:
        return (
          <Template6
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );
      case 7:
        return (
          <Template7
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );
      case 8:
        return (
          <Template8
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );
      case 9:
        return (
          <Template9
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
            setTemplateData={setTemplateData}
          />
        );
      default:
        return (
          <Template1
            ref={TempRef}
            inputData={inputData}
            isLogo={isLogo}
            setIsLogo={setIsLogo}
            setShow={setShow}
          />
        );
    }
  };

  return (
    <div className={classes.resumeContainer}>
      <div
        style={{
          flex: 1,
        }}
      >
        <Box
          px={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2}>
            {show && template === 1 && (
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked
                    checked={isLogo}
                    onChange={(e) => {
                      setIsLogo(e.target.checked);
                    }}
                  />
                }
                label="View College Logo"
              />
            )}

            {/* <Button
            variant="contained"
            color="secondary"
            sx={{ borderRadius: 100, width: 125 }}
            onClick={downloadResume}
          >
            Download
          </Button> */}

            {/* <ReactToPrint trigger={ () => <Button
            variant="contained"
            color="secondary"
            sx={{ borderRadius: 100, width: 125 }}
            // onClick={downloadResume}
          >
            Download
          </Button> } 
          content={ () => TempRef.current }
          /> */}
          </Stack>
        </Box>

        <Grid>
          <div
            id="resume"
            style={{
              position: "relative",
              margin: "0 auto",
              width: "21cm",
              minHeight: "29.7cm",
              padding: "1.27cm",
              background: "white",
            }}
          >
            {/* <PDFViewer
                style={{
                  width: "100%",
                  height: "100vh",
                }}
              >
                {renderTemplate(template)}
              </PDFViewer> */}
            {renderPreview(template)}
          </div>
        </Grid>
        {/* <Template1 /> */}
      </div>
    </div>
  );
};

export default ResumePreviewer;
