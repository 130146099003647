let sortingArr = [
    "Full time",
    "Project",
    "Internship",
    "Part time",
    "Positions of Responsibility",
    "Freelance",
  ]
  
  function find_duplicate_in_array(array){
    const count = {}
    
    array.forEach(item => {
        if (count[item.typeOfWork]) {
           count[item.typeOfWork] = [...count[item.typeOfWork], item]
           return
        }
        count[item.typeOfWork] = [item]
    })
    return Object.values(count).sort((a, b) => {
      return sortingArr.indexOf(a[0].typeOfWork) - sortingArr.indexOf(b[0].typeOfWork)
    })
    
    }


export default find_duplicate_in_array