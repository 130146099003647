import { Grid } from "@mui/material";
import React from "react";
import { FaPlusCircle } from "react-icons/fa";
import CustomButton from "../../../../../components/CustomButton/Index";
import CustomDatePicker from "../../../../../components/CustomDateField/Index";
import CustomInputField from "../../../../../components/CustomInputField/Index";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  createAchievementThunk,
  updateAchievementThunk,
} from "../../../../../redux/Dashboard/achievement.slice";
import classNames from "classnames";

export default function Awards({
  onSpecificChange,
  classes,
  actionBtn,
  updateValue,
  value,
  clearInput,
  handleChange,
  setType,
  setCategory,
  setIsEditing,
}) {
  const dispatch = useDispatch();
  const { resumeId } = useParams();
  const token = localStorage.getItem("token");
  const handleSave = () => {
    dispatch(
      createAchievementThunk({
        token: token,
        resume_id: resumeId,
        ...value,
      })
    );
    clearInput();
    setType("");
    setCategory("");
  };

  const handleUpdate = () => {
    setIsEditing(false);
    dispatch(updateAchievementThunk({ body: value, token }));
    clearInput();
    setType("");
    setCategory("");
  };
  return (
    <>
      <Grid item sm={12} xs={12} className={classes.gridItem}>
        <CustomInputField
          value={value.ac_name}
          onChange={(e) => {
            if (e.target.value.length <= 200) {
              handleChange(e);
            }
          }}
          name={"ac_name"}
          label={"Description"}
          required={true}
          fullWidth
        />
      </Grid>
      <Grid item sm={12} xs={12} className={classes.gridItem}>
        <CustomDatePicker
          maxDate={new Date()}
          minDate={"1 Jan 1970"}
          value={value.ac_year ? value.ac_year : null}
          name={"ac_year"}
          changeCallBack={(e, v) => onSpecificChange("ac_year", v)}
          label={"MONTH/YEAR"}
          fullWidth
        />
      </Grid>
      <CustomButton
        disabled={!value.ac_name}
        text={updateValue ? "Update" : "Save"}
        onClick={updateValue ? handleUpdate : handleSave}
        className={
          updateValue
            ? classNames(actionBtn, "uap_award_update_btn")
            : classNames(actionBtn, "uap_award_save_btn")
        }
        variant={updateValue ? "outlined" : "contained"}
        color={updateValue ? "warning" : "primary"}
        icon={<FaPlusCircle />}
      />
      <div variant="body2" className={classes.helperDiv}>
        {updateValue ? "Update" : "Save"} your details before moving on
      </div>
    </>
  );
}
