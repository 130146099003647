import { Button } from "@mui/material";
import React from "react";

export default function CustomButton({ icon, text, actionCallBack, ...props }) {
  return (
    <Button onClick={actionCallBack} {...props}>
      {icon}
      {text}
    </Button>
  );
}
