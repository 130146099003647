const filterURL = (url = "") => {
  if (!url?.trim()) {
    return "";
  }
  //get the index of the start of the part of the URL we want to keep
  let index = url?.indexOf("in/"),
    //then get everything after the found index
    strOut = url?.substr(index);

  if (index === -1) {
    return "in/" + url;
  } else {
    return strOut;
  }
};

export default filterURL;
