import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Link as MUILink,
  Stack,
} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fade from "react-reveal/Fade";
import { useLocation, useNavigate } from "react-router";
import CustomButton from "../../../components/CustomButton/Index";
import endPoints from "../../../config/endPoint";
import { setUTMValue } from "../../../redux/utmSlice";
import loginStyles from "../../../styles/pages/Login/index.styles";
import gradient from "../makestyles/gradient";
import MobileNumber, { EmailField } from "./MobileNumber";
import OTPInput from "./OTPInput";
import TermsAndCondition from "./popup";
import GoogleLogin from "./SocialLogin/Google";
import Timer from "./Timer";
import { gapi } from "gapi-script";
import CustomTabPanel from "../../../components/CustomTabs";
import { checkUserPlanThunk } from "../../../redux/counterSlice";

const AUTH_URL = endPoints.apiGateWay;

const createId = (num) => {
  return Number(Number(num).toString(8));
};

const LoginForm = ({ setIsAuth, addMobileNo, adduserId }) => {
  const classes = loginStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const utm = useSelector((state) => state.UTM);

  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPSent, setOTPSent] = useState(false);
  const [resp, setResp] = useState({});
  const [loginMessage, setLoginMessage] = useState("");
  const [isError, setisError] = useState(false);
  const [isChecked, setChecked] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const parseParams = (querystring) => {
    // parse query string
    const params = new URLSearchParams(querystring);

    const obj = {};

    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }

    return obj;
  };
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  useEffect(() => {
    dispatch(setUTMValue(parseParams(location.search)));
  }, [dispatch, location.search]);

  const getOTPEmail = async () => {
    setLoading(true);
    const mailData = await axios.post(`${endPoints.sendEmail}`, {
      isRegister: true,
      email: email,
    });
    if (mailData) {
      setResp({
        ...resp,
        ...mailData.data,
      });
      setOTPSent(true);
      setLoading(false);
    }
  };

  const verifyOTPEmail = async () => {
    localStorage.clear();
    axios
      .post(`${endPoints.registerWithEmail}`, {
        token: resp.token,
        hash: resp.hash,
        otp: OTP,
        email,
      })
      .then((r) => {
        axios
          .post(`${endPoints.emailAuth}`, {
            userId: r.data.userId,
          })
          .then((response) => {
            dispatch(
              checkUserPlanThunk({
                token: response.data.results.token,
                email: email,
              })
            );
            if (Object.values(utm).find((v) => v !== "") && r.data.isNewUTM) {
              axios.post(`${endPoints.registerUTM}`, {
                ...utm,
                token: response.data.results.token,
              });
            }

            localStorage.setItem("token", response.data.results.token);
            navigate("/dashboard");
          });
      });
  };

  const resendOTPMail = async () => {
    axios
      .post(`${endPoints.resendMail}`, {
        email,
      })
      .then((r) => {
        setResp({
          ...resp,
          ...r.data,
        });

        setOTPSent(false);
        setOTPSent(true);
      });
  };

  const getOTP = async () => {
    try {
      const reqBody = { number: `+91${mobile}` };

      const response = await axios(`${AUTH_URL}/createOTP`, {
        method: "POST",
        data: reqBody,
      });
      setResp({
        ...reqBody,
        ...response.data.results,
      });

      setOTPSent(true);
    } catch (error) {}
  };

  const resendOTP = () => {
    setOTPSent(false);
    setisError(false);
    setLoginMessage("");
    setOTP("");
    getOTP();
  };

  const verifyOTP = async () => {
    try {
      const reqBody = {
        phone: `${resp.number}`,
        hash: `${resp.hash}`,
        otp: `${OTP}`,
      };

      setisError(false);

      const response = await axios(`${AUTH_URL}/verifyOTP`, {
        method: "POST",
        data: reqBody,
      }).catch((e) => {
        setisError(true);
        setLoginMessage(e.response?.data?.error);
      });
      if (response) {
        if (!response.data.success) {
          setisError(true);
        }

        if (response.data.success) {
          setLoginMessage(response.data.results.data);

          axios
            .post(`${endPoints.registerUser}`, { phone: resp.number })
            .then((r) => {
              dispatch(
                checkUserPlanThunk({
                  token: response.data.results.token,
                  phone: resp.number,
                })
              );
              if (Object.values(utm).find((v) => v !== "") && r.data.isNewUTM) {
                axios
                  .post(`${endPoints.registerUTM}`, {
                    ...utm,
                    token: response.data.results.token,
                  })
                  .then((r) => {})
                  .catch((e) => {});
              }
            });
          localStorage.setItem("token", response.data.results.token);
          navigate("/dashboard", { state: createId(resp.number.substr(3)) });
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <Fade>
        <Box
          mt={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
        >
          <GoogleLogin />
          <Divider className={classes.socialDivider}>OR</Divider>
          <Grid
            item
            xs={12}
            sm={10}
            md={9}
            lg={9}
            alignItems="center"
            className={classes.stack}
          >
            {/* <Heading heading="Log in" alignItems="center" /> */}

            <CustomTabPanel
              tabs={[
                {
                  label: "Login with Mobile",
                  data: (
                    <Stack spacing={5}>
                      <MobileNumber
                        className={"uap_mobile_number_input"}
                        mobile={mobile}
                        setMobile={setMobile}
                        OTPSent={OTPSent}
                        sendOTP={getOTP}
                      />
                      {OTPSent && (
                        <OTPInput
                          className={"uap_otp_input"}
                          OTP={OTP}
                          setOTP={setOTP}
                          isError={isError}
                          verifyOTP={verifyOTP}
                          loginMessage={loginMessage}
                        />
                      )}

                      <FormControlLabel
                        className={classNames("login_tc_checkbox")}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={(e) => setChecked(e.target.checked)}
                          />
                        }
                        label={
                          <p
                            style={{
                              textAlign: "left",
                              margin: "0",
                            }}
                          >
                            I have read and agreed to VIPApplicant{" "}
                            <TermsAndCondition text="Terms of Use" /> and{" "}
                            <TermsAndCondition text="Privacy Policy" />{" "}
                          </p>
                        }
                        style={{
                          margin: "auto",
                          marginTop: "10px",
                        }}
                      />
                      {/* <Typography sx={{ opacity: 0.6, fontSize: 10 }} variant="caption" >{loginMessage}</Typography> */}
                      {OTPSent && <Timer initialMinute={2} initialSecond={0} />}

                      <Stack
                        spacing={1}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        {!OTPSent && (
                          <CustomButton
                            variant="contained"
                            disabled={mobile.length !== 10}
                            onClick={getOTP}
                            sx={gradient}
                            className={classNames("login_btn_otp")}
                            text={"GET OTP"}
                          />
                        )}
                        {OTPSent && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Button
                              variant="contained"
                              disabled={!(isChecked && OTP.length === 6)}
                              onClick={verifyOTP}
                              sx={gradient}
                              className={classNames("login_btn_otp_verify")}
                            >
                              VERIFY & LOGIN
                            </Button>
                          </div>
                        )}
                        {OTPSent && (
                          <MUILink
                            component="button"
                            variant="body2"
                            onClick={resendOTP}
                            className={classNames("login_btn_otp_resend")}
                          >
                            RESEND OTP
                          </MUILink>
                        )}
                      </Stack>
                    </Stack>
                  ),
                },
                {
                  label: "Login with E-mail",
                  data: (
                    <Stack spacing={5}>
                      <EmailField
                        className={"uap_input_email"}
                        mobile={email}
                        setMobile={setEmail}
                        OTPSent={OTPSent}
                        sendOTP={getOTPEmail}
                      />
                      {OTPSent && (
                        <OTPInput
                          className={"uap_otp_input"}
                          OTP={OTP}
                          setOTP={setOTP}
                          isError={isError}
                          verifyOTP={verifyOTP}
                          loginMessage={loginMessage}
                        />
                      )}

                      <FormControlLabel
                        className={classNames("login_tc_checkbox_email")}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={(e) => setChecked(e.target.checked)}
                          />
                        }
                        label={
                          <p
                            style={{
                              textAlign: "left",
                              margin: "0",
                            }}
                          >
                            I have read and agreed to VIPApplicant{" "}
                            <TermsAndCondition text="Terms of Use" /> and{" "}
                            <TermsAndCondition text="Privacy Policy" />{" "}
                          </p>
                        }
                        style={{
                          margin: "auto",
                          marginTop: "10px",
                        }}
                      />
                      {/* <Typography sx={{ opacity: 0.6, fontSize: 10 }} variant="caption" >{loginMessage}</Typography> */}
                      {OTPSent && <Timer initialMinute={2} initialSecond={0} />}

                      <Stack
                        spacing={1}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        {!OTPSent && (
                          <CustomButton
                            variant="contained"
                            disabled={
                              !email.match(
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
                              ) || !isChecked
                            }
                            onClick={getOTPEmail}
                            sx={gradient}
                            className={classNames("login_btn_otp")}
                            text={
                              isLoading ? (
                                <CircularProgress
                                  disableShrink
                                  size={25}
                                  color="primary"
                                  thickness={5}
                                />
                              ) : (
                                "GET OTP"
                              )
                            }
                          />
                        )}
                        {OTPSent && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Button
                              variant="contained"
                              disabled={!(isChecked && OTP.length === 6)}
                              onClick={verifyOTPEmail}
                              sx={gradient}
                              className={classNames(
                                "login_btn_otp_verify_email"
                              )}
                            >
                              VERIFY & LOGIN
                            </Button>
                          </div>
                        )}
                        {OTPSent && (
                          <MUILink
                            component="button"
                            variant="body2"
                            onClick={resendOTPMail}
                            className={classNames("login_btn_otp_resend")}
                          >
                            RESEND OTP
                          </MUILink>
                        )}
                      </Stack>
                    </Stack>
                  ),
                },
              ]}
            />
          </Grid>
        </Box>
      </Fade>
    </>
  );
};

export default LoginForm;
