export const specialization = [
    "Cardio Thoracic and Vascular Surgery",
    "Visual Communication",
    "Education",
    "Clinical Immunology",
    "Agriculture",
    "Cardiology",
    "Vocational Course",
    "Pediatric Cardio-Thoracic Vascular Surgery",
    "Reproductive Medicine",
    "Mechanical",
    "Special Education",
    "ENT",
    "Homeopathy",
    "Transportation & Automobile Design",
    "Metallurgy",
    "Chemical",
    "Insurance",
    "Plastic Surgery",
    "Advertising/Mass Communication",
    "Marketing",
    "Law",
    "Operations",
    "Ayurveda",
    "Dentistry",
    "Mineral",
    "Industrial & Systems Engineering",
    "Printmaking",
    "Electronics & Embedded Technology",
    "Critical Care Medicine",
    "Petroleum",
    "Finance",
    "Chemistry",
    "Digital Game Design",
    "English",
    "Home science",
    "Cardiac -Anaes.",
    "Endocrinology",
    "Film and Video Communication",
    "Burns & Plastic Surgery",
    "Cardio Thoracic Surgery",
    "Elementary Education",
    "Maternity & Child Health",
    "Electronics",
    "Marine Engineering",
    "Pulmonary Medicine",
    "History",
    "Sociology",
    "Pediatric Anaesthesia",
    "P.S.M",
    "Neonatology",
    "Surgical Oncology",
    "Clinical Haematology",
    "Optometry",
    "Unani Medicine",
    "Economics",
    "Plastic & Reconstructive Surgery",
    "Information Design",
    "Linguistics",
    "Oncology",
    "Emergency Medicine",
    "Neuro Surgery",
    "Virology",
    "Hospitality and Hotel Management",
    "Political Science",
    "Bio-Chemistry/Bio-Technology",
    "Petroleum Engineering",
    "Anthropology",
    "Dairy Technology",
    "Environmental",
    "Music",
    "Journalism / Mass Communication",
    "Graphic/ Web Designing",
    "Lifestyle Accessory Design",
    "Electrical",
    "Engineering",
    "Pulmonary Med. & Critical Care Med.",
    "Botany",
    "Physical Education",
    "Opthalmology",
    "Geriatrics",
    "Physical Medicine & Rehabilitation",
    "Palliative Medicine",
    "Civil",
    "Pediatric Cardiology",
    "Interaction Design",
    "Home Science",
    "Arts & Humanities",
    "Nuclear",
    "Furniture Design",
    "Graphic Design",
    "Endocrine Surgery",
    "PR/ Advertising",
    "Rheumatology",
    "Sports Medicine",
    "Second Attempt",
    "Data Informatics",
    "Aviation",
    "Mechatronics",
    "Export/Import",
    "Sculpture",
    "Plastics",
    "Child & Adolescent Psychiatry",
    "Universal Design",
    "Systems Architecting and Engineering",
    "Textile",
    "Architecture",
    "Venereology",
    "Sanskrit",
    "Neurology",
    "New Media Design",
    "Psychology",
    "Systems",
    "Hand & Micro Surgery",
    "Energy",
    "Fashion Designing/Other Designing",
    "First Attempt",
    "Biology",
    "Hepato Pancreato Biliary Surgery",
    "Hospital Administration",
    "Electronics/Telecommunication",
    "Visual Arts",
    "Production/Industrial",
    "Astrophysics",
    "Exhibition Design",
    "Biophysics",
    "Pediatric Hepatology",
    "Neonatal",
    "Astronautical Engineering",
    "CCM",
    "Tuberculosis & Respiratory Diseases / Pulmonary Medicine",
    "Other Specialization",
    "General Practitioner",
    "Social & Preventive Medicine / Community Medicine",
    "Pediatrics",
    "Medicine",
    "Ceramics",
    "Food Technology",
    "Bio-Physics",
    "ICWA (CMA)",
    "Vascular Surgery",
    "Strategic Design Management",
    "Apparel Design",
    "Dermatology",
    "Mechanical Engineering",
    "Environmental Science",
    "Pharmacy",
    "Orthopaedic",
    "Design For Retail Experience",
    "Radiotherapy",
    "Hotel Management",
    "Mass Communication",
    "Anatomy",
    "Ceramic & Glass Design",
    "Information Technology",
    "Product Design",
    "Aviation Medicine/Aerospace Medicine",
    "Marine",
    "Geology",
    "Haematology Pathology",
    "Hepatology",
    "Chemical Engineering & Materials Science",
    "Textile Design",
    "Film",
    "Philosophy",
    "Lab Medicine",
    "Hospitality Management",
    "Nursing",
    "Vocational Courses",
    "Gyneocology",
    "Cyber Security Engineering",
    "Electrical Engineering",
    "Mining",
    "Painting",
    "Urology/Genito -Urinary Surgery",
    "Infectious Diseases",
    "Paint/Oil",
    "Obstretrics",
    "Veterinary Science",
    "Genetics",
    "Geriatric Mental Health",
    "Hindi",
    "Journalism",
    "Literature",
    "General",
    "Automobile",
    "Gynaecological Oncology",
    "Physics",
    "Computers",
    "Animation Film Design",
    "Immunology",
    "Clinical Pharmacology",
    "Tourism",
    "Bio-Chemistry",
    "Pediatric Oncology",
    "Art History",
    "Gastroenterology",
    "Neuro Radiology",
    "Biomedical",
    "Organ Transplant Anaesthesia & Critical Care",
    "Neuro Anaesthesia",
    "Thoracic Medicine",
    "Management",
    "Pathology",
    "Statistics",
    "Computers & Management",
    "Urology",
    "Traumatology and Surgery",
    "Commerce",
    "Maths",
    "Photography Design",
    "Arts&Humanities",
    "Tropical Medicine",
    "Pass Course",
    "Blood Banking & Immuno. Haem./Imm. Haem. & Blood Trans.",
    "Psychiatry",
    "Organic Chemistry",
    "Pediatric Gastroenterology",
    "Pediatric Surgery",
    "Medical Genetics",
    "Radiology",
    "International Business",
    "Biotechnology",
    "Surgical Gastroenterology/G.I. Surgery",
    "Obstetrics",
    "Nuclear Medicine",
    "Hospitality Administration",
    "Environmental science",
    "PR/Advertising",
    "HR/Industrial Relations",
    "Unani",
    "Anaesthesiology",
    "Microbiology",
    "R&D",
    "Toy & Game Design",
    "Pharmacology",
    "Hand Surgery",
    "Health Administration",
    "CA",
    "Forensic Medicine/Forensic Medicine & Toxicology",
    "Communication",
    "Aerospace & Mechanical Engineering",
    "General Surgery",
    "CS",
    "Pediatric Nephrology",
    "Pursuing",
    "Zoology",
    "Fine Arts",
    "Public Health (Epidemiology)",
    "Thoracic Surgery",
    "Nephrology",
    "Civil & Environmental Engineering",
    "Instrumentation"
]

export const degreeList = [
    "B.Tech",
    "B.E.",
    "MVSC",
    "M.Ed",
    "LLB",
    "M.Com",
    "MBA/PGDM",
    "B.U.M.S",
    "B.B.A/ B.M.S",
    "MM",
    "Other Post Graduate",
    "MS/M.Sc(Science)",
    "BHMS",
    "CA",
    "B.Sc",
    "B.Des.",
    "MBBS",
    "B.A",
    "MDS",
    "BAMS",
    "DM",
    "M.Des.",
    "PG Diploma",
    "B.P.Ed",
    "LLM",
    "Integrated PG",
    "CS",
    "MPHIL",
    "BDS",
    "MCA",
    "Medical-MS/MD",
    "MCM",
    "M.Pharma",
    "BHMCT",
    "M.Tech",
    "ICWA (CMA)",
    "BFA",
    "BMM",
    "Other Doctorate",
    "B.El.Ed",
    "Diploma",
    "B.Arch",
    "BCA",
    "Other Graduate",
    "B.Pharma",
    "B.Ed",
    "Ph.D/Doctorate",
    "M.Ch",
    "MFA",
    "M.Arch",
    "M.A",
    "B.Com",
    "BVSC",
    "BHM"
]
