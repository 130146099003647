import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

const inputStyles = makeStyles((theme) => ({
    inputLabelNoShrink: {
      transform: "translate(2px, -30px) scale(1)",
      color: grey[800],
    },
    root: {
      width: "100%",
      "& .MuiInputBase-root": {
        color: grey[800],
        height: 50,
      },
      "& .MuiFormLabel-root": {
        color: grey[800],
      },
    },
    notchedOutline: {
      borderWidth: "1px",
      boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
      borderColor: "white",
    },
  }));

export default inputStyles