import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import "../../App.css";
import loginBg from "../../assets/login-mobile-bg.svg";
import loginImg from "../../assets/loginSideImg.jpg";
// import viplogo from "../images/vipLogo2.png"
import viplogo from "../../assets/vip.svg";
import LoginForm from "./components/LoginForm";

const useStyles = makeStyles((theme) => ({
  contextGrid: {
    padding: "32px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: 0,
    },
  },
  loginGrid: {
    padding: "32px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "10px !important",
    },
  },
  pageContainer: {
    margin: 0,
    [theme.breakpoints.down("md")]: {
      minHeight: "635px",
      backgroundImage: `url(${loginBg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "bottom center",
    },
  },
  faIcon: {
    color: "green",
    margin: "0 10px 0 20px",
    fontSize: "large",
  },
  typographyBody: {
    fontSize: "large",
    color: "#666",
    fontWeight: 100,
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  headText: {
    marginTop: "40px",
    fontSize: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  span: {
    display: "inline-block",
  },
}));

const LoginPage = ({ setIsAuth, addMobileNo, adduserId }) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        spacing={4}
        height="100vh"
        width={"100%"}
        className={classes.pageContainer}
      >
        <Grid item xs={12} sm={12} md={12} className={classes.contextGrid}>
          <a
            href="https://vipapplicant.com"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            {/* <Button>VIP APPLICANT</Button> */}
            <img src={viplogo} className="Vip-logo-2" alt="logo" />
          </a>
          <Typography variant="h1" className={classes.headText}>
            <span className={classes.span}>
              <FaCheckCircle className={classes.faIcon} />
              ATS Friendly
            </span>{" "}
            <span className={classes.span}>
              <FaCheckCircle className={classes.faIcon} />
              AI Enabled
            </span>{" "}
            <span className={classes.span}>
              <FaCheckCircle className={classes.faIcon} />
              Resume Score
            </span>{" "}
          </Typography>
          <Typography variant="body" className={classes.typographyBody}>
            Build your professional resume and stand out to your recruiters.
          </Typography>
        </Grid>
        {/* <Grid item xs={6}></Grid>
        <Grid item xs={6}></Grid> */}
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          className={classes.loginGrid}
          sx={{ display: { xs: "none", sm: "none", md: "block" } }}
        >
          <Box
            sx={{
              height: "60vh",
              width: "110%",
              backgroundImage: `url(${loginImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "none",
            }}
            display="flex"
            justifyContent="center"
            alignItems="baseline"
          >
            {/* <CustomCollapse /> */}
            {/* <img src={loginImg} className="Login-img" alt="LoginLogo" /> */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.loginGrid}>
          <Box component="main" sx={{ flexGrow: 1 }}>
            <LoginForm
              setIsAuth={setIsAuth}
              addMobileNo={addMobileNo}
              adduserId={adduserId}
            />
          </Box>
        </Grid>
        {/* <PopChat messages={["abcd", "rere"]} /> */}
        <p
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          ©2022 - Kimyo Learning Pvt. Ltd. All Rights Reserved.
        </p>
      </Grid>
    </>
  );
};

export default LoginPage;
