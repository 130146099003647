import { TextField } from "@mui/material";
import classNames from "classnames";
import React from "react";
import customInputStyles from "../../styles/components/CustomInputStyle/index.styles";

export default function CustomInputField({
  startAdornment,
  endAdornment,
  required,
  ...props
}) {
  const classes = customInputStyles();
  return (
    <TextField
      {...props}
      className={
        props.type === "textarea"
          ? classNames(props.className, "text-area")
          : classNames(classes.root, props.className)
      }
      InputLabelProps={{
        shrink: false,
        className: classes.inputLabelNoShrink,
      }}
      value={props.value && props.value}
      required={required}
      InputProps={{
        // readOnly: {OTPSent},
        startAdornment: startAdornment,
        classes: {
          notchedOutline: classes.notchedOutline,
        },
        endAdornment: endAdornment,

        ...props.InputProps,
      }}
    />
  );
}
