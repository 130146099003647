import { Button, Card, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import BulletSuggestion from "./bulletSuggestion";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "95%",
    position: "absolute",
    zIndex: 100,
    right: "100%",
    top: 0,
    [theme.breakpoints.down("sm")]: {
      top: "100%",
      right: "inherit",
    },

    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const SuggestionCard = (props) => {
  const classes = useStyles();
  return (
    <>
      <Grid mb={5} className={classes.cardContainer}>
        <Card
          sx={{
            minHeight: "100%",
            padding: "20px 10px",
            boxShadow: "2px 5px 15px rgba(0, 0, 0, 0.17)",
            borderRadius: "20px",
          }}
        >
          <h3
            style={{
              textAlign: "left",
              marginLeft: 20,
              marginTop: 10,
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Dynamic Suggestions for Bullet Points{" "}
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={props.outSideClick}
              >
                Close
              </Button>
            </span>
            <Divider
              style={{
                marginTop: 10,
              }}
            />
          </h3>
          <BulletSuggestion text={props.text} />
          {/* <List text={props.text} typeOfWork={props.typeOfWork} /> */}
        </Card>
      </Grid>
    </>
  );
};

export default SuggestionCard;
