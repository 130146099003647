export const calcPercentage = (data) => {
   let percentage = 0
   if(data.completion_pd === 1){
       percentage = percentage + 25
   }
   if(data.completion_ed === 1){
    percentage = percentage + 15
}
if(data.completion_ex === 1){
    percentage = percentage + 15
}
if(data.completion_sk === 1){
    percentage = percentage + 15
}
if(data.completion_ac === 1){
    percentage = percentage + 15
}
if(data.completion_xt === 1){
    percentage = percentage + 15
}

return percentage
}