import {
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import React from "react";
import { FaCheckCircle, FaEye, FaPlusCircle, FaUpload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomButton from "../../../components/CustomButton/Index";
import CustomInputField from "../../../components/CustomInputField/Index";
import CustomModal from "../../../components/CustomModal/Index";
import CustomNotification from "../../../components/CustomNotification/Index";
import endPoints from "../../../config/endPoint";
import {
  createResumeThunk,
  fetchResumeByUserID,
} from "../../../redux/Dashboard/dashboard.slice";
import DashboardHeaderStyles from "../../../styles/pages/Dashboard/components/header.style";
import { uniqueNumericId } from "../../../utils/createId";
import { uploadFile } from "../../../utils/s3";
import ResumeCheckComponent from "./resumeChecks";
import ModalIMG from "../../../assets/temp1.jpg";
import CustomSelectField from "../../../components/CustomSelectField/Index";

export default function DashboardHeader({ styles }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = DashboardHeaderStyles();
  const [uploadedResumeID, setResumeID] = React.useState("");
  const [modalError, setError] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [isOpen, setOpen] = React.useState(false);
  const [isUpload, setUpload] = React.useState(false);
  const dashboard = useSelector((state) => state.dashboard);
  const [name, setName] = React.useState("");
  const token = localStorage.getItem("token");
  const [warn, setWarn] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const userPlanLimits = useSelector((state) => state.counter.user_plan_limits);
  const [isClone, setClone] = React.useState(false);
  const creationLimit = userPlanLimits.find(
    (p) => p.feature_name === "resume_creation"
  )?.usage_limit;
  React.useEffect(() => {
    axios
      .post(`${endPoints.getUserProfileDetail}`, { token: token })
      .then((r) => {
        setName(r.data.results[0]?.first_name);
      });
  }, [token]);

  return (
    <Grid
      container
      spacing={4}
      style={{
        padding: "0px 20px",
      }}
    >
      <Grid item md={7} xs={12}>
        <CustomNotification
          variant="filled"
          severity={"warning"}
          alertMessage={`You can only add upto ${creationLimit} resumes. Upgrade your plan to create more resumes.`}
          open={warn}
          onClose={() => setWarn(false)}
        />
        <Typography variant="h3" color="primary" className={classes.h3}>
          YOUR SMART AI-POWERED RESUME BUILDER
        </Typography>
        <Typography variant="h4" className={classes.h4}>
          {name ? `Welcome back, ${name}` : "Welcome!"}
        </Typography>
        <div className={classes.btnContainer}>
          <CustomButton
            className={classNames(classes.btn, `dashboard_create_resume_btn`)}
            icon={<FaPlusCircle className={classes.icon} />}
            text={"Create New Resume"}
            variant="contained"
            color="primary"
            style={styles.customButtonStyle}
            onClick={(e) => {
              if (dashboard.resumes.length < creationLimit) {
                setOpen(true);
              } else {
                setWarn(true);
              }
            }}
          />
          <input
            onClick={(e) => {
              if (dashboard.resumes.length >= creationLimit) {
                setWarn(true);
              }
            }}
            onChange={async (e) => {
              if (dashboard.resumes.length < creationLimit) {
                setUpload(true);
                let file = await uploadFile(e.target.files[0]);
                axios
                  .post(`${endPoints.parsingUrl}`, {
                    awsPath: file.Location,
                    fileName: e.target.files[0].name,
                    token: token,
                  })
                  .then((r) => {
                    setResumeID(r.data.results.data);
                    dispatch(fetchResumeByUserID(token));
                    // setUpload(false);
                    setUploaded(true);
                  })
                  .catch((e) => setUpload(false));
              } else {
                setWarn(true);
              }
            }}
            style={{ display: "none" }}
            id="contained-button-file"
            type={dashboard.resumes.length >= creationLimit ? "text" : "file"}
            accept="application/msword, .docx, application/pdf, text/plain, application/odt, application/rtf"
          />
          <label htmlFor="contained-button-file">
            <CustomButton
              className={classNames(classes.btn, `dashboard_upload_resume_btn`)}
              icon={<FaUpload className={classes.icon} />}
              component="span"
              text={"Upload Your Resume"}
              variant="contained"
              color="warning"
              style={styles.customButtonStyle}
            />
          </label>
        </div>

        <CustomModal
          mbContent={2}
          dialogContent={
            <div
              style={{
                textAlign: "center",
              }}
            >
              {uploaded ? (
                <div className={classes.modalDialogContainer}>
                  <section id="section-1">
                    <img
                      className="modal-image"
                      height={150}
                      src={ModalIMG}
                      alt="not found"
                    />
                    <img
                      className="modal-image"
                      height={150}
                      style={{
                        transform: "rotate(-5deg)",
                      }}
                      src={ModalIMG}
                      alt="not found"
                    />
                    <img
                      className="modal-image"
                      height={150}
                      style={{
                        transform: "rotate(5deg)",
                      }}
                      src={ModalIMG}
                      alt="not found"
                    />
                  </section>
                  <section id="section-2">
                    <FaCheckCircle className={classes.faIcon} />
                    <Typography variant="h3">Your Resume is ready.</Typography>
                    <Typography variant="body">
                      You can now view, edit and download.
                    </Typography>
                    <br />
                    <CustomButton
                      text={
                        !uploaded ? (
                          <CircularProgress
                            size={20}
                            thickness={6}
                            color="warning"
                          />
                        ) : (
                          <>
                            <FaEye
                              style={{
                                marginRight: 10,
                              }}
                            />{" "}
                            Check it now{" "}
                          </>
                        )
                      }
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (uploaded) {
                          setUploaded(false);
                          navigate(`/dashboard/${uploadedResumeID}/create`);
                        }
                      }}
                      style={{
                        marginTop: 10,
                      }}
                    />
                  </section>
                </div>
              ) : (
                <LinearProgress color="warning" />
              )}
            </div>
          }
          dialogContentText={
            !uploaded ? <Typography variant="body">Please wait</Typography> : ""
          }
          dialogTitle={
            !uploaded ? (
              <Typography variant="h4">Uploading...</Typography>
            ) : (
              "Resume Uploaded Successfully !"
            )
          }
          isOpen={isUpload}
          // cancelBtnText={"Close"}
          setOpen={(val) => {
            setUpload(val);
            setUploaded(val);
          }}
        />

        <CustomModal
          isOpen={isOpen}
          confirmBtnColor={"primary"}
          mbContent={2}
          confirmBtnText={"Create"}
          cancelBtnColor={"warning"}
          cancelBtnText={"Cancel"}
          cancelBtnClass={classNames(`dashboard_resume_cancel`)}
          confirmBtnClass={classNames(`dashboard_resume_btn_create`)}
          setOpen={setOpen}
          btnDisable={!title}
          actionCallback={(e) => {
            if (
              dashboard.resumes.findIndex(
                (arr) =>
                  arr.resume_title?.toString()?.toLowerCase() ===
                  title?.toString()?.toLowerCase()
              ) === -1
            ) {
              let resume_id = uniqueNumericId(16);

              dispatch(
                createResumeThunk({
                  token: localStorage.getItem("token"),
                  resume_id: resume_id,
                  resume_title: title,
                  resume_created_at: "now",
                  resume_updated_at: "now",
                })
              ).then(() => {
                if (!!isClone) {
                  axios
                    .post(endPoints.duplicateResume, {
                      token: localStorage.getItem("token"),
                      resumeId: isClone,
                      newResumeId: resume_id,
                    })
                    .then(() => navigate(`/dashboard/${resume_id}/create`));
                } else {
                  navigate(`/dashboard/${resume_id}/create`);
                }
              });
              setOpen(false);
              setError("");
              setTitle("");
            } else {
              // setOpen(false);
              setError("Resume with same title cannot be created twice.");
              // setTitle("");
            }
          }}
          dialogContentText={"Please provide a title for your resume"}
          dialogContent={
            <>
              <CustomInputField
                autoFocus
                fullWidth
                value={title}
                error={modalError}
                className={"uap_dashboard_resume_title_input"}
                onChange={(e) => {
                  setError("");
                  if (e.target.value.length <= 40) {
                    setTitle(e.target.value);
                  }
                }}
                helperText={
                  !modalError
                    ? "More than 40 character is not accepted"
                    : modalError
                }
              />
              <CustomSelectField
                autoFocus
                fullWidth
                mt={7}
                value={isClone}
                onChange={(e) => {
                  setClone(e.target.value);
                }}
                items={dashboard.resumes.map((re) => ({
                  name: re.resume_title,
                  value: re.resume_id,
                }))}
                label="Clone Resume (optional)"
                helperText={"Copy / Clone data from your existing resume."}
              />
            </>
          }
          dialogTitle={"Create New Resume"}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <ResumeCheckComponent />
      </Grid>
    </Grid>
  );
}
