import { Box, Button, Container, Typography } from "@mui/material";
// material
import { styled } from "@mui/material/styles";
import { FaDashcube } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import Page from "../../components/Page";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function PaymentSuccess() {
  return (
    <RootStyle
      title="Payment Successful | VIPApplicant Admin"
      style={{
        // background: "rgba(248,153,29,0.1)",
        background:
          "linear-gradient(30deg, rgba(248,153,29,0.2) 0%, rgba(11,38,87,0.2) 40%, rgba(248,153,29,0.2) 100%)",
      }}
    >
      <Container>
        <Box
          sx={{
            maxWidth: 480,
            margin: "auto",
            textAlign: "center",
            // color: "#fff",
          }}
        >
          <div>
            <Box
              component="img"
              src="/static/purchase-success.svg"
              sx={{ height: 280, mx: "auto" }}
            />
          </div>
          <div>
            <Typography variant="h3" paragraph>
              Thank You
            </Typography>
          </div>
          <Typography sx={{ mb: { xs: 5, sm: 10 } }}>
            Your Plan is upgraded, start using our product by clicking the below
            button
          </Typography>

          <Button
            to="/plans"
            size="large"
            variant="contained"
            color="primary"
            component={RouterLink}
          >
            <FaDashcube /> &emsp; Okay
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
