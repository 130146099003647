import { CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import classNames from "classnames";
import React from "react";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Flip from "react-reveal/Flip";
import { useParams } from "react-router";
import CustomButton from "../../../components/CustomButton/Index";
import CustomDatePicker from "../../../components/CustomDateField/Index";
import CustomInputAndSelectField from "../../../components/CustomInputAndSelectField/Index";
import CustomInputField from "../../../components/CustomInputField/Index";
import CustomModal from "../../../components/CustomModal/Index";
import CustomNotification from "../../../components/CustomNotification/Index";
import endPoints from "../../../config/endPoint";
import gradingSystem, {
  eductionType,
  majorsList,
} from "../../../constants/education.data";
import {
  createEducationThunk,
  deleteEducationThunk,
  getEducationThunk,
  updateEducationThunk,
} from "../../../redux/Dashboard/education.slice";
import _ from "lodash";
import EducationStyles from "../../../styles/pages/Resume/components/education.styles";
import formatDate, { sortArrayWithDate } from "../../../utils/dateFormatter";
import ClassSubComponent from "../components/subComponents/education/class.subComponent";
import CardContainerComponent from "./CardContainer/Index";

export default function EducationComponent({
  value = {},
  onSpecificChange,
  bulkUpdate,
  handleChange,
  clearInput,
  formError,
  setFormError,
  isEditing,
  setIsEditing,
}) {
  var typingTimer;
  var doneTiming = 2000;
  const classes = EducationStyles();
  const [educationList, setEducationList] = React.useState([]);
  const [degrees, setDegrees] = React.useState([]);
  const [majors, setMajors] = React.useState([]);
  const [universities, setUniversities] = React.useState([]);
  const [colleges, setColleges] = React.useState([]);
  const [type, setType] = React.useState("");
  const [isUpdate, setUpdate] = React.useState("");
  const { resumeId } = useParams();
  const token = localStorage.getItem("token");

  React.useEffect(() => {
    if (!_.isEmpty(value)) {
      setIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange]);

  // const month = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];

  const dispatch = useDispatch();
  const education = useSelector((state) => state.education);

  const getCollege = (v) => {
    axios
      .post(`${endPoints.getCollege}`, {
        lib_inst_univ_name: v,
        options: "lib_inst_name",
        token,
      })
      .then((r) => {
        setColleges(r.data.results);
      });
  };

  React.useEffect(() => {
    dispatch(
      getEducationThunk({
        token: localStorage.getItem("token"),
        resume_id: resumeId,
      })
    );
  }, [dispatch, resumeId]);

  const checkGraduation = (name) => {
    switch (name) {
      case "CGPA out of 4":
        return "/4";

      case "CGPA out of 10":
        return "/10";
      default:
        return "%";
    }
  };

  const renderEducation = (name) => {
    switch (name) {
      case "Class 12":
        return (
          <ClassSubComponent
            value={value}
            onSpecificChange={onSpecificChange}
            handleChange={handleChange}
          />
        );

      case "Class 10":
        return (
          <ClassSubComponent
            value={value}
            onSpecificChange={onSpecificChange}
            handleChange={handleChange}
          />
        );

      default:
        return (
          <>
            <Grid sm={12} item xs={12}>
              <CustomInputAndSelectField
                value={value.ed_degree_qual}
                name={"ed_degree_qual"}
                changeCallBack={(e, v) => {
                  onSpecificChange("ed_degree_qual", v);
                  handleChange(
                    {
                      name: "ed_major_special",
                      value: "",
                    },
                    true
                  );
                  if (v === "Class 10" || v === "Class 12") {
                    setMajors(majorsList);
                  } else {
                    axios
                      .post(`${endPoints.getSpecialization}`, {
                        getAll: false,
                        lib_degspez_degree: v,
                        options: "lib_degspez_spez",
                        token: token,
                      })
                      .then((r) => {
                        setMajors(r.data.results?.sort());
                      });
                  }
                }}
                label={"DEGREE/QUALIFICATION"}
                required={true}
                fullWidth
                options={degrees
                  .map((degree) => degree.lib_degspez_degree)
                  .sort()}
                freeSolo={false}
              />
              {value.ed_degree_qual?.toLowerCase()?.includes("other") ? (
                <CustomInputField
                  style={{
                    marginTop: "20px",
                  }}
                  value={value.ed_degree_qual_other}
                  onChange={(e) => {
                    onSpecificChange("ed_degree_qual_other", e.target.value);
                  }}
                  required={true}
                  name="ed_degree_qual_other"
                  fullWidth
                  helperText="Provide Other qualification"
                />
              ) : (
                ""
              )}
            </Grid>

            <Grid sm={12} item xs={12}>
              <CustomInputAndSelectField
                value={value.ed_major_special}
                name={"ed_major_special"}
                changeCallBack={(e, v) =>
                  onSpecificChange("ed_major_special", v)
                }
                label={"MAJOR/SPECIALISATION"}
                required={true}
                fullWidth
                options={majors.map((major) => major.lib_degspez_spez).sort()}
                freeSolo={false}
              />
              {value.ed_major_special?.toLowerCase()?.includes("other") ? (
                <CustomInputField
                  style={{
                    marginTop: "20px",
                  }}
                  value={value.ed_major_special_other}
                  onChange={(e) =>
                    onSpecificChange("ed_major_special_other", e.target.value)
                  }
                  required={true}
                  name="ed_major_special_other"
                  fullWidth
                  helperText="Provide Other Major/Specialisation"
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid sm={12} item xs={12}>
              <CustomInputAndSelectField
                freeSolo={true}
                value={value.ed_university_name}
                name={"ed_university_name"}
                textProps={{
                  onKeyDown: (e) => {
                    clearTimeout(typingTimer);
                    typingTimer = setTimeout(function () {
                      axios({
                        method: "POST",
                        url: `${endPoints.getUniversity}`,
                        headers: {
                          "Content-Type": "application/json",
                        },
                        data: JSON.stringify({
                          token,
                          uniPrefix: e.target.value,
                          options: "lib_inst_univ_name",
                        }),
                      }).then((r) => setUniversities(r.data.results?.sort()));
                    }, doneTiming);
                  },
                  onChange: (e) => {
                    handleChange(
                      {
                        name: "ed_university_name",
                        value: e.target.value,
                      },
                      true
                    );
                  },
                }}
                changeCallBack={(e, v) => {
                  handleChange(
                    {
                      name: "ed_university_name",
                      value: v,
                    },
                    true
                  );
                  // getCollege(v)
                }}
                required
                label={"UNIVERSITY NAME"}
                fullWidth
                options={universities.map(
                  (university) => university.lib_inst_univ_name
                )}
              />
            </Grid>
            <Grid sm={12} item xs={12}>
              <CustomInputAndSelectField
                value={value.ed_college_name}
                name={"ed_college_name"}
                textProps={{
                  onChange: (e) => {
                    onSpecificChange("ed_college_name", e.target.value);
                  },
                }}
                onFocus={(e) => getCollege(value.ed_university_name)}
                changeCallBack={(e, v) => {
                  onSpecificChange("ed_college_name", v);
                }}
                label={"COLLEGE NAME"}
                required={true}
                fullWidth
                options={colleges.map((college) => college.lib_inst_name)}
                freeSolo={true}
              />
            </Grid>
          </>
        );
    }
  };

  const handleDeleteResume = (token, resume_id) => {
    dispatch(deleteEducationThunk({ token, resume_id, ed_id: type }));
    setType("");
  };

  const handleUpdate = () => {
    setIsEditing(false);
    dispatch(updateEducationThunk({ body: value, token }));
    setUpdate("");
    clearInput();
  };

  const handleSave = (e) => {
    if (Object.keys(value).length !== 0) {
      setEducationList([...educationList, value]);
      dispatch(
        createEducationThunk({
          token: localStorage.getItem("token"),
          resume_id: resumeId,
          ...value,
        })
      );
      clearInput();
    } else {
      alert("Please add some Data");
    }
  };
  if (education.loading)
    return (
      <div className={classes.loader}>
        <CircularProgress disableShrink size={30} thickness={6} />
      </div>
    );
  return (
    <>
      <CustomNotification
        severity={"error"}
        variant="filled"
        alertMessage={
          <>
            Education is important for recruiters
            <br />
            Please add atleast one.
          </>
        }
        open={Object.keys(formError).length ? true : false}
        onClose={setFormError}
      />

      <CustomModal
        isOpen={!!type}
        setOpen={setType}
        confirmBtnColor={"error"}
        confirmBtnText={"Delete"}
        cancelBtnColor={"primary"}
        cancelBtnText={"Cancel"}
        dialogTitle={"Deleting Education"}
        mbContent={2}
        actionCallback={(e) =>
          handleDeleteResume(localStorage.getItem("token"), resumeId)
        }
        dialogContentText={
          "Please note the the action will delete the resume permanently."
        }
        dialogContent={"Are you sure you want to delete?"}
      />
      <Grid className={classes.container} container spacing={2} rowGap={4}>
        <Grid item sm={5} className={classes.contentLeft} xs={12}>
          <Box id="cardContainer">
            {/* <iframe
              style={{
                width: "100%",
              }}
              height="315"
              src="https://www.youtube.com/embed/5fA1bDJf6ns"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe> */}
            <Typography className={classes.helperTypo} variant="h5">
              ALL EDUCATION
              <Typography
                className={classes.helperTypo}
                color="#000"
                variant="body2"
              >
                Add your education details - one degree at a time.
              </Typography>
            </Typography>

            {!education.educations.length
              ? ""
              : // <Typography className={classes.typography} variant="h5">
                //   Start adding your Education qualification
                // </Typography>
                sortArrayWithDate(
                  education.educations.filter((edu) => {
                    return parseInt(edu.ed_id) !== parseInt(isUpdate);
                  }),
                  "ed_graduate_year"
                ).map((a, idx) => {
                  return (
                    <Flip bottom key={idx}>
                      <CardContainerComponent
                        cardClass={classNames(
                          `edu_card_number_${idx}`,
                          "edu_card_item"
                        )}
                        uapEditClassName="edu_edit_btn"
                        uapDeleteClassName={"edu_delete_btn"}
                        headTitle={a.ed_type}
                        editCallback={(e) => {
                          setUpdate(a.ed_id);
                          bulkUpdate(a);
                        }}
                        deleteCallback={(e) => {
                          setType(a.ed_id);
                        }}
                        title={
                          !a.ed_major_special_other
                            ? a.ed_major_special
                            : a.ed_major_special_other
                        }
                        subtitle={
                          a.ed_grade_marks
                            ? `${a.ed_grade_marks}${checkGraduation(
                                a.ed_grading_sys
                              )}`
                            : ""
                        }
                        title2={
                          a.ed_college_name
                            ? a.ed_college_name
                            : a.ed_school_name
                        }
                        subtitle2={formatDate(new Date(a.ed_graduate_year))}
                      />
                    </Flip>
                  );
                })}
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          container
          spacing={3}
          className={classes.contentRight}
          rowGap={4}
        >
          <Grid sm={12} item xs={12}>
            <CustomInputAndSelectField
              value={value.ed_type}
              changeCallBack={(e, v) => {
                onSpecificChange("ed_type", v);
                handleChange(
                  {
                    name: "ed_degree_qual",
                    value: "",
                  },
                  true
                );
                handleChange(
                  {
                    name: "ed_major_special",
                    value: "",
                  },
                  true
                );
                axios
                  .post(`${endPoints.getDegrees}`, {
                    getAll: false,
                    lib_degspez_edu: v,
                    options: "lib_degspez_degree",
                    token: token,
                  })
                  .then((r) => {
                    setDegrees(r.data.results);
                  });
              }}
              name={"ed_type"}
              label={"SELECT EDUCATION"}
              required={true}
              fullWidth
              options={eductionType.map((edu) => edu)}
              freeSolo={false}
            />
          </Grid>
          {renderEducation(value.ed_type)}
          <Grid sm={12} item xs={12}>
            <CustomDatePicker
              maxDate={"1 Dec 2030"}
              minDate={"1 Jan 1970"}
              value={value.ed_graduate_year ? value.ed_graduate_year : null}
              name={"ed_graduate_year"}
              date={new Date()}
              changeCallBack={(e, v) => {
                handleChange(
                  {
                    name: "ed_graduate_year",
                    value: v,
                  },
                  true
                );
                // onSpecificChange("ed_graduate_year", v);
              }}
              label={"GRADUATION YEAR"}
              required={true}
              fullWidth
            />
          </Grid>
          <Grid sm={6} item xs={12}>
            <CustomInputAndSelectField
              value={value.ed_grading_sys}
              name={"ed_grading_sys"}
              changeCallBack={(e, v) => {
                onSpecificChange("ed_grading_sys", v);
                handleChange(
                  {
                    name: "ed_grade_marks",
                    value: "",
                  },
                  true
                );
              }}
              label={"Grading System"}
              fullWidth
              options={gradingSystem.map((grade) => grade.label)}
              freeSolo={false}
            />
          </Grid>
          <Grid sm={6} item xs={12}>
            <CustomInputField
              value={value.ed_grade_marks}
              name={"ed_grade_marks"}
              onChange={(e) => {
                if (value.ed_grading_sys === "Percentage(%)") {
                  if (
                    (e.target.value.match(/^[0-9.]{0,5}?$/) &&
                      parseFloat(e.target.value) <= 100 &&
                      !e.target.value.toString().includes("..")) ||
                    e.target.value.length === 0
                  ) {
                    handleChange(e);
                  }
                } else if (value.ed_grading_sys === "CGPA out of 4") {
                  if (
                    (e.target.value.match(/^[0-9.]{0,4}?$/) &&
                      parseFloat(e.target.value) <= 4 &&
                      !e.target.value.toString().includes("..")) ||
                    e.target.value.length === 0
                  ) {
                    handleChange(e);
                  }
                } else {
                  if (
                    (e.target.value.match(/^[0-9.]{0,4}?$/) &&
                      parseFloat(e.target.value) <= 10 &&
                      !e.target.value.toString().includes("..")) ||
                    e.target.value.length === 0
                  ) {
                    handleChange(e);
                  }
                }
              }}
              label={"CGPA / Percentage"}
              fullWidth
            />
          </Grid>
          <CustomButton
            text={isUpdate ? "Update" : "Save"}
            disabled={
              !(
                value.ed_type &&
                (value.ed_degree_qual
                  ? value.ed_degree_qual &&
                    value.ed_college_name &&
                    value.ed_university_name
                  : value.ed_school_name) &&
                value.ed_graduate_year &&
                value.ed_major_special
              )
            }
            onClick={isUpdate ? handleUpdate : handleSave}
            className={
              isUpdate
                ? classNames(classes.actionBtn, "uap_edu_update_btn")
                : classNames(
                    classes.actionBtn,
                    "uap_edu_save_btn",
                    "css-1ur7ajc"
                  )
            }
            variant={isUpdate ? "outlined" : "contained"}
            color={isUpdate ? "warning" : "primary"}
            icon={<FaPlusCircle />}
          />

          <div variant="body2" className={classes.helperDiv}>
            {isUpdate ? "Update" : "Save"} your details before moving on
          </div>
        </Grid>
      </Grid>
    </>
  );
}
