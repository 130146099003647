import React from "react";
import EmptyImg from "../../assets/empty.svg";

export default function EmptyPreview() {
  return (
    <div>
      <img
        src={EmptyImg}
        alt="not found"
        style={{ height: "45vh" }}
        width={"100%"}
        height={"100%"}
      />
    </div>
  );
}
